import PropTypes from "prop-types";

export default function Location({ fill }) {
  return (
    <svg
      id="Location-1"
      focusable="false"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      fill={fill}
      aria-hidden="true"
      role="presentation"
    >
      <path d="M12,2A7,7,0,0,0,5,9c0,5.25,7,13,7,13s7-7.75,7-13A7,7,0,0,0,12,2Zm0,9.5A2.5,2.5,0,1,1,14.5,9,2.5,2.5,0,0,1,12,11.5Z" />
    </svg>
  );
}

Location.propTypes = {
  fill: PropTypes.string,
};
