export const TIME_FORMAT = "H:mm";
export const AM_PM_TIME_FORMAT = "ha";
export const HH_MM_TIME_FORMAT = "HH:mm";
export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const ALTERNATIVE_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const EUROPE_LONDON_TIME_ZONE = "Europe/London";
export const DEFAULT_DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm:ss";
export const DAY_MONTH_DATE_TIME_FORMAT = "dddd DD MMMM";
export const DEFAULT_DATE_FORMAT_REGEXP = /^\d{2}\/\d{2}\/\d{4}/;
