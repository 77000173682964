import PropTypes from "prop-types";

export default function UpgradeDelivery({ fill }) {
  return (
    <svg
      id="Upgrade-delivery-1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="284.000000pt"
      height="284.000000pt"
      viewBox="0 0 284.000000 284.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,284.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path d="M708 2017 c-164 -89 -296 -166 -295 -171 2 -5 158 -98 348 -206 l344 -197 3 -301 2 -300 -35 -22 c-31 -19 -40 -20 -65 -10 -60 25 -59 24 -62 295 l-3 249 -290 168 c-159 92 -293 168 -297 168 -5 0 -8 -152 -8 -338 0 -255 3 -342 13 -355 6 -9 155 -100 331 -202 248 -146 324 -186 346 -183 43 6 649 370 655 393 3 11 4 171 3 357 l-3 336 -190 -114 c-104 -63 -196 -114 -203 -114 -8 0 -27 9 -43 21 -27 19 -30 26 -27 67 l3 46 203 120 c111 66 202 123 202 126 0 12 -588 330 -611 329 -13 0 -158 -73 -321 -162z" />
        <path
          fill="#000000"
          d="M2247 1853 c-4 -3 -7 -89 -7 -189 l0 -184 -187 -2 -188 -3 -3 -29 c-2 -16 2 -32 10 -37 7 -5 93 -9 191 -9 l177 0 0 -185 0 -185 25 -6 c51 -13 55 2 55 197 l0 179 179 0 c195 0 210 4 197 55 l-6 25 -185 0 -185 0 0 174 c0 96 -3 181 -6 190 -6 16 -54 22 -67 9z"
        />
      </g>
    </svg>
  );
}

UpgradeDelivery.propTypes = {
  fill: PropTypes.string,
};
