import PropTypes from "prop-types";

import { ParcelTypes } from "../../../../../models/types";

const Status = ({ routeStatus, textClassName = "", statusClassName = "" }) => (
  <div className={statusClassName}>
    <span className={textClassName}>{routeStatus}</span>
  </div>
);

Status.propTypes = {
  routeStatus: ParcelTypes.Route.routeStatus,
  textClassName: PropTypes.string,
  statusClassName: PropTypes.string,
};

export default Status;
