import PropTypes from "prop-types";

export default function CallMe({ fill }) {
  return (
    <svg
      id="Call-me-1"
      xmlns="http://www.w3.org/2000/svg"
      width="284"
      height="284"
      viewBox="0 0 284 284"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.228 60C136.187 83.6377 127.515 111.48 127.515 141.297C127.515 171.113 136.187 198.956 151.228 222.593H143.287C141.47 222.593 140.691 222.593 138.922 222.593C124.968 198.585 117 170.842 117 141.297C117 111.751 124.968 84.0085 138.922 60C140.624 59.9996 142.043 60.0005 143.287 60.0002L151.228 60Z"
        fill={fill}
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="132"
        y="59"
        width="35"
        height="164"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M167 60C160.085 70.8668 154.517 82.6222 150.518 95.0496C145.818 109.655 143.287 125.188 143.287 141.297C143.287 156.905 145.663 171.972 150.086 186.179C154.112 199.111 159.833 211.329 167 222.593H159.059C157.242 222.593 156.462 222.593 154.694 222.593C148.086 211.224 142.821 199.017 139.108 186.179C134.98 171.905 132.772 156.851 132.772 141.297C132.772 125.246 135.124 109.727 139.509 95.0496C143.196 82.7101 148.321 70.9655 154.694 60C156.396 59.9996 157.815 60.0005 159.059 60.0002L167 60Z"
          fill="#414042"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="132.772" y="60" width="35.0496" height="31.5446" fill={fill} />
      </g>
      <mask
        id="mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="132"
        y="61"
        width="35"
        height="163"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M167 61.0517C160.085 71.9184 154.517 83.6739 150.518 96.1012C145.818 110.706 143.287 126.239 143.287 142.348C143.287 157.956 145.663 173.024 150.086 187.23C154.112 200.162 159.833 212.381 167 223.645H159.059C157.242 223.645 156.462 223.645 154.694 223.645C148.086 212.276 142.821 200.069 139.108 187.23C134.98 172.957 132.772 157.902 132.772 142.348C132.772 126.297 135.124 110.778 139.509 96.1012C143.196 83.7617 148.321 72.0172 154.694 61.0517C156.396 61.0512 157.815 61.0521 159.059 61.0519L167 61.0517Z"
          fill="#414042"
        />
      </mask>
      <g mask="url(#mask1)">
        <rect
          x="132.772"
          y="191.086"
          width="35.0496"
          height="31.5446"
          fill={fill}
        />
      </g>
    </svg>
  );
}

CallMe.propTypes = {
  fill: PropTypes.string,
};
