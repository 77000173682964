export default function PhotoID() {
  return (
    <svg
      focusable="false"
      viewBox="0 0 48 32"
      width="48px"
      height="32px"
      aria-hidden="true"
      role="presentation"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="9" transform="translate(-524.000000, -394.000000)">
          <g id="Group-17" transform="translate(466.000000, 347.000000)">
            <g id="Group-16" transform="translate(10.000000, 37.000000)">
              <g id="Page-1" transform="translate(48.000000, 10.000000)">
                <g id="Group-19">
                  <g id="Group-18">
                    <path
                      d="M0,32 L48,32 L48,0 L0,0 L0,32 Z M2,29.999 L46,29.999 L46,1.999 L2,1.999 L2,29.999 Z"
                      id="Fill-1"
                      fill="rgb(65, 64, 66)"
                    />
                    <path
                      d="M19.117,25.9997 C18.564,25.9997 18.117,25.5527 18.117,24.9997 C18.117,21.6587 15.399,18.9407 12.058,18.9407 C8.718,18.9407 6,21.6587 6,24.9997 C6,25.5527 5.553,25.9997 5,25.9997 C4.447,25.9997 4,25.5527 4,24.9997 C4,20.5567 7.615,16.9407 12.058,16.9407 C16.502,16.9407 20.117,20.5567 20.117,24.9997 C20.117,25.5527 19.67,25.9997 19.117,25.9997"
                      id="RED_IMPORTANT"
                      fill="#E30238"
                    />
                  </g>
                  <g
                    id="Group-10"
                    transform="translate(4.000000, 5.000000)"
                    fill="rgb(65, 64, 66)"
                  >
                    <path
                      d="M10.491,11.6481 C10.145,11.6481 9.81,11.4691 9.625,11.1491 C9.347,10.6701 9.512,10.0591 9.989,9.7831 C11.218,9.0721 11.98,7.7511 11.98,6.3341 C11.98,4.1381 10.195,2.3521 8,2.3521 C5.804,2.3521 4.018,4.1381 4.018,6.3341 C4.018,7.7501 4.781,9.0711 6.008,9.7821 C6.486,10.0581 6.649,10.6701 6.372,11.1481 C6.095,11.6261 5.481,11.7901 5.006,11.5121 C3.163,10.4451 2.018,8.4601 2.018,6.3341 C2.018,3.0361 4.702,0.3521 8,0.3521 C11.298,0.3521 13.98,3.0361 13.98,6.3341 C13.98,8.4611 12.835,10.4471 10.991,11.5131 C10.833,11.6051 10.661,11.6481 10.491,11.6481"
                      id="Fill-2"
                    />
                    <path
                      d="M34.9998,7.0007 L20.9998,7.0007 C20.4468,7.0007 19.9998,6.5537 19.9998,6.0007 C19.9998,5.4477 20.4468,5.0007 20.9998,5.0007 L34.9998,5.0007 C35.5528,5.0007 35.9998,5.4477 35.9998,6.0007 C35.9998,6.5537 35.5528,7.0007 34.9998,7.0007"
                      id="Fill-6"
                    />
                    <path
                      d="M28.9998,11.0007 L20.9998,11.0007 C20.4468,11.0007 19.9998,10.5537 19.9998,10.0007 C19.9998,9.4477 20.4468,9.0007 20.9998,9.0007 L28.9998,9.0007 C29.5528,9.0007 29.9998,9.4477 29.9998,10.0007 C29.9998,10.5537 29.5528,11.0007 28.9998,11.0007"
                      id="Fill-8"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
