import { Col } from "react-bootstrap";
import { compose } from "recompose";
import { propTypes as ReduxFormPropTypes } from "redux-form";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import CardWithTitle from "../../../../components/CardWithTitle";
import { UPDATE_CONTACT_DETAILS } from "../../../../constants/analytics";
import { UpdateContactDetails as Fields } from "../../../../constants/forms";
import { UPDATE_DETAILS } from "../../../../constants/strings";
import { deliveryEditValidators } from "../../../../models/validators";
import { onUpdateContactDetailsSubmit } from "../../actions";
import EditPanel from "../../components/EditPanel";
import { hadleInputFocus, withParcelEdit } from "../../hocs";

const UpdateContactDetails = ({ handleSubmit, onSubmit, error, ...rest }) => (
  <Col className="col-sm-12 p-0">
    <CardWithTitle title={UPDATE_DETAILS}>
      <EditPanel onSubmit={handleSubmit(onSubmit)} error={error} {...rest} />
    </CardWithTitle>
  </Col>
);

UpdateContactDetails.defaultProps = {
  onSubmit: () => null,
};

UpdateContactDetails.propTypes = {
  ...ReduxFormPropTypes,
};

export default compose(
  withParcelEdit({
    onSubmit: onUpdateContactDetailsSubmit,
    validate: deliveryEditValidators.updateContactDetails,
  }),
  withTrack(UPDATE_CONTACT_DETAILS.LOAD),
  withTrackProps({
    onSubmit: UPDATE_CONTACT_DETAILS.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      switch (fieldName) {
        case Fields.CONTACT_NAME:
          return UPDATE_CONTACT_DETAILS.CONTACT_NAME_INPUT;
        case Fields.MOBILE:
          return UPDATE_CONTACT_DETAILS.MOBILE_NUMBER_INPUT;
        case Fields.EMAIL:
          return UPDATE_CONTACT_DETAILS.EMAIL_ADDRESS_INPUT;
        default:
          break;
      }
    },
  }),
  hadleInputFocus
)(UpdateContactDetails);
