import PropTypes from "prop-types";

export default function Destroy({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="284"
      height="284"
      viewBox="0 0 284 284"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62 52C56.4772 52 52 56.4772 52 62V222C52 227.523 56.4772 232 62 232H222C227.523 232 232 227.523 232 222V62C232 56.4772 227.523 52 222 52H62ZM142 72.5714L202.429 107.46V177.237L142 212.125L81.5714 177.237V107.46L142 72.5714ZM90.6096 112.678V172.019L142 201.689L193.39 172.019V112.678L142 83.0079L90.6096 112.678ZM150.542 142.479L171.038 121.834L163.15 113.889L142.654 134.534L122.655 114.388L114.767 122.333L134.767 142.479L113.851 163.547L121.739 171.493L142.654 150.424L164.066 171.992L171.954 164.046L150.542 142.479Z"
        fill={fill}
      />
    </svg>
  );
}

Destroy.propTypes = {
  fill: PropTypes.string,
};
