const Traffic = props => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
    <rect width="48" height="48" fill="white" />
    <path
      d="M41.5949 41.6374H6.40551C5.53721 41.6374 4.76015 41.1885 4.32561 40.437C3.89106 39.6856 3.89186 38.7868 4.32561 38.0354L21.9203 7.56039C22.354 6.80894 23.1319 6.35999 24.0002 6.35999C24.8685 6.35999 25.6456 6.80894 26.0801 7.56039L43.6748 38.0354C44.1085 38.7876 44.1085 39.6864 43.674 40.437C43.2402 41.1893 42.4624 41.6374 41.5949 41.6374ZM24.0002 7.96052C23.861 7.96052 23.5152 7.99973 23.3072 8.36066L5.71167 38.8357C5.5028 39.1966 5.64205 39.5167 5.71167 39.6367C5.7813 39.7568 5.98777 40.0369 6.40551 40.0369H41.5949C42.0118 40.0369 42.2183 39.7568 42.2879 39.6367C42.3576 39.5167 42.4968 39.1974 42.2879 38.8357L24.6932 8.36066C24.4852 7.99973 24.1394 7.96052 24.0002 7.96052Z"
      fill="#414042"
    />
    <path
      d="M21.4984 29.6587C21.2215 29.6587 20.9968 29.4246 20.9968 29.1361V26.5227C20.9968 26.2342 21.2215 26 21.4984 26C21.7753 26 22 26.2342 22 26.5227V29.1361C22 29.4251 21.7753 29.6587 21.4984 29.6587Z"
      fill="#414042"
    />
    <path
      d="M10.5016 34.3627H13.7L21.8294 26.9152C22.0376 26.7245 22.0581 26.3947 21.8751 26.1778C21.692 25.9608 21.3755 25.9394 21.1674 26.1302L13.3219 33.3174H10.5016C10.2247 33.3174 10 33.551 10 33.8401C10 34.1291 10.2247 34.3627 10.5016 34.3627Z"
      fill="#414042"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4963 38C15.5675 38 14.786 37.331 14.5726 36.4309H10.5016C10.2247 36.4309 10 36.1973 10 35.9083C10 35.6192 10.2247 35.3856 10.5016 35.3856H14.5866C14.8183 34.5137 15.5867 33.872 16.4963 33.872C17.5887 33.872 18.477 34.7976 18.477 35.936C18.477 37.0744 17.5882 38 16.4963 38ZM16.4963 34.9173C15.9571 34.9173 15.5182 35.3741 15.5182 35.936C15.5182 36.4979 15.9571 36.9547 16.4963 36.9547C17.0355 36.9547 17.4738 36.4979 17.4738 35.936C17.4738 35.3741 17.035 34.9173 16.4963 34.9173Z"
      fill="#414042"
    />
    <path d="M37.0596 36.602C37.0154 36.602 36.9708 36.5966 36.9261 36.5855C36.6526 36.5164 36.4899 36.2521 36.5633 35.9935L36.9713 34.5583C36.9928 34.4829 36.8307 34.3529 36.6603 34.2587L35.4846 33.744C34.9524 33.4671 34.6481 32.9694 34.6589 32.4305L34.6779 31.5153C34.6892 31.0958 34.6117 30.3729 33.9656 30.21L30.401 29.3105L29.9745 30.2617C29.6117 31.0697 28.8532 31.5824 28.0465 31.5824C27.9854 31.5824 27.9243 31.5795 27.8627 31.5733L27.3044 31.5191C27.0221 31.4916 26.8174 31.2543 26.8461 30.989C26.8759 30.7237 27.1273 30.5294 27.4096 30.558L27.9679 30.6121C28.3785 30.6498 28.8188 30.354 29.0292 29.8853L29.4001 29.058L29.1375 28.9917C28.6017 28.8569 28.0562 29.3025 27.5379 29.7698L26.7014 30.5686C26.295 30.9581 25.7084 31.1451 25.1336 31.0697L21.9826 30.5681C21.8015 30.5633 21.6332 30.6135 21.6137 30.6831L21.0091 32.808C20.9357 33.066 20.6545 33.2192 20.3805 33.1496C20.1064 33.0805 19.9443 32.8157 20.0176 32.5576L20.6222 30.4328C20.7766 29.8891 21.3427 29.5605 22.0678 29.6045L22.1227 29.6103L25.2896 30.1143C25.5267 30.1443 25.7889 30.0631 25.9696 29.8901L26.8169 29.0811C27.3306 28.6172 28.2676 27.772 29.4023 28.0576L34.2304 29.2759C35.1839 29.5165 35.735 30.3613 35.7032 31.5365L35.6842 32.4484C35.6806 32.6287 35.7822 32.7954 35.9506 32.8829L37.1381 33.4038C38.0634 33.9161 38.049 34.5018 37.9617 34.8077L37.5538 36.2429C37.4937 36.4594 37.2864 36.602 37.0596 36.602Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9603 35.2764C23.1353 35.3209 23.3123 35.3426 23.4883 35.3426C23.8394 35.3426 24.1858 35.2556 24.4983 35.0855C24.8152 34.9134 25.0712 34.6697 25.247 34.3778L31.8885 36.0536C31.8706 36.9104 32.4729 37.7031 33.3908 37.9348C33.5653 37.9792 33.7413 38 33.9142 38C34.8098 38 35.6309 37.437 35.8726 36.585C36.0127 36.0931 35.9408 35.5784 35.6699 35.1367C35.3999 34.6955 34.9632 34.3795 34.4397 34.2476C33.9178 34.1152 33.3708 34.1828 32.9017 34.4384C32.5934 34.606 32.3426 34.8414 32.1673 35.1233L25.5124 33.444C25.5151 33.1085 25.4229 32.7768 25.2398 32.4784C24.9694 32.0372 24.5327 31.7216 24.0097 31.5897C23.4868 31.4573 22.9402 31.5254 22.4717 31.7801C22.0032 32.0348 21.6675 32.4465 21.5274 32.9389C21.238 33.9557 21.881 35.0043 22.9603 35.2764ZM24.4569 33.6559C24.5182 33.419 24.4805 33.1737 24.3515 32.9621C24.2176 32.7447 24.002 32.589 23.7439 32.5238C23.2107 32.3866 22.6611 32.6872 22.5184 33.1892C22.3757 33.6909 22.6934 34.2084 23.2261 34.3433C23.4842 34.4085 23.7542 34.3742 23.9851 34.2495C24.2101 34.1269 24.3726 33.9316 24.4451 33.6974C24.4467 33.6904 24.4486 33.6834 24.4506 33.6764C24.4525 33.6695 24.4546 33.6627 24.4569 33.6559ZM33.9137 35.1483C33.7403 35.1483 33.5694 35.1914 33.4149 35.275C33.184 35.4011 33.0182 35.6045 32.9489 35.8476C32.8797 36.0907 32.9156 36.3444 33.0485 36.5623C33.1824 36.7798 33.398 36.9359 33.6561 37.0011C34.1867 37.135 34.7379 36.8368 34.8816 36.3347C35.0243 35.8331 34.7071 35.3155 34.1744 35.1807C34.0882 35.1595 34.0005 35.1483 33.9137 35.1483Z"
    />
  </svg>
);

export default Traffic;
