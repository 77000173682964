import { connect } from "react-redux";
import { compose, withProps } from "recompose";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";

import { fetchRelatedParcels } from "../../Parcel/actions";
import { getRelatedParcels } from "../../Parcel/selectors";
import { getRelatedParcelsInitialValues } from "../view/ReturnToSender/model";

export const withRelatedParcelsInitialValues = compose(
  connect(null, (dispatch, { parcel }) => ({
    findParcels: () => dispatch(fetchRelatedParcels(parcel.parcelCode)),
  })),
  withAppLoader({
    loadFn: async ({ findParcels }) => {
      await findParcels();
    },
  }),
  connect(state => ({
    relatedParcels: getRelatedParcels(state),
  })),
  withProps(({ relatedParcels, additionalInitialValues }) => {
    const relatedParcelsInitialValues =
      getRelatedParcelsInitialValues(relatedParcels);
    return {
      additionalInitialValues: {
        ...additionalInitialValues,
        ...relatedParcelsInitialValues,
      },
      relatedParcelsInitialValues,
    };
  })
);
