import { keys, zipWith } from "lodash";

import { isParcelCode } from "../../../../models/parcel";

export const formInitialValues = {
  contactName: "",
  email: "",
  mobile: "",
  allCheckboxes: true,
  isInternationalParcel: false,
};

export const formatDataForRequest = values => {
  const {
    contactName,
    email,
    mobile,
    sendersReference,
    instructions,
    deliveryDate,
    serviceCode,
    freeOfCharge,
    ...rest
  } = values;

  const parcelCodes = keys(rest).filter(isParcelCode);

  return {
    sendersReference,
    instructions,
    deliveryDate,
    parcelCodes,
    notificationDetails: {
      contactName,
      email,
      mobile,
    },
    serviceCode,
    freeOfCharge,
  };
};

export const formatServices = ({ upgradeDescription, upgradeServiceCode }) =>
  zipWith(upgradeDescription, upgradeServiceCode, (label, value) => ({
    label,
    value,
  }));
