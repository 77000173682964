import { compose, withHandlers } from "recompose";

import { withAppUser } from "@dpdgroupuk/mydpd-app";

export default compose(
  withAppUser,
  withHandlers({
    hasAppRoles:
      ({ appUser }) =>
      (roles = []) => {
        if (appUser) {
          roles = typeof roles === "string" ? [roles] : roles;

          const userAppRoles = appUser.appRoles || [];

          return roles.length > 0
            ? roles.some(role => userAppRoles.includes(role))
            : true;
        }

        return false;
      },
  })
);
