import { useMemo } from "react";

import PropTypes from "prop-types";
import { useFlexLayout } from "react-table";

import { DndTable as Table } from "@dpdgroupuk/mydpd-ui";
import { withTrack } from "@dpdgroupuk/react-event-tracker";

import Delimiter from "../../../../components/Delimiter";
import { PARCEL_DETAILS_COMPONENT } from "../../../../constants/analytics";
import { formatParcelDetailsAddressString } from "../../../../models/address";
import { getReferencesString } from "../../../../models/parcel";
import { formatDDMMYYYY } from "../../../../utils/date";
import { formatPostcode } from "../../../../utils/format";

const columns = [
  {
    Header: "Senders ref",
    Cell: ({ row: { original } }) => getReferencesString(original),
    width: 87,
  },
  {
    Header: "Account",
    Cell: ({
      row: {
        original: {
          shipperDetails: { accountNumber },
        },
      },
    }) => accountNumber,
    width: 67,
  },
  {
    Header: "Service",
    Cell: ({
      row: {
        original: {
          service: { serviceDescription },
          product: { productDescription },
        },
      },
    }) => `${productDescription} - ${serviceDescription}`,
    width: 74,
  },
  {
    Header: "Consignment",
    accessor: "consignmentNumber",
    width: 92,
  },
  {
    Header: "Collection",
    Cell: ({
      row: {
        original: { collectionDate },
      },
    }) => formatDDMMYYYY(collectionDate),
    width: 85,
  },
  {
    Header: "Postcode",
    Cell: ({
      row: {
        original: {
          deliveryDetails: {
            address: { postcode },
          },
        },
      },
    }) => formatPostcode(postcode),
    width: 75,
  },
  {
    Header: "Address",
    Cell: ({
      row: {
        original: {
          deliveryDetails: { address },
        },
      },
    }) => formatParcelDetailsAddressString(address),
    width: 90,
  },
  {
    Header: "Email Address",
    Cell: ({
      row: {
        original: {
          deliveryDetails: {
            notificationDetails: { email },
          },
        },
      },
    }) => email && email.toLowerCase(),
    width: 130,
  },
  {
    Header: "Phone Number",
    Cell: ({
      row: {
        original: {
          deliveryDetails: {
            notificationDetails: { mobile },
          },
        },
      },
    }) => mobile,
    width: 65,
  },
];

const ParcelDetails = ({ parcel }) => {
  // eslint-disable-next-line
  const memoColumns = useMemo(() => columns, [columns]);

  return (
    <>
      <Table
        columns={memoColumns}
        data={[parcel]}
        tableHooks={[useFlexLayout]}
      />
      <Delimiter />
    </>
  );
};

ParcelDetails.propTypes = {
  parcel: PropTypes.object,
};

export default withTrack(PARCEL_DETAILS_COMPONENT.LOAD)(ParcelDetails);
