import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import CardWithTitle from "../../../../components/CardWithTitle";
import RelatedParcelsList from "./RelatedParcelsList";

const RelatedParcels = ({ isRelatedParcelsAvailable, ...rest }) =>
  isRelatedParcelsAvailable ? (
    <Col sm={12}>
      <CardWithTitle title="Consignment">
        <RelatedParcelsList {...rest} />
      </CardWithTitle>
    </Col>
  ) : null;

RelatedParcels.propTypes = {
  isRelatedParcelsAvailable: PropTypes.bool,
};

export default RelatedParcels;
