import { get } from "lodash";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Delimiter from "../../../../components/Delimiter";
import { isParcelStatusEstimatedDate } from "../../../../models/parcel";
import { ParcelTypes } from "../../../../models/types";
import styles from "./ParcelEstimatedInstructions.module.scss";

const ParcelEstimatedInstructions = ({
  parcel: { deliveryDepot, parcelStatusType, estimatedInstructions },
}) => {
  const routeCode = get(deliveryDepot, "route.routeCode");

  return (
    isParcelStatusEstimatedDate(parcelStatusType) &&
    !routeCode && (
      <>
        <Container className="p-0 pb-2">
          <Row>
            <Col sm={12}>
              <div className={styles.instructions}>{estimatedInstructions}</div>
            </Col>
          </Row>
        </Container>
        <Delimiter />
      </>
    )
  );
};

ParcelEstimatedInstructions.propTypes = {
  parcel: PropTypes.shape(ParcelTypes.Parcel),
};

export default ParcelEstimatedInstructions;
