import PropTypes from "prop-types";

export default function CalendarSmall({ fill }) {
  return (
    <svg
      id="Calendar-small-1"
      focusable="false"
      fill={fill}
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      aria-hidden="true"
      role="presentation"
    >
      <path d="M18,2 L17,2 L17,0 L15,0 L15,2 L5,2 L5,0 L3,0 L3,2 L2,2 C0.9,2 0,2.9 0,4 L0,20 C0,21.1 0.9,22 2,22 L18,22 C19.1,22 20,21.1 20,20 L20,4 C20,2.9 19.1,2 18,2 Z M18,4 L18,7 L2,7 L2,4 L18,4 Z M2,20 L2,9 L18,9 L18,20 L2,20 Z" />
    </svg>
  );
}

CalendarSmall.propTypes = {
  fill: PropTypes.string,
};
