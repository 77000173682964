import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { PARCEL_STATUS } from "@dpdgroupuk/redback-enums";

import {
  isParcelStatusIssue,
  isParcelStatusRFIReason,
} from "../../../../models/parcel";
import { ParcelTypes } from "../../../../models/types";
import { issueIcons, statusIcons } from "../../model";
import styles from "./ParcelAdditionalStatus.module.scss";
import { IssueStatus, ParcelStatusIcon, ReasonStatus } from "./View";

const ParcelAdditionalStatus = ({ parcel }) => {
  const {
    parcelStatusType,
    issueDetails: { issueName, issueCode, issueDelayDays },
    rfiReason,
    estimatedDeliveryDate,
  } = parcel;

  return (
    <Container className="px-0">
      <Container>
        <Row>
          <Col sm={12}>
            <div className={styles.inner}>
              {isParcelStatusIssue(parcelStatusType) && (
                <>
                  <ParcelStatusIcon icon={issueIcons[issueCode]} />
                  <IssueStatus
                    issueName={issueName}
                    issueDelayDays={issueDelayDays}
                    estimatedDeliveryDate={estimatedDeliveryDate}
                  />
                </>
              )}
              {isParcelStatusRFIReason(parcelStatusType) && (
                <>
                  <ParcelStatusIcon
                    icon={statusIcons[PARCEL_STATUS.RFI_REASON]}
                  />
                  <ReasonStatus {...rfiReason} />
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

ParcelAdditionalStatus.propTypes = {
  className: PropTypes.string,
  parcel: PropTypes.shape(ParcelTypes.Parcel),
};

export default ParcelAdditionalStatus;
