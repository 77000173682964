import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { compose, lifecycle, withProps } from "recompose";
import { propTypes as ReduxFormPropTypes } from "redux-form";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import CardWithTitle from "../../../../components/CardWithTitle";
import { CHANGE_DELIVERY_DATE } from "../../../../constants/analytics";
import { Fields } from "../../../../constants/forms";
import { CHANGE_DATE, DELIVERY_DATE } from "../../../../constants/strings";
import { deliveryEditValidators } from "../../../../models/validators";
import { formatDDMMYYYY } from "../../../../utils/date";
import {
  checkMinDate,
  onChangeParcelDateSubmit,
  onChangeParcelDateSubmitREI,
} from "../../actions";
import EditPanel from "../../components/EditPanel";
import ParcelDayPicker from "../../components/ParcelDayPicker";
import { hadleInputFocus, loadActionDates, withParcelEdit } from "../../hocs";
import { getValue } from "../../../../utils/object";
const ChangeDeliveryDate = ({
  handleSubmit,
  onSubmit,
  error,
  cardDates,
  onClickDate,
  defaultDate,
  ...rest
}) => (
  <Col className="col-12 p-0">
    <CardWithTitle title={CHANGE_DATE}>
      <EditPanel
        onSubmit={handleSubmit(onSubmit)}
        error={error}
        offContactDetailsValidation={true}
        {...rest}
      >
        <Col className="col-12">
          <ParcelDayPicker
            defaultHeaderDate={defaultDate}
            title={DELIVERY_DATE}
            dates={cardDates}
            onChange={onClickDate}
            name={Fields.DELIVERY_DATE}
          />
        </Col>
      </EditPanel>
    </CardWithTitle>
  </Col>
);

ChangeDeliveryDate.defaultProps = {
  onSubmit: () => null,
  cardDates: [],
};

ChangeDeliveryDate.propTypes = {
  cardDates: PropTypes.arrayOf(PropTypes.string),
  defaultDate: PropTypes.string,
  ...ReduxFormPropTypes,
};

export default compose(
  loadActionDates,
  withProps(({ parcel }) => ({
    defaultDate: formatDDMMYYYY(getValue(parcel, "customerTargetDate", "")), // TODO: must be clarified
  })),
  withParcelEdit({
    onSubmit: (parcelCode, values, parcel) => {
      if (parcel.destinationTypeCode === "I") {
        return onChangeParcelDateSubmitREI(parcelCode, {
          ...values,
        });
      }
      return onChangeParcelDateSubmit(parcelCode, {
        ...values,
      });
    },
    validate: deliveryEditValidators.changeDeliveryDateValidator,
  }),
  withTrack(CHANGE_DELIVERY_DATE.LOAD),
  withTrackProps({
    onSubmit: CHANGE_DELIVERY_DATE.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      switch (fieldName) {
        case Fields.DELIVERY_DATE:
          return CHANGE_DELIVERY_DATE.CLICK_DROP_DOWN_LIST;
        case Fields.INSTRUCTIONS:
          return CHANGE_DELIVERY_DATE.INSTRUCTIONS_INPUT;
        case Fields.CONTACT_NAME:
          return CHANGE_DELIVERY_DATE.CONTACT_NAME_INPUT;
        case Fields.MOBILE:
          return CHANGE_DELIVERY_DATE.MOBILE_NUMBER_INPUT;
        case Fields.EMAIL:
          return CHANGE_DELIVERY_DATE.EMAIL_ADDRESS_INPUT;
        default:
          break;
      }
    },
    onClickDate: CHANGE_DELIVERY_DATE.CLICK_DATE,
  }),
  hadleInputFocus,
  lifecycle({
    componentDidUpdate() {
      this.props.dispatch(checkMinDate());
    },
  })
)(ChangeDeliveryDate);
