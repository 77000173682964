import PropTypes from "prop-types";

import { PARCEL_ACTION_CODE } from "@dpdgroupuk/redback-enums";

import ContactDetails from "./ContactDetails";
import ContactDetailsWithInstructions from "./ContactDetailsWithInstructions";

const ContactInfo = ({
  actionCode,
  showNotificationTitle,
  isMobileEmailRequired,
  offContactDetailsValidation,
}) =>
  [
    PARCEL_ACTION_CODE.CME,
    PARCEL_ACTION_CODE.TBC,
    PARCEL_ACTION_CODE.KMI,
  ].includes(actionCode) ? (
    <ContactDetails
      isMobileEmailRequired={isMobileEmailRequired}
      offValidation={offContactDetailsValidation}
    />
  ) : (
    <ContactDetailsWithInstructions
      showNotificationTitle={showNotificationTitle}
      isMobileEmailRequired={isMobileEmailRequired}
      offContactDetailsValidation={offContactDetailsValidation}
    />
  );

ContactInfo.defaultProps = {
  showCancelCollection: false,
};

ContactInfo.propTypes = {
  title: PropTypes.string,
  actionCode: PropTypes.string.isRequired,
  showNotificationTitle: PropTypes.bool,
  offContactDetailsValidation: PropTypes.bool,
  isMobileEmailRequired: PropTypes.bool,
};

export default ContactInfo;
