import { createSelector } from "reselect";

export const getAuthUser = state => state.app.auth.user;

export const getBusinessUnit = createSelector(
  getAuthUser,
  ({ businessId }) => businessId
);

export const getUserAccounts = createSelector(
  getAuthUser,
  ({ accounts }) => accounts
);

export const getDefaultUserAccount = createSelector(
  getAuthUser,
  user => user.account
);

export const getAppRoles = createSelector(getAuthUser, user => user.appRoles);
