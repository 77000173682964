import { isDateWithinLast10Months, isValidDate } from "./date";
import { POSTCODE } from "./regex";

// eslint-disable-next-line import/no-anonymous-default-export
export default values => {
  const errors = {};

  if (values.deliveryDate) {
    if (!isValidDate(values.deliveryDate)) {
      errors.deliveryDate = "Please enter valid date";
    }

    if (!isDateWithinLast10Months(values.deliveryDate)) {
      errors.deliveryDate = "Please enter a date within the last 10 months";
    }
  }

  if (values.deliveryPostcode && !POSTCODE.test(values.deliveryPostcode)) {
    errors.deliveryPostcode = "Invalid Postcode";
  }
  return errors;
};
