import { get, head, isEmpty, omitBy, pick, valuesIn } from "lodash";
import queryString from "query-string";

import { APIS_ORIGIN_URL } from "../apis";
import {
  Fields,
  FILTER_INITIAL_VALUES,
  FilterFields,
  SEARCH_REQUEST_FIELDS,
} from "../constants/forms";
import * as M from "../constants/strings";
import { omitNilValues } from "./object";

export const INITIAL_PAGE_QUERY = { page: 1, pageSize: 25 };

export const parseQuery = (location, parseNumbers) => {
  const query = queryString.parse(location.search, {
    parseNumbers,
  });

  return {
    ...INITIAL_PAGE_QUERY,
    ...query,
  };
};
export const stringifyQuery = (query, encode = true) =>
  queryString.stringify(query, {
    encode,
  });

export const transformQuery = (findByCode, values = {}) => ({
  findByCode,
  ...values,
});

export const getSearchQuery = location => {
  let query = parseQuery(location);
  query = removeAccountAllQuery(query);
  const res = pick(query, [
    "searchParam", // from external, can be customerReference or collectionReference
    ...SEARCH_REQUEST_FIELDS,
  ]);

  return isEmpty(res) ? null : omitNilValues(res);
};

export const getLocationState = location => {
  const locationState = get(location, "state", {}) || {};
  if (locationState.expiresIn && locationState.expiresIn > Date.now()) {
    return locationState;
  }
  return {};
};

export const createLocationState = (state = {}) => omitNilValues(state);

export const deleteExtraFilterValues = (values, location) => {
  if (values) {
    if (location.pathname !== M.OVERDUE_PATH) {
      values[FilterFields.OVERDUE] = null;
    }
    if (location.pathname !== M.EXCEPTION_PATH) {
      values[FilterFields.EXCEPTIONS] = null;
    }

    return omitNilValues(values);
  }

  return values;
};

export const getFiltersQuery = location => {
  let query = parseQuery(location);
  query = removeAccountAllQuery(query);

  let res = pick(query, valuesIn(FilterFields));
  res = deleteExtraFilterValues(res, location);

  return isEmpty(res) ? null : omitNilValues(res);
};

export const getFiltersInitialValues = ({
  location,
  searchFilterMap = {},
  defaultValues = FILTER_INITIAL_VALUES,
  customers,
}) => {
  const searchQuery = getSearchQuery(location);
  const filtersQuery = getFiltersQuery(location);

  const mappedFilters = {};

  let defaultAccountCode = defaultValues[FilterFields.ACCOUNT_CODE];

  Object.keys(searchFilterMap).forEach(key => {
    const filterKey = searchFilterMap[key];
    const searchValue = get(searchQuery, key);
    mappedFilters[filterKey] = searchValue || get(filtersQuery, filterKey);
  });

  if (customers?.length === 1) {
    defaultAccountCode = head(customers).account;
  }

  const initialValues = {
    ...{ ...defaultValues, [FilterFields.ACCOUNT_CODE]: defaultAccountCode },
    ...omitNilValues({ ...filtersQuery, ...mappedFilters }),
  };

  return deleteExtraFilterValues(initialValues, location);
};

export const getDisabledFields = (location, searchFilterMap = {}) => {
  const searchQuery = getSearchQuery(location) || {};
  const disabledFields = [];

  Object.keys(searchFilterMap).forEach(key => {
    if (searchQuery[key]) {
      disabledFields.push(searchFilterMap[key]);
    }
  });

  return disabledFields;
};

export const areEmptyFilters = location => getFiltersQuery(location) === null;

export const getQueryPagination = location => {
  const query = parseQuery(location, true);
  const res = pick(query, ["page", "pageSize"]);

  return isEmpty(res) ? null : res;
};

export const getCurrentPageByTotal = (location, total) => {
  const { page, pageSize } = getQueryPagination(location);
  if (typeof total === "number") {
    const lastPage = Math.ceil(total / pageSize);
    if (page > lastPage) {
      return lastPage;
    }
  }

  return page;
};

export const getExportCSVLink = findByCode =>
  `${APIS_ORIGIN_URL}/parcels/csv?findByCode=${findByCode}`;

export const parseSortByValue = values => {
  let sortFilters = {
    sortBy: values.sortBy,
    sortOrder: undefined,
  };
  if (sortFilters.sortBy) {
    const [sortBy, sortOrder] = sortFilters.sortBy.split("-");
    sortFilters = {
      sortBy,
      sortOrder,
    };
  }
  return sortFilters;
};

export const removeAccountAllQuery = query => {
  const fields = [Fields.ACCOUNT, FilterFields.ACCOUNT_CODE];

  if (query) {
    return omitBy(
      query,
      (value, key) =>
        fields.includes(key) && String(value).toLowerCase() === M.ALL
    );
  }

  return query;
};
