import { Redirect, Route } from "react-router";
import { Switch } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import DashboardResult from "../pages/DashboardResult";
import DashboardWatchList from "../pages/DashboardWatchList/DashboardWatchList";
import ParcelDetails from "../pages/Parcel";
import Search from "../pages/Search";
import SearchResult from "../pages/SearchResult";
import * as routes from "./constants";

export const Routes = () => (
  <Switch>
    <Redirect exact from="/" to={routes.SEARCH} />
    <Route exact strict path={routes.SEARCH} component={Search} />
    <Route exact strict path={routes.DELIVERIES} component={SearchResult} />
    <Route exact path={routes.DASHBOARD} component={Dashboard} />
    <Route
      exact
      path={`${routes.DASHBOARD}/:deliveryType`}
      component={DashboardResult}
    />
    <Route exact path={routes.WATCHLIST} component={DashboardWatchList} />
    <Route path={routes.PARCEL} component={ParcelDetails} />
  </Switch>
);
