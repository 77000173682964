import { useEffect, useMemo, useState } from "react";

import { get, isEmpty, sortBy } from "lodash";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { compose, lifecycle, withProps } from "recompose";
import { Field, propTypes as ReduxFormPropTypes } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import CardWithTitle from "../../../../components/CardWithTitle";
import WarningModal from "../../../../components/WarningModal";
import { DELIVER_TO_NEIGHBOUR } from "../../../../constants/analytics";
import { Fields } from "../../../../constants/forms";
import * as M from "../../../../constants/strings";
import { AddressTypes } from "../../../../models/types";
import { deliveryEditValidators } from "../../../../models/validators";
import { formatDDMMYYYY } from "../../../../utils/date";
import { joinStringsWithComma } from "../../../../utils/string";
import { checkMinDate, onDeliverToNeighbourSubmit } from "../../actions";
import EditPanel from "../../components/EditPanel";
import ParcelDayPicker from "../../components/ParcelDayPicker";
import {
  hadleInputFocus,
  loadActionDates,
  loadNeighbourAddresses,
  withParcelEdit,
} from "../../hocs";
import { getValue } from "../../../../utils/object";

const DeliverToNeighbour = ({
  handleSubmit,
  onSubmit,
  address,
  cardDates,
  error,
  onClickDate,
  onClickNeighbour,
  onGoBackClick,
  defaultDate,
  ...rest
}) => {
  const formatAddress = useMemo(() => {
    let orderedAddress = sortBy(address, "propertyIndex");
    const currentAddress = get(
      rest,
      "parcel.deliveryDetails.address.street",
      ""
    );
    if (!isEmpty(currentAddress)) {
      orderedAddress = orderedAddress.filter(address =>
        address.property ? !currentAddress.includes(address.property) : true
      );
    }
    return orderedAddress.map(
      ({ udprn, property, street, organisation, county, town, locality }) => ({
        label: joinStringsWithComma([
          organisation,
          property,
          street,
          county,
          town,
          locality,
        ]),
        value: udprn,
      })
    );
    // eslint-disable-next-line
  }, [address]);

  const [show, changeShow] = useState(!address.length);

  useEffect(() => {
    !formatAddress.length && changeShow(true);
  }, [formatAddress]);

  return (
    <Col className="col-12 p-0">
      <CardWithTitle title={M.DELIVER_PARCEL_TO_NEIGHBOUR}>
        <Row>
          <Col className="col-12 col-md-6">
            <Field
              label={M.DELIVER_NEIGHBOUR.toUpperCase()}
              component={FormControl.Dropdown}
              name={Fields.ADDRESS}
              required
              onChange={onClickNeighbour}
              placeholder={!formatAddress.length ? M.NO_NEIGHBOURS : undefined}
              values={formatAddress}
              helperText={M.PLEASE_SELECT_NEIGHBOUR}
            />
          </Col>
        </Row>
        <EditPanel
          onSubmit={handleSubmit(onSubmit)}
          error={error}
          offContactDetailsValidation={true}
          {...rest}
        >
          {cardDates.length > 0 && (
            <Col className="col-12">
              <ParcelDayPicker
                onChange={onClickDate}
                name={Fields.DELIVERY_DATE}
                dates={cardDates}
                title={M.DELIVERY_DATE}
                defaultHeaderDate={defaultDate}
              />
            </Col>
          )}
        </EditPanel>
        <WarningModal
          show={show}
          close={() => {
            changeShow(false);
            onGoBackClick();
          }}
          message={M.NO_NEIGHBOURS_FOR_POSTCODE}
        />
      </CardWithTitle>
    </Col>
  );
};

DeliverToNeighbour.defaultProps = {
  onSubmit: () => null,
  cardDates: [],
  address: [],
};

DeliverToNeighbour.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  addresses: PropTypes.arrayOf(PropTypes.shape(AddressTypes.Address)),
  onGoBackClick: PropTypes.func,
  defaultDate: PropTypes.string,
  ...ReduxFormPropTypes,
};

export default compose(
  loadNeighbourAddresses,
  loadActionDates,
  withProps(({ address, dates, parcel }) => ({
    additionalInitialValues: {
      address,
      isDatesAvailable: !!dates.length,
    },
    defaultDate: formatDDMMYYYY(getValue(parcel, "estimatedDeliveryDate", "")),
  })),
  withParcelEdit({
    onSubmit: (
      parcelCode,
      {
        contactName,
        email,
        mobile,
        address,
        deliveryDate,
        instructions,
        neighbourAddress,
      }
    ) => {
      const pickedAddress = address.find(
        ({ udprn }) => udprn === neighbourAddress
      );

      return onDeliverToNeighbourSubmit(parcelCode, {
        deliveryDate,
        instructions,
        address: {
          organisation: get(pickedAddress, "organisation", ""),
          property: get(pickedAddress, "property", ""),
          street: get(pickedAddress, "street", ""),
          locality: get(pickedAddress, "locality", ""),
        },
        notificationDetails: {
          contactName,
          email,
          mobile,
        },
      });
    },
    validate: deliveryEditValidators.deliverToNeighbourValidator,
  }),
  withTrack(DELIVER_TO_NEIGHBOUR.LOAD),
  withTrackProps({
    onSubmit: DELIVER_TO_NEIGHBOUR.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      switch (fieldName) {
        case Fields.DELIVERY_DATE:
        case Fields.ADDRESS:
          return DELIVER_TO_NEIGHBOUR.CLICK_DROP_DOWN_LIST;
        case Fields.INSTRUCTIONS:
          return DELIVER_TO_NEIGHBOUR.INSTRUCTIONS_INPUT;
        case Fields.CONTACT_NAME:
          return DELIVER_TO_NEIGHBOUR.CONTACT_NAME_INPUT;
        case Fields.MOBILE:
          return DELIVER_TO_NEIGHBOUR.MOBILE_NUMBER_INPUT;
        case Fields.EMAIL:
          return DELIVER_TO_NEIGHBOUR.EMAIL_ADDRESS_INPUT;
        default:
          break;
      }
    },
    onClickDate: DELIVER_TO_NEIGHBOUR.SELECT_DATE,
    onClickNeighbour: DELIVER_TO_NEIGHBOUR.SELECT_NEIGHBOUR,
  }),
  hadleInputFocus,
  lifecycle({
    componentDidUpdate() {
      this.props.dispatch(checkMinDate());
    },
  })
)(DeliverToNeighbour);
