import _ from "lodash";

export const omitNilValues = obj => _.pickBy(obj, _.identity);

export const getValue = (object, path, defaultValue) => {
  const value = _.get(object, path);
  if (value === undefined || value === null) {
    return defaultValue;
  } else {
    return value;
  }
};

export const toUppercaseValues = (obj, omit = []) =>
  _.mapValues(obj, (value, key) => {
    if (_.isString(value) && !omit.includes(key)) {
      value = value.toUpperCase();
    } else if (_.isPlainObject(value)) {
      value = toUppercaseValues(value);
    } else if (_.isArray(value)) {
      value = value.map(item => toUppercaseValues(item));
    }

    return value;
  });
