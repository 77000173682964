import { UmsActionTypes } from "./actions";

export const initialState = {
  customer: {},
  customers: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case UmsActionTypes.FETCH_CUSTOMER.SUCCESS: {
      return {
        ...state,
        customer: action.payload.data,
      };
    }
    case UmsActionTypes.FETCH_CUSTOMERS.SUCCESS: {
      return {
        ...state,
        customers: action.payload.data,
      };
    }
    default:
      return state;
  }
};
