import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { compose } from "recompose";

import withAppRolesValidator from "../HOCS/withAppRolesValidator";

const ProtectedRoute = ({
  appRoles = [],
  hasAppRoles,
  redirectUrl = "/",
  render = () => {},
  children,
  ...rest
}) => {
  const canActivate = hasAppRoles(appRoles);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        canActivate ? (
          render() || children
        ) : (
          <Redirect
            to={{
              pathname: redirectUrl,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  appRoles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  hasAppRoles: PropTypes.func,
  render: PropTypes.func,
  redirectUrl: PropTypes.string,
  children: PropTypes.node,
};

export default compose(withAppRolesValidator)(ProtectedRoute);
