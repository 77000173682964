import moment from "moment-timezone";

import * as DATE_FORMAT from "../constants/dateFormats";

const format = (date, outputFormat, inputFormat) =>
  moment(date, inputFormat).format(outputFormat);

export const formatTime = date => format(date, DATE_FORMAT.TIME_FORMAT);

export const formatDayMonth = date =>
  format(date, DATE_FORMAT.DAY_MONTH_DATE_TIME_FORMAT);

export const formatDDMMYYYY = (date = new Date()) =>
  date ? moment(date).format(DATE_FORMAT.DEFAULT_DATE_FORMAT) : "";

export const formatDDMMMYYYY = date =>
  date
    ? moment(date, DATE_FORMAT.DEFAULT_DATE_FORMAT).format("DD MMM YYYY")
    : "";

export const formatCurrentDateTime = time =>
  moment(time, DATE_FORMAT.HH_MM_TIME_FORMAT).format(
    DATE_FORMAT.DATE_TIME_FORMAT
  );

export const getMilliseconds = date => moment(date).valueOf();

export const chooseSmallerDate = (date1, date2) =>
  getMilliseconds(date1) > getMilliseconds(date2) ? date2 : date1;

/**
 * @param {String} seconds - date like '55728'
 * @returns {String} date like "2018-10-23T12:24:08"
 */
export const formatInternalTime = seconds => {
  const time = moment().startOf("day").add(seconds, "s");
  return format(time, DATE_FORMAT.DATE_TIME_FORMAT);
};

export const getUKCurrentMomentTime = () =>
  moment().tz(DATE_FORMAT.EUROPE_LONDON_TIME_ZONE);

export const getCurrentDateTime = () =>
  getUKCurrentMomentTime().format(DATE_FORMAT.DATE_TIME_FORMAT);

export const calculatePositionInPeriod = (date, periodStart, periodEnd) => {
  const start = getMilliseconds(periodStart);
  const end = getMilliseconds(periodEnd);
  const value = getMilliseconds(date);
  return Math.round(((value - start) / (end - start)) * 100);
};

export const getDayOfWeek = day =>
  moment(day, DATE_FORMAT.DEFAULT_DATE_FORMAT).format("dd")[0];
export const getDay = day =>
  moment(day, DATE_FORMAT.DEFAULT_DATE_FORMAT).format("D");
export const getMontAndYear = day =>
  moment(day, DATE_FORMAT.DEFAULT_DATE_FORMAT).format("MMMM YYYY");
export const getDayMonth = day =>
  moment(day, DATE_FORMAT.DEFAULT_DATE_FORMAT).format("dddd DD MMMM");

export const isSunday = date => date.getDay() === 0;
export const isSaturday = date => date.getDay() === 6;
export const isWeekend = date => isSunday(date) || isSaturday(date);
