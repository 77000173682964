import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose, lifecycle, withProps } from "recompose";
import {
  change,
  Field,
  propTypes as ReduxFormPropTypes,
  untouch,
} from "redux-form";

import { withNotifier } from "@dpdgroupuk/mydpd-app";
import { FormControl, withOverlay, withPrompt } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import CardWithTitle from "../../../../components/CardWithTitle";
import { ADJUST_SERVICE } from "../../../../constants/analytics";
import { DEFAULT_DATE_FORMAT } from "../../../../constants/dateFormats";
import {
  AdjustService as Fields,
  PARCEL_EDIT_FORM,
} from "../../../../constants/forms";
import * as M from "../../../../constants/strings";
import withOnLoadFailure from "../../../../HOCS/withOnLoadFailure";
import { isParcelCode } from "../../../../models/parcel";
import { deliveryEditValidators } from "../../../../models/validators";
import {
  checkMinDate,
  clearDateAndService,
  clearServices,
  fetchUpgradeDeliveryTimes,
  onAdjustService,
} from "../../actions";
import CustomCheckbox from "../../components/CustomCheckbox";
import EditPanel from "../../components/EditPanel";
import { hadleInputFocus, loadActionDates, withParcelEdit } from "../../hocs";
import { withRelatedParcelsInitialValues } from "../../hocs/withRelatedParcelsInitialValues";
import {
  getParcelFormStateValues,
  getUpgradeDeliveryTimes,
} from "../../selectors";
import styles from "./AdjustService.module.scss";
import {
  formatDataForRequest,
  formatServices,
  formInitialValues,
} from "./model";

const AdjustService = ({
  handleSubmit,
  onSubmit,
  error,
  getDatesRange,
  filterDate,
  onSelectDate,
  onSelectService,
  services,
  preventDatePickerBlur,
  setIsDatePickerOpen,
  ...rest
}) => (
  <Col className="col-12 p-0">
    <CardWithTitle title={M.UPGRADE_DELIVERY}>
      <Row>
        <Col className="col-6">
          <Field
            name={Fields.DELIVERY_DATE}
            component={FormControl.DatePicker}
            label={M.DELIVERY_DATE}
            // NOTE: getDatesRange is required to forbid change month by clicking header arrows and set proper date if date was changed
            getDatesRange={getDatesRange}
            filterDate={filterDate}
            dateFormat={DEFAULT_DATE_FORMAT}
            autoFocus={false}
            onDatePickerChange={setIsDatePickerOpen}
            onBlur={(...props) => {
              preventDatePickerBlur(props[0]);
              onSelectDate(props[2]);
            }}
          />
        </Col>
        <Col className="col-6">
          <Field
            component={FormControl.Dropdown}
            values={services}
            label={M.DELIVERY_SERVICE}
            name={Fields.SERVICE}
            onChange={onSelectService}
            disabled={!services.length}
            autoFocus={false}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-6">
          <Field
            autoFocus={false}
            name={Fields.REF}
            label={M.REFERENCE}
            component={FormControl.Input}
            maxLength={35}
            helperText={M.MAX_35_CHARACTERS}
          />
        </Col>
      </Row>
      <EditPanel
        onSubmit={handleSubmit(onSubmit)}
        offContactDetailsValidation={true}
        error={error}
        additionalInfoComponent={
          <Row>
            <Container className={styles.infoText}>{M.ONLY_DELIVER}</Container>
            <Field
              component={CustomCheckbox}
              name={Fields.FREE_OF_CHARGE}
              label={M.I_ACCEPT_AN_ADDITIONAL_CHARGE_MAY_BE_APPLIED}
              id={Fields.FREE_OF_CHARGE + "_adjustService"}
            />
          </Row>
        }
        {...rest}
      />
    </CardWithTitle>
  </Col>
);

AdjustService.defaultProps = {
  onSubmit: () => null,
};

AdjustService.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  getDatesRange: PropTypes.func,
  filterDate: PropTypes.func,
  ...ReduxFormPropTypes,
};

export default compose(
  withOnLoadFailure,
  withPrompt,
  withOverlay,
  withNotifier,
  loadActionDates,
  withRelatedParcelsInitialValues,
  withProps(({ additionalInitialValues }) => ({
    additionalInitialValues: {
      ...additionalInitialValues,
      ...formInitialValues,
    },
  })),
  connect(
    state => ({
      services: formatServices(getUpgradeDeliveryTimes(state)),
      formValues: getParcelFormStateValues(state),
    }),
    (dispatch, { match, parcel: { businessUnit }, notifier }) => ({
      onSelectDate: date => {
        if (date && date.length !== 0) {
          dispatch(
            notifier.runAsync(
              fetchUpgradeDeliveryTimes(match.params, {
                businessUnit,
                date,
              })
            )
          );
        }
      },
      clearData: () => dispatch(clearDateAndService()),
      clearServices: () => {
        dispatch(clearServices());
        dispatch(change(PARCEL_EDIT_FORM, Fields.SERVICE, ""));
        dispatch(untouch(PARCEL_EDIT_FORM, Fields.SERVICE));
      },
    })
  ),
  withParcelEdit({
    onSubmit: (parcelCode, values) =>
      onAdjustService(parcelCode, formatDataForRequest(values)),
    validate: deliveryEditValidators.adjustService,
  }),
  withTrack(ADJUST_SERVICE.LOAD),
  withTrackProps({
    onSubmit: ADJUST_SERVICE.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      const field = isParcelCode(fieldName) ? Fields.PARCEL_CODE : fieldName;
      switch (field) {
        case Fields.DELIVERY_DATE:
          return ADJUST_SERVICE.CLICK_DATE_DROP_DOWN_LIST;
        case Fields.SERVICE:
          return ADJUST_SERVICE.CLICK_DELIVERY_SERVICE_DROP_DOWN_LIST;
        case Fields.FREE_OF_CHARGE:
          return ADJUST_SERVICE.CLICK_TERMS_CHECKBOX;
        case Fields.REF:
          return ADJUST_SERVICE.REFERENCE_INPUT;
        case Fields.INSTRUCTIONS:
          return ADJUST_SERVICE.INSTRUCTIONS_INPUT;
        case Fields.CONTACT_NAME:
          return ADJUST_SERVICE.CONTACT_NAME_INPUT;
        case Fields.MOBILE:
          return ADJUST_SERVICE.MOBILE_NUMBER_INPUT;
        case Fields.EMAIL:
          return ADJUST_SERVICE.EMAIL_ADDRESS_INPUT;
        case Fields.ALL_CHECKBOXES:
          return ADJUST_SERVICE.CLICK_PARCEL_NO_CHECKBOX_SELECT_ALL;
        case Fields.PARCEL_CODE:
          return ADJUST_SERVICE.CLICK_PARCEL_NO_CHECKBOX;
        default:
          break;
      }
    },
    onSelectDate: ADJUST_SERVICE.CLICK_DATE,
    onSelectService: ADJUST_SERVICE.CLICK_DELIVERY_SERVICE,
  }),
  hadleInputFocus,
  lifecycle({
    componentWillUnmount() {
      this.props.clearData();
    },
    componentDidUpdate(prevProps) {
      this.props.dispatch(checkMinDate());

      if (
        prevProps.formValues?.[Fields.DELIVERY_DATE] !==
        this.props.formValues?.[Fields.DELIVERY_DATE]
      ) {
        this.props.clearServices();
      }
    },
  })
)(AdjustService);
