import { noop } from "lodash";
import PropTypes from "prop-types";

import { Button } from "@dpdgroupuk/mydpd-ui";

import { EXTRACT, WATCH_LIST_REMOVE } from "../../../constants/strings";
import styles from "./Footer.module.scss";

const Footer = ({
  isItemSelected,
  exportLink,
  onRemoveClick,
  onExportPress,
  canEdit,
}) => (
  <Button.ButtonGroup className={styles.buttons}>
    {canEdit && (
      <Button
        onClick={onRemoveClick}
        variant="dark"
        disabled={!isItemSelected}
        className="mr-2"
      >
        {WATCH_LIST_REMOVE}
      </Button>
    )}
    <Button href={exportLink} variant="primary" onClick={onExportPress}>
      {EXTRACT}
    </Button>
  </Button.ButtonGroup>
);

Footer.defaultProps = {
  onExportPress: noop,
};

Footer.propTypes = {
  isItemSelected: PropTypes.bool,
  exportLink: PropTypes.string.isRequired,
  onRemoveClick: PropTypes.func,
  onExportPress: PropTypes.func,
  canEdit: PropTypes.bool,
};

export default Footer;
