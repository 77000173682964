import { PropTypes } from "prop-types";
import { Col } from "react-bootstrap";

import CardWithTitle from "../../../../components/CardWithTitle";
import { PARCEL_NUMBER } from "../../../../constants/strings";
import { ParcelTypes } from "../../../../models/types";
import ParcelDetails from "../ParcelDetails";
import ParcelStatus from "../ParcelStatus";

const HeaderWithDetails = ({ parcel, children, additionalParcelStatus }) => (
  <Col sm={12}>
    <CardWithTitle
      title={`${PARCEL_NUMBER(parcel.parcelNumber)} (${
        parcel.shipperDetails.organisation
      })`}
    >
      <ParcelStatus parcel={parcel}>{additionalParcelStatus}</ParcelStatus>
      {children}
      <ParcelDetails parcel={parcel} />
    </CardWithTitle>
  </Col>
);

HeaderWithDetails.propTypes = {
  parcel: PropTypes.shape(ParcelTypes.Parcel),
  children: PropTypes.node,
  additionalParcelStatus: PropTypes.node,
};

export default HeaderWithDetails;
