import PropTypes from "prop-types";

import { useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

import styles from "./View.module.scss";

const IssueIcon = ({ icon: Icon }) => {
  const theme = useMyDpdTheme();

  return (
    <span className={styles.issueLogo}>
      <Icon fill={theme.palette.brand} />
    </span>
  );
};

IssueIcon.propTypes = {
  icon: PropTypes.func,
};

export default IssueIcon;
