import PropTypes from "prop-types";
import { Redirect, withRouter } from "react-router";
import ReactRouterPropTypes from "react-router-prop-types";
import { branch, compose, renderComponent, withHandlers } from "recompose";

import { Main } from "@dpdgroupuk/mydpd-ui";
import { PARCEL_ACTION_CODE } from "@dpdgroupuk/redback-enums";

import Delimiter from "../../components/Delimiter";
import withDatePicker from "../../HOCS/withDatePicker";
import { ParcelTypes } from "../../models/types";
import { DELIVERIES } from "../../router/constants";
import BackButton from "../Parcel/components/BackButton";
import styles from "./EditDeliveryPage.module.scss";
import {
  AdjustService,
  AlternativeAddress,
  AlternativeAddressAndService,
  CallMe,
  ChangeDeliveryDate,
  CollectFromDepot,
  CollectFromPickupShop,
  DeliverToNeighbour,
  DeliverToSafePlace,
  DisposeRequest,
  ProvideDeliveryInstructions,
  RedeliverToSameAddress,
  ReturnToSender,
  UpdateContactDetails,
} from "./view";

const EditDeliveryBlock = props => {
  const { actionCode } = props.match.params;
  switch (actionCode) {
    case PARCEL_ACTION_CODE.RED:
      return <ChangeDeliveryDate {...props} />;
    case PARCEL_ACTION_CODE.REI:
      return <RedeliverToSameAddress {...props} />;
    case PARCEL_ACTION_CODE.SFP:
      return <DeliverToSafePlace {...props} />;
    case PARCEL_ACTION_CODE.DTN:
      return <DeliverToNeighbour {...props} />;
    case PARCEL_ACTION_CODE.TBC:
      return <CollectFromDepot {...props} />;
    case PARCEL_ACTION_CODE.KMI:
      return <UpdateContactDetails {...props} />;
    case PARCEL_ACTION_CODE.LOC:
      return <ProvideDeliveryInstructions {...props} />;
    case PARCEL_ACTION_CODE.RTC:
    case PARCEL_ACTION_CODE.RTI:
      return <ReturnToSender {...props} />;
    case PARCEL_ACTION_CODE.ALT:
    case PARCEL_ACTION_CODE.ALI:
    case PARCEL_ACTION_CODE.ALA:
      return <AlternativeAddress {...props} />;
    case PARCEL_ACTION_CODE.CME:
      return <CallMe {...props} />;
    case PARCEL_ACTION_CODE.UPC:
      return <AdjustService {...props} />;
    case PARCEL_ACTION_CODE.ALU:
      return <AlternativeAddressAndService {...props} />;
    case PARCEL_ACTION_CODE.DIP:
      return <DisposeRequest {...props} />;
    case PARCEL_ACTION_CODE.PKU:
      return <CollectFromPickupShop {...props} />;
    default:
      return <Redirect to={props.redirectUrl} />;
  }
};

const commonPropTypes = {
  ...ReactRouterPropTypes,
  actions: PropTypes.object,
  parcel: PropTypes.shape(ParcelTypes.Parcel),
  reloadParcelFn: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string,
};

EditDeliveryBlock.propTypes = { ...commonPropTypes };

const EditDelivery = ({ onGoBackClick, ...rest }) => (
  <Main body className={styles.main}>
    <BackButton onClick={onGoBackClick} />
    <EditDeliveryBlock onGoBackClick={onGoBackClick} {...rest} />
    <Delimiter />
  </Main>
);

EditDelivery.propTypes = {
  ...commonPropTypes,
  onGoBackClick: PropTypes.func,
};

export default compose(
  withRouter,
  branch(
    ({ actions, match }) =>
      !actions.availableActions.includes(match.params.actionCode),
    renderComponent(({ redirectUrl }) => <Redirect to={redirectUrl} />)
  ),
  withDatePicker,
  withHandlers({
    onGoBackClick:
      ({ history, parcel }) =>
      () =>
        history.push(`${DELIVERIES}/${parcel.parcelCode}`),
  })
)(EditDelivery);
