const LocalEvent = props => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
    <rect width="48" height="48" fill="white" />
    <path
      d="M41.5949 41.6374H6.40551C5.53721 41.6374 4.76015 41.1885 4.32561 40.437C3.89106 39.6856 3.89186 38.7868 4.32561 38.0354L21.9203 7.56039C22.354 6.80894 23.1319 6.35999 24.0002 6.35999C24.8685 6.35999 25.6456 6.80894 26.0801 7.56039L43.6748 38.0354C44.1085 38.7876 44.1085 39.6864 43.674 40.437C43.2402 41.1893 42.4624 41.6374 41.5949 41.6374ZM24.0002 7.96052C23.861 7.96052 23.5152 7.99973 23.3072 8.36066L5.71167 38.8357C5.5028 39.1966 5.64205 39.5167 5.71167 39.6367C5.7813 39.7568 5.98777 40.0369 6.40551 40.0369H41.5949C42.0118 40.0369 42.2183 39.7568 42.2879 39.6367C42.3576 39.5167 42.4968 39.1974 42.2879 38.8357L24.6932 8.36066C24.4852 7.99973 24.1394 7.96052 24.0002 7.96052Z"
      fill="#414042"
    />
    <path
      d="M29.8257 24.5676C29.6924 24.5676 29.5638 24.4943 29.5003 24.3664C29.4106 24.187 29.4836 23.9691 29.663 23.8794C30.0988 23.6614 30.3698 23.2278 30.3698 22.7476C30.3698 22.0466 29.7995 21.4764 29.0986 21.4764C28.3976 21.4764 27.8274 22.0466 27.8274 22.7476C27.8274 23.2278 28.0983 23.6618 28.5341 23.8794C28.7136 23.9691 28.7866 24.187 28.6969 24.3664C28.6072 24.5466 28.3881 24.6181 28.2098 24.5291C27.5255 24.1877 27.1006 23.5049 27.1006 22.7476C27.1006 21.6464 27.997 20.75 29.0982 20.75C30.1994 20.75 31.0958 21.6464 31.0958 22.7476C31.0958 23.5049 30.6709 24.1877 29.9866 24.5291C29.9357 24.5553 29.8805 24.5676 29.8257 24.5676Z"
      fill="#414042"
    />
    <path d="M31.6414 31.4031C31.4405 31.4031 31.2782 31.2408 31.2782 31.0399V26.6815C31.2782 26.2794 30.9488 25.9424 30.5278 25.9144L27.6462 25.9148C27.2492 25.942 26.9198 26.2791 26.9198 26.6811V27.6886C26.9198 27.8895 26.7574 28.0518 26.5566 28.0518C26.3557 28.0518 26.1934 27.8895 26.1934 27.6886V26.6811C26.1934 25.8977 26.821 25.2425 27.6222 25.1891L30.5518 25.1884C31.377 25.2425 32.0046 25.8981 32.0046 26.6811V31.0395C32.0046 31.2404 31.8422 31.4031 31.6414 31.4031Z" />
    <path
      d="M30.5521 37.534H27.6465V31.3595C27.6465 31.1587 27.8088 30.9963 28.0097 30.9963C28.2105 30.9963 28.3729 31.1587 28.3729 31.3595V36.8076H29.8257V31.3595C29.8257 31.1587 29.9881 30.9963 30.1889 30.9963C30.3898 30.9963 30.5521 31.1587 30.5521 31.3595V37.534Z"
      fill="#414042"
    />
    <path
      d="M20.3823 24.577C20.249 24.577 20.1208 24.5037 20.0569 24.3762C19.9672 24.1967 20.0398 23.9785 20.2193 23.8888C20.6555 23.6705 20.9264 23.2368 20.9264 22.7566C20.9264 22.0557 20.3562 21.4854 19.6552 21.4854C18.9542 21.4854 18.384 22.0557 18.384 22.7566C18.384 23.2368 18.6549 23.6708 19.0911 23.8888C19.2706 23.9785 19.3432 24.1967 19.2535 24.3762C19.1634 24.5556 18.9455 24.6275 18.7661 24.5385C18.0822 24.1967 17.6572 23.5139 17.6572 22.7566C17.6572 21.6554 18.5536 20.759 19.6548 20.759C20.7561 20.759 21.6525 21.6554 21.6525 22.7566C21.6525 23.5139 21.2275 24.1967 20.5436 24.5385C20.4924 24.5647 20.4372 24.577 20.3823 24.577Z"
      fill="#414042"
    />
    <path d="M17.1135 31.3718C17.0902 31.3718 17.0659 31.3696 17.0419 31.3646C16.8455 31.3253 16.7176 31.1339 16.7572 30.9371L17.565 26.8997C17.7618 25.9133 18.6349 25.197 19.6414 25.197C20.6769 25.197 21.55 25.9133 21.7469 26.8997L21.8907 27.6178C21.9299 27.8146 21.8024 28.0057 21.6059 28.0453C21.4073 28.0863 21.218 27.957 21.1785 27.7605L21.0346 27.0425C20.9053 26.3941 20.3311 25.9238 19.6704 25.9238C18.9807 25.9238 18.4069 26.3941 18.2772 27.0425L17.4694 31.0798C17.4349 31.2527 17.2831 31.3718 17.1135 31.3718Z" />
    <path
      d="M20.7237 37.5027H18.5885L18.2038 31.3508C18.1915 31.1503 18.3437 30.9781 18.5438 30.9654C18.7439 30.9542 18.9161 31.1049 18.9291 31.3054L19.2709 36.7763H20.0413L20.383 31.3054C20.3954 31.1049 20.5705 30.9549 20.7684 30.9654C20.9685 30.9778 21.1207 31.1503 21.1084 31.3508L20.7237 37.5027Z"
      fill="#414042"
    />
    <path d="M25.8302 32.8505C25.6293 32.8505 25.467 32.6882 25.467 32.4873V29.2174C25.467 28.9628 25.2738 28.7496 25.0264 28.7322L22.9761 28.7332C22.7546 28.7496 22.5614 28.9628 22.5614 29.2174V32.4873C22.5614 32.6882 22.399 32.8505 22.1982 32.8505C21.9973 32.8505 21.835 32.6882 21.835 32.4873V29.2174C21.835 28.5829 22.3253 28.0515 22.9507 28.0079L25.0518 28.0068C25.7034 28.0515 26.1934 28.5829 26.1934 29.2174V32.4873C26.1934 32.6882 26.031 32.8505 25.8302 32.8505Z" />
    <path
      d="M25.104 37.5278H22.9248V32.7314C22.9248 32.5305 23.0872 32.3682 23.288 32.3682C23.4889 32.3682 23.6512 32.5305 23.6512 32.7314V36.8014H24.3776V32.7314C24.3776 32.5305 24.54 32.3682 24.7408 32.3682C24.9417 32.3682 25.104 32.5305 25.104 32.7314V37.5278Z"
      fill="#414042"
    />
    <path
      d="M24.0143 27.6889C23.2131 27.6889 22.5615 27.0374 22.5615 26.2361C22.5615 25.4349 23.2131 24.7833 24.0143 24.7833C24.8156 24.7833 25.4671 25.4349 25.4671 26.2361C25.4671 27.0374 24.8156 27.6889 24.0143 27.6889ZM24.0143 25.5097C23.6137 25.5097 23.2879 25.8355 23.2879 26.2361C23.2879 26.6367 23.6137 26.9625 24.0143 26.9625C24.4149 26.9625 24.7407 26.6367 24.7407 26.2361C24.7407 25.8355 24.4149 25.5097 24.0143 25.5097Z"
      fill="#414042"
    />
  </svg>
);

export default LocalEvent;
