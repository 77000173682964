import PropTypes from "prop-types";

const Other = ({ fill = "#DC0032" }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1372 31.3181C21.0345 30.7532 20.8793 30.1883 20.8793 29.572C20.8793 27.7746 21.4956 26.7989 23.0887 25.3609L26.6311 22.0742C28.5826 20.2254 30.6368 18.2739 30.6368 14.7817C30.6368 10.5706 27.3501 8.00285 23.2428 8.00285C18.9815 8.00285 16.465 10.1598 15.1812 12.4708L14 10.8787C15.4379 8.46505 18.2613 6 23.4482 6C28.9432 6 32.8976 9.49216 32.8976 14.679C32.8976 19.301 30.3812 21.3552 28.1204 23.4083L24.578 26.6437C23.2942 27.8248 22.986 28.3384 22.986 29.725C22.986 30.1358 23.0887 30.7521 23.2428 31.317H21.1372V31.3181Z"
      fill="#414042"
    />
    <path
      d="M22.267 37.224C23.705 37.224 24.5255 38.0457 24.5255 39.5863C24.5255 41.1783 23.7038 42 22.267 42C20.8279 42 20.0576 41.1783 20.0576 39.5863C20.0588 38.0457 20.8279 37.224 22.267 37.224Z"
      fill={fill}
    />
  </svg>
);

Other.propTypes = {
  fill: PropTypes.string,
};

export default Other;
