import classNames from "classnames";
import PropTypes from "prop-types";

import "./Title.scss";

const Title = ({ title, containerStyle = {}, children }) => (
  <div className={classNames("cc-modal-header", containerStyle)}>
    <div>{title}</div>
    {children && children}
  </div>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
  containerStyle: PropTypes.any,
  children: PropTypes.any,
};

export default Title;
