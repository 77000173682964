import { useMemo } from "react";

import { get, noop } from "lodash";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import ReactRouterPropTypes from "react-router-prop-types";

import { APP_ROLES } from "@dpdgroupuk/mydpd-enums";
import { Card, Main } from "@dpdgroupuk/mydpd-ui";

import { FILTER_INITIAL_VALUES } from "../../constants/forms";
import * as M from "../../constants/strings";
import SearchFilterForm from "../../containers/FilterForm";
import {
  areEmptyFilters,
  getFiltersInitialValues,
  getQueryPagination,
} from "../../utils/query";
import SearchResult, { DeliveryListPropTypes } from "./components";

const DeliveryListView = params => {
  const {
    pageTitle,
    location,
    totalCount,
    onClickRow,
    onReset,
    onFilter,
    onNext,
    onPrevious,
    onFirst,
    onLast,
    onExportPress,
    onFieldEntry,
    findByCode,
    products,
    countries,
    depots,
    services,
    parcels,
    searchFilterMap,
    customers,
    app,
    match,
  } = params;
  const { page, pageSize, filters, emptyFilters } = useMemo(
    () => ({
      ...getQueryPagination(location),
      filters: getFiltersInitialValues({
        location,
        searchFilterMap,
        defaultValues: FILTER_INITIAL_VALUES,
        customers,
      }),
      emptyFilters: areEmptyFilters(location),
    }),
    // eslint-disable-next-line
    [location]
  );
  const showExtract = useMemo(
    () => get(app, "state.auth.user.appRoles")?.includes(APP_ROLES.EXREPORTS),
    [app]
  );

  return (
    <Main body>
      <Card.Stack>
        <Col className="col-12">
          <SearchFilterForm
            title={pageTitle}
            emptyFilters={emptyFilters}
            initialValues={filters}
            onReset={onReset}
            onFilter={onFilter}
            onFieldEntry={onFieldEntry}
            products={products}
            countries={countries}
            services={services}
            depots={depots}
            location={location}
            searchFilterMap={searchFilterMap}
          />
        </Col>
        <Col className="col-12">
          <SearchResult
            onClickRow={onClickRow}
            page={page}
            totalCount={totalCount}
            pageSize={pageSize}
            onNext={onNext}
            onPrevious={onPrevious}
            onFirst={onFirst}
            onLast={onLast}
            parcels={parcels}
            onExportPress={onExportPress}
            findByCode={findByCode}
            showExtract={showExtract}
            deliveryType={match.params.deliveryType}
          />
        </Col>
      </Card.Stack>
    </Main>
  );
};

// eslint-disable-next-line no-unused-vars
const { page, pageSize, ...SearchListPropTypes } = DeliveryListPropTypes;

DeliveryListView.propTypes = {
  ...SearchListPropTypes,
  ...SearchFilterForm.propTypes,
  ...ReactRouterPropTypes,
  pageTitle: PropTypes.string,
};

DeliveryListView.defaultProps = {
  pageTitle: M.DELIVERY_SEARCH,
  onExportPress: noop,
};

export default DeliveryListView;
