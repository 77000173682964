import { useMemo, useState } from "react";

import moment from "moment";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import { Button, Modal } from "@dpdgroupuk/mydpd-ui";
import { PICKUP_LOCATION_STATUS } from "@dpdgroupuk/redback-enums";

import {
  CalendarEvent,
  ClockEvent,
  Location,
} from "../../../../components/Icon";
import MapView from "../../../../components/MapView";
import { DEFAULT_DATE_FORMAT } from "../../../../constants/dateFormats";
import * as M from "../../../../constants/strings";
import { formatPickupShopWorkingHours } from "../../../../models/parcel";
import { joinStringsWithComma } from "../../../../utils/string";
import { getEventLocationMapUrl } from "../../model";
import PickupDetail from "../PickupDetail/PickupDetail";
import styles from "./PickupDetails.module.scss";

const PickupDetails = ({ pickupLocation, parcel, onClickShowMap }) => {
  const [showMap, toggleMap] = useState(false);
  const [schedule, toggleSchedule] = useState(false);
  const {
    pickupValueGoods,
    pickupStatus,
    estimatedDeliveryDate,
    pickupExpiryDate,
    estimatedDeliveryEndTime,
  } = parcel;
  const { name, postcode, street, town, longitude, latitude, workingHours } =
    pickupLocation;

  const address = joinStringsWithComma([name, street, town, postcode]);

  const timetable = useMemo(
    () => formatPickupShopWorkingHours(workingHours),
    [workingHours]
  );

  const mapImageUrl = useMemo(
    () => showMap && getEventLocationMapUrl({ latitude, longitude }),
    // eslint-disable-next-line
    [showMap, pickupLocation]
  );
  let dayMonth = moment(estimatedDeliveryDate, "YYYY-MM-DD").format(
    DEFAULT_DATE_FORMAT
  );
  const status = parseInt(pickupStatus);
  if (status === PICKUP_LOCATION_STATUS.ACTIVE) {
    dayMonth = moment(pickupExpiryDate, "YYYY-MM-DD").format(
      DEFAULT_DATE_FORMAT
    );
  }
  const time =
    estimatedDeliveryEndTime && !pickupExpiryDate
      ? `After ${moment(estimatedDeliveryEndTime, "HH:mm:ss").format("HH:mm")}`
      : "";

  const toggleMapHandler = () => {
    !showMap && onClickShowMap();
    toggleMap(!showMap);
  };

  return (
    <Container className="mb-4">
      <Row>
        <Row className={styles.infoContainer}>
          <Row className={styles.pickupDetails}>
            {moment().isValid(dayMonth) && (
              <PickupDetail
                iconFill={"#000"}
                value={dayMonth}
                icon={CalendarEvent}
                itemClassName={styles.pickupDetail}
                valueClassName={styles.pickupDetail__value}
              />
            )}
            {time && (
              <PickupDetail
                value={time}
                icon={ClockEvent}
                itemClassName={styles.pickupDetail}
                valueClassName={styles.pickupDetail__value}
              />
            )}
            {address && (
              <PickupDetail
                value={address}
                icon={Location}
                itemClassName={styles.pickupDetail}
                valueClassName={styles.pickupDetail__value}
              />
            )}
          </Row>
          {address && (
            <>
              <Row className={styles.buttonContainer}>
                <Button
                  className={styles.button}
                  onClick={() => toggleSchedule(!schedule)}
                  variant="dark"
                >
                  {M.WORKING_HOURS}
                </Button>
                <Button
                  className={styles.button}
                  onClick={toggleMapHandler}
                  variant="dark"
                >
                  {M.SEE_ON_MAP}
                </Button>
              </Row>
              <MapView
                onClose={toggleMapHandler}
                show={showMap}
                src={mapImageUrl || ""}
              />
              <Modal
                show={schedule}
                dialogClassName={styles.modal}
                onHide={() => null}
                bsPrefix="modal"
              >
                <Modal.Body>
                  <Container fluid className={styles.container}>
                    <Row className={styles.header}>{M.WORKING_HOURS}</Row>
                    <Row className={styles.content}>
                      {timetable.map((time, i) => (
                        <div className={styles.dayContainer} key={time}>
                          <div className={styles.day}>
                            {moment().day(i).format("dddd")}
                          </div>
                          <div className={styles.time}>{time}</div>
                        </div>
                      ))}
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer className={styles.footer}>
                  <Button
                    onClick={() => toggleSchedule(!schedule)}
                    variant={"dark"}
                  >
                    {M.CLOSE}
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </Row>
      </Row>
      <Row>
        <div className={styles.title}>
          {M.TO_COLLECT_PARCEL_YOU_NEED_TO_BRING}
        </div>
        <Row className={styles.pickupDocumentsContainer}>
          <Col className={styles.pickupDocuments}>
            <div>
              <div className={styles.dot} />
              <PickupDetail value={M.DPD_PICKUP_PASS} />
            </div>
            {pickupValueGoods === "H" && (
              <div>
                <div className={styles.dot} />
                <PickupDetail value={M.PHOTO_ID} />
              </div>
            )}
          </Col>
          <Col className={styles.description}>
            {pickupValueGoods === "H"
              ? M.COLLECTION_PARCEL_NOTICE_PICKUP_PASS_BOTH
              : M.COLLECTION_PARCEL_NOTICE_PICKUP_PASS}
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

PickupDetails.propTypes = {
  pickupLocation: PropTypes.object,
  address: PropTypes.object,
  parcel: PropTypes.object,
  pickupValueGoods: PropTypes.string,
  organisation: PropTypes.string,
  postcode: PropTypes.string,
  street: PropTypes.string,
  town: PropTypes.string,
  onClickShowMap: PropTypes.func,
};

export default PickupDetails;
