const Winds = props => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
    <rect width="48" height="48" fill="white" />
    <path
      d="M41.5949 41.6374H6.40551C5.53721 41.6374 4.76015 41.1885 4.32561 40.437C3.89106 39.6856 3.89186 38.7868 4.32561 38.0354L21.9203 7.56039C22.354 6.80894 23.1319 6.35999 24.0002 6.35999C24.8685 6.35999 25.6456 6.80894 26.0801 7.56039L43.6748 38.0354C44.1085 38.7876 44.1085 39.6864 43.674 40.437C43.2402 41.1893 42.4624 41.6374 41.5949 41.6374ZM24.0002 7.96052C23.861 7.96052 23.5152 7.99973 23.3072 8.36066L5.71167 38.8357C5.5028 39.1966 5.64205 39.5167 5.71167 39.6367C5.7813 39.7568 5.98777 40.0369 6.40551 40.0369H41.5949C42.0118 40.0369 42.2183 39.7568 42.2879 39.6367C42.3576 39.5167 42.4968 39.1974 42.2879 38.8357L24.6932 8.36066C24.4852 7.99973 24.1394 7.96052 24.0002 7.96052Z"
      fill="#414042"
    />
    <path d="M17.5829 27.2057H26.3486C27.88 27.2057 29.1229 26.0371 29.1229 24.6029C29.1229 23.1686 27.88 22 26.3486 22C24.8171 22 23.5743 23.1686 23.5743 24.6029C23.5743 24.9257 23.8343 25.1857 24.1571 25.1857C24.48 25.1857 24.74 24.9257 24.74 24.6029C24.74 23.8114 25.4629 23.1657 26.3486 23.1657C27.2343 23.1657 27.9571 23.8086 27.9571 24.6029C27.9571 25.3943 27.2343 26.04 26.3486 26.04H17.5829C17.26 26.04 17 26.3 17 26.6229C17 26.9457 17.26 27.2057 17.5829 27.2057Z" />
    <path d="M26.3486 30.7943H17.5829C17.26 30.7943 17 31.0543 17 31.3772C17 31.7 17.26 31.96 17.5829 31.96H26.3486C27.2343 31.96 27.9571 32.6057 27.9571 33.3972C27.9571 34.1886 27.2343 34.8343 26.3486 34.8343C26.0257 34.8343 25.7657 35.0943 25.7657 35.4172C25.7657 35.74 26.0257 36 26.3486 36C27.88 36 29.1229 34.8315 29.1229 33.3972C29.1257 31.9629 27.88 30.7943 26.3486 30.7943Z" />
    <path d="M30.4171 26.7515C30.0943 26.7515 29.8343 27.0115 29.8343 27.3343C29.8343 27.9858 29.16 28.5143 28.3314 28.5143H17.5829C17.26 28.5143 17 28.7743 17 29.0972C17 29.42 17.26 29.68 17.5829 29.68H28.3314C29.8029 29.68 31 28.6286 31 27.3343C31 27.0115 30.74 26.7515 30.4171 26.7515Z" />
  </svg>
);

export default Winds;
