import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";

import { getFoucusedParcelFormField } from "../selectors";

export default compose(
  connect(state => ({ focusedInput: getFoucusedParcelFormField(state) })),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (
        this.props.focusedInput &&
        this.props.onInputFocus &&
        prevProps.focusedInput !== this.props.focusedInput
      ) {
        this.props.onInputFocus(this.props.focusedInput);
      }
    },
  })
);
