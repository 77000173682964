import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { deliveriesApi } from "../../apis";
import { NO_SERVICE_DISRUPTION } from "../../constants/strings";

export const ActionTypes = createActionTypes("DASHBOARD_STATS", {
  FETCH_DASHBOARD_STATS: createAsyncActionTypes("FETCH_DASHBOARD_STATS"),
  FETCH_WATCH_LIST: createAsyncActionTypes("FETCH_WATCH_LIST"),
  FETCH_PDF_AVAILABLE: createAsyncActionTypes("FETCH_PDF_AVAILABLE"),
  CLEAR_STATS: "CLEAR_STATS",
  FETCH_SERVICE_DISRUPTION: createAsyncActionTypes("FETCH_SERVICE_DISRUPTION"),
  CLEAR_SERVICE_DISRUPTION: "CLEAR_SERVICE_DISRUPTION",
});

export const fetchDashboardStats = createAsyncAction(
  (query = {}, fetchOptions) =>
    deliveriesApi.getDashboardStats(query, fetchOptions),
  ActionTypes.FETCH_DASHBOARD_STATS
);

export const clearStats = () => ({
  type: ActionTypes.CLEAR_STATS,
});

export const fetchWatchList = createAsyncAction(
  (query = {}, fetchOptions) =>
    deliveriesApi.getWatchList(query, fetchOptions).then(({ data }) => data),
  ActionTypes.FETCH_WATCH_LIST
);

export const fetchIsPDFavailable = createAsyncAction(
  fetchOptions =>
    deliveriesApi
      .getDashboardPDFavailable(fetchOptions)
      .then(({ data }) => data),
  ActionTypes.FETCH_PDF_AVAILABLE
);

export const fetchServiceDisruption = createAsyncAction(
  (query = {}, fetchOptions) =>
    deliveriesApi.getServiceDisruption(query, fetchOptions).then(({ data }) =>
      data.length > 0
        ? { serviceDisruption: data, unaffectedMessage: "" }
        : {
            serviceDisruption: [],
            unaffectedMessage: NO_SERVICE_DISRUPTION,
          }
    ),
  ActionTypes.FETCH_SERVICE_DISRUPTION
);

export const clearServiceDisruption = () => ({
  type: ActionTypes.CLEAR_SERVICE_DISRUPTION,
});
