import { useMemo } from "react";

import PropTypes from "prop-types";

import { formatPickupShopWorkingDayAndHours } from "../../../../../../models/parcel";
import styles from "./Timetable.module.scss";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const Timetable = ({ data = [] }) => {
  const timetable = useMemo(
    () => formatPickupShopWorkingDayAndHours(data),
    [data]
  );

  return (
    <div className={styles.timetable}>
      {timetable.map(
        ({ time, day }, i) =>
          time && (
            <div key={i} className={styles.date}>
              <span className={styles.day}>{days[day - 1]}</span>
              <span className={styles.time}>{time}</span>
            </div>
          )
      )}
    </div>
  );
};

Timetable.propTypes = {
  data: PropTypes.array,
};

export default Timetable;
