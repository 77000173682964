import { useMemo } from "react";

import { mapValues } from "lodash";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useFlexLayout, useRowSelect } from "react-table";
import { compose, withProps } from "recompose";

import { DndTable as Table } from "@dpdgroupuk/mydpd-ui";

import { ReturnToConsignor as Fields } from "../../../../constants/forms";
import { APPLY_THIS_INSTRUCTION_TO_THIS_PARCEL } from "../../../../constants/strings";
import { getReferencesString } from "../../../../models/parcel";
import { getParcelFormValues, getRelatedParcelValues } from "../../selectors";
import styles from "./RelatedItems.module.scss";

const renderStatus = ({
  row: {
    original: { parcelStatusHtml },
  },
}) => (
  <span
    dangerouslySetInnerHTML={{
      __html: parcelStatusHtml,
    }}
  />
);

export const columns = [
  {
    Header: "Parcel No",
    accessor: "parcelNumber",
    width: 80,
  },
  {
    Header: "Senders Ref",
    Cell: ({ row: { original } }) => getReferencesString(original),
    width: 150,
  },
  {
    Header: "Status",
    Cell: renderStatus,
    width: 150,
  },
];

const RelatedItems = ({ relatedParcels, onChangeCheckbox }) => {
  // eslint-disable-next-line
  const memoColumns = useMemo(() => columns, [columns]);
  const initialRowsChecked = useMemo(() => {
    // { selectedRowIds: {0: true, 3: true, 2: true ...} } // all parcels are selected by default
    const selectedRowIds = Object.assign(
      {},
      new Array(relatedParcels.length).fill(true)
    );
    return { selectedRowIds };
  }, [relatedParcels]);

  return (
    <Container className="mb-4 w-100">
      <h6>{APPLY_THIS_INSTRUCTION_TO_THIS_PARCEL}</h6>
      <div className={styles.table}>
        <Table
          columns={memoColumns}
          data={relatedParcels}
          getSelectedRows={onChangeCheckbox}
          initialState={initialRowsChecked}
          tableHooks={[useFlexLayout, useRowSelect]}
          selectable
          classes={{ container: styles.tableContent }}
        />
      </div>
    </Container>
  );
};

RelatedItems.propTypes = {
  relatedParcels: PropTypes.array,
  onChangeCheckbox: PropTypes.func,
};

export default compose(
  connect(state => ({
    relatedParcelValues: getRelatedParcelValues(state),
    formState: getParcelFormValues(state),
  })),
  withProps(props => {
    const { initialize, relatedParcelValues, formState } = props;
    return {
      onChangeCheckbox: selectedParcels => {
        const newValues = {};
        mapValues(relatedParcelValues, (value, key) => {
          newValues[key] = selectedParcels.some(
            parcel => parcel.parcelCode === key
          );
        });
        initialize({
          ...formState,
          ...newValues,
          [Fields.ALL_CHECKBOXES]:
            selectedParcels.length === Object.keys(relatedParcelValues).length,
        });
      },
    };
  })
)(RelatedItems);
