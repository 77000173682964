import { useMemo } from "react";

import classNames from "classnames";
import { get } from "lodash";
import PropTypes from "prop-types";

import styles from "./CarouselItem.module.scss";

const CarouselItem = ({
  item,
  index,
  parcel = {},
  selectedPickupShop,
  onClickItem,
}) => {
  const parcelPickupShop = parcel.pickupLocationCode;
  const selectedShop = get(
    selectedPickupShop,
    "pickupLocation.pickupLocationCode"
  );
  const currentPickupShop = get(item, "pickupLocation.pickupLocationCode");

  const isSelected = useMemo(
    () =>
      currentPickupShop === selectedShop ||
      currentPickupShop === parcelPickupShop,
    [parcelPickupShop, selectedShop, currentPickupShop]
  );
  return (
    <div
      className={classNames(styles.sliderItem, isSelected && styles.active)}
      onClick={() => onClickItem(item)}
    >
      <div className={styles.itemIndex}>
        <div className={styles.itemIndexInner}>
          <span>{index + 1}</span>
        </div>
      </div>
      <div className={styles.shopName}>
        {item.pickupLocation.address.organisation.toLocaleUpperCase()}
      </div>
    </div>
  );
};

CarouselItem.propTypes = {
  item: PropTypes.object,
  onClickItem: PropTypes.func,
  parcel: PropTypes.object,
  index: PropTypes.number,
  selectedPickupShop: PropTypes.object,
};

export default CarouselItem;
