import moment from "moment";
import PropTypes from "prop-types";

import { DEFAULT_DATE_FORMAT } from "../../../../../../constants/dateFormats";
import { CONFIRM_PICKUP_SHOP } from "../../../../../../constants/strings";
import styles from "./PromptInner.module.scss";

export const PromptMessage = ({
  pickupLocation,
  pickupDate,
  collectionTime,
}) => (
  <div className={styles.container}>
    <h2 className={styles.title}>{CONFIRM_PICKUP_SHOP}</h2>
    <p className={styles.subtitle}>
      {`Would you like to continue and Pickup your delivery from ${pickupLocation.address.organisation}, ${pickupLocation.address.town}`}
    </p>
    <p className={styles.desc}>
      {`Your parcel will be ready to collect ${
        collectionTime ? `after ${collectionTime}` : ""
      } on ${moment(pickupDate).format(DEFAULT_DATE_FORMAT)}`}
    </p>
  </div>
);

PromptMessage.propTypes = {
  pickupLocation: PropTypes.object,
  pickupDate: PropTypes.string,
  collectionTime: PropTypes.string,
};
