import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { Field, reduxForm, propTypes as ReduxFormPropTypes } from "redux-form";

import { withOverlay, withPrompt } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import CardWithTitle from "../../../../components/CardWithTitle";
import {
  DISPOSE_MODAL,
  DISPOSE_REQUEST_ID,
} from "../../../../constants/analytics";
import {
  disposeRequest as Fields,
  PARCEL_EDIT_FORM,
} from "../../../../constants/forms";
import {
  ACTION_CAN_NOT_BE_COMPLETED,
  ACTION_WILL_APPLY,
  CONFIRM_DISPOSED,
  DISPOSE_REQUEST,
  DISPOSE_REQUEST_TITLE,
  TICK_CHECKBOX,
} from "../../../../constants/strings";
import { deliveryEditValidators } from "../../../../models/validators";
import { DELIVERIES } from "../../../../router";
import { disposeRequest } from "../../../Parcel/actions";
import CustomCheckbox from "../../components/CustomCheckbox";
import EditPanel from "../../components/EditPanel";
import { hadleInputFocus } from "../../hocs";

const DisposeRequest = ({
  onClickCheckbox,
  handleSubmit,
  onSubmit,
  error,
  ...rest
}) => (
  <Col className="col-12 p-0">
    <CardWithTitle title={DISPOSE_REQUEST_TITLE}>
      <EditPanel
        onSubmit={handleSubmit(onSubmit)}
        error={error}
        offContactDetailsValidation={true}
        {...rest}
      >
        <Field
          component={CustomCheckbox}
          name={Fields.AGREEMENT}
          label={`${CONFIRM_DISPOSED} (${ACTION_WILL_APPLY})`}
          id={Fields.AGREEMENT}
          onFocus={onClickCheckbox}
        />
      </EditPanel>
    </CardWithTitle>
  </Col>
);

DisposeRequest.defaultProps = {
  onSubmit: () => null,
  onClickCheckbox: () => null,
};

DisposeRequest.propTypes = {
  onChangePostCode: PropTypes.func,
  onClickCheckbox: PropTypes.func,
  ...ReduxFormPropTypes,
};

export default compose(
  connect(null, {
    onConfirmDisposeRequest: disposeRequest,
  }),
  withOverlay,
  withPrompt,
  withProps(({ prompt, history, match, reloadParcelFn }) => ({
    disposeRequestHandler: (
      parcelCode,
      { message, onClickConfirm, onClickReject, onLoad }
    ) => {
      onLoad && onLoad();
      prompt.showConfirmation({
        message,
        onConfirm: () =>
          onClickConfirm(parcelCode)
            .then(() => {
              history.push(`${DELIVERIES}/${parcelCode}`);
              match.params.parcelCode === parcelCode && reloadParcelFn(true);
            })
            .catch(() => {
              prompt.showInfo({
                message: ACTION_CAN_NOT_BE_COMPLETED,
              });
            }),
        onReject: onClickReject,
      });
    },
  })),
  reduxForm({
    form: PARCEL_EDIT_FORM,
    validate: deliveryEditValidators.disposeRequest,
    onSubmit(
      values,
      dispatch,
      {
        match,
        disposeRequestHandler,
        onConfirmDisposeRequest,
        onLoadDisposeRequestModal,
        onCancelDisposeRequest,
      }
    ) {
      disposeRequestHandler(match.params.parcelCode, {
        onLoad: onLoadDisposeRequestModal,
        message: DISPOSE_REQUEST,
        onClickConfirm: onConfirmDisposeRequest,
        onClickReject: onCancelDisposeRequest,
      });
    },
  }),
  withProps(({ submitFailed, invalid }) => ({
    error: submitFailed && invalid && TICK_CHECKBOX,
  })),
  withTrack(DISPOSE_REQUEST_ID.LOAD),
  withTrackProps({
    onSubmit: DISPOSE_REQUEST_ID.CLICK_SUBMIT,
    onConfirmDisposeRequest: DISPOSE_MODAL.SUBMIT_DISPOSE_REQUEST,
    onCancelDisposeRequest: DISPOSE_MODAL.CANCEL_DISPOSE_REQUEST,
    onLoadDisposeRequestModal: DISPOSE_MODAL.LOAD_MODAL,
    onClickCheckbox: DISPOSE_MODAL.CLICK_CHECKBOX,
  }),
  hadleInputFocus
)(DisposeRequest);
