import PropTypes from "prop-types";
import { compose } from "recompose";

import { Header } from "@dpdgroupuk/mydpd-app";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { DELIVERIES_MENU, MENU } from "./constants/analytics";
import { DASHBOARD, SEARCH, WATCH_LIST } from "./constants/strings";
import * as routes from "./router/constants";

const secondaryMenuItems = [
  {
    href: routes.SEARCH,
    name: SEARCH,
  },
  {
    href: routes.DASHBOARD,
    name: DASHBOARD,
  },
  {
    href: routes.WATCHLIST,
    name: WATCH_LIST,
  },
];

export const AppHeader = ({
  onPrimaryMenuSelect,
  onSecondaryMenuSelect,
  ...headerProps
}) => (
  <Header
    secondaryMenuItems={secondaryMenuItems}
    onPrimaryMenuSelect={onPrimaryMenuSelect}
    onSelectSecondaryMenu={onSecondaryMenuSelect}
    {...headerProps}
  />
);

AppHeader.propTypes = {
  onPrimaryMenuSelect: PropTypes.func,
  onSecondaryMenuSelect: PropTypes.func,
};

const PRIMARY_INDEX_TO_ACTION_ID = {
  0: MENU.CLICK_MYDPD,
  1: MENU.CLICK_SHIPPING,
  2: MENU.CLICK_COLLECTIONS,
  3: MENU.CLICK_DELIVERIES,
  4: MENU.CLICK_SHOP_RETURNS,
  5: MENU.CLICK_ADDRESS_BOOK,
  6: MENU.CLICK_DEPOT_FINDER,
};

const SECONDARY_INDEX_TO_ACTION_ID = {
  0: DELIVERIES_MENU.CLICK_SEARCH,
  1: DELIVERIES_MENU.CLICK_DASHBOARD,
  2: DELIVERIES_MENU.CLICK_WATCH_LIST,
};

export default compose(
  withTrack(MENU.LOAD),
  withTrackProps({
    onPrimaryMenuSelect: index => PRIMARY_INDEX_TO_ACTION_ID[index],
    onSecondaryMenuSelect: index => SECONDARY_INDEX_TO_ACTION_ID[index],
    onClickDpdLogo: MENU.CLICK_DPD_LOGO,
    onClickHelp: MENU.CLICK_HELP,
    onClickLogOff: MENU.CLICK_LOG_OFF,
  })
)(AppHeader);
