import { useMemo } from "react";

import { Col, Container, Row } from "react-bootstrap";
import ReactRouterPropTypes from "react-router-prop-types";
import { branch, compose, renderComponent } from "recompose";

import { Card, Error, Main } from "@dpdgroupuk/mydpd-ui";
import icon from "@dpdgroupuk/mydpd-ui/assets/no-search-results.svg";

import { FILTER_INITIAL_VALUES } from "../../constants/forms";
import {
  NO_WATCH_LIST_ITEMS,
  NO_WATCH_LIST_RESULTS,
  WATCH_LIST,
} from "../../constants/strings";
import SearchFilterForm from "../../containers/FilterForm";
import {
  areEmptyFilters,
  getFiltersInitialValues,
  getQueryPagination,
} from "../../utils/query";
import WatchList, { DeliveryListPropTypes } from "./WatchList";

const WatchListView = params => {
  const {
    enums,
    parcels,
    findByCode,
    location,
    totalCount,
    onReset,
    onFilter,
    onNext,
    onPrevious,
    onFirst,
    onLast,
    onClickRow,
    selectedItems,
    onRemoveClick,
    onExportPress,
    onFieldEntry,
    products,
    countries,
    services,
    depots,
    setSelectedItems,
    customers,
    match,
  } = params;

  const { page, pageSize, filters, emptyFilters } = useMemo(
    () => ({
      ...getQueryPagination(location),
      filters: getFiltersInitialValues({
        location,
        defaultValues: FILTER_INITIAL_VALUES,
        customers,
      }),
      emptyFilters: areEmptyFilters(location),
    }),
    // eslint-disable-next-line
    [location]
  );

  return (
    <Main body>
      <Card.Stack fluid>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <SearchFilterForm
                emptyFilters={emptyFilters}
                initialValues={filters}
                enums={enums}
                onReset={onReset}
                onFilter={onFilter}
                onFieldEntry={onFieldEntry}
                products={products}
                countries={countries}
                services={services}
                depots={depots}
                location={location}
                title={WATCH_LIST}
              />
            </Col>
            <Col className="col-12">
              <WatchList
                onClickRow={onClickRow}
                page={page}
                totalCount={totalCount}
                pageSize={pageSize}
                onNext={onNext}
                onPrevious={onPrevious}
                onFirst={onFirst}
                onLast={onLast}
                parcels={parcels}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                isItemSelected={!!selectedItems.length}
                onRemoveClick={onRemoveClick}
                onExportPress={onExportPress}
                findByCode={findByCode}
                deliveryType={match.params.deliveryType}
              />
            </Col>
          </Row>
        </Container>
      </Card.Stack>
    </Main>
  );
};

// eslint-disable-next-line no-unused-vars
const { page, pageSize, ...SearchListPropTypes } = DeliveryListPropTypes;

WatchListView.propTypes = {
  ...SearchListPropTypes,
  ...SearchFilterForm.propTypes,
  ...ReactRouterPropTypes,
};

export default compose(
  branch(
    props => props.parcels.length === 0,
    renderComponent(() => (
      <Error
        icon={icon}
        message={NO_WATCH_LIST_RESULTS}
        subtitle={NO_WATCH_LIST_ITEMS}
      />
    ))
  )
)(WatchListView);
