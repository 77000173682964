import { connect } from "react-redux";
import { compose } from "recompose";

import { withNotifier } from "@dpdgroupuk/mydpd-app";

import { DELIVERIES, SEARCH } from "../../router";
import { createLocationState, stringifyQuery } from "../../utils/query";
import { searchDeliveries } from "./actions";

export default compose(
  withNotifier,
  connect(null, (dispatch, { history }) => ({
    searchHandler: async (values, fetchOptions) => {
      try {
        const {
          parcelCodes = [],
          findByCode,
          expiresIn,
        } = await dispatch(searchDeliveries(values, undefined, fetchOptions));

        if (parcelCodes.length === 1) {
          history.push(`${DELIVERIES}/${parcelCodes[0]}`);
        } else {
          history.push(
            `${DELIVERIES}?${stringifyQuery({
              ...values,
            })}`,
            createLocationState({
              searchFindByCode: findByCode,
              expiresIn,
            })
          );
        }
      } catch (e) {
        history.push(SEARCH);
        throw e;
      }
    },
  }))
);
