import { useMemo } from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field, propTypes as ReduxFormPropTypes } from "redux-form";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";
import { FormControl, withSnackbar } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import CardWithTitle from "../../../../components/CardWithTitle";
import { COLLECT_FROM_DEPOT } from "../../../../constants/analytics";
import { CollectFromDepot as Fields } from "../../../../constants/forms";
import {
  COLLECT_DEPOT,
  COLLECT_PARCEL_NOTICE,
  SELECT_DATE,
  SELECT_TIME,
  UPPERCASE,
} from "../../../../constants/strings";
import { deliveryEditValidators } from "../../../../models/validators";
import { formatDDMMYYYY } from "../../../../utils/date";
import { fetchDepot, onCollectFromDepot } from "../../actions";
import DepotDetails from "../../components/DepotDetails/DepotDetails";
import EditPanel from "../../components/EditPanel";
import { copyTextToClipboard } from "../../helpers";
import { hadleInputFocus, loadActionDates, withParcelEdit } from "../../hocs";
import { getDepot } from "../../selectors";
import styles from "./CollectFromDepot.module.scss";

const timeSlots = [
  {
    label: "09:00 - 10:00",
    value: "09:00",
  },
  {
    label: "10:00 - 11:00",
    value: "10:00",
  },
  {
    label: "11:00 - 12:00",
    value: "11:00",
  },
  {
    label: "12:00 - 13:00",
    value: "12:00",
  },
  {
    label: "13:00 - 14:00",
    value: "13:00",
  },
  {
    label: "14:00 - 15:00",
    value: "14:00",
  },
];

const changeDateFormat = dates => dates.map(date => formatDDMMYYYY(date));

const CollectFromDepot = ({
  handleSubmit,
  onSubmit,
  error,
  depot,
  dates,
  onClickCopy,
  onClickDropdown,
  snackbar,
  ...rest
}) => {
  const isEmptyDepot = useMemo(() => isEmpty(depot), [depot]);

  const formattedDates = useMemo(() => changeDateFormat(dates), [dates]);

  return (
    !isEmptyDepot && (
      <Col className="col-12 p-0">
        <CardWithTitle title={COLLECT_DEPOT}>
          <Row>
            <Col className="col-12 col-md-4">
              <Field
                component={FormControl.Dropdown}
                onChange={onClickDropdown}
                values={formattedDates}
                label={SELECT_DATE}
                name={Fields.DIARY_DATE}
                textTransform={UPPERCASE}
              />
            </Col>
            <Col className="col-12 col-md-4">
              <Field
                component={FormControl.Dropdown}
                onChange={onClickDropdown}
                values={timeSlots}
                label={SELECT_TIME}
                name={Fields.DIARY_TIME}
                textTransform={UPPERCASE}
              />
            </Col>
          </Row>
          <Col className="col-12">
            <DepotDetails
              depot={depot}
              onClickCopy={copyTextToClipboard(snackbar, onClickCopy)}
            />
          </Col>
          <EditPanel
            onSubmit={handleSubmit(onSubmit)}
            error={error}
            additionalInfoComponent={
              <div className={styles.notice}>{COLLECT_PARCEL_NOTICE}</div>
            }
            {...rest}
          />
        </CardWithTitle>
      </Col>
    )
  );
};

CollectFromDepot.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  ...ReduxFormPropTypes,
};

export default compose(
  loadActionDates,
  connect(),
  withAppLoader({
    loadFn: ({ dispatch, parcel }, fetchOptions) =>
      dispatch(fetchDepot(parcel.deliveryDepot.depotCode, fetchOptions)),
  }),
  connect(state => ({
    depot: getDepot(state),
  })),
  withParcelEdit({
    onSubmit: onCollectFromDepot,
    validate: deliveryEditValidators.collectFromDepot,
  }),
  withSnackbar,
  withTrack(COLLECT_FROM_DEPOT.LOAD),
  withTrackProps({
    onSubmit: COLLECT_FROM_DEPOT.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      switch (fieldName) {
        case Fields.DIARY_DATE:
          return COLLECT_FROM_DEPOT.SELECT_DATE;
        case Fields.DIARY_TIME:
          return COLLECT_FROM_DEPOT.SELECT_TIME;
        case Fields.CONTACT_NAME:
          return COLLECT_FROM_DEPOT.CONTACT_NAME_INPUT;
        case Fields.MOBILE:
          return COLLECT_FROM_DEPOT.MOBILE_NUMBER_INPUT;
        case Fields.EMAIL:
          return COLLECT_FROM_DEPOT.EMAIL_ADDRESS_INPUT;
        default:
          break;
      }
    },
    onClickDropdown: COLLECT_FROM_DEPOT.CLICK_DROP_DOWN_LIST,
    onClickCopy: COLLECT_FROM_DEPOT.CLICK_COPY_ADDRESS,
  }),
  hadleInputFocus
)(CollectFromDepot);
