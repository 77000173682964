const createPageMeta = (interfaceId, rest = {}) => ({
  INTERFACE_ID: interfaceId,
  ...rest,
});

export const SEARCH_DELIVERY = createPageMeta("jCg.AM6FYFAUAhfA", {
  CLICK_SEARCH: "y99yEs6C48kIGFGP",
  CLICK_ACCOUNT_DROPDOWN: "3o3EqM6FYFAUAicF",
  CLICK_CASE_REFERENCE: "uSkyqM6FYFAUAn7v",
  CLICK_COLLECTION_NO: "JmziqM6FYFAUAn5B",
  CLICK_CUSTOMER_REFERENCE: "hx5izM6FYFAUAnyk",
  CLICK_DATE_PICKER_FROM: "dFnazM6FYFAUAogJ",
  CLICK_DATE_PICKER_TO: "SkGnzM6FYFAUAmjg",
  CLICK_DATE_SEARCH: "eIryzM6FYFAUAoFj",
  CLICK_EMAIL_ADDRESS: "XIRSzM6FYFAUAoBN",
  CLICK_PARCEL_REFERENCE: "65ZcqM6FYFAUAnk2",
  CLICK_PHONE_NUMBER: "VZYyzM6FYFAUAoDN",
  CLICK_POSTCODE: "7H1SqM6FYFAUAn66",
  CLICK_FIND_POSTCODE: "rq9AnM6FYFAUAi1c",
  LOAD: "lZIhAM6FYFAUAhuK",
  SELECT_FROM_DATE: "ZNaGzM6FYFAUAoiu",
  SELECT_TO_DATE: "K29HzM6FYFAUAmUY",
  TEXT_ENTRY: "Ei9szM6FYFAUAnAs",
});

export const FIND_POSTCODE_MODAL = createPageMeta("fL_wnM6FYFAUAjXJ", {
  LOAD: "GkcYnM6FYFAUAkEM",
  CLICK_ADDRESS: "OhB4nM6FYFAUAkfm",
  CLICK_CLEAR: "AhbYnM6FYFAUAkSb",
  CLICK_FIND_POSTCODE: "IzCEnM6FYFAUAkg",
});

export const SEARCH_RESULT_LIST = createPageMeta("yTFtdnaD.AAAAR9e");

export const DASHBOARD = createPageMeta("mROtN86C48kIGEG_", {
  LOAD: "QP6dN86C48kIGEvM",
  DASHBOARD: "mROtN86C48kIGEG_",
  CLICK_DATA_ONLY_NO_PARCEL: "WjocP86C48kIGOdR",
  CLICK_EXCEPTIONS: "60VcP86C48kIGOgM",
  CLICK_INSTRUCTIONS_REQUIRED: "v9Qf386C48kIGDRM",
  CLICK_INSTRUCTIONS_IN_PROGRESS: "J1A0P86C48kIGN5h",
  CLICK_OVERDUE: "dvI8P86C48kIGOjD",
  CLICK_PARCELS_NO_DATA: "ZVhsP86C48kIGOOx",
  CLICK_PROALERT: "Jkl8P86C48kIGOlU",
  CLICK_RETURNS: "bgACP86C48kIGOnZ",
  CLICK_SUBMIT: "usj4va6C48kIGNHY",
  CLICK_WATCH_LIST: "gIqCP86C48kIGAAZ",
});

export const DASHBOARD_RESULT_VIEW = createPageMeta("fJLB3s6C48kIGOYx", {
  LOAD: "DxgqPs6C48kIGAqb",
});

export const OUT_FOR_PARCEL = createPageMeta("2g4p9naD.AAAAVky", {
  LOAD: "LxuLlfaD.AAAAWzi",
});

export const GENERAL_VIEW = createPageMeta("RCPJN86C48kIGMhX", {
  LOAD: "pfo5N86C48kIGNQ8",
  DELIVERY_HISTORY: "pfhszi6C48kIGOai",
  CONSIGNMENT: ".DR8zi6C48kIGAIw",
  ADDITIONAL_INFORMATION: "cwkCzi6C48kIGAL3",
  VIDEO: "D4n4Pa6C48kIGFdH",
  CHAT: "6SxQPa6C48kIGLfm",
  CLICK_SHOW_SHOP_MAP_PICKUP_DELIVERY: "eLxGbm6C48kIGNs5", // 4
  CLICK_SHOW_SHOP_MAP_COLLECT_FROM_DEPOT: "AWotUW6C48kIGELA", // 3
});

export const PARCEL_DETAILS_COMPONENT = {
  LOAD: "hKgP8faD.AAAATGn",
};

export const PARCEL_HISTORY_COMPONENT = {
  LOAD: "hKgP8faD.AAAATGn",
  CLOSE: "RoyVrm6C48kIGEUx",
  IMAGE: "NFMRjm6C48kIGOfx",
  NEXT_IMAGE: "V9DVrm6C48kIGEWM",
  PREVIOUS_IMAGE: "t.k1rm6C48kIGEXp",
  CLICK_MAP: "",
  CLOSE_MAP: "",
};

export const PARCEL_OPTIONS_COMPONENT = {
  LOAD: "hKgP8faD.AAAATGn",
};

export const CHANGE_PARCEL_DATE_COMPONENT = {
  LOAD: "",
};

export const CHANGE_COLLECTION_DATE_COMPONENT = {
  LOAD: "jKUTN_aD.AAAAVQG",
  CLICK_CONTACT_NAME: "9_a0j_aD.AAAAYCf",
  CLICK_EMAIL: "tWW0j_aD.AAAAYCl",
  CLICK_SMS: "MeO0j_aD.AAAAYCr",
  CLICK_DATE: "7G_Yt_aD.AAAAV8F",
  CLICK_INSTRUCTIONS: "Yp5XD_aD.AAAAWiH",
  CLICK_SUBMIT: ".WlvD_aD.AAAAW77",
  CLICK_BACK: "pXqoj_aD.AAAAXvw",
};

export const CHANGE_COLLECTION_INSTRUCTION_COMPONENT = {
  LOAD: "90HAb_aD.AAAAXDH",
  CLICK_CONTACT_NAME: "URWQb_aD.AAAAXUg",
  CLICK_EMAIL: ".JWQb_aD.AAAAXUs",
  CLICK_SMS: "wFWQb_aD.AAAAXVG",
  CLICK_INSTRUCTIONS: "gZWQb_aD.AAAAXU4",
  CLICK_SUBMIT: "Utkwb_aD.AAAAXXu",
  CLICK_BACK: "reWQb_aD.AAAAXUU",
};

export const DELIVER_TO_NEIGHBOUR_COMPONENT = {
  LOAD: "",
};

export const CHANGE_SAFE_PLACE_COMPONENT = {
  LOAD: "",
};

export const CANCEL_DELIVERY_COMPONENT = {
  LOAD: "",
};

export const MENU = {
  LOAD: "y2WbKM6FYFAUAuYp",
  CLICK_SEARCH: "LXGrKM6FYFAUAuW9",
  CLICK_ADDRESS_BOOK: "jUXzKM6FYFAUAuUi",
  CLICK_COLLECTIONS: "gYMTKM6FYFAUAuPR",
  CLICK_DPD_LOGO: "4emvKM6FYFAUAmjL",
  CLICK_DEPOT_FINDER: "AXbLKM6FYFAUAuV6",
  CLICK_HELP: "7VHrKM6FYFAUAuXi",
  CLICK_LOG_OFF: "y2WbKM6FYFAUAuYp",
  CLICK_MYDPD: "AR9DKM6FYFAUAuK8",
  CLICK_SHIPPING: "1U1jKM6FYFAUAuN",
  CLICK_SHOP_RETURNS: "OFWzKM6FYFAUAuTr",
};

export const DELIVERIES_MENU = {
  CLICK_SEARCH: "tS2wqM6FYFAUAnfs",
  CLICK_DASHBOARD: "cB3wqM6FYFAUAnjH",
  CLICK_WATCH_LIST: "a9yIqM6FYFAUAnju",
};

export const MAP_VIEW = {
  LOAD: "",
};

export const ETA_BAR = {
  LOAD: "",
};

export const PARCEL_STATUS = {
  LOAD: "",
};

export const SHIPMENT_DETAILS = {
  LOAD: "",
};

export const FOOTER = {
  LOAD: "",
};

export const SEARCH_FILTER_LIST = {
  LOAD: "DxgqPs6C48kIGAqb",
  CLICK_PRODUCT: "TKjw2c6C48kIGHGj",
  CLICK_DELIVERY_COUNTRY: "plW42c6C48kIGH4F",
  ACCOUNT_SELECTION: "w52P.c6C48kIGBYP",
  CLICK_SERVICE: "woko2c6C48kIGHxL",
  CLICK_FIND_POSTCODE: "0offWc6C48kIGHz5",
  CLICK_COLLECTION_COUNTRY: "w9jP.c6C48kIGBaf",
  CLICK_DELIVERY_DEPOT: "aAxo2c6C48kIGHy_",
  CLICK_DESTINATION: "F4Br.c6C48kIGKOn",
  CLICK_RANGE: "azsv.c6C48kIGBcY",
  CLICK_OFD_TODAY: "SdWY2c6C48kIGH1A",
  CLICK_DELIVERED: "wQw42c6C48kIGH2x",
  CLICK_SORT_BY: "i.D3.c6C48kIGBGt",
  CLICK_DIRECTION: "GhDv.c6C48kIGBh9",
  CLICK_FILTER: "eEB_.c6C48kIGI10",
  CLICK_EXPORT: "i.D3.c6C48kIGBGt",
  CLICK_NEXT_PAGE: "aTbfWc6C48kIGHxc",
  CLICK_PREVIOUS_PAGE: "4PNA2c6C48kIGH.4",
  CLICK_DELIVERY: "tZGQ2c6C48kIGIQQ",
  CLICK_REMOVE_FROM_WATCH_LIST: "oytLP86C48kIGJK5",
  CLICK_CHECKBOX: "bjprP86C48kIGJp_",
};

export const SEARCH = {
  LOAD: "LD8ap46D.AAAAYev",
  CLICK_SEARCH: "zrDAM3aD.AAAAYOh",
  CLICK_SEARCH_SELECTOR: "VF.AM3aD.AAAAYD_",
};

export const DELIVERY_IMAGE_VIEW = {
  LOAD: "",
};

export const WATCH_LIST_MODAL = {
  SUBMIT_ADD_TO_WATCHLIST: "NCEmDS6FYFxo8Kmm",
  REJECT_ADD_TO_WATCHLIST: "FMEmDS6FYFxo8Kl2",
  SUBMIT_REMOVE_FROM_WATCHLIST: "iBHKDS6FYFxo8JpV",
  REJECT_REMOVE_FROM_WATCHLIST: "qqHKDS6FYFxo8JoI",
};

export const DISPOSE_MODAL = createPageMeta("iyEgma6C48kIGKj0", {
  LOAD_MODAL: "qGWQma6C48kIGKvY",
  SUBMIT_DISPOSE_REQUEST: "3JWQma6C48kIGKx_",
  CANCEL_DISPOSE_REQUEST: "AuWQma6C48kIGKwO",
});

export const EXTRACT_CSV_MODAL = createPageMeta("1kZ_Bm6C48kIGAiG", {
  CLOSE: "Xfawhm6C48kIGBGl",
  LINK: "MnlQhm6C48kIGBBq",
  LOAD: ".JcCghm6C48kIGAyR",
});

export const RETURN_TO_CONSIGNOR = {
  LOAD: "",
  CLICK_ADDRESS_DESCRIPTION: "drn4ny6GAqBWYlx0",
  CLICK_DROP_DOWN_LIST: "ANebKy6FYFxo8Ir8",
  CLICK_PARCEL_NO_CHECKBOX: "fM4PKy6FYFxo8AK5",
  CLICK_PARCEL_NO_CHECKBOX_SELECT_ALL: "UV9nKy6FYFxo8JXz",
  CLICK_SUBMIT: "3w.bKy6FYFxo8Iz3",
  CLICK_TERMS_CHECKBOX: "U4LHKy6FYFxo8JUs",
  CONTACT_NAME_INPUT: "IrebKy6FYFxo8Iu_",
  EMAIL_ADDRESS_INPUT: "83ebKy6FYFxo8IxB",
  INSTRUCTIONS_INPUT: "0TebKy6FYFxo8It.",
  MOBILE_NUMBER_INPUT: "kHebKy6FYFxo8IwA",
  REFERENCE_INPUT: "dusHKy6FYFxo8JOF",
};

export const ADJUST_SERVICE = {
  LOAD: "",
  CLICK_DATE: "bFw1Aq6FYFxqCBK4",
  CLICK_DATE_DROP_DOWN_LIST: "LGw1Aq6FYFxqCBGv",
  CLICK_DELIVERY_SERVICE: "JPKvAq6FYFxqCEnP",
  CLICK_DELIVERY_SERVICE_DROP_DOWN_LIST: "wuePAq6FYFxqCEfy",
  CLICK_PARCEL_NO_CHECKBOX: "U8w1Aq6FYFxqCBEQ",
  CLICK_PARCEL_NO_CHECKBOX_SELECT_ALL: "nYw1Aq6FYFxqCBCS",
  CLICK_SUBMIT: "rHQ1Aq6FYFxqCA.V",
  CLICK_TERMS_CHECKBOX: "xgw1Aq6FYFxqCBA0",
  CONTACT_NAME_INPUT: "OuQ1Aq6FYFxqCA4C",
  EMAIL_ADDRESS_INPUT: "jZQ1Aq6FYFxqCA6U",
  INSTRUCTIONS_INPUT: "NGQ1Aq6FYFxqCA24",
  MOBILE_NUMBER_INPUT: "BRQ1Aq6FYFxqCA5L",
  REFERENCE_INPUT: "9PQ1Aq6FYFxqCA_e",
};

export const COLLECT_FROM_PICKUP_SHOP = {
  LOAD: "",
  CLICK_SUBMIT: "lpdLJq6FYFxq1UxK",
  CONTACT_NAME_INPUT: "O4dLJq6FYFxq1Upy",
  EMAIL_ADDRESS_INPUT: "HSdLJq6FYFxq1Usk",
  MOBILE_NUMBER_INPUT: "EsdLJq6FYFxq1UrL",
  CLICK_COPY_ADDRESS: "n9dLJq6FYFxq1Uz7",
  CLICK_NEXT: "HQdLJq6FYFxq1UoZ",
  CLICK_PREVIOUS: "uVdLJq6FYFxq1Uyi",
  CLICK_PICKUP_SHOP: "gJ5VK.6C48kIGIeV",
  CLICK_PICKUP_SHOP_MAP_POINT: "rUmTK.6C48kIGACS",
};

export const ALTERNATIVE_ADDRESS = {
  LOAD: "",
  CLICK_ADDRESS_INPUT: "hr0yEK6FYFxqf4IC",
  CLICK_DATE_DROP_DOWN_LIST: "24c24K6FYFxqf8.3",
  CLICK_FIND_POSTCODE: "SLYUny6GAqBWYl3b",
  CLICK_PARCEL_NO_CHECKBOX: "8NY_Hy6GAqBWYjRR",
  CLICK_PARCEL_NO_CHECKBOX_SELECT_ALL: ".xY_Hy6GAqBWYjPV",
  CLICK_SUBMIT: "8iY_Hy6GAqBWYjKa",
  CLICK_TERMS_CHECKBOX: "yeY_Hy6GAqBWYjN5",
  CONTACT_NAME_INPUT: "1vo_Hy6GAqBWYjE8",
  EMAIL_ADDRESS_INPUT: ".oY_Hy6GAqBWYjHK",
  INSTRUCTIONS_INPUT: "QXo_Hy6GAqBWYjD1",
  MOBILE_NUMBER_INPUT: "DgY_Hy6GAqBWYjGD",
  POSTCODE_INPUT: "z2YMuK6FYFxo5aKk",
  REFERENCE_INPUT: "JmY_Hy6GAqBWYjMl",
};

export const CHANGE_DELIVERY_DATE = {
  LOAD: "",
  CLICK_DATE: "XNiqOS6C48kIGNw0",
  CLICK_DROP_DOWN_LIST: "Xq.yOS6C48kIGNrD",
  CLICK_SUBMIT: "bbRGOS6C48kIGOHl",
  CONTACT_NAME_INPUT: "3braOS6C48kIGN1s",
  EMAIL_ADDRESS_INPUT: "0qe6OS6C48kIGN4Z",
  INSTRUCTIONS_INPUT: "KGzqOS6C48kIGNzX",
  MOBILE_NUMBER_INPUT: "c146OS6C48kIGN2q",
};

export const PROVIDE_DELIVERY_INSTRUCTIONS = {
  LOAD: "",
  CLICK_SUBMIT: ".vDyKy6FYFxo8JRu",
  CONTACT_NAME_INPUT: "_5DyKy6FYFxo8JOs",
  EMAIL_ADDRESS_INPUT: ".dDyKy6FYFxo8JP4",
  INSTRUCTIONS_INPUT: "mpDyKy6FYFxo8JOG",
  MOBILE_NUMBER_INPUT: "R1DyKy6FYFxo8JPS",
};
export const CALL_ME = {
  LOAD: "",
  CLICK_SUBMIT: ".s370gq6FYFxqCEcw",
  CONTACT_NAME_INPUT: "NZ70gq6FYFxqCEWH",
  EMAIL_ADDRESS_INPUT: ".td70gq6FYFxqCEYu",
  NOTES_INPUT: "Ux70gq6FYFxqCEU0",
  MOBILE_NUMBER_INPUT: "JV70gq6FYFxqCEXa",
};

export const DELIVER_TO_NEIGHBOUR = {
  LOAD: "",
  CLICK_DROP_DOWN_LIST: "XN.NBS6C48kIGLIM",
  CLICK_SUBMIT: "iABNBS6C48kIGLN0",
  CONTACT_NAME_INPUT: "JL.NBS6C48kIGLKc",
  EMAIL_ADDRESS_INPUT: "dn.NBS6C48kIGLL8",
  INSTRUCTIONS_INPUT: "KT.NBS6C48kIGLJs",
  MOBILE_NUMBER_INPUT: "a7.NBS6C48kIGLLM",
  SELECT_DATE: "v9.NBS6C48kIGLI8",
  SELECT_NEIGHBOUR: "z113BS6C48kIGNhx",
};

export const UPDATE_CONTACT_DETAILS = {
  LOAD: "",
  CLICK_SUBMIT: "TP8Pyy6FYFxo8HDO",
  CONTACT_NAME_INPUT: "u58Pyy6FYFxo8G9_",
  EMAIL_ADDRESS_INPUT: "Ud8Pyy6FYFxo8G_9",
  MOBILE_NUMBER_INPUT: "eV8Pyy6FYFxo8G..",
};

export const DELIVER_TO_SAFE_PLACE = {
  LOAD: "",
  CLICK_CHECKBOX: "XpSQDS6FYFxo8LYK",
  CLICK_DATE: "9YsAhS6C48kIGFLH",
  CLICK_DROP_DOWN_LIST: "XwsAhS6C48kIGFKU",
  CLICK_SAFE_PLACE: "xs.gDS6FYFxo8LUs",
  CLICK_SUBMIT: "KmsAhS6C48kIGFQN",
  CONTACT_NAME_INPUT: "jMsAhS6C48kIGFMr",
  EMAIL_ADDRESS_INPUT: "4SsAhS6C48kIGFOP",
  INSTRUCTIONS_INPUT: "vksAhS6C48kIGFL5",
  MOBILE_NUMBER_INPUT: "u8sAhS6C48kIGFNd",
};

export const COLLECT_FROM_DEPOT = {
  LOAD: "",
  CLICK_COPY_ADDRESS: "dY54jS6FYFxo8F0G",
  CLICK_DROP_DOWN_LIST: "0IL_DS6FYFxo8EYr",
  CLICK_SUBMIT: "IOL_DS6FYFxo8Efc",
  CONTACT_NAME_INPUT: "3ML_DS6FYFxo8EbT",
  EMAIL_ADDRESS_INPUT: "5SL_DS6FYFxo8EdD",
  MOBILE_NUMBER_INPUT: "t8L_DS6FYFxo8EcL",
  SELECT_DATE: "g4L_DS6FYFxo8EZj",
  SELECT_TIME: "2.L_DS6FYFxo8EgU",
};

export const ALTERNATIVE_ADDRESS_SERVICE = {
  LOAD: "",
  CLICK_ADDRESS_INPUT: "gsRcuK6FYFxo5Ti4",
  CLICK_ADDRESS: "8Iev_K6FYFxqCDy.",
  CLICK_DATE: "9CUiuK6FYFxo5UKP",
  CLICK_DATE_DROP_DOWN_LIST: "ckRcuK6FYFxo5TiM",
  CLICK_DELIVERY_SERVICE: "lllPAq6FYFxqCEju",
  CLICK_DELIVERY_SERVICE_DROP_DOWN_LIST: "tG13Aq6FYFxqCCM5",
  CLICK_FIND_POSTCODE: "lIRcuK6FYFxo5ThT",
  CLICK_PARCEL_NO_CHECKBOX: "_ARcuK6FYFxo5Tgn",
  CLICK_PARCEL_NO_CHECKBOX_SELECT_ALL: "wnhcuK6FYFxo5TfG",
  CLICK_SUBMIT: "t1hcuK6FYFxo5Tci",
  CLICK_TERMS_CHECKBOX: "QLhcuK6FYFxo5TeH",
  CONTACT_NAME_INPUT: "bKhcuK6FYFxo5TX5",
  EMAIL_ADDRESS_INPUT: "c2hcuK6FYFxo5TZR",
  INSTRUCTIONS_INPUT: "lihcuK6FYFxo5TXN",
  MOBILE_NUMBER_INPUT: "IGhcuK6FYFxo5TYl",
  POSTCODE_INPUT: "8SRcuK6FYFxo5Tj4",
  REFERENCE_INPUT: "a9hcuK6FYFxo5TdO",
};

export const DISPOSE_REQUEST_ID = {
  LOAD: "",
  CLICK_CHECKBOX: "eR.XGa6C48kIGMXx",
  CLICK_SUBMIT: "n.jfGa6C48kIGJf6",
};
