import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import { Field } from "redux-form";

import { Fields } from "../../../../constants/forms";
import { ACCEPT_COST, ONLY_DELIVER } from "../../../../constants/strings";
import CustomCheckbox from "../CustomCheckbox";
import styles from "./TermsAndInstructions.module.scss";

const TermAndInstructions = ({ checkboxId }) => (
  <Row>
    <div className={styles.infoText}>{ONLY_DELIVER}</div>
    <Field
      component={CustomCheckbox}
      name={Fields.AGREEMENT}
      label={ACCEPT_COST}
      id={checkboxId || Fields.AGREEMENT}
      classes={{ container: "pl-0" }}
    />
  </Row>
);

TermAndInstructions.propTypes = {
  checkboxId: PropTypes.string,
};

export default TermAndInstructions;
