import { forwardRef } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import ElasticCarousel from "react-elastic-carousel";

import { Arrow } from "../Icon";
import styles from "./Carousel.module.scss";

const DefaultArrows = ({ type, onClick, isEdge }) => (
  <button
    onClick={onClick}
    disabled={isEdge}
    className={classNames(styles.button)}
  >
    <Arrow
      type={type}
      className={type === "PREV" ? styles.prevButton : styles.nextButton}
    />
  </button>
);

const DefaultCarouselItem = ({ index }) => <div>{index}</div>;

// eslint-disable-next-line react/display-name
const Carousel = forwardRef(
  (
    {
      itemsToShow,
      pagination,
      focusOnSelect,
      renderArrow,
      className,
      itemPadding,
      CarouselItem,
      onClickCarouselItem,
      carouselItemProps,
      items,
      ...rest
    },
    ref
  ) => (
    <ElasticCarousel
      ref={ref}
      itemsToShow={itemsToShow}
      pagination={pagination}
      focusOnSelect={focusOnSelect}
      renderArrow={renderArrow}
      className={className}
      itemPadding={itemPadding}
      autoTabIndexVisibleItems={true}
      {...rest}
    >
      {items.map((item, index) => (
        <CarouselItem
          key={index}
          item={item}
          index={index}
          {...carouselItemProps}
          onClickItem={onClickCarouselItem}
        />
      ))}
    </ElasticCarousel>
  )
);

Carousel.defaultProps = {
  itemsToShow: 3,
  pagination: false,
  focusOnSelect: true,
  renderArrow: DefaultArrows,
  itemPadding: [0, 8],
  CarouselItem: DefaultCarouselItem,
  autoTabIndexVisibleItems: true,
};

Carousel.propTypes = {
  CarouselItem: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  itemsToShow: PropTypes.number,
  isRTL: PropTypes.bool,
  pagination: PropTypes.bool,
  focusOnSelect: PropTypes.bool,
  className: PropTypes.string,
  onClickCarouselItem: PropTypes.func,
  itemPadding: PropTypes.array,
  renderArrow: PropTypes.func,
  carouselItemProps: PropTypes.object,
  classNameItem: PropTypes.string,
  classNameActiveItem: PropTypes.string,
  autoTabIndexVisibleItems: PropTypes.bool,
};

DefaultCarouselItem.propTypes = {
  index: PropTypes.number,
};

DefaultArrows.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  isEdge: PropTypes.bool,
};

export default Carousel;
