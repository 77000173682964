import { get, isArray } from "lodash";
import moment from "moment";

import { PARCEL_STATUS } from "@dpdgroupuk/redback-enums";

import { joinStringsWithComma } from "../utils/string";
import { PARCEL_CODE } from "./validators/regex";

export const pageToStartPos = (page, pageSize) => (page - 1) * pageSize + 1;

export const pageToPos = (page, pageSize) =>
  page && pageSize
    ? {
        startPos: pageToStartPos(page, pageSize),
        endPos: page * pageSize,
      }
    : {};

export const getContactSmsPhoneEmail = ({
  contact,
  notifySms,
  phoneNumber,
  notifyEmail,
}) => [contact, notifySms, phoneNumber, notifyEmail].filter(v => v).join("\n");

export const getCollectionTimeFromPickupShop = selectedPickupShop => {
  const pickupDate = get(selectedPickupShop, "pickupDate");
  const day = moment(pickupDate).day();
  const driverWindow = get(
    selectedPickupShop,
    "pickupLocation.pickupLocationDriverWindow",
    []
  ).find(window => window && window.pickupLocationDriverWindowDay === day);
  return get(driverWindow, "pickupLocationDriverWindowEndTime", "0:00");
};

export const formatPickupShopWorkingHours = workingHours =>
  formatPickupShopWorkingDayAndHours(workingHours).map(({ time }) => time);

export const formatPickupShopWorkingDayAndHours = workingHours => {
  const mappedData = workingHours
    .sort(
      (a, b) => a.pickupLocationOpenWindowDay - b.pickupLocationOpenWindowDay
    )
    .map((currentItem, i) => {
      const prevItem = workingHours[i - 1];
      const nextItem = workingHours[i + 1];

      if (
        currentItem.pickupLocationOpenWindowDay ===
        prevItem?.pickupLocationOpenWindowDay
      ) {
        if (
          prevItem.pickupLocationOpenWindowEndTime ===
          currentItem.pickupLocationOpenWindowStartTime
        ) {
          return {
            day: currentItem.pickupLocationOpenWindowDay,
            time: `${prevItem.pickupLocationOpenWindowStartTime} - ${currentItem.pickupLocationOpenWindowEndTime}`,
          };
        } else {
          return {
            day: currentItem.pickupLocationOpenWindowDay,
            time: `${prevItem.pickupLocationOpenWindowStartTime} - ${prevItem.pickupLocationOpenWindowEndTime}, ${currentItem.pickupLocationOpenWindowStartTime} - ${currentItem.pickupLocationOpenWindowEndTime}`,
          };
        }
      } else if (
        currentItem.pickupLocationOpenWindowDay !==
        nextItem?.pickupLocationOpenWindowDay
      ) {
        return {
          day: currentItem.pickupLocationOpenWindowDay,
          time: `${currentItem.pickupLocationOpenWindowStartTime} - ${currentItem.pickupLocationOpenWindowEndTime}`,
        };
      } else {
        return undefined;
      }
    })
    .filter(item => item !== undefined);

  return mappedData;
};

export const getParcelStatusHtml = selectedPickupShop => {
  const pickupDate = get(selectedPickupShop, "pickupDate");

  const fromDate = moment(pickupDate).format("dddd D MMMM");
  const untilDate = moment(pickupDate).add(7, "days").format("dddd D MMMM");
  const collectionTime = getCollectionTimeFromPickupShop(selectedPickupShop);
  return `Your parcel will be ready for collection from
                        <span class="redtext">${fromDate}</span> ${
    collectionTime ? `after <span class="redtext">${collectionTime}</span>` : ""
  }
                          until 
                         <span class="redtext">${untilDate}</span>`;
};

export const getLiability = ({ insurance, insuranceValue }) => {
  if (insurance) {
    return `Yes\n£${insuranceValue}`;
  }

  return "No";
};

export const calculateDeliveriesQuantity = deliveries =>
  deliveries.reduce((acc, delivery) => acc + delivery.quantity, 0);

export const getQuantityUrl = (collectionNumber, domain) =>
  `${domain}/tracking/trackingSearch.do?appmode=app&search.javascriptValidated=2&search.searchType=6&search.collectionRequestNumber=${collectionNumber}`;

export const getNotificationDetails = ({ deliveryDetails }) => {
  const { contactName, email, mobile } =
    deliveryDetails.notificationDetails || {};
  return {
    contactName,
    email,
    mobile,
  };
};

export const getReference = ({ shipperDetails }) => {
  const { shippingRef1 } = shipperDetails;
  return {
    sendersReference: shippingRef1 ?? "",
  };
};

export const getShippingReferenceString = (shipperDetails, ref) => {
  const reference = get(shipperDetails, ref, "");

  return isArray(reference) ? joinStringsWithComma(reference) : reference;
};

export const getReferencesString = ({ shipperDetails }) =>
  joinStringsWithComma([
    getShippingReferenceString(shipperDetails, "shippingRef1"),
    getShippingReferenceString(shipperDetails, "shippingRef2"),
    getShippingReferenceString(shipperDetails, "shippingRef3"),
  ]);

export const isParcelStatusCollectFromDepot = status =>
  status === PARCEL_STATUS.COLLECT_FROM_DEPOT_INSTRUCTIONS;

export const isParcelStatusPickupDelivery = status =>
  status === PARCEL_STATUS.PICKUP_DELIVERY;

export const isParcelStatusOutForDelivery = status =>
  status === PARCEL_STATUS.OUT_FOR_DELIVERY;

export const isParcelStatusEstimatedDate = status =>
  status === PARCEL_STATUS.ESTIMATED_DATE;

export const isParcelStatusIssue = status => status === PARCEL_STATUS.ISSUE;

export const isParcelStatusRFIReason = status =>
  status === PARCEL_STATUS.RFI_REASON;

export const isParcelCode = value => PARCEL_CODE.test(value);
