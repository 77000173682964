import PropTypes from "prop-types";

export default function Warning({ fill }) {
  return (
    <svg
      id="Warning-1"
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 284 284"
      fill="none"
    >
      <path
        d="M222.75 223H61.2357C57.2504 223 53.6838 220.939 51.6893 217.49C49.6948 214.041 49.6985 209.916 51.6893 206.467L132.446 66.5917C134.437 63.1426 138.007 61.082 141.993 61.082C145.978 61.082 149.545 63.1426 151.539 66.5917L232.296 206.467C234.287 209.92 234.287 214.045 232.292 217.49C230.302 220.943 226.731 223 222.75 223ZM141.993 68.4282C141.354 68.4282 139.767 68.6082 138.812 70.2648L58.0511 210.14C57.0925 211.797 57.7316 213.266 58.0511 213.817C58.3707 214.368 59.3184 215.654 61.2357 215.654H222.75C224.663 215.654 225.611 214.368 225.931 213.817C226.25 213.266 226.889 211.801 225.931 210.14L145.174 70.2648C144.219 68.6082 142.632 68.4282 141.993 68.4282Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M138.32 175.79C138.32 177.821 139.965 179.463 141.993 179.463C144.02 179.463 145.666 177.817 145.666 175.79V123.679C145.666 121.652 144.02 120.006 141.993 120.006C139.965 120.006 138.32 121.652 138.32 123.679V175.79ZM145.665 190.482C145.665 192.511 144.021 194.155 141.992 194.155C139.963 194.155 138.319 192.511 138.319 190.482C138.319 188.453 139.963 186.809 141.992 186.809C144.021 186.809 145.665 188.453 145.665 190.482Z"
        fill={fill}
      />
    </svg>
  );
}

Warning.propTypes = {
  fill: PropTypes.string,
};
