export default function Home() {
  return (
    <svg
      id="Home"
      width="44"
      height="50"
      viewBox="0 0 44 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7141 43.4938C36.5991 41.3357 44 32.5322 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 32.613 7.51495 41.4707 17.5139 43.5423L22.0968 50L26.7141 43.4938Z"
        fill="#DC0032"
      />
      <path
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="11"
        y="11"
        width="22"
        height="22"
      >
        <path d="M11 11H33V33H11V11Z" fill="white" />
      </path>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 22.4646H14.0904V31.8659H20.6198V24.8069H23.3802V31.8552H29.8991V22.4646H33L22 12.375L11 22.4646Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
