import PropTypes from "prop-types";

import { Button, useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

import { ArrowLeft } from "../../../../components/Icon";
import { SHOW_DELIVERY_OPTIONS } from "../../../../constants/strings";

const BackButton = ({ onClick }) => {
  const { variant } = useMyDpdTheme();

  return (
    <Button
      onClick={onClick}
      variant={variant}
      className={"pl-0 mb-4 d-flex align-items-center "}
    >
      <ArrowLeft />
      {SHOW_DELIVERY_OPTIONS}
    </Button>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func,
};

export default BackButton;
