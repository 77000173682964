import { ADDRESS_COPIED, FAILED_COPY_ADDRESS } from "../../constants/strings";
import { omitNilValues } from "../../utils/object";
import { replaceLinebreak } from "../../utils/string";

export const copyTextToClipboard = (snackbar, onClickCopyAddress) => text => {
  onClickCopyAddress && onClickCopyAddress();

  navigator.clipboard
    .writeText(text)
    .then(() => snackbar.showSuccess({ message: ADDRESS_COPIED }))
    .catch(() =>
      snackbar.showAlert({
        message: FAILED_COPY_ADDRESS,
      })
    );
};

export const createEditParcelPayload = values => {
  const filledValues = omitNilValues(values);
  if (filledValues.instructions) {
    filledValues.instructions = replaceLinebreak(filledValues.instructions);
  }
  return filledValues;
};
