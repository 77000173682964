import { connect } from "react-redux";
import { compose } from "recompose";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";

import withLoaderHandlers from "../../../HOCS/withLoaderHandlers";
import { fetchActionDates } from "../actions";
import { getActionDates, getCardDates } from "../selectors";

export const loadActionDates = compose(
  withLoaderHandlers,
  withAppLoader({
    loadFn: ({ dispatch, match }, fetchOptions) =>
      dispatch(fetchActionDates(match.params, fetchOptions)),
  }),
  connect(state => ({
    dates: getActionDates(state),
    cardDates: getCardDates(state),
  }))
);
