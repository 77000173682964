import { PARCEL_DASHBOARD_TYPES } from "../../constants/dashboard";
import { ActionTypes } from "./actions";

export const initialState = {
  stats: {},
  serviceDisruption: [],
  unaffectedMessage: "",
  isServicePDFavailable: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_DASHBOARD_STATS.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        stats: {
          ...state.stats,
          ...data,
        },
      };
    }
    case ActionTypes.FETCH_PDF_AVAILABLE.SUCCESS: {
      const { available } = action.payload;
      return {
        ...state,
        isServicePDFavailable: available,
      };
    }
    case ActionTypes.FETCH_WATCH_LIST.SUCCESS: {
      const { findByTotal } = action.payload;
      return {
        ...state,
        stats: {
          ...state.stats,
          [PARCEL_DASHBOARD_TYPES.WATCH]: findByTotal,
        },
      };
    }
    case ActionTypes.CLEAR_STATS: {
      return {
        ...initialState,
      };
    }
    case ActionTypes.FETCH_SERVICE_DISRUPTION.SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ActionTypes.CLEAR_SERVICE_DISRUPTION: {
      return {
        ...state,
        serviceDisruption: initialState.serviceDisruption,
        unaffectedMessage: initialState.unaffectedMessage,
      };
    }
    default:
      return state;
  }
};
