const CivilUnrest = props => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
    <rect width="48" height="48" fill="white" />
    <path
      d="M41.5949 41.6374H6.40551C5.53721 41.6374 4.76015 41.1885 4.32561 40.437C3.89106 39.6856 3.89186 38.7868 4.32561 38.0354L21.9203 7.56039C22.354 6.80894 23.1319 6.35999 24.0002 6.35999C24.8685 6.35999 25.6456 6.80894 26.0801 7.56039L43.6748 38.0354C44.1085 38.7876 44.1085 39.6864 43.674 40.437C43.2402 41.1893 42.4624 41.6374 41.5949 41.6374ZM24.0002 7.96052C23.861 7.96052 23.5152 7.99973 23.3072 8.36066L5.71167 38.8357C5.5028 39.1966 5.64205 39.5167 5.71167 39.6367C5.7813 39.7568 5.98777 40.0369 6.40551 40.0369H41.5949C42.0118 40.0369 42.2183 39.7568 42.2879 39.6367C42.3576 39.5167 42.4968 39.1974 42.2879 38.8357L24.6932 8.36066C24.4852 7.99973 24.1394 7.96052 24.0002 7.96052Z"
      fill="#414042"
    />
    <path
      d="M20.8828 29.9168C20.7643 29.9168 20.6522 29.8441 20.6075 29.7268C20.5502 29.575 20.6263 29.405 20.7784 29.3477C21.6458 29.0191 22.2061 28.2118 22.2061 27.2909C22.2061 26.0744 21.2166 25.085 20.0002 25.085C18.7837 25.085 17.7943 26.0744 17.7943 27.2909C17.7943 28.2118 18.3546 29.0188 19.2219 29.3477C19.3737 29.4053 19.4502 29.575 19.3928 29.7271C19.3355 29.8791 19.1652 29.9553 19.0134 29.898C17.9325 29.4882 17.2061 28.4409 17.2061 27.2912C17.2061 25.7506 18.4596 24.4971 20.0002 24.4971C21.5408 24.4971 22.7943 25.7506 22.7943 27.2912C22.7943 28.4409 22.0678 29.4885 20.9866 29.898C20.9525 29.9106 20.9175 29.9168 20.8828 29.9168Z"
      fill="#414042"
    />
    <path d="M24.4116 35.5032C24.2489 35.5032 24.1175 35.3717 24.1175 35.2091C24.1175 32.9385 22.2704 31.0914 19.9998 31.0914C19.0322 31.0914 18.0919 31.4338 17.3525 32.0552C16.4181 32.8405 15.8822 33.9899 15.8822 35.2091C15.8822 35.3717 15.7504 35.5032 15.5881 35.5032C15.4257 35.5032 15.2939 35.3717 15.2939 35.2091C15.2939 33.8161 15.9063 32.5023 16.9737 31.6049C17.8192 30.8944 18.8937 30.5032 19.9998 30.5032C22.5945 30.5032 24.7057 32.6144 24.7057 35.2091C24.7057 35.3717 24.5742 35.5032 24.4116 35.5032Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.4 27.5C27.5933 27.5 27.75 27.6045 27.75 27.7333V31.2667C27.75 31.3955 27.5933 31.5 27.4 31.5C27.2067 31.5 27.05 31.3955 27.05 31.2667V27.7333C27.05 27.6045 27.2067 27.5 27.4 27.5Z"
      fill="#414042"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.4004 21.7H24.4004C24.2347 21.7 24.1004 21.8343 24.1004 22V26C24.1004 26.1657 24.2347 26.3 24.4004 26.3H30.4004C30.5661 26.3 30.7004 26.1657 30.7004 26V22C30.7004 21.8343 30.5661 21.7 30.4004 21.7ZM24.4004 21C23.8481 21 23.4004 21.4477 23.4004 22V26C23.4004 26.5523 23.8481 27 24.4004 27H30.4004C30.9527 27 31.4004 26.5523 31.4004 26V22C31.4004 21.4477 30.9527 21 30.4004 21H24.4004Z"
      fill="#414042"
    />
  </svg>
);

export default CivilUnrest;
