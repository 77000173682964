import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import icon from "@dpdgroupuk/mydpd-ui/assets/not-found.svg";

import styles from "./NoResults.module.scss";

const NoResults = ({ description }) => (
  <Row className="d-flex justify-content-center">
    <Col md={12} className={styles.errorContainer}>
      <img className={styles.errorImg} src={icon} alt="" />
      <span className={styles.errorMessage}>{description}</span>
    </Col>
  </Row>
);

export const propTypes = {
  description: PropTypes.string,
  pageTitle: PropTypes.string,
};

NoResults.propTypes = propTypes;

export default NoResults;
