import PropTypes from "prop-types";

import { PARCEL_ISSUE_SUBTEXT } from "../../../../../constants/strings";
import styles from "./View.module.scss";

const SubText = () => (
  <div className={styles.subtext}>{PARCEL_ISSUE_SUBTEXT}</div>
);

const IssueStatus = ({ estimatedDeliveryDate, issueName, issueDelayDays }) => (
  <span className={styles.issueStatus}>
    {issueDelayDays !== null
      ? `There is currently a ${issueDelayDays} day delay in your area due to ${issueName}*`
      : `There are currently delays in your area due to ${issueName}`}
    {(issueDelayDays !== null || estimatedDeliveryDate !== null) && <SubText />}
  </span>
);

IssueStatus.propTypes = {
  issueName: PropTypes.string,
  issueDelayDays: PropTypes.number,
  estimatedDeliveryDate: PropTypes.string,
};

export default IssueStatus;
