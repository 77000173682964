import { reset } from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { deliveriesApi } from "../../apis";
import { WATCH_LIST_FORM } from "../../constants/forms";

export const ActionTypes = createActionTypes("DASH_WATCH_LIST", {
  REMOVE_FROM_WATCH_LIST: createAsyncActionTypes("REMOVE_FROM_WATCH_LIST"),
});

export const removeFromWatchList = createAsyncAction(
  deliveriesApi.removeParcelFromWatchList,
  ActionTypes.REMOVE_FROM_WATCH_LIST
);

export const resetForm = () => reset(WATCH_LIST_FORM);
