import PropTypes from "prop-types";

import { FURTHER_INTRUCTIONS } from "../../../../../constants/strings";
import styles from "./View.module.scss";

const ReasonStatus = ({ rfiReasonDescription, rfiReasonExplanation }) => (
  <div className={styles.reasonStatus}>
    <span className={styles.errorMessage}> {FURTHER_INTRUCTIONS}</span>
    {rfiReasonDescription &&
      rfiReasonExplanation &&
      `${rfiReasonDescription}: ${rfiReasonExplanation}`}
  </div>
);

ReasonStatus.propTypes = {
  rfiReasonDescription: PropTypes.string,
  rfiReasonExplanation: PropTypes.string,
};

export default ReasonStatus;
