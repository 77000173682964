import { useMemo } from "react";

import { zipWith } from "lodash";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import { Table } from "@dpdgroupuk/mydpd-ui";
import { withTrack } from "@dpdgroupuk/react-event-tracker";

import CardWithTitle from "../../../../components/CardWithTitle";
import { PARCEL_HISTORY_COMPONENT } from "../../../../constants/analytics";
import { PARCEL_HISTORY } from "../../../../constants/strings";
import { formatDDMMMYYYY } from "../../../../utils/date";
import ImageViewCell from "./ImageViewCell";
import MapViewCell from "./MapViewCell";

const renderHistoryEvent = ({ row: rowData }) => {
  const notificationName = rowData.notificationName
    ? rowData.notificationName.split(",")
    : rowData.notificationName;
  const notificationMethodName = rowData.notificationName
    ? rowData.notificationMethodName.split(",")
    : rowData.notificationName;
  const notification = zipWith(
    notificationName,
    notificationMethodName,
    (a, b) => [a + " " + b + " notification sent "]
  );

  return (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: rowData.text,
        }}
        style={{ lineHeight: "14px" }}
      />
      <br />
      {(rowData.notificationName || rowData.notificationMethodName) && (
        <>
          {notification.map((value, index, array) => (
            <span key={index} style={{ lineHeight: "14px" }}>
              {value}
              {index < array.length - 1 && (
                <span style={{ lineHeight: "14px" }}>{"& "}</span>
              )}
            </span>
          ))}
        </>
      )}
    </>
  );
};

const columns = [
  {
    text: "Date",
    dataIndex: "date",
    component: props => formatDDMMMYYYY(props.row.date),
    style: {
      width: "97px",
    },
  },
  {
    text: "Time",
    dataIndex: "time",
    component: props =>
      props.row.time && props.row.time.match(/^[0-9]{2}[:][0-9]{2}/)[0],
    style: {
      width: "61px",
    },
  },
  {
    text: "Location",
    dataIndex: "eventLocation",
    style: {
      width: "170px",
    },
  },
  {
    text: "Event",
    component: renderHistoryEvent,
    style: {
      width: "308px",
    },
  },
  {
    text: "Map",
    component: MapViewCell,
    style: {
      width: "84px",
    },
  },
  {
    text: "Image",
    dataIndex: "images",
    component: ImageViewCell,
    style: {
      width: "84px",
    },
  },
];

const ParcelHistory = ({ data }) => {
  // eslint-disable-next-line
  const memoColumns = useMemo(() => columns, [columns]);
  return (
    data.length > 0 && (
      <Col sm={12}>
        <CardWithTitle title={PARCEL_HISTORY}>
          <Table columns={memoColumns} data={data} selectable={false} />
        </CardWithTitle>
      </Col>
    )
  );
};

ParcelHistory.propTypes = {
  data: PropTypes.array,
};

export default withTrack(PARCEL_HISTORY_COMPONENT.LOAD)(ParcelHistory);
