import * as Sentry from "@sentry/react";
import { compose, withHandlers } from "recompose";

import { LoaderError } from "@dpdgroupuk/mydpd-ui";

export default compose(
  withHandlers({
    onLoadFailure: () => error => {
      if (error.statusCode && error.statusCode >= 500) {
        return Sentry.captureException(error);
      }
      throw new LoaderError(error);
    },
  })
);
