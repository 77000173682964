import { compose } from "recompose";

import {
  trackProps,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import { SEARCH_DELIVERY } from "../../constants/analytics";
import { Fields } from "../../constants/forms";

const MAP_FIELD_TO_ANALYTICS = {
  // TODO: add analytics for dateType dropdown
  [Fields.ACCOUNT]: SEARCH_DELIVERY.CLICK_ACCOUNT_DROPDOWN,
  [Fields.DELIVERY_REFERENCE]: SEARCH_DELIVERY.CLICK_PARCEL_REFERENCE,
  [Fields.POSTCODE]: SEARCH_DELIVERY.CLICK_POSTCODE,
  [Fields.COLLECTION_CODE]: SEARCH_DELIVERY.CLICK_COLLECTION_NO,
  [Fields.CASE_CODE]: SEARCH_DELIVERY.CLICK_CASE_REFERENCE,
  [Fields.REFERENCE]: SEARCH_DELIVERY.CLICK_CUSTOMER_REFERENCE,
  [Fields.EMAIL]: SEARCH_DELIVERY.CLICK_EMAIL_ADDRESS,
  [Fields.PHONE]: SEARCH_DELIVERY.CLICK_PHONE_NUMBER,
  [Fields.DATE_TYPE]: SEARCH_DELIVERY.CLICK_DATE_SEARCH,
  [Fields.POSTCODE_CUSTOMER_REFERENCE]: SEARCH_DELIVERY.CLICK_POSTCODE,
  [Fields.POSTCODE_DATE]: SEARCH_DELIVERY.CLICK_POSTCODE,
  [Fields.POSTCODE_EMAIL]: SEARCH_DELIVERY.CLICK_POSTCODE,
  [Fields.POSTCODE_PHONE]: SEARCH_DELIVERY.CLICK_POSTCODE,
  [Fields.COLLECTION_START_DATE]: SEARCH_DELIVERY.CLICK_DATE_PICKER_FROM,
  [Fields.DELIVERY_START_DATE]: SEARCH_DELIVERY.CLICK_DATE_PICKER_FROM,
  [Fields.TARGET_START_DATE]: SEARCH_DELIVERY.CLICK_DATE_PICKER_FROM,
  [Fields.SHIPPED_START_DATE]: SEARCH_DELIVERY.CLICK_DATE_PICKER_FROM,
  [Fields.COLLECTION_END_DATE]: SEARCH_DELIVERY.CLICK_DATE_PICKER_TO,
  [Fields.DELIVERY_END_DATE]: SEARCH_DELIVERY.CLICK_DATE_PICKER_TO,
  [Fields.TARGET_END_DATE]: SEARCH_DELIVERY.CLICK_DATE_PICKER_TO,
  [Fields.SHIPPED_END_DATE]: SEARCH_DELIVERY.CLICK_DATE_PICKER_TO,
};

const DATE_PICKER_SELECT_ANALYTICS = {
  [Fields.COLLECTION_START_DATE]: SEARCH_DELIVERY.SELECT_FROM_DATE,
  [Fields.DELIVERY_START_DATE]: SEARCH_DELIVERY.SELECT_FROM_DATE,
  [Fields.TARGET_START_DATE]: SEARCH_DELIVERY.SELECT_FROM_DATE,
  [Fields.SHIPPED_START_DATE]: SEARCH_DELIVERY.SELECT_FROM_DATE,
  [Fields.COLLECTION_END_DATE]: SEARCH_DELIVERY.SELECT_TO_DATE,
  [Fields.DELIVERY_END_DATE]: SEARCH_DELIVERY.SELECT_TO_DATE,
  [Fields.TARGET_END_DATE]: SEARCH_DELIVERY.SELECT_TO_DATE,
  [Fields.SHIPPED_END_DATE]: SEARCH_DELIVERY.SELECT_TO_DATE,
};

export default compose(
  withTrack(trackProps(SEARCH_DELIVERY)),
  withTrackProps({
    onFieldEntry: (e, prevValues, nextValues, path) =>
      MAP_FIELD_TO_ANALYTICS[path],
    onTypeChange: value => MAP_FIELD_TO_ANALYTICS[value],
    onFindClick: SEARCH_DELIVERY.CLICK_FIND_POSTCODE,
    onDateChange: (e, prevValues, nextValues, path) =>
      DATE_PICKER_SELECT_ANALYTICS[path],
    onSubmitSuccess: SEARCH_DELIVERY.CLICK_SEARCH,
  })
);
