import { noop } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withHandlers, withProps } from "recompose";

import { createCancelableRequest } from "@dpdgroupuk/fetch-client";
import { withNotifier } from "@dpdgroupuk/mydpd-app";
import PostcodeFinderInput from "@dpdgroupuk/mydpd-postcode-finder";
import {
  trackProps,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import { FIND_POSTCODE_MODAL } from "../../constants/analytics";
import { FIND_POSTCODE } from "../../constants/strings";
import { postcodeAutocompleteMapper } from "../../models/address";
import { omitNilValues } from "../../utils/object";
import { search } from "./actions";

PostcodeFinderInput.defaultProps = {
  onFindClick: noop,
  onFind: noop,
  onClear: noop,
  onSelectAddress: noop,
  onShow: noop,
};

PostcodeFinderInput.propTypes = {
  classNameInput: PropTypes.any,
  disabled: PropTypes.bool,
  modalClasses: PropTypes.object,
};

export default compose(
  withNotifier,
  connect(null, (dispatch, { notifier }) => ({
    search: notifier.runAsync(
      createCancelableRequest(
        async (values, options) =>
          await dispatch(search(omitNilValues(values), options))
      )
    ),
  })),
  withProps({
    buttonText: FIND_POSTCODE,
    optionLabelMapper: postcodeAutocompleteMapper,
  }),
  withHandlers({
    onSelectAddress: props => values => props.onSelectionChange(values),
    shouldSearch: props => () => !props.meta.error,
  }),
  withTrack(trackProps(FIND_POSTCODE_MODAL)),
  withTrackProps({
    onFind: FIND_POSTCODE_MODAL.CLICK_FIND_POSTCODE,
    onClear: FIND_POSTCODE_MODAL.CLICK_CLEAR,
    onShow: FIND_POSTCODE_MODAL.LOAD,
    onSelectAddress: FIND_POSTCODE_MODAL.CLICK_ADDRESS,
  })
)(PostcodeFinderInput);
