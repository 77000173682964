import PropTypes from "prop-types";
import { Container, Row } from "react-bootstrap";

import styles from "./Tiles.module.scss";

const Tiles = ({ children }) => (
  <section className={styles.tiles}>
    <Container>
      <Row>{children}</Row>
    </Container>
  </section>
);

Tiles.propTypes = {
  children: PropTypes.node,
};

export default Tiles;
