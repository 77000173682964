import { Col } from "react-bootstrap";
import { compose } from "recompose";
import { Field, propTypes as ReduxFormPropTypes } from "redux-form";

import { Form } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import CardWithTitle from "../../../../components/CardWithTitle";
import {
  CALL_ME,
  PROVIDE_DELIVERY_INSTRUCTIONS,
} from "../../../../constants/analytics";
import { Fields } from "../../../../constants/forms";
import {
  CALL_ME as CALL_ME_TITLE,
  INSTRUCTION_LOCATE_PARCEL,
  MAX_200_CHARACTERS,
  NOT_SURE_WHAT_TO_DO_NEXT,
} from "../../../../constants/strings";
import { deliveryEditValidators } from "../../../../models/validators";
import { onCallMe } from "../../actions";
import EditPanel from "../../components/EditPanel";
import { hadleInputFocus, withParcelEdit } from "../../hocs";
import styles from "./CallMe.module.scss";

const CallMe = ({ handleSubmit, onSubmit, error, ...rest }) => (
  <Col className="col-12 p-0">
    <CardWithTitle title={CALL_ME_TITLE}>
      <div className={styles.message}>{NOT_SURE_WHAT_TO_DO_NEXT}</div>
      <EditPanel onSubmit={handleSubmit(onSubmit)} error={error} {...rest}>
        <Col className="col-12">
          <Field
            component={Form.MaterialTextInput}
            multiline
            classes={{
              input: styles.textarea,
            }}
            rows={8}
            label={INSTRUCTION_LOCATE_PARCEL}
            name={Fields.NOTES}
            maxLength={200}
            helperText={MAX_200_CHARACTERS}
          />
        </Col>
      </EditPanel>
    </CardWithTitle>
  </Col>
);

CallMe.defaultProps = {
  onSubmit: () => null,
};

CallMe.propTypes = {
  ...ReduxFormPropTypes,
};

export default compose(
  withParcelEdit({
    onSubmit: (parcelCode, values) => {
      const query = {
        notes: values.notes,
        notificationDetails: {
          mobile: values.mobile,
          email: values.email,
          contactName: values.contactName,
        },
      };
      return onCallMe(parcelCode, query);
    },
    validate: deliveryEditValidators.callMe,
  }),
  withTrack(CALL_ME.LOAD),
  withTrackProps({
    onSubmit: PROVIDE_DELIVERY_INSTRUCTIONS.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      switch (fieldName) {
        case Fields.NOTES:
          return CALL_ME.NOTES_INPUT;
        case Fields.CONTACT_NAME:
          return CALL_ME.CONTACT_NAME_INPUT;
        case Fields.MOBILE:
          return CALL_ME.MOBILE_NUMBER_INPUT;
        case Fields.EMAIL:
          return CALL_ME.EMAIL_ADDRESS_INPUT;
        default:
          break;
      }
    },
  }),
  hadleInputFocus
)(CallMe);
