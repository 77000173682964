import { compose } from "recompose";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { SEARCH_FILTER_LIST } from "../../constants/analytics";
import { FilterFields } from "../../constants/forms";

const MAP_FILTER_FIELD_TO_ACTION_ID = {
  [FilterFields.PRODUCT]: SEARCH_FILTER_LIST.CLICK_PRODUCT,
  [FilterFields.DELIVERY_COUNTRY]: SEARCH_FILTER_LIST.CLICK_DELIVERY_COUNTRY,
  [FilterFields.ACCOUNT_CODE]: SEARCH_FILTER_LIST.ACCOUNT_SELECTION,

  [FilterFields.SERVICE]: SEARCH_FILTER_LIST.CLICK_SERVICE,
  [FilterFields.COLLECT_COUNTRY]: SEARCH_FILTER_LIST.CLICK_COLLECTION_COUNTRY,

  [FilterFields.DELIVERY_DEPOT]: SEARCH_FILTER_LIST.CLICK_DELIVERY_DEPOT,
  [FilterFields.DESTINATION]: SEARCH_FILTER_LIST.CLICK_DESTINATION,
  [FilterFields.RANGE]: SEARCH_FILTER_LIST.CLICK_RANGE,

  [FilterFields.OFD_TODAY]: SEARCH_FILTER_LIST.CLICK_OFD_TODAY,
  [FilterFields.DELIVERED]: SEARCH_FILTER_LIST.CLICK_DELIVERED,
  [FilterFields.SORT_BY]: SEARCH_FILTER_LIST.CLICK_SORT_BY,
  [FilterFields.DIRECTION]: SEARCH_FILTER_LIST.CLICK_DIRECTION,
};

export default compose(
  withTrack(SEARCH_FILTER_LIST.LOAD),
  withTrackProps({
    onFilter: SEARCH_FILTER_LIST.CLICK_FILTER,
    onNext: SEARCH_FILTER_LIST.CLICK_NEXT_PAGE,
    onPrevious: SEARCH_FILTER_LIST.CLICK_PREVIOUS_PAGE,
    onFindBtnClick: SEARCH_FILTER_LIST.CLICK_FIND_POSTCODE,
    onClickRow: SEARCH_FILTER_LIST.CLICK_COLLECTION,
    onExportPress: SEARCH_FILTER_LIST.CLICK_EXPORT,
    onRemoveClick: SEARCH_FILTER_LIST.CLICK_REMOVE_FROM_WATCH_LIST,
    onFieldEntry: (event, newValue, previousValue, name) =>
      MAP_FILTER_FIELD_TO_ACTION_ID[name],
  })
);
