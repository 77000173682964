import classNames from "classnames";
import PropTypes from "prop-types";
import { Carousel } from "react-bootstrap";

import { Button, Modal } from "@dpdgroupuk/mydpd-ui";

import "./styles.scss";
import { IMAGE_VIEW } from "../../../../../constants/strings";
import ResponsiveImage from "./ResponsiveImage";

const ImageView = ({
  show,
  images,
  onClose,
  onHide,
  hideOnClickOutside,
  onImageSelect,
  carouselProps,
  buttonText,
  classes,
  children,
  ...modalProps
}) => (
  <Modal
    show={show}
    {...modalProps}
    onHide={(hideOnClickOutside && onClose) || onHide}
    dialogClassName={classNames(
      "mydpd-image-view",
      images.length === 1 && "mydpd-one-image-view",
      classes.modal
    )}
  >
    <Modal.Header>{IMAGE_VIEW}</Modal.Header>
    <Modal.Body>
      {children || (
        <Carousel
          interval={null}
          wrap={false}
          onSelect={onImageSelect}
          {...carouselProps}
        >
          {images.map((image, index) => (
            <Carousel.Item key={index}>
              <Carousel.Caption>
                <h5>{image.title}</h5>
              </Carousel.Caption>
              <ResponsiveImage alt={image.title} src={image.src} />
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </Modal.Body>
    {children ? null : (
      <Modal.Footer
        className={classNames("mydpd-image-view-footer", classes.footer)}
      >
        {children || <Button onClick={onClose}>{buttonText}</Button>}
      </Modal.Footer>
    )}
  </Modal>
);

ImageView.defaultProps = {
  images: [],
  classes: {},
  buttonText: "OK",
  onHide: () => null,
};

ImageView.propTypes = {
  show: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  onClose: PropTypes.func,
  ...Modal.propTypes,
  onHide: PropTypes.func,
  hideOnClickOutside: PropTypes.bool,
  onImageSelect: PropTypes.func,
  carouselProps: PropTypes.object,
  buttonText: PropTypes.string,
  classes: PropTypes.object,
};

export default ImageView;
