import { useMemo } from "react";

import { isEmpty, isNil, omitBy } from "lodash";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import { DndTable as Table } from "@dpdgroupuk/mydpd-ui";

import { deliveriesApi } from "../../../../apis";
import CardWithTitle from "../../../../components/CardWithTitle";
import { ADDITIONAL_INFORMATION } from "../../../../constants/strings";
import { DELIVERIES } from "../../../../router";

const additionalInfoDataCheck = (additionalInfoData, partnerRef) => {
  const additionalInfo = omitBy(additionalInfoData, isNil);
  const partnerRefData = partnerRef.filter(
    part => !isEmpty(omitBy(part, isNil))
  );
  return !isEmpty(additionalInfo) || !isEmpty(partnerRefData);
};

const createLinkedColumns = ({ partnerRef }) =>
  partnerRef
    .filter(({ carrierName }) => carrierName)
    .map(({ carrierName, carrierRef, carrierURL }) => ({
      Header: carrierName,
      Cell: function CarrierUrlColumn() {
        return (
          <a target="_blank" href={carrierURL} rel="noreferrer">
            {carrierRef}
          </a>
        );
      },
    }));

const createCustomColumns = ({
  relabelledFromDisplayParcelCode,
  relabelledFromParcelCode,
  relabelledAsDisplayParcelCode,
  relabelledAsParcelCode,
  parcelsCollectedOnDelivery,
  parcelsDeliveredOnDelivery,
}) => [
  ...(relabelledFromDisplayParcelCode || relabelledFromParcelCode
    ? [
        {
          Header: "Relabelled From",
          accessor: relabelledFromDisplayParcelCode
            ? "relabelledFromDisplayParcelCode"
            : "relabelledFromParcelCode",
        },
      ]
    : []),
  ...(relabelledAsDisplayParcelCode || relabelledAsParcelCode
    ? [
        {
          Header: "Relabelled As",
          accessor: relabelledAsDisplayParcelCode
            ? "relabelledAsDisplayParcelCode"
            : "relabelledAsParcelCode",
        },
      ]
    : []),
  ...(parcelsCollectedOnDelivery
    ? [
        {
          Header: "Parcels Collected",
          accessor: "parcelsCollectedOnDelivery",
        },
      ]
    : []),
  ...(parcelsDeliveredOnDelivery
    ? [
        {
          Header: "Parcels Delivered",
          accessor: "parcelsDeliveredOnDelivery",
        },
      ]
    : []),
];

const openParcel = async props => {
  const parcelNumber = props.target.innerText;
  const data = await deliveriesApi
    .searchParcels({ searchParam: parcelNumber }, {})
    .then(({ data }) => data);
  if (data.parcelCodes[0]) {
    window.open(`${DELIVERIES}/${data.parcelCodes[0]}`, "_blank")?.focus();
  }
};

const AdditionalInformation = ({ parcel }) => {
  const {
    relabelledFromParcelCode,
    relabelledAsParcelCode,
    parcelsCollectedOnDelivery,
    parcelsDeliveredOnDelivery,
    partnerRef,
  } = parcel;

  const linkedColumns = useMemo(() => createLinkedColumns(parcel), [parcel]);

  const customColumns = useMemo(() => createCustomColumns(parcel), [parcel]);

  const isAdditionalInfoSectionVisible = useMemo(
    () =>
      additionalInfoDataCheck(
        {
          relabelledFromParcelCode,
          relabelledAsParcelCode,
          parcelsCollectedOnDelivery,
          parcelsDeliveredOnDelivery,
        },
        partnerRef
      ),
    // eslint-disable-next-line
    [parcel]
  );

  return (
    isAdditionalInfoSectionVisible && (
      <Col sm={12}>
        <CardWithTitle title={ADDITIONAL_INFORMATION}>
          <Table
            columns={[...linkedColumns, ...customColumns]}
            data={[parcel]}
            onClickRow={openParcel}
          />
        </CardWithTitle>
      </Col>
    )
  );
};

AdditionalInformation.propTypes = {
  parcel: PropTypes.object,
};

export default AdditionalInformation;
