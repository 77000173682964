import { useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import {
  NotifierProvider,
  PROJECT_NAME,
  Provider,
} from "@dpdgroupuk/mydpd-app";
import { Layout, Loader } from "@dpdgroupuk/mydpd-ui";

import { apisClient } from "./apis";
import Footer from "./Footer";
import Header from "./Header";
import { UmsActions } from "./redux";
import { Routes } from "./router";
import tracker from "./tracker";

import "./sentry";

const resetLocationHistory = () => {
  window.history.replaceState({}, document.title);
};

function App({ fetchInitialData }) {
  useEffect(() => {
    resetLocationHistory();

    window.addEventListener("beforeunload", resetLocationHistory);

    return () => {
      window.removeEventListener("beforeunload", resetLocationHistory);
    };
  }, []);

  return (
    <Provider
      errorTooltip
      projectName={PROJECT_NAME.DELIVERIES}
      apis={apisClient}
      tracker={tracker}
    >
      <Loader loadFn={fetchInitialData}>
        <Layout header={<Header />} footer={<Footer />}>
          <NotifierProvider>
            <Routes />
          </NotifierProvider>
        </Layout>
      </Loader>
    </Provider>
  );
}

App.propTypes = {
  fetchInitialData: PropTypes.func,
};

const mapDispatchToProps = {
  fetchInitialData: UmsActions.fetchInitialUmsData,
};

export default compose(connect(null, mapDispatchToProps))(App);
