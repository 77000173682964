import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import styles from "./Tiles.module.scss";

const Tile = ({ children, title }) => (
  <Col lg={6} sm={12} className={styles.tile}>
    {title && <h6 className={styles.tile__title}>{title}</h6>}
    <div className={styles.tile__inner}>{children}</div>
  </Col>
);

Tile.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Tile;
