export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // email address
// export EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9-]{1,}[A-Za-z0-9]))$/; // TODO: check it
export const POSTCODE =
  /^((GIR[ ]?0AA)|([a-zA-Z]{1,2}(([0-9]{1,2})|([0-9][a-zA-Z]))[ ]?[0-9][a-zA-Z]{2})){1}$/;
export const PHONE_NUMBER = /^([+]\d{1,14}|\d{0,15})$/;
export const DATE =
  /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d$/;
export const NUMERIC = /^[0-9]*$/;
export const PARCEL_CODE = /^[0-9]{14}[*]\d{5}/;
