import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";
import { withOverlay } from "@dpdgroupuk/mydpd-ui";

import { SERVICE_DISRUPTION_MESSAGE } from "../../../constants/strings";
import { getBusinessUnit } from "../../../redux/auth/selectors";
import { clearServiceDisruption, fetchServiceDisruption } from "../actions";
import {
  getUnaffectedMessageSelector,
  hasAsterisk,
  isPostcodeEmpty,
} from "../selectors";
import styles from "./ServiceDisruption.module.scss";

const ServiceDisruption = ({ unaffectedMessage, hasAsterisk }) => (
  <Col sm={12}>
    {unaffectedMessage && (
      <h6 className={styles.message}>{unaffectedMessage}</h6>
    )}
    {hasAsterisk && (
      <h6 className={styles.text}>
        <span className={styles.asterisk}>*</span> {SERVICE_DISRUPTION_MESSAGE}
      </h6>
    )}
  </Col>
);

ServiceDisruption.propTypes = {
  unaffectedMessage: PropTypes.string,
  hasAsterisk: PropTypes.bool,
};

export default compose(
  connect(),
  connect(
    state => ({
      businessUnit: getBusinessUnit(state),
      unaffectedMessage: getUnaffectedMessageSelector(state),
      isSubmitDisabled: isPostcodeEmpty(state),
      hasAsterisk: hasAsterisk(state),
    }),
    dispatch => ({
      clearData: () => dispatch(clearServiceDisruption()),
    })
  ),
  withOverlay,
  withAppLoader({
    loadFn: async ({ dispatch, businessUnit }) => {
      await dispatch(fetchServiceDisruption({ businessUnit }));
    },
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.clearData();
    },
  })
)(ServiceDisruption);
