export const PARCEL_DASHBOARD_TYPES = {
  INSTRUCTIONS_REQUIRED: "rfiInstructionsRequired",
  EXCEPTION: "rfiException",
  INSTRUCTIONS_PROVIDED: "rfiInstructionsProvided",
  MISSING_DATA: "rfiMissingData",
  RETURN: "rfiReturn",
  WATCH: "rfiWatch",
  PREADVICE_ONLY: "preadviceOnly",
  OVERDUE: "overdue",
  PRO_ALERT: "proAlert",
};

export const POTS = {
  FOR_YOU_TO_ACTION: [
    PARCEL_DASHBOARD_TYPES.INSTRUCTIONS_REQUIRED,
    PARCEL_DASHBOARD_TYPES.MISSING_DATA,
  ],
  FOR_YOU_TO_REVIEW: [
    PARCEL_DASHBOARD_TYPES.PREADVICE_ONLY,
    PARCEL_DASHBOARD_TYPES.OVERDUE,
    PARCEL_DASHBOARD_TYPES.PRO_ALERT,
    PARCEL_DASHBOARD_TYPES.EXCEPTION,
  ],
  FOR_INFO_ONLY: [
    PARCEL_DASHBOARD_TYPES.INSTRUCTIONS_PROVIDED,
    PARCEL_DASHBOARD_TYPES.RETURN,
    PARCEL_DASHBOARD_TYPES.WATCH,
  ],
};

export const POT_TITLES = [
  "For You To Action",
  "For You To Review",
  "For Information Only",
];

export const DASHBOARD_HELP = [
  {
    name: "For You To Action",
    content: `Instructions Required - Please supply additional information so our driver can deliver these parcels. We'll need this information within the next 24 hours.

Parcel Only No Data - We can see that we have parcels from you, but no data. This means unless the data is provided, your customers won't get the best possible delivery experience, which means no one-hour delivery window notification, and in some cases without the full address, we will be unable to locate the delivery point.`,
  },
  {
    name: "For You To Review",
    content: `Data Only No Parcels - We have received your parcel data, however, as yet, we have not seen these parcels in our network.

Overdue - These parcels have not received a delivery attempt, as expected.

Pro-Alert - These parcels have been delayed. As you supplied your customers' contact details, we have notified them of the delay and will let them know when the parcel is out for delivery.

Exceptions - These parcels have had exceptions allocated to them whilst they were in transit, at the delivery depot/Pickup point, or out for delivery. Please review using the Exceptions filter in MyDPD.`,
  },
  {
    name: "For Information Only",
    content: `Instructions in Progress - We are in the process of carrying out the instructions supplied.
    
Returns - These parcels are now being returned to you.

Watch List - You have created a list of parcels that you can monitor quickly via this Watch List.
  `,
  },
];
