import PropTypes from "prop-types";

import { Card, Error, Tile } from "@dpdgroupuk/mydpd-ui";
import icon from "@dpdgroupuk/mydpd-ui/assets/little-problem.svg";

import DashboardCard from "../../../components/DashboardCard";
import { DASHBOARD_HELP, POT_TITLES } from "../../../constants/dashboard";
import { ACCESS_DENIED, NO_ACCESS } from "../../../constants/strings";
import { moveItemToFirst } from "../../../models/validators/dashboard";
import styles from "./DashboardStats.module.scss";

const DashboardStats = ({ stats, onClickTile, showStats, POTS }) => {
  const renderTiles = POT => (
    <Tile.Stack className="p-0 m-0" dashboard={true}>
      {POT.map(item => {
        const { title, amount, id } = stats.find(stat => stat.id === item);
        return (
          <Tile
            key={id}
            title={title}
            disabled={!amount}
            dashboard={true}
            onClick={() =>
              onClickTile({
                title,
                amount,
                id,
              })
            }
          >
            {amount}
          </Tile>
        );
      })}
    </Tile.Stack>
  );

  const renderPots = () =>
    POT_TITLES.map((title, index) => {
      const pot = Object.values(POTS)[index];
      return (
        <DashboardCard
          title={title}
          help={moveItemToFirst(DASHBOARD_HELP, index)}
          key={index}
        >
          {renderTiles(pot)}
        </DashboardCard>
      );
    });

  return (
    <Card className={styles.statsCard}>
      {showStats ? (
        renderPots()
      ) : (
        <Error icon={icon} message={ACCESS_DENIED} subtitle={NO_ACCESS} />
      )}
    </Card>
  );
};

DashboardStats.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      amount: PropTypes.number,
    })
  ),
  onClickTile: PropTypes.func,
  showStats: PropTypes.bool,
  POTS: PropTypes.object,
};

export default DashboardStats;
