import PropTypes from "prop-types";

export default function Seedling({ fill }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.125 6.42857H2.625C2.27953 6.42857 2 6.70179 2 7.03571C2 11.3882 5.64492 14.9286 10.125 14.9286H12V20.3929C12 20.7282 12.2798 21 12.625 21C12.9702 21 13.25 20.7282 13.25 20.3929V14.3214C13.25 9.96897 9.60547 6.42857 5.125 6.42857ZM10.125 13.7143C6.54297 13.7143 3.59492 11.0429 3.27813 7.64286H5.125C8.70547 7.64286 11.6562 10.3147 11.9727 13.7143H10.125ZM21.375 4H18.875C16.2676 4 13.8008 5.22984 12.3086 7.28844C12.0742 7.55938 12.1367 7.93884 12.418 8.13616C12.6969 8.33064 13.0883 8.26897 13.2902 7.99743C14.582 6.2544 16.668 5.21429 18.875 5.21429H20.7215C20.4194 8.39344 17.773 10.9973 14.448 11.2629C14.1038 11.2902 13.848 11.5831 13.8762 11.9175C13.9036 12.2353 14.1765 12.475 14.4988 12.475C14.5159 12.475 14.5336 12.4738 14.5507 12.4726C18.7266 12.1395 22 8.68259 22 4.60714C22 4.2717 21.7188 4 21.375 4Z"
        fill={fill}
      />
    </svg>
  );
}

Seedling.defaultProps = {
  fill: "#509E2F",
};

Seedling.propTypes = {
  fill: PropTypes.string,
};
