import classNames from "classnames";
import PropTypes from "prop-types";

import { CheckboxWithLabel } from "@dpdgroupuk/mydpd-ui";

import styles from "./CustomCheckbox.module.scss";

const CustomCheckbox = ({ label, classes, input, ...rest }) => {
  const { invalid, submitFailed } = rest.meta;
  const isInvalidSubmit = invalid && submitFailed;
  return (
    <div className={classNames("col-12", styles.checkbox, classes.container)}>
      <CheckboxWithLabel
        className={styles.checkbox}
        label={label}
        classes={{
          label: classNames(
            styles.label,
            isInvalidSubmit && styles.labelInvalid,
            classes.label
          ),
          checkmark: classNames(
            styles.checkmark,
            isInvalidSubmit && styles.checkmarkInvalid,
            classes.checkmark
          ),
        }}
        {...rest}
        {...input}
      />
    </div>
  );
};

CustomCheckbox.defaultProps = {
  classes: {
    container: "",
    checkmark: "",
    label: "",
  },
};

CustomCheckbox.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.shape({
    container: PropTypes.string,
    label: PropTypes.string,
    checkmark: PropTypes.string,
  }),
  input: PropTypes.object,
  list: PropTypes.arrayOf(PropTypes.string),
};

export default CustomCheckbox;
