import { useMemo } from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { Button, FormControl } from "@dpdgroupuk/mydpd-ui";

import {
  COPY_DEPOT_ADDRESS,
  DEPOT_ADDRESS,
} from "../../../../constants/strings";
import { joinStringsWithComma } from "../../../../utils/string";

const DepotDetails = ({ depot, onClickCopy }) => {
  const isEmptyDepot = useMemo(() => isEmpty(depot), [depot]);

  const fullAddress = useMemo(() => {
    const { town, city, county, postcode } = depot;
    return joinStringsWithComma([town, city, county, postcode]);
  }, [depot]);

  return (
    !isEmptyDepot && (
      <Row>
        <Col className="col-12 col-md-8 pl-0">
          <FormControl.Input
            label={DEPOT_ADDRESS}
            disabled
            value={`${depot.depotName} ${fullAddress}`}
          />
        </Col>
        <Col className="col-12 col-md-4">
          <Button onClick={() => onClickCopy(fullAddress)}>
            {COPY_DEPOT_ADDRESS}
          </Button>
        </Col>
      </Row>
    )
  );
};

DepotDetails.propTypes = {
  depot: PropTypes.object,
  onClickCopy: PropTypes.func,
};

export default DepotDetails;
