import { branch, renderComponent } from "recompose";

import DeliveryListView from "./DeliveryListView";
import NoResultsView from "./NoResultsView";
export { DeliveryListColumns, DeliveryListPropTypes } from "./components";
export { default as withDeliveryHandlers } from "./withDeliveryHandlers";
export { default as withDeliveryAnalytics } from "./withTrackAnalytics";
export { default as reducer } from "../../redux/deliveries/reducer";

export default branch(
  ({ parcels }) => parcels.length === 0,
  renderComponent(NoResultsView)
)(DeliveryListView);
