import { ActionTypes } from "./actions";

export const initialState = {
  dates: [],
  addresses: [],
  depot: {},
  alternativeTimes: [],
  services: {},
  shops: [],
  selectedPickupShop: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ACTION_DATES.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        dates: data,
      };
    }
    case ActionTypes.GET_NEIGHBOUR_ADDRESSES.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        addresses: data,
      };
    }
    case ActionTypes.GET_RETURN_ADDRESS.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        addresses: data,
      };
    }
    case ActionTypes.GET_DEPOT.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        depot: data,
      };
    }
    case ActionTypes.CLEAR_ALTERNATIVE_DATES: {
      return {
        ...state,
        dates: initialState.dates,
        alternativeTimes: initialState.alternativeTimes,
      };
    }
    case ActionTypes.GET_ALTERNATIVE_DELIVERY_DATES.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        dates: data,
      };
    }
    case ActionTypes.GET_UPGRADE_DELIVERY_TIMES.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        services: data,
      };
    }
    case ActionTypes.GET_ALTERNATIVE_DELIVERY_TIMES.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        alternativeTimes: data,
      };
    }
    case ActionTypes.GET_ALTERNATIVE_DELIVERY_TIMES.FAILURE: {
      return {
        ...state,
        alternativeTimes: [],
      };
    }
    case ActionTypes.CLEAR_SERVICES: {
      return {
        ...state,
        services: initialState.services,
      };
    }
    case ActionTypes.CLEAR_DATE_AND_SERVICE: {
      return {
        ...state,
        services: initialState.services,
        dates: initialState.dates,
      };
    }
    case ActionTypes.CLEAR_DELIVERY_EDIT_DATA: {
      return {
        ...initialState,
      };
    }
    case ActionTypes.GET_PICKUP_LOCATION_SHOP.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        shops: data,
      };
    }
    case ActionTypes.SELECT_PICKUP_SHOP: {
      return {
        ...state,
        selectedPickupShop: action.payload,
      };
    }
    default:
      return state;
  }
};
