import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { getFormSyncErrors, reduxForm } from "redux-form";

import { withOverlay, withPrompt } from "@dpdgroupuk/mydpd-ui";

import { Fields, PARCEL_EDIT_FORM } from "../../../constants/forms";
import {
  ACTION_CAN_NOT_BE_COMPLETED,
  CORRECT_HIGHLIGHTED_FIELDS,
  SERVICE_IS_NOT_AVAILABLE,
  SUBMIT_PROMPT_REJECT,
} from "../../../constants/strings";
import { getNotificationDetails, getReference } from "../../../models/parcel";
import { DELIVERIES } from "../../../router";
import { getValue } from "../../../utils/object";

import { getIsMobileEmailRequired } from "../selectors";

import { createEditParcelPayload } from "../helpers";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../../constants/dateFormats";

export const withParcelEdit = ({ onSubmit, validate, ...restPrams }) =>
  compose(
    connect(state => ({
      isMobileEmailRequired: getIsMobileEmailRequired(state),
      errors: Object.values(getFormSyncErrors(PARCEL_EDIT_FORM)(state)),
    })),
    withOverlay,
    withPrompt,
    withProps(
      ({
        parcel,
        additionalInitialValues = {},
        initialAddressValues = {},
      }) => ({
        initialValues: {
          ...additionalInitialValues,
          ...getNotificationDetails(parcel),
          ...getReference(parcel),
          ...initialAddressValues,
        },
      })
    ),
    reduxForm({
      form: PARCEL_EDIT_FORM,
      validate,
      async onSubmit(values, dispatch, { overlay, match, parcel }) {
        const deliveryDate = getValue(values, Fields.DELIVERY_DATE, "");
        const currentDate = moment();

        if (
          deliveryDate.length &&
          moment(deliveryDate, DEFAULT_DATE_FORMAT).isBefore(currentDate, "day")
        ) {
          values[Fields.DELIVERY_DATE] =
            currentDate.format(DEFAULT_DATE_FORMAT);
        }

        overlay.show();
        return dispatch(
          onSubmit(match.params.parcelCode, createEditParcelPayload(values), {
            values,
            parcel,
          })
        ).finally(() => {
          overlay.hide();
        });
      },
      async onSubmitSuccess(
        submitResult,
        dispatch,
        { history, match, reloadParcelFn }
      ) {
        history.push(`${DELIVERIES}/${match.params.parcelCode}`);
        reloadParcelFn(false);
      },
      onSubmitFail(errors, dispatch, submitError, props) {
        if (
          submitError &&
          !errors &&
          submitError.message !== SUBMIT_PROMPT_REJECT
        ) {
          props.prompt.showInfo({
            message: submitError.message.includes(SERVICE_IS_NOT_AVAILABLE)
              ? submitError.message
              : ACTION_CAN_NOT_BE_COMPLETED,
          });
        }
      },
      ...restPrams,
    }),
    withProps(({ submitFailed, invalid, error, errors }) => {
      const errorText = errors.length > 1 ? CORRECT_HIGHLIGHTED_FIELDS : error;
      return {
        error: submitFailed && invalid && errorText,
      };
    })
  );
