import { compose } from "recompose";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";

import withLoaderHandlers from "../../HOCS/withLoaderHandlers";
import { getSearchQuery } from "../../utils/query";
import NoSearchResultsView from "./NoSearchResultsView";
import withSearchHandler from "./withSearchHandler";

export default compose(
  withSearchHandler,
  withLoaderHandlers,
  withAppLoader({
    loadFn: async ({ location, searchHandler }, fetchOptions) => {
      const query = getSearchQuery(location);
      return searchHandler(query, fetchOptions);
    },
    ErrorComponent: NoSearchResultsView,
  })
)();
