import { keys } from "lodash";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose, lifecycle, withProps } from "recompose";
import {
  clearFields,
  Field,
  propTypes as ReduxFormPropTypes,
} from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";
import { PARCEL_ACTION_CODE } from "@dpdgroupuk/redback-enums";

import CardWithTitle from "../../../../components/CardWithTitle";
import { ALTERNATIVE_ADDRESS } from "../../../../constants/analytics";
import { DEFAULT_DATE_FORMAT } from "../../../../constants/dateFormats";
import {
  alternativeAddress as Fields,
  PARCEL_EDIT_FORM,
  POSTCODE_MAX_LENGTH,
} from "../../../../constants/forms";
import {
  CHANGE_ADDRESS,
  CITY,
  COUNTRY,
  COUNTY,
  DELIVERY_DATE,
  MAX_35_CHARACTERS,
  NAME,
  PLEASE_SELECT_DATE,
  POSTCODE,
  REFERENCE,
  STREET,
  SUBURB,
  TOWN,
} from "../../../../constants/strings";
import PostcodeFinderInput from "../../../../features/PostcodeFinder/PostcodeFinderInput";
import {
  getNotificationDetails,
  isParcelCode,
} from "../../../../models/parcel";
import { deliveryEditValidators } from "../../../../models/validators";
import {
  checkMinDate,
  clearAlternativeDeliveryDates,
  getAlternativeDatesAction,
  onAlternativeAddress,
  resetAddressAction,
} from "../../actions";
import EditPanel from "../../components/EditPanel";
import TermAndInstructions from "../../components/TermsAndInstructions";
import { hadleInputFocus, withParcelEdit } from "../../hocs";
import { withRelatedParcelsInitialValues } from "../../hocs/withRelatedParcelsInitialValues";
import { getActionDates } from "../../selectors";

const AlternativeAddress = ({
  handleSubmit,
  onSubmit,
  onAddressSelect,
  error,
  alternativeDates,
  onFindPostcode,
  onAddressInput,
  onPostcodeChange,
  preventDatePickerBlur,
  setIsDatePickerOpen,
  dates,
  getDatesRange,
  filterDate,
  ...rest
}) => (
  <Col className="col-12 p-0">
    <CardWithTitle title={CHANGE_ADDRESS}>
      <Row>
        <Col className="col-12 col-md-6">
          <Field
            autoFocus={false}
            name={Fields.COUNTRY}
            component={FormControl.Input}
            label={COUNTRY}
            disabled={true}
            maxLength={255}
          />
          <Field
            autoFocus={false}
            name={Fields.POSTCODE}
            component={PostcodeFinderInput}
            label={POSTCODE}
            maxLength={POSTCODE_MAX_LENGTH}
            onSelectionChange={onAddressSelect}
            onFind={onFindPostcode}
            onFocus={onAddressInput}
            onChange={onPostcodeChange}
            labelKey={option => option.postcode}
            withAutocomplete
            required
            id={Fields.POSTCODE}
          />
          <Field
            autoFocus={false}
            name={Fields.NAME}
            component={FormControl.Input}
            label={NAME}
            maxLength={35}
            helperText={MAX_35_CHARACTERS}
            required
          />
          <Field
            autoFocus={false}
            name={Fields.STREET}
            component={FormControl.Input}
            label={STREET}
            maxLength={35}
            helperText={MAX_35_CHARACTERS}
            required
          />
          <Field
            autoFocus={false}
            name={Fields.SUBURB}
            component={FormControl.Input}
            label={SUBURB}
            maxLength={35}
            helperText={MAX_35_CHARACTERS}
          />
        </Col>
        <Col className="col-12 col-md-6">
          <Field
            autoFocus={false}
            name={Fields.TOWN}
            component={FormControl.Input}
            label={TOWN}
            maxLength={35}
            helperText={MAX_35_CHARACTERS}
          />
          <Field
            autoFocus={false}
            name={Fields.CITY}
            component={FormControl.Input}
            label={CITY}
            maxLength={35}
            helperText={MAX_35_CHARACTERS}
            required
          />
          <Field
            autoFocus={false}
            name={Fields.COUNTY}
            component={FormControl.Input}
            label={COUNTY}
            maxLength={35}
            helperText={MAX_35_CHARACTERS}
          />
          <Field
            name={Fields.DELIVERY_DATE}
            component={FormControl.DatePicker}
            placeholder={PLEASE_SELECT_DATE}
            label={DELIVERY_DATE}
            // NOTE: getDatesRange is required to forbid change month by clicking header arrows and set proper date if date was changed
            getDatesRange={getDatesRange}
            filterDate={filterDate}
            onDatePickerChange={setIsDatePickerOpen}
            onBlur={e => preventDatePickerBlur(e)}
            disabled={!dates.length}
            dateFormat={DEFAULT_DATE_FORMAT}
            required
          />
          <Field
            autoFocus={false}
            name={Fields.REFERENCE_CHANGE_ADDRESS}
            component={FormControl.Input}
            label={REFERENCE}
            maxLength={35}
            helperText={MAX_35_CHARACTERS}
          />
        </Col>
      </Row>
      <EditPanel
        onSubmit={handleSubmit(onSubmit)}
        error={error}
        offContactDetailsValidation={true}
        additionalInfoComponent={
          <Container>
            <TermAndInstructions
              checkboxId={Fields.AGREEMENT + "_alternativeAddress"}
            />
          </Container>
        }
        {...rest}
      />
    </CardWithTitle>
  </Col>
);

AlternativeAddress.defaultProps = {
  onSubmit: () => null,
  onChangePostCode: () => null,
  getDates: () => null,
  toggleAllCheckboxes: () => null,
  onChangeCheckbox: () => null,
  onFindPostcode: () => null,
  onAddressInput: () => null,
  dates: [],
};

AlternativeAddress.propTypes = {
  onChangePostCode: PropTypes.func,
  getDates: PropTypes.func,
  toggleAllCheckboxes: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  onFindPostcode: PropTypes.func,
  onAddressInput: PropTypes.func,
  dates: PropTypes.arrayOf(PropTypes.string),
  getDatesRange: PropTypes.func,
  filterDate: PropTypes.func,
  ...ReduxFormPropTypes,
};

const getAction = parcel => {
  if (parcel.destinationTypeCode === "I") {
    return PARCEL_ACTION_CODE.ALI;
  }
  return PARCEL_ACTION_CODE.ALT;
};

export default compose(
  withRelatedParcelsInitialValues,
  withProps(({ additionalInitialValues, parcel }) => ({
    additionalInitialValues: {
      ...additionalInitialValues,
      ...getNotificationDetails(parcel),
      country: parcel.deliveryDetails.address.countryName,
      countryCode: parcel.deliveryDetails.address.countryCode,
    },
  })),
  connect(
    state => ({
      dates: getActionDates(state),
    }),
    (dispatch, { match, parcel }) => ({
      onAddressSelect: address => {
        dispatch(
          clearFields(PARCEL_EDIT_FORM, false, false, Fields.DELIVERY_DATE)
        );
        dispatch(
          getAlternativeDatesAction(address, match.params.actionCode, parcel)
        );
      },
      onPostcodeChange: postcode => {
        if (!postcode) {
          dispatch(
            clearFields(PARCEL_EDIT_FORM, false, false, Fields.DELIVERY_DATE)
          );
          dispatch(resetAddressAction());
          dispatch(clearAlternativeDeliveryDates());
        }
      },
    })
  ),
  withParcelEdit({
    onSubmit: (parcelCode, values, parcel) => {
      const parcelCodes = keys(values).filter(isParcelCode);
      const query = {
        parcelCodes,
        instructions: values.instructions,
        deliveryDate: values.deliveryDate,
        sendersReference: values.sendersReference,
        notificationDetails: {
          mobile: values.mobile,
          email: values.email,
          contactName: values.contactName,
        },
        address: {
          city: values.city,
          countryCode: values.countryCode,
          county: values.county,
          organisation: values.name,
          postcode: values.postcode,
          street: values.street,
          town: values.town,
          property: values.suburb,
        },
        actionCode: getAction(parcel, values),
      };
      return onAlternativeAddress(parcelCode, query);
    },
    validate: deliveryEditValidators.alternativeAddress,
  }),
  withTrack(ALTERNATIVE_ADDRESS.LOAD),
  withTrackProps({
    onSubmit: ALTERNATIVE_ADDRESS.CLICK_SUBMIT,
    onFindPostcode: ALTERNATIVE_ADDRESS.CLICK_FIND_POSTCODE,
    onInputFocus: fieldName => {
      const field = isParcelCode(fieldName) ? Fields.PARCEL_CODE : fieldName;
      switch (field) {
        case Fields.POSTCODE:
          return ALTERNATIVE_ADDRESS.POSTCODE_INPUT;
        case Fields.AGREEMENT:
          return ALTERNATIVE_ADDRESS.CLICK_TERMS_CHECKBOX;
        case Fields.DELIVERY_DATE:
          return ALTERNATIVE_ADDRESS.CLICK_DATE_DROP_DOWN_LIST;
        case Fields.REFERENCE_CHANGE_ADDRESS:
          return ALTERNATIVE_ADDRESS.REFERENCE_INPUT;
        case Fields.INSTRUCTIONS:
          return ALTERNATIVE_ADDRESS.INSTRUCTIONS_INPUT;
        case Fields.CONTACT_NAME:
          return ALTERNATIVE_ADDRESS.CONTACT_NAME_INPUT;
        case Fields.MOBILE:
          return ALTERNATIVE_ADDRESS.MOBILE_NUMBER_INPUT;
        case Fields.EMAIL:
          return ALTERNATIVE_ADDRESS.EMAIL_ADDRESS_INPUT;
        case Fields.ALL_CHECKBOXES:
          return ALTERNATIVE_ADDRESS.CLICK_PARCEL_NO_CHECKBOX_SELECT_ALL;
        case Fields.PARCEL_CODE:
          return ALTERNATIVE_ADDRESS.CLICK_PARCEL_NO_CHECKBOX;
        default:
          break;
      }
    },
    onAddressInput: ALTERNATIVE_ADDRESS.CLICK_ADDRESS_INPUT,
  }),
  hadleInputFocus,
  lifecycle({
    componentDidUpdate() {
      this.props.dispatch(checkMinDate());
    },
    componentWillUnmount() {
      this.props.dispatch(clearAlternativeDeliveryDates());
    },
  })
)(AlternativeAddress);
