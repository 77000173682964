import PropTypes from "prop-types";

export default function ChangeAddressUpgrade({ fill }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6153 26.2588C20.484 26.2588 20.3527 26.2085 20.2527 26.1082L18.1907 24.0408C17.9902 23.8399 17.9902 23.5146 18.1907 23.3136C18.3912 23.1132 18.7153 23.1132 18.9158 23.3136L20.9778 25.3811C21.1784 25.582 21.1784 25.9068 20.9778 26.1077C20.8778 26.209 20.7466 26.2588 20.6153 26.2588Z"
        fill="#414042"
      />
      <path
        d="M16.5039 26.246C16.3726 26.246 16.2414 26.1957 16.1414 26.0954C15.9408 25.895 15.9408 25.5697 16.1414 25.3688L18.1906 23.3142C18.3911 23.1133 18.7152 23.1138 18.9157 23.3142C19.1162 23.5151 19.1162 23.8404 18.9157 24.0414L16.8665 26.0954C16.7665 26.1957 16.6352 26.246 16.5039 26.246Z"
        fill="#414042"
      />
      <path
        d="M18.5532 24.1914C18.4219 24.1914 18.2907 24.141 18.1907 24.0413C17.9902 23.8404 17.9902 23.5151 18.1907 23.3142L20.2527 21.2462C20.4532 21.0453 20.7773 21.0458 20.9778 21.2462C21.1784 21.4471 21.1784 21.7724 20.9778 21.9734L18.9158 24.0413C18.8153 24.141 18.684 24.1914 18.5532 24.1914Z"
        fill="#414042"
      />
      <path
        d="M18.5532 24.1914C18.422 24.1914 18.2907 24.1411 18.1907 24.0409L16.1415 21.9858C15.9415 21.7848 15.9415 21.4595 16.1415 21.2586C16.342 21.0582 16.6661 21.0582 16.8666 21.2586L18.9153 23.3137C19.1158 23.5146 19.1158 23.8399 18.9153 24.0409C18.8153 24.1411 18.684 24.1914 18.5532 24.1914Z"
        fill="#414042"
      />
      <path
        d="M27.2825 31.2222H18.5548C18.2712 31.2222 18.042 30.9925 18.042 30.7083C18.042 30.4241 18.2712 30.1944 18.5548 30.1944H26.2568V22.9997C26.2568 22.7161 26.4861 22.4858 26.7697 22.4858C27.0533 22.4858 27.2825 22.7161 27.2825 22.9997V31.2222Z"
        fill={fill}
      />
      <path
        d="M14.4511 33.2638C14.168 33.2638 13.9383 33.0341 13.9383 32.7499V27.097H9.8357V32.7499C9.8357 33.0341 9.60595 33.2638 9.32288 33.2638C9.0398 33.2638 8.81006 33.0341 8.81006 32.7499V26.0692H14.9639V32.7499C14.9639 33.0341 14.7341 33.2638 14.4511 33.2638Z"
        fill="#414042"
      />
      <path
        d="M22.4003 20.3336C22.269 20.3336 22.1377 20.2833 22.0377 20.183L19.9885 18.1285C19.788 17.9275 19.788 17.6022 19.9885 17.4013C20.189 17.2009 20.5131 17.2009 20.7136 17.4013L22.7629 19.4559C22.9634 19.6568 22.9634 19.9821 22.7629 20.183C22.6629 20.2838 22.5316 20.3336 22.4003 20.3336Z"
        fill={fill}
      />
      <path
        d="M22.4004 20.3336C22.2691 20.3336 22.1378 20.2832 22.0378 20.1835C21.8373 19.9826 21.8373 19.6573 22.0378 19.4564L26.1491 15.3339C26.3496 15.1329 26.6737 15.1334 26.8742 15.3339C27.0747 15.5348 27.0747 15.8601 26.8742 16.061L22.763 20.1835C22.663 20.2838 22.5317 20.3336 22.4004 20.3336Z"
        fill={fill}
      />
      <path
        d="M28.8205 22.6621C28.6892 22.6621 28.5579 22.6117 28.4579 22.5115L25.5359 19.5812C25.3354 19.3803 25.3354 19.055 25.5359 18.8541C25.7364 18.6536 26.0605 18.6536 26.261 18.8541L29.1831 21.7843C29.3836 21.9853 29.3836 22.3106 29.1831 22.5115C29.0831 22.6117 28.9518 22.6621 28.8205 22.6621Z"
        fill={fill}
      />
      <path
        d="M14.2034 19.8294C14.0727 19.8294 13.9414 19.7795 13.8414 19.6793C13.6409 19.4789 13.6404 19.1536 13.8404 18.9522L20.0906 12.6666L23.3639 15.9489C23.5644 16.1499 23.5644 16.4752 23.3639 16.6761C23.1634 16.8765 22.8393 16.8765 22.6388 16.6761L20.0916 14.122L14.5665 19.6783C14.4665 19.779 14.3347 19.8294 14.2034 19.8294Z"
        fill={fill}
      />
      <path
        d="M19.0774 35.3334H4.71851V26.597C4.71851 26.3128 4.94825 26.0831 5.23133 26.0831C5.5144 26.0831 5.74414 26.3128 5.74414 26.597V34.3056H18.0518V26.597C18.0518 26.3128 18.281 26.0831 18.5646 26.0831C18.8482 26.0831 19.0774 26.3128 19.0774 26.597V35.3334Z"
        fill="#414042"
      />
      <path
        d="M3.1795 26.2608C3.04873 26.2608 2.91745 26.211 2.81745 26.1108C2.61694 25.9104 2.61643 25.5856 2.81643 25.3841L11.8851 16.2639L15.4179 19.8062C15.6179 20.0072 15.6179 20.3325 15.4179 20.5334C15.2174 20.7338 14.8928 20.7338 14.6923 20.5334L11.8861 17.7193L3.54309 26.1098C3.44309 26.211 3.3113 26.2608 3.1795 26.2608Z"
        fill="#414042"
      />
      <path
        d="M43.8334 24.8333H38.8334V29.8333H37.1667V24.8333H32.1667V23.1666H37.1667V18.1666H38.8334V23.1666H43.8334V24.8333Z"
        fill="#414042"
      />
    </svg>
  );
}

ChangeAddressUpgrade.defaultProps = {
  fill: "#DC0032",
};

ChangeAddressUpgrade.propTypes = {
  fill: PropTypes.string,
};
