import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { Card } from "@dpdgroupuk/mydpd-ui";

import styles from "./DashboardCard.module.scss";

const DashboardCard = ({
  title,
  helpModalTitle,
  children,
  help,
  closeButtonText,
  confirmButtonText,
  confirmButtonVariant,
  closeButtonVariant,
  classes,
}) => (
  <div>
    <Card.Header className={classNames(classes.header, styles.header)}>
      <Col className="pl-0">
        <Card.StepTitle
          className={classes.title}
          title={title}
          textClassName={classNames(classes.text, styles.titleText)}
        />
      </Col>
      <Col className="col-auto">
        <Row className="align-items-center">
          {_.isArray(help) && (
            <Card.HelpIcon
              help={help}
              helpModalTitle={helpModalTitle}
              closeButtonText={closeButtonText}
              confirmButtonText={confirmButtonText}
              confirmButtonVariant={confirmButtonVariant}
              closeButtonVariant={closeButtonVariant}
            />
          )}
        </Row>
      </Col>
    </Card.Header>
    {children}
  </div>
);

DashboardCard.defaultProps = {
  classes: {},
  rounded: true,
};

DashboardCard.propTypes = {
  title: PropTypes.string,
  helpModalTitle: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  help: PropTypes.array,
  closeButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtonVariant: PropTypes.string,
  closeButtonVariant: PropTypes.string,
  rounded: PropTypes.bool,
};

export default DashboardCard;
