import { removeSpaces } from "./string";

/**
 * postcode examples
 *   B54*
 *   BT118*
 *   AB101*
 * @param postcode
 * @returns {*|string}
 */
export const formatOuterCode = postcode => {
  if (postcode.length > 3 && postcode.length <= 6) {
    return postcode.slice(0, postcode.length - 2) + " " + postcode.slice(-2);
  }
  return postcode;
};

export const formatPostcode = (postcode = "") => {
  const normalizedPostcode = removeSpaces(postcode || ""); // back-end may return with 2 spaces or no spaces
  return (
    normalizedPostcode.slice(0, normalizedPostcode.length - 3) +
    " " +
    normalizedPostcode.slice(-3)
  );
};
