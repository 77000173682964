import { get } from "lodash";

export const getSelectedPickupShopIndex = ({
  pickupShops = [],
  selectedShopCode,
}) =>
  pickupShops.findIndex(
    ({ pickupLocation: { pickupLocationCode } }) =>
      pickupLocationCode === selectedShopCode
  );

export const calculateMapFitBounds = ({
  parcelDestination,
  pickupShops = [],
}) => {
  const parcelLatitude = get(parcelDestination, "latitude");
  const parcelLongitude = get(parcelDestination, "longitude");
  let latitudeBoundMin = parcelLatitude;
  let latitudeBoundMax = parcelLatitude;
  let longitudeBoundMin = parcelLongitude;
  let longitudeBoundMax = parcelLongitude;

  pickupShops.forEach(
    ({
      pickupLocation: {
        addressPoint: { latitude, longitude },
      },
    }) => {
      // Latitude bounds
      latitudeBoundMin =
        latitude < latitudeBoundMin ? latitude : latitudeBoundMin;
      latitudeBoundMax =
        latitude > latitudeBoundMax ? latitude : latitudeBoundMax;
      // Longitude bounds
      longitudeBoundMin =
        longitude < longitudeBoundMin ? longitude : longitudeBoundMin;
      longitudeBoundMax =
        longitude > longitudeBoundMax ? longitude : longitudeBoundMax;
    }
  );

  // Bounds format: [[south, west], [north, east]]
  const bounds = [
    [longitudeBoundMax, latitudeBoundMin],
    [longitudeBoundMin, latitudeBoundMax],
  ];

  return bounds;
};
