import { useMemo, useState } from "react";
import * as React from "react";

import { isEmpty, omitBy } from "lodash";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";

import { Button, Page } from "@dpdgroupuk/mydpd-ui";

import { APIS_ORIGIN_URL } from "../../../../apis";
import {
  CalendarSmall,
  Clock,
  Location,
  PhotoID,
  UtilityBill,
} from "../../../../components/Icon";
import MapView from "../../../../components/MapView";
import * as M from "../../../../constants/strings";
import { isParcelStatusCollectFromDepot } from "../../../../models/parcel";
import { formatDayMonth, formatTime } from "../../../../utils/date";
import { joinStringsWithComma } from "../../../../utils/string";
import PickupDetail from "../PickupDetail/PickupDetail";
import Tile from "../Tiles/Tile";
import Tiles from "../Tiles/Tiles";
import styles from "./DepotDetails.module.scss";

const checkDepotDetails = (depot, parcelStatusType) =>
  !isEmpty(omitBy(depot, isEmpty)) &&
  isParcelStatusCollectFromDepot(parcelStatusType);

const DepotDetails = ({ parcel, depot, onClickShowMap }) => {
  const { depotName, town, street, postcode, depotCode } = depot;
  const [showMap, toggleMap] = useState(false);
  const time = formatTime(parcel.collectFromDepotDiaryDate);
  const dayMonth = formatDayMonth(parcel.collectFromDepotDiaryDate);
  const address = joinStringsWithComma([depotName, town, street, postcode]);

  const isDepotDetailsVisible = React.useMemo(
    () => checkDepotDetails(depot, parcel.parcelStatusType),
    // eslint-disable-next-line
    [parcel]
  );

  const mapImageUrl = useMemo(
    () => showMap && `${APIS_ORIGIN_URL}/map/depot?depotCode=${depotCode}`,
    [depotCode, showMap]
  );

  const toggleMapHandler = () => {
    !showMap && onClickShowMap();
    toggleMap(!showMap);
  };

  return (
    isDepotDetailsVisible && (
      <Container className="pb-4 px-0">
        <Page.Section title="Depot Details">
          <Container>
            <Tiles>
              <Tile title={M.PARCEL_IS_READY_TO_COLLECT_FROM_DEPOT}>
                <div className={styles.container}>
                  <h6> {M.PARCEL_READY_FOR_COLLECTION} </h6>
                  <PickupDetail
                    itemClassName={styles.pickupDetail}
                    iconClassName={styles.pickupDetail__icon}
                    valueClassName={styles.pickupDetail__value}
                    value={dayMonth}
                    icon={CalendarSmall}
                  />
                  <PickupDetail
                    itemClassName={styles.pickupDetail}
                    iconClassName={styles.pickupDetail__icon}
                    valueClassName={styles.pickupDetail__value}
                    value={time}
                    icon={Clock}
                  />
                  {address && (
                    <>
                      <PickupDetail
                        itemClassName={styles.pickupDetail}
                        iconClassName={styles.pickupDetail__icon}
                        valueClassName={styles.pickupDetail__value}
                        value={address}
                        icon={Location}
                      />
                      <Button
                        className={styles.button}
                        onClick={toggleMapHandler}
                      >
                        {M.SEE_ON_MAP}
                      </Button>
                      {showMap && (
                        <MapView
                          onClose={toggleMapHandler}
                          show={showMap}
                          src={mapImageUrl}
                        />
                      )}
                    </>
                  )}
                </div>
              </Tile>
              <Tile title={M.TO_COLLECT_PARCEL_YOU_NEED_TO_BRING}>
                <div className={styles.pickup__info}>
                  <PickupDetail
                    itemClassName={styles.pickup__item}
                    iconClassName={styles.pickup__icon}
                    value={M.UTILITY_BILL}
                    icon={UtilityBill}
                  />
                  <PickupDetail
                    itemClassName={styles.pickup__item}
                    iconClassName={styles.pickup__icon}
                    value={M.PHOTO_ID}
                    icon={PhotoID}
                  />
                  <p className={styles.pickup__notice}>
                    {M.COLLECTION_PARCEL_NOTICE_BOTH}
                  </p>
                </div>
              </Tile>
            </Tiles>
          </Container>
        </Page.Section>
      </Container>
    )
  );
};

DepotDetails.propTypes = {
  depot: PropTypes.object,
  date: PropTypes.string,
  parcel: PropTypes.object,
  onClickShowMap: PropTypes.func,
};

export default DepotDetails;
