import PropTypes from "prop-types";

import { Form } from "@dpdgroupuk/mydpd-ui";

import { getDay, getDayMonth, getDayOfWeek } from "../../utils/date";

const DayPicker = ({
  dates = [],
  selected,
  onSelect,
  defaultHeaderDate,
  meta,
  ...rest
}) => (
  <Form.DateRange
    label="DateRange"
    dates={dates}
    defaultHeaderDate={defaultHeaderDate}
    getDayOfWeek={getDayOfWeek}
    getDay={getDay}
    getDayMonth={getDayMonth}
    meta={meta}
    selected={selected}
    onSelect={onSelect}
    {...rest}
  />
);

DayPicker.propTypes = {
  selected: PropTypes.string,
  defaultHeaderDate: PropTypes.string,
  dates: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  meta: PropTypes.object,
};

export const ReduxifiedDayPicker = ({ input, values, ...inputProps }) => (
  <DayPicker
    selected={input.value}
    dates={values}
    onSelect={input.onChange}
    {...input}
    {...inputProps}
  />
);

ReduxifiedDayPicker.propTypes = {
  input: PropTypes.object,
  values: PropTypes.arrayOf(PropTypes.string),
};

export default DayPicker;
