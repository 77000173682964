import { reduce } from "lodash";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { WATCH_LIST_FORM } from "../../constants/forms";

export const getSelectedItems = createSelector(
  getFormValues(WATCH_LIST_FORM),
  (values = {}) =>
    reduce(values, (acc, value, key) => (value ? [...acc, key] : acc), [])
);
