import { useEffect, useRef } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { Popover } from "@dpdgroupuk/mydpd-ui";

import { delay } from "../utils";
import styles from "./InputWithTitle.module.scss";

const InputWithTitle = ({
  autoFocus,
  label,
  type,
  classNameContainer,
  classNameLabelContainer,
  classNameLabel,
  classNameField,
  meta,
  input,
  required,
  multiline,
  subLabel,
  disabled,
  tooltipPosition,
  containerStyle,
  hideErrors,
  ...inputProps
}) => {
  const popover = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    meta.error &&
      popover &&
      popover.current.props.isOpen &&
      popover.current.updatePopover(true);
  }, [meta.error, meta.touched]);

  useEffect(() => {
    !disabled && autoFocus && inputRef.current && inputRef.current.focus();
  }, [disabled, autoFocus]);

  return (
    <div
      className={classNames(
        styles.fieldContainer,
        multiline && styles.multilineFieldContainer,
        classNameContainer && classNameContainer
      )}
    >
      {subLabel ||
        (label && (
          <div
            className={classNames(
              styles.fieldLabelContainer,
              classNameLabelContainer && classNameLabelContainer
            )}
          >
            {label && (
              <div
                className={classNames(
                  styles.fieldLabel,
                  classNameLabel && classNameLabel
                )}
              >
                {required && <span className={styles.required}>{"*"}</span>}
                {label}
              </div>
            )}
            {subLabel && (
              <div
                className={classNames(
                  styles.fieldLabel,
                  classNameLabel && classNameLabel
                )}
              >
                {subLabel}
              </div>
            )}
          </div>
        ))}
      <div
        className={classNames(styles.field, classNameField && classNameField)}
      >
        <Popover
          ref={popover}
          isOpen={meta.error && meta.touched && !hideErrors}
          content={meta.error}
          position={tooltipPosition}
          containerStyle={containerStyle}
          disableReposition
        >
          {multiline ? (
            <textarea
              className={classNames(
                meta.error && meta.touched && styles.invalid
              )}
              {...inputProps}
              {...input}
              disabled={disabled}
              onBlur={() => delay(input.onBlur)}
              ref={inputRef}
            />
          ) : (
            <input
              type={type}
              className={classNames(
                meta.error && meta.touched && styles.invalid
              )}
              {...inputProps}
              {...input}
              disabled={disabled}
              onDrop={e => e.preventDefault()}
              onBlur={() => delay(input.onBlur)}
              ref={inputRef}
            />
          )}
        </Popover>
      </div>
    </div>
  );
};

InputWithTitle.defaultProps = {
  type: "text",
  multiline: false,
  required: false,
  autoFocus: true,
  hideErrors: false,
};

InputWithTitle.propTypes = {
  label: PropTypes.string,
  tooltipPosition: PropTypes.string,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
  hideErrors: PropTypes.bool,
  type: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  containerStyle: PropTypes.object,
  classNameContainer: PropTypes.string,
  classNameLabel: PropTypes.string,
  classNameField: PropTypes.string,
  classNameLabelContainer: PropTypes.string,
  subLabel: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default InputWithTitle;
