const Unexpected = props => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
    <rect width="48" height="48" fill="white" />
    <path
      d="M41.5949 41.6374H6.40551C5.53721 41.6374 4.76015 41.1885 4.32561 40.437C3.89106 39.6856 3.89186 38.7868 4.32561 38.0354L21.9203 7.56039C22.354 6.80894 23.1319 6.35999 24.0002 6.35999C24.8685 6.35999 25.6456 6.80894 26.0801 7.56039L43.6748 38.0354C44.1085 38.7876 44.1085 39.6864 43.674 40.437C43.2402 41.1893 42.4624 41.6374 41.5949 41.6374ZM24.0002 7.96052C23.861 7.96052 23.5152 7.99973 23.3072 8.36066L5.71167 38.8357C5.5028 39.1966 5.64205 39.5167 5.71167 39.6367C5.7813 39.7568 5.98777 40.0369 6.40551 40.0369H41.5949C42.0118 40.0369 42.2183 39.7568 42.2879 39.6367C42.3576 39.5167 42.4968 39.1974 42.2879 38.8357L24.6932 8.36066C24.4852 7.99973 24.1394 7.96052 24.0002 7.96052Z"
      fill="#414042"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.008 23.0405C24.2191 23.0405 24.3901 23.2116 24.3901 23.4226V28.008C24.3901 28.2191 24.2191 28.3901 24.008 28.3901C23.797 28.3901 23.6259 28.2191 23.6259 28.008V23.4226C23.6259 23.2116 23.797 23.0405 24.008 23.0405Z"
      fill="#414042"
    />
    <path d="M24.0359 22.6584C24.247 22.6584 24.418 22.4873 24.418 22.2763C24.418 22.0652 24.247 21.8942 24.0359 21.8942C23.8249 21.8942 23.6538 22.0652 23.6538 22.2763C23.6538 22.4873 23.8249 22.6584 24.0359 22.6584Z" />
    <path
      d="M29.7677 28.3821C29.9787 28.3821 30.1498 28.211 30.1498 28C30.1498 27.789 29.9787 27.6179 29.7677 27.6179C29.5566 27.6179 29.3856 27.789 29.3856 28C29.3856 28.211 29.5566 28.3821 29.7677 28.3821Z"
      fill="#414042"
    />
    <path d="M26.9136 23.4226C27.1247 23.4226 27.2958 23.2515 27.2958 23.0405C27.2958 22.8295 27.1247 22.6584 26.9136 22.6584C26.7026 22.6584 26.5315 22.8295 26.5315 23.0405C26.5315 23.2515 26.7026 23.4226 26.9136 23.4226Z" />
    <path d="M29.0034 25.5124C29.2145 25.5124 29.3856 25.3413 29.3856 25.1303C29.3856 24.9193 29.2145 24.7482 29.0034 24.7482C28.7924 24.7482 28.6213 24.9193 28.6213 25.1303C28.6213 25.3413 28.7924 25.5124 29.0034 25.5124Z" />
    <path
      d="M29.0034 31.2801C29.2145 31.2801 29.3856 31.109 29.3856 30.898C29.3856 30.6869 29.2145 30.5159 29.0034 30.5159C28.7924 30.5159 28.6213 30.6869 28.6213 30.898C28.6213 31.109 28.7924 31.2801 29.0034 31.2801Z"
      fill="#414042"
    />
    <path
      d="M26.9136 33.4173C27.1247 33.4173 27.2958 33.2462 27.2958 33.0352C27.2958 32.8241 27.1247 32.653 26.9136 32.653C26.7026 32.653 26.5315 32.8241 26.5315 33.0352C26.5315 33.2462 26.7026 33.4173 26.9136 33.4173Z"
      fill="#414042"
    />
    <path
      d="M24.0359 34.1815C24.247 34.1815 24.418 34.0104 24.418 33.7994C24.418 33.5884 24.247 33.4173 24.0359 33.4173C23.8249 33.4173 23.6538 33.5884 23.6538 33.7994C23.6538 34.0104 23.8249 34.1815 24.0359 34.1815Z"
      fill="#414042"
    />
    <path
      d="M21.1578 33.4173C21.3689 33.4173 21.5399 33.2462 21.5399 33.0352C21.5399 32.8241 21.3689 32.653 21.1578 32.653C20.9468 32.653 20.7757 32.8241 20.7757 33.0352C20.7757 33.2462 20.9468 33.4173 21.1578 33.4173Z"
      fill="#414042"
    />
    <path
      d="M18.9966 31.2801C19.2076 31.2801 19.3787 31.109 19.3787 30.898C19.3787 30.6869 19.2076 30.5159 18.9966 30.5159C18.7855 30.5159 18.6144 30.6869 18.6144 30.898C18.6144 31.109 18.7855 31.2801 18.9966 31.2801Z"
      fill="#414042"
    />
    <path
      d="M18.2323 28.4127C18.4434 28.4127 18.6144 28.2416 18.6144 28.0306C18.6144 27.8195 18.4434 27.6485 18.2323 27.6485C18.0213 27.6485 17.8502 27.8195 17.8502 28.0306C17.8502 28.2416 18.0213 28.4127 18.2323 28.4127Z"
      fill="#414042"
    />
    <path
      d="M18.9966 25.5124C19.2076 25.5124 19.3787 25.3413 19.3787 25.1303C19.3787 24.9193 19.2076 24.7482 18.9966 24.7482C18.7855 24.7482 18.6144 24.9193 18.6144 25.1303C18.6144 25.3413 18.7855 25.5124 18.9966 25.5124Z"
      fill="#414042"
    />
    <path
      d="M21.1578 23.4226C21.3689 23.4226 21.5399 23.2515 21.5399 23.0405C21.5399 22.8295 21.3689 22.6584 21.1578 22.6584C20.9468 22.6584 20.7757 22.8295 20.7757 23.0405C20.7757 23.2515 20.9468 23.4226 21.1578 23.4226Z"
      fill="#414042"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.8305 26.3315C26.938 26.513 26.878 26.7474 26.6964 26.855L24.2073 28.3292C24.0258 28.4367 23.7914 28.3767 23.6838 28.1951C23.5763 28.0135 23.6363 27.7791 23.8179 27.6716L26.307 26.1974C26.4886 26.0899 26.723 26.1499 26.8305 26.3315Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 20.7642C20.0038 20.7642 16.7642 24.0038 16.7642 28C16.7642 31.9962 20.0038 35.2358 24 35.2358C27.9962 35.2358 31.2358 31.9962 31.2358 28C31.2358 24.0038 27.9962 20.7642 24 20.7642ZM16 28C16 23.5817 19.5817 20 24 20C28.4183 20 32 23.5817 32 28C32 32.4183 28.4183 36 24 36C19.5817 36 16 32.4183 16 28Z"
      fill="#414042"
    />
    <circle cx="31" cy="33" r="5" />
    <path d="M15 14.75H16L15.7775 16.75H15.2293L15 14.75Z" fill="white" />
    <rect x="15" y="17.25" width="1" height="1" rx="0.5" fill="white" />
  </svg>
);

export default Unexpected;
