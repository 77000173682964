import PropTypes from "prop-types";

export default function CollectFromPickup({ fill }) {
  return (
    <svg
      id="Collect-from-pickup-1"
      xmlns="http://www.w3.org/2000/svg"
      width="284"
      height="284"
      viewBox="0 0 284 284"
      fill="none"
    >
      <path
        d="M229.664 179.538C229.689 179.538 229.706 179.555 229.706 179.58V217.177C229.706 224.097 224.097 229.706 217.177 229.706H66.8236C59.9032 229.706 54.2942 224.097 54.2942 217.177V179.58C54.2942 179.555 54.3109 179.538 54.336 179.538H229.664Z"
        fill={fill}
      />
      <path
        d="M183.765 208.799H100.235C97.9299 208.799 96.0588 206.932 96.0588 204.622C96.0588 202.312 97.9299 200.446 100.235 200.446H183.765C186.074 200.446 187.941 202.312 187.941 204.622C187.941 206.932 186.074 208.799 183.765 208.799Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.8236 233.882H217.177C226.39 233.882 233.882 226.39 233.882 217.176V179.538V66.8235C233.882 57.6102 226.39 50.1176 217.177 50.1176H66.8236C57.6103 50.1176 50.1177 57.6102 50.1177 66.8235V179.538V217.176C50.1177 226.39 57.6103 233.882 66.8236 233.882ZM58.4706 175.362V66.8235C58.4706 62.2169 62.2169 58.4706 66.8236 58.4706H217.177C221.783 58.4706 225.529 62.2169 225.529 66.8235V175.362H58.4706ZM58.4706 183.715H225.529V217.176C225.529 221.783 221.783 225.529 217.177 225.529H66.8236C62.2169 225.529 58.4706 221.783 58.4706 217.176V183.715Z"
        fill="#414042"
      />
      <path
        d="M181.672 99.2956L159.174 112.786C158.923 112.932 158.602 113.007 158.272 113.007C157.908 113.007 157.562 112.915 157.303 112.752L156.605 112.318L154.316 110.898C153.807 110.585 153.41 109.87 153.41 109.273V106.918V105.74C153.41 105.138 153.82 104.291 154.342 103.99L154.801 103.714L177.805 89.9194L143.646 70.9833C142.848 70.5406 141.541 70.5364 140.743 70.9791L105.514 90.1074L146.364 113.487C146.895 113.775 147.296 114.465 147.296 115.087V115.241V117.476L147.292 122.65L147.279 148.816C147.279 149.426 146.861 150.127 146.327 150.411L143.249 152.115C143.249 152.115 143.215 152.12 143.203 152.128C142.952 152.249 142.652 152.328 142.33 152.328H142.272C141.904 152.337 141.557 152.266 141.29 152.12L139.348 151.046L138.199 150.411C137.673 150.132 137.26 149.426 137.26 148.816L137.251 122.65H137.243V120.412C137.214 120.094 136.984 119.706 136.729 119.564L102.34 99.6715L102.324 138.559C102.324 139.469 102.967 140.597 103.756 141.056L140.818 162.77C141.607 163.233 142.894 163.229 143.679 162.761L180.252 141.027C181.038 140.559 181.677 139.431 181.677 138.521L181.672 99.2956Z"
        fill={fill}
      />
    </svg>
  );
}

CollectFromPickup.propTypes = {
  fill: PropTypes.string,
};
