import { combineReducers } from "redux";

import { reducer as dashboard } from "./Dashboard";
import { reducer as editDelivery } from "./EditDelivery";
import { reducer as parcelView } from "./Parcel";

export const uiReducers = combineReducers({
  dashboard,
  parcelView,
  editDelivery,
});
