import { useMemo } from "react";

import classNames from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import { Button } from "@dpdgroupuk/mydpd-ui";
import { PARCEL_ACTION_CODE } from "@dpdgroupuk/redback-enums";

import { SUBMIT } from "../../../../constants/strings";
import NotificationDetails from "../NotificationDetails";
import RelatedItems from "../RelatedItems";
import styles from "./EditPanel.module.scss";

const EditPanel = ({
  onSubmit,
  error,
  children,
  disableSubmit,
  relatedParcelsInitialValues,
  additionalInfoComponent,
  showNotificationTitle,
  isMobileEmailRequired,
  offContactDetailsValidation,
  ...rest
}) => {
  const isRelatedParcelTableVisible = useMemo(
    () => !isEmpty(relatedParcelsInitialValues),
    [relatedParcelsInitialValues]
  );

  const { actionCode } = rest.match.params;

  // TODO: temporary, will be refactored
  const isNotificationDetailVisible = actionCode !== PARCEL_ACTION_CODE.DIP;

  return (
    <Container fluid className="p-0">
      <Row>
        {isNotificationDetailVisible && (
          <NotificationDetails
            actionCode={actionCode}
            showNotificationTitle={showNotificationTitle}
            isMobileEmailRequired={isMobileEmailRequired}
            offContactDetailsValidation={offContactDetailsValidation}
          />
        )}
        {isRelatedParcelTableVisible && <RelatedItems {...rest} />}
        {children}
      </Row>
      <Row>
        {additionalInfoComponent && (
          <Col className="col-12">{additionalInfoComponent}</Col>
        )}
      </Row>
      <Row>
        <Col className="col-12 text-right">
          <Button
            className={styles.submitButton}
            onClick={onSubmit}
            disabled={disableSubmit}
          >
            {SUBMIT}
          </Button>
          <div className={classNames(styles.error, !error && "d-none")}>
            {error}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

EditPanel.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.any,
  children: PropTypes.node,
  disableSubmit: PropTypes.bool,
  relatedParcelsInitialValues: PropTypes.array,
  actionCode: PropTypes.string,
  additionalInfoComponent: PropTypes.node,
  showNotificationTitle: PropTypes.bool,
  isMobileEmailRequired: PropTypes.bool,
  offContactDetailsValidation: PropTypes.bool,
};

export default EditPanel;
