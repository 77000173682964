import classNames from "classnames";
import PropTypes from "prop-types";

import { Button, FallBackImage, Modal } from "@dpdgroupuk/mydpd-ui";

import { MAP_VIEW, OK } from "../../constants/strings";
import Delimiter from "../Delimiter";
import styles from "./MapViewModal.module.scss";

const MapView = ({
  src,
  show,
  onClose,
  onHide,
  hideOnClickOutside,
  longitude,
  latitude,
}) => (
  <Modal
    dialogClassName={classNames(styles.modal)}
    show={show}
    onHide={(hideOnClickOutside && onClose) || onHide}
  >
    <Modal.Body>
      <span className={classNames(styles.modalHeader)}>{MAP_VIEW}</span>
      <Delimiter />
      <FallBackImage className={styles.img} src={src} />
    </Modal.Body>
    <Modal.Footer className={classNames(styles.modalFooter)}>
      {longitude && latitude ? (
        <div>
          {latitude}, {longitude}
        </div>
      ) : (
        ""
      )}
      <Button onClick={onClose}>{OK}</Button>
    </Modal.Footer>
  </Modal>
);

MapView.defaultProps = {
  onHide: () => null,
};

MapView.propTypes = {
  src: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onHide: PropTypes.func,
  hideOnClickOutside: PropTypes.bool,
};

export default MapView;
