import PropTypes from "prop-types";

import { ParcelTypes } from "../../../models/types";
import AdditionalInformation from "../components/AdditionalInformation";
import HeaderWithDetails from "../components/HeaderWithDetails";
import ParcelEstimatedInstructions from "../components/ParcelEstimatedInstructions";
import ParcelHistory from "../components/ParcelHistory";
import RelatedParcels from "../components/RelatedParcels";

const EstimatedDateParcelView = ({
  parcel,
  children,
  onClickDeliveryHistory,
  onClickConsignment,
  onClickAdditionalInformation,
}) => (
  <>
    <HeaderWithDetails parcel={parcel}>
      <ParcelEstimatedInstructions parcel={parcel} />
    </HeaderWithDetails>
    {children}
    <ParcelHistory data={parcel.parcelEvents} onOpen={onClickDeliveryHistory} />
    <RelatedParcels
      parcelCode={parcel.parcelCode}
      onOpen={onClickConsignment}
    />
    <AdditionalInformation
      parcel={parcel}
      onOpen={onClickAdditionalInformation}
    />
  </>
);

EstimatedDateParcelView.propTypes = {
  parcel: PropTypes.shape(ParcelTypes.Parcel),
  children: PropTypes.node,
  onClickDeliveryHistory: PropTypes.func,
  onClickConsignment: PropTypes.func,
  onClickAdditionalInformation: PropTypes.func,
};

export default EstimatedDateParcelView;
