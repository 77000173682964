import PropTypes from "prop-types";

export default function Arrow({ type, className, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <mask
        id={type}
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="18"
        height="18"
      >
        <path d="M1.25 1.25H18.75V18.75H1.25V1.25Z" fill={fill} />
      </mask>
      <g mask={`url(${type})`}>
        <path
          d="M14.6875 3.30625L7.92721 10L14.6875 16.6938L12.6063 18.75L3.75 10L12.6063 1.25L14.6875 3.30625Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

Arrow.propTypes = {
  type: PropTypes.string,
  fill: PropTypes.string,
  className: PropTypes.string,
};

Arrow.defaultProps = {
  fill: "#000000",
};
