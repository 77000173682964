import PropTypes from "prop-types";

import styles from "./PickupShopDetail.module.scss";

const PickupShopDetail = ({ icon: Icon, text, visible = false }) =>
  visible ? (
    <div className={styles.detail}>
      {Icon && (
        <span className={styles.detail__icon}>
          {" "}
          <Icon />
        </span>
      )}
      {text && <p className={styles.detail__text}>{text}</p>}
    </div>
  ) : null;

PickupShopDetail.propTypes = {
  text: PropTypes.string,
  visible: PropTypes.bool,
  icon: PropTypes.func,
};

export default PickupShopDetail;
