import PropTypes from "prop-types";

export default function PhotoIDSmall({ fill }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 15H18C18.2761 15 18.5 14.7761 18.5 14.5C18.5 14.2239 18.275 14 18 14H14C13.7239 14 13.5 14.2239 13.5 14.5C13.5 14.7761 13.725 15 14 15ZM19 5H5C3.89531 5 3 5.89531 3 7V17C3 18.1047 3.89531 19 5 19H19C20.1047 19 21 18.1047 21 17V7C21 5.89531 20.1031 5 19 5ZM20 17C20 17.5513 19.5513 18 19 18H5C4.44875 18 4 17.5513 4 17V8H20V17ZM4 7C4 6.44875 4.44875 6 5 6H19C19.5513 6 20 6.44875 20 7H4ZM14 11H18C18.2761 11 18.5 10.7761 18.5 10.5C18.5 10.2239 18.275 10 18 10H14C13.7239 10 13.5 10.2239 13.5 10.5C13.5 10.7761 13.725 11 14 11ZM14 13H18C18.2761 13 18.5 12.7761 18.5 12.5C18.5 12.2239 18.275 12 18 12H14C13.7239 12 13.5 12.2239 13.5 12.5C13.5 12.7761 13.725 13 14 13ZM9 13C10.1047 13 11 12.1047 11 11C11 9.89531 10.1031 9 9 9C7.89687 9 7 9.89688 7 11C7 12.1031 7.89687 13 9 13ZM9 10C9.55125 10 10 10.4488 10 11C10 11.5512 9.55 12 9 12C8.45 12 8 11.55 8 11C8 10.45 8.45 10 9 10ZM6 17C6.27612 17 6.5 16.7761 6.5 16.5C6.5 15.6719 7.17188 15 8 15H10C10.8284 15 11.5 15.6716 11.5 16.5C11.5 16.7761 11.7239 17 12 17C12.2761 17 12.5 16.7761 12.5 16.5C12.5 15.1194 11.3806 14 10 14H8C6.61938 14 5.5 15.1194 5.5 16.5C5.5 16.775 5.72375 17 6 17Z"
        fill={fill}
      />
    </svg>
  );
}

PhotoIDSmall.defaultProps = {
  fill: "#000",
};

PhotoIDSmall.propTypes = {
  fill: PropTypes.string,
};
