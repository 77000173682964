import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { Card, useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

import serviceBannerImage from "../../assets/images/serviceBanner.png";
import serviceBannerImageLocal from "../../assets/images/serviceBannerLocal.png";

import styles from "./ServiceBanner.module.scss";
import { THEME_NAMES } from "../../constants/config";

const ServiceBanner = ({ onClickServicePDF }) => {
  const { themeName } = useMyDpdTheme();

  return (
    <Card
      className={classNames(
        styles.serviceBannerContainer,
        themeName === THEME_NAMES.DPD
          ? styles.bannerColourRed
          : styles.bannerColourBlue
      )}
    >
      <Row>
        <Col lg={6}>
          <div className={styles.bannerContent}>
            <h6 className={styles.bannerTitle}>
              For network, traffic
              <br />
              and weather updates
            </h6>
            <button
              className={styles.bannerButton}
              onClick={() => onClickServicePDF()}
            >
              View Bulletin
            </button>
          </div>
        </Col>
        <Col>
          <img
            className={styles.bannerImg}
            src={
              themeName === THEME_NAMES.DPD
                ? serviceBannerImage
                : serviceBannerImageLocal
            }
            alt=""
          />
        </Col>
      </Row>
    </Card>
  );
};

ServiceBanner.propTypes = {
  bannerURL: PropTypes.string,
  onClickServicePDF: PropTypes.func,
};

export default ServiceBanner;
