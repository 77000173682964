/* eslint-disable react/style-prop-object */
import * as React from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";

import { useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

import { Home, PickupShopMarker } from "../../../../../../components/Icon";
import { getAddressPoint } from "../../../../../Parcel/selectors";
import { getPickupShops, getSelectedPickupShop } from "../../../../selectors";
import { calculateMapFitBounds } from "../../utils";
import styles from "./PickupShopsMap.module.scss";

const { REACT_APP_MAPBOX_API_TOKEN } = process.env;

const Map = ReactMapboxGl({
  accessToken: REACT_APP_MAPBOX_API_TOKEN,
  attributionControl: false,
});

const padding =
  window.innerWidth > 768
    ? { top: 106, bottom: 80, left: 60, right: 60 }
    : { top: 86, bottom: 60, left: 40, right: 40 };

const PickupShopsMap = ({
  pickupShops = [],
  onMarkerClick,
  fitBounds,
  mapRef,
  selectedPickupShop,
  addressPoint,
}) => {
  const theme = useMyDpdTheme();
  const center = React.useMemo(() => {
    const shop = selectedPickupShop.pickupLocation
      ? selectedPickupShop
      : pickupShops[0];
    const latitude = get(shop, "pickupLocation.addressPoint.latitude");
    const longitude = get(shop, "pickupLocation.addressPoint.longitude");
    return [longitude, latitude];
    // eslint-disable-next-line
  }, [selectedPickupShop]);
  const homeCoordinates = React.useMemo(
    () => [addressPoint.longitude, addressPoint.latitude],
    [addressPoint]
  );
  return (
    <Map
      style="mapbox://styles/mapbox/streets-v11"
      containerStyle={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
      fitBounds={fitBounds}
      fitBoundsOptions={{ padding }}
      center={center}
      ref={mapRef}
    >
      {pickupShops.map((pickupShop, index) => {
        const {
          pickupLocation: {
            pickupLocationCode,
            addressPoint: { latitude, longitude },
          },
        } = pickupShop;
        const selectedPickupShopCode = get(
          selectedPickupShop,
          "pickupLocation.pickupLocationCode"
        );
        return (
          <Marker
            key={pickupLocationCode}
            coordinates={[longitude, latitude]}
            anchor="bottom"
            onClick={onMarkerClick(pickupShop)}
            className={
              pickupLocationCode === selectedPickupShopCode &&
              styles.markerSelected
            }
          >
            <PickupShopMarker
              index={index}
              fill={
                pickupLocationCode === selectedPickupShopCode
                  ? theme.palette.brand
                  : undefined
              }
            />
          </Marker>
        );
      })}
      <Marker key="home" coordinates={homeCoordinates} anchor="bottom">
        <Home />
      </Marker>
    </Map>
  );
};

PickupShopsMap.propTypes = {
  pickupShops: PropTypes.array.isRequired,
  onMarkerClick: PropTypes.func,
  fitBounds: PropTypes.array,
  mapRef: PropTypes.object,
  selectedPickupShop: PropTypes.object,
  addressPoint: PropTypes.object,
};

export default compose(
  connect(state => ({
    pickupShops: getPickupShops(state),
    selectedPickupShop: getSelectedPickupShop(state),
    addressPoint: getAddressPoint(state),
  })),
  withProps(({ pickupShops, carousel, onSelect }) => {
    const fitBounds = calculateMapFitBounds({
      parcelDestination: pickupShops[0].pickupLocation.addressPoint,
      pickupShops,
    });

    const onMarkerClick = shop => () => {
      const selectedIndex = pickupShops.findIndex(
        pickupShop =>
          pickupShop.pickupLocation.pickupLocationCode ===
          shop.pickupLocation.pickupLocationCode
      );
      onSelect(shop);
      carousel.current.goTo(selectedIndex);
    };

    return {
      fitBounds,
      onMarkerClick,
    };
  })
)(PickupShopsMap);
