import { compose, withHandlers } from "recompose";

import { withFeedback } from "@dpdgroupuk/react-sentry-feedback";

export default compose(
  withFeedback,
  withHandlers({
    onReportFeedbackClick:
      ({ feedback }) =>
      eventId =>
        feedback.showReportDialog({
          eventId,
          onClose: window.location.reload,
        }),
  })
);
