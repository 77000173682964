import {
  camelCase,
  isEmpty,
  keysIn,
  mapKeys,
  omitBy,
  pick,
  uniq,
  valuesIn,
} from "lodash";

import {
  COMMON_SEARCH_FIELDS,
  Fields,
  POSTCODE_FIELDS,
  SEARCH_REQUEST_FIELDS,
  SEARCH_TYPE_FIELDS_MAP,
} from "../../constants/forms";
import { transformAccount } from "../../models/query";
import { removeSpaces } from "../../utils/string";

const emptyValues = {};

const caseCodeDefault = "00000000";

export const findFilledPostcode = object =>
  omitBy(pick(object, valuesIn(POSTCODE_FIELDS)), isEmpty);

const transformPostcodeField = object => {
  const filledPostcode = findFilledPostcode(object);
  const postcodeFieldKey = keysIn(filledPostcode)[0];

  return {
    [Fields.POSTCODE]: filledPostcode[postcodeFieldKey],
  };
};

export const getTargetSearchQuery = (query = emptyValues) => {
  const targetValues = {};
  const { type } = query;

  COMMON_SEARCH_FIELDS.forEach(key => (targetValues[key] = query[key]));

  if (type) {
    const typeFormFields = SEARCH_TYPE_FIELDS_MAP[type] || [];
    typeFormFields.forEach(key => (targetValues[key] = query[key]));
  }

  return omitBy(targetValues, isEmpty);
};

export const transformSearchFormValues = (formData = emptyValues) => {
  const searchQuery = getTargetSearchQuery(formData);

  return mapKeys(
    omitBy(
      {
        ...pick(searchQuery, [...SEARCH_REQUEST_FIELDS]),
        [`${searchQuery[Fields.DATE_TYPE]}_${Fields.FROM_DATE}`]:
          searchQuery[Fields.FROM_DATE],
        [`${searchQuery[Fields.DATE_TYPE]}_${Fields.TO_DATE}`]:
          searchQuery[Fields.TO_DATE],
        [Fields.CASE_CODE]: searchQuery[Fields.CASE_CODE]
          ? `${caseCodeDefault.slice(searchQuery[Fields.CASE_CODE].length)}${
              searchQuery[Fields.CASE_CODE]
            }`
          : undefined,
        [Fields.COLLECTION_CODE]: searchQuery[Fields.COLLECTION_CODE]
          ? removeSpaces(searchQuery[Fields.COLLECTION_CODE] || "")
          : undefined,
        [Fields.DELIVERY_REFERENCE]: searchQuery[Fields.DELIVERY_REFERENCE]
          ? uniq(
              searchQuery[Fields.DELIVERY_REFERENCE].split("\n").filter(v => v)
            ).join(",")
          : undefined,
        [Fields.ACCOUNT]: transformAccount(searchQuery[Fields.ACCOUNT]),
        ...transformPostcodeField(searchQuery),
      },
      isEmpty
    ),
    (_, key) => camelCase(key)
  );
};

export const onlyNumberNormalizer = (value, previousValue) => {
  const v = value === undefined ? previousValue : value;
  return (v || "").replace(/[^0-9\n]/g, "");
};
