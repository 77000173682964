export default function UtilityBill() {
  return (
    <svg
      id="Utility-bill-1"
      focusable="false"
      viewBox="0 0 104 118"
      width="43px"
      height="43px"
      aria-hidden="true"
      role="presentation"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group" fill="rgb(65, 64, 66)">
          <ellipse id="Oval" cx="83" cy="66.5" rx="6" ry="3.5" />
          <ellipse id="Oval-Copy" cx="85" cy="80.5" rx="6" ry="3.5" />
          <ellipse id="Oval-Copy-2" cx="83" cy="94.5" rx="6" ry="3.5" />
          <rect id="Rectangle" x="21" y="63" width="48" height="7" rx="3.5" />
          <rect
            id="Rectangle-Copy"
            x="25"
            y="77"
            width="48"
            height="7"
            rx="3.5"
          />
          <rect
            id="Rectangle-Copy-2"
            x="21"
            y="91"
            width="48"
            height="7"
            rx="3.5"
          />
          <polygon id="Path-2" points="75 11 75 25 68 25 74 47 76 33 84 34" />
          <g id="Utility" fillRule="nonzero">
            <path
              d="M103.4,2.84217094e-14 C91.5,19.6 92.3,39.2 98.6,59.5 C104.1,77.3 102.8,95 95.6,112.3 C94.1,115.8 92.2,117.2 88.2,117.2 C60.2,117 32.2,117.1 4.2,117.1 C2.7,117.1 1.2,117.1 5.68434189e-14,117.1 C2.4,109.7 5.3,102.6 6.9,95.3 C9.6,82.9 8.5,70.4 4.6,58.3 C-0.8,41.4 -1.4,24.6 5.1,7.9 C8.2,0.1 8.2,0.1 16.6,0.1 C43.4,0.1 70.3,0.1 97.1,0.1 C98.9,2.84217094e-14 100.7,2.84217094e-14 103.4,2.84217094e-14 Z M94.3,5.2 C91.8,5.2 90.2,5.2 88.6,5.2 C64.8,5.2 41,5.3 17.2,5.1 C13.2,5.1 11.3,6.4 10,10.1 C4.3,25.6 4.8,41.1 9.7,56.6 C13.1,67.3 14.8,78.4 13.2,89.6 C12.1,96.9 10.3,104.1 8.8,111.6 C9.4,111.7 10.2,111.8 11,111.8 C36.1,111.8 61.3,111.7 86.4,111.9 C89.6,111.9 90.7,110.4 91.7,107.9 C97.7,92.1 98.4,76 93.3,59.9 C87.5,41.8 86.5,24 94.3,5.2 Z"
              id="Shape"
            />
          </g>
          <path
            d="M50.2677094,11 C53.8677094,17.8 56.9677094,23.1 59.4677094,28.6 C61.6677094,33.5 60.2677094,38.2 56.3677094,41 C52.6677094,43.8 47.5677094,43.7 43.8677094,40.8 C40.0677094,37.8 38.8677094,33.3 41.1677094,28.3 C43.6677094,22.9 46.6677094,17.8 50.2677094,11 Z M54,29 C52.8954305,29 52,31.2385763 52,34 C52,36.7614237 52.8954305,39 54,39 C55.1045695,39 56,36.7614237 56,34 C56,31.2385763 55.1045695,29 54,29 Z"
            id="Combined-Shape"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  );
}
