import { set } from "lodash";

export const toUppercase = (state, action) => {
  switch (action.type) {
    case "@@redux-form/BLUR":
      if (typeof action.payload === "string") {
        const values = { ...state.values };
        set(values, action.meta.field, action.payload.toUpperCase());
        return {
          ...state,
          values,
        };
      }
      return state;
    default:
      return state;
  }
};

export const fieldValueToUppercase = field => (state, action) => {
  switch (action.type) {
    case "@@redux-form/BLUR":
      if (typeof action.payload === "string" && action.meta.field === field) {
        const values = { ...state.values };
        set(values, action.meta.field, action.payload.toUpperCase());
        return {
          ...state,
          values,
        };
      }
      return state;
    default:
      return state;
  }
};
