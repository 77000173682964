import PropTypes from "prop-types";

export default function Parking({ fill }) {
  return (
    <svg
      id="Parking"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <path d="M0 0L16 0V16H0L0 0Z" fill={fill} />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 0H9.15385C12.067 0 14.4286 2.38781 14.4286 5.33333C14.4286 8.27885 12.067 10.6667 9.15385 10.6667H6.51648V16H3V0ZM6.42859 6.85716H9.24178C10.2128 6.85716 11 6.08965 11 5.14287C11 4.1961 10.2128 3.42859 9.24178 3.42859H6.42859V6.85716Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

Parking.propTypes = {
  fill: PropTypes.string,
};

Parking.defaultProps = {
  fill: "#414042",
};
