import { useMemo } from "react";

import { isEmpty } from "lodash/lang";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field, propTypes } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import { DEFAULT_DATE_FORMAT } from "../../../../constants/dateFormats";
import {
  DATE_TYPES_OPTIONS,
  Fields,
  POSTCODE_MAX_LENGTH,
} from "../../../../constants/forms";
import * as M from "../../../../constants/strings";
import PostcodeFinderInput from "../../../../features/PostcodeFinder/PostcodeFinderInput";
import { onlyNumberNormalizer } from "../../model";
import styles from "../Form.module.scss";
import {
  getCollectionOrCaseCodeObject,
  getReferenceOrEmailOrPhoneObject,
} from "../helpers";

const SearchOptions = ({
  optionType,
  onFieldEntry,
  dateType,
  onSelectAddressFromModal,
  onFindClick, // only for analytics
  clearFields,
  preventDatePickerBlur,
  setIsDatePickerOpen,
  getEndDatesRange,
  relatedDate,
}) => {
  const CollectionOrCaseCodeObject = useMemo(
    () => getCollectionOrCaseCodeObject(optionType),
    [optionType]
  );
  const ReferenceOrEmailOrPhoneObject = useMemo(
    () => getReferenceOrEmailOrPhoneObject(optionType),
    [optionType]
  );

  return (
    <>
      {optionType === Fields.DELIVERY_REFERENCE && (
        <Col className={styles.inputContainer}>
          <Field
            name={Fields.DELIVERY_REFERENCE}
            component={FormControl.Input}
            label={M.DELIVERY_REFERENCE}
            multiline
            rows={3}
            normalize={onlyNumberNormalizer}
            classes={{
              input: styles.textarea,
            }}
            helperText={M.PARCEL_REFERENCE_NOTICE}
            onBlur={onFieldEntry}
            required
          />
        </Col>
      )}
      {optionType === Fields.POSTCODE && (
        <Col className={styles.postcodeContainer}>
          <Field
            component={PostcodeFinderInput}
            name={Fields.POSTCODE}
            label={M.POSTCODE}
            maxLength={POSTCODE_MAX_LENGTH}
            id={Fields.POSTCODE}
            onSelectionChange={onSelectAddressFromModal}
            helperText={M.DESTINATION}
            onFindClick={onFindClick}
            onBlur={onFieldEntry}
            required
          />
        </Col>
      )}
      {!isEmpty(CollectionOrCaseCodeObject) && (
        <Col className={styles.inputContainer}>
          <Field
            component={FormControl.Input}
            name={CollectionOrCaseCodeObject.name}
            label={CollectionOrCaseCodeObject.label}
            maxLength={25}
            onBlur={onFieldEntry}
            helperText={M.MAX_25_CHARACTERS}
            required
          />
        </Col>
      )}
      {!isEmpty(ReferenceOrEmailOrPhoneObject) && (
        <>
          <Col className={styles.inputContainer}>
            <Field
              component={FormControl.Input}
              name={ReferenceOrEmailOrPhoneObject.requiredName}
              label={ReferenceOrEmailOrPhoneObject.label}
              maxLength={ReferenceOrEmailOrPhoneObject.maxLength}
              minLength={ReferenceOrEmailOrPhoneObject.minLength}
              onBlur={onFieldEntry}
              helperText={ReferenceOrEmailOrPhoneObject.helperText}
              required
              normalize={ReferenceOrEmailOrPhoneObject.normalize}
            />
          </Col>
          <Row className="w-100 pl-2 pr-2">
            <Col className={styles.postcodeContainer}>
              <Field
                name={ReferenceOrEmailOrPhoneObject.optionalName}
                component={PostcodeFinderInput}
                label={M.POSTCODE}
                onSelectionChange={values =>
                  onSelectAddressFromModal(
                    values,
                    ReferenceOrEmailOrPhoneObject.optionalName
                  )
                }
                maxLength={POSTCODE_MAX_LENGTH}
                id={Fields.POSTCODE}
                onFindClick={onFindClick}
                onBlur={onFieldEntry}
                helperText={M.DESTINATION}
              />
            </Col>
          </Row>
        </>
      )}
      {optionType === Fields.DATE_TYPE && (
        <>
          <Col className={styles.inputContainer}>
            <Field
              component={FormControl.Dropdown}
              label={M.DATE_TYPE.toUpperCase()}
              name={Fields.DATE_TYPE}
              values={DATE_TYPES_OPTIONS}
              onBlur={onFieldEntry}
              required
            />
          </Col>
          <Row className="w-100 pl-2 pr-2">
            <Col className={styles.inputContainer}>
              <Field
                component={FormControl.DatePicker}
                label={M.FROM_DATE}
                name={Fields.FROM_DATE}
                onBlur={onFieldEntry}
                dateFormat={DEFAULT_DATE_FORMAT}
                helperText={M.PLEASE_CHOOSE_A_FROM_DATE}
                required
                disabled={!dateType}
                onChange={() => clearFields([Fields.TO_DATE])}
              />
            </Col>
            <Col className={styles.inputContainer}>
              <Field
                component={FormControl.DatePicker}
                label={M.TO_DATE}
                name={Fields.TO_DATE}
                onDatePickerChange={setIsDatePickerOpen}
                onBlur={(e, ...data) => {
                  onFieldEntry(e, ...data);
                  preventDatePickerBlur(e);
                }}
                dateFormat={DEFAULT_DATE_FORMAT}
                helperText={M.PLEASE_CHOOSE_A_TO_DATE}
                getDatesRange={getEndDatesRange}
                relatedDate={relatedDate}
                required
                disabled={!dateType}
              />
            </Col>
            <Col className={styles.postcodeContainer}>
              <Field
                name={Fields.POSTCODE_DATE}
                component={PostcodeFinderInput}
                label={M.POSTCODE}
                onSelectionChange={values =>
                  onSelectAddressFromModal(values, Fields.POSTCODE_DATE)
                }
                maxLength={POSTCODE_MAX_LENGTH}
                id={Fields.POSTCODE}
                helperText={M.DESTINATION}
                onFindClick={onFindClick}
                onBlur={onFieldEntry}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

SearchOptions.propTypes = {
  optionType: PropTypes.string,
  onFieldEntry: PropTypes.func,
  dateType: PropTypes.string,
  onSelectAddressFromModal: PropTypes.func,
  onFindClick: PropTypes.func,
  getEndDatesRange: PropTypes.func,
  relatedDate: PropTypes.object,
  ...propTypes,
};

export default SearchOptions;
