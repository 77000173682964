import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { GENERAL_VIEW } from "../../../constants/analytics";
import { fetchDepot } from "../actions";
import AdditionalInformation from "../components/AdditionalInformation";
import DepotDetails from "../components/DepotDetails";
import HeaderWithDetails from "../components/HeaderWithDetails";
import ParcelHistory from "../components/ParcelHistory/ParcelHistory";
import RelatedParcels from "../components/RelatedParcels";
import { getDepot } from "../selectors";

const CollectFromDepotParcelView = ({
  parcel,
  children,
  onClickDeliveryHistory,
  onClickConsignment,
  onClickAdditionalInformation,
  onClickShowMap,
  depot,
}) => (
  <>
    <HeaderWithDetails parcel={parcel}>
      <DepotDetails
        parcel={parcel}
        depot={depot}
        onClickShowMap={onClickShowMap}
      />
    </HeaderWithDetails>
    {children}
    <ParcelHistory data={parcel.parcelEvents} onOpen={onClickDeliveryHistory} />
    <RelatedParcels
      parcelCode={parcel.parcelCode}
      onOpen={onClickConsignment}
    />
    <AdditionalInformation
      parcel={parcel}
      onOpen={onClickAdditionalInformation}
    />
  </>
);

CollectFromDepotParcelView.propTypes = {
  parcel: PropTypes.object,
  children: PropTypes.node,
  depot: PropTypes.object,
  onClickDeliveryHistory: PropTypes.func,
  onClickShowMap: PropTypes.func,
  onClickConsignment: PropTypes.func,
  onClickAdditionalInformation: PropTypes.func,
};

export default compose(
  withAppLoader({
    loadFn: ({ dispatch, parcel }, fetchOptions) =>
      dispatch(fetchDepot(parcel.deliveryDepot.depotCode, fetchOptions)),
  }),
  connect(state => ({
    depot: getDepot(state),
  })),
  withTrackProps({
    onClickShowMap: GENERAL_VIEW.CLICK_SHOW_SHOP_MAP_PICKUP_DELIVERY,
  })
)(CollectFromDepotParcelView);
