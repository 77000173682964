import PropTypes from "prop-types";

export default function Precise({ fill = "#DC0032" }) {
  return (
    <svg viewBox="0 0 45 42" fill={fill} id="precise">
      <text
        transform="matrix(1 0 0 1 17.7051 23.1885)"
        fontFamily="PlutoSansDPDRegular, Pluto Sans DPD"
        fontSize="10.692"
        fontWeight="normal"
        letterSpacing="0.356400025"
        fill="#808285"
      >
        60
      </text>
      <path d="M46.22,16.89c-0.15-0.32-0.46-0.52-0.81-0.52h-2.65C41.07,7.07,33.03,0,23.39,0C12.53,0,3.7,8.97,3.7,20 s8.84,20,19.7,20c7.01,0,13.54-3.83,17.06-9.99c0.49-0.87,0.2-1.98-0.65-2.48c-0.86-0.5-1.95-0.21-2.44,0.66 c-2.88,5.05-8.22,8.18-13.96,8.18c-8.89,0-16.11-7.34-16.11-16.36c0-9.02,7.23-16.36,16.11-16.36c7.65,0,14.07,5.45,15.7,12.73 h-2.63c-0.35,0-0.66,0.2-0.81,0.52c-0.15,0.32-0.1,0.7,0.12,0.97l4.48,5.45c0.17,0.21,0.42,0.33,0.69,0.33 c0.27,0,0.52-0.12,0.69-0.33l4.48-5.45C46.32,17.58,46.37,17.21,46.22,16.89z" />
    </svg>
  );
}

Precise.propTypes = {
  fill: PropTypes.string,
};
