import { values } from "lodash";
import PropTypes from "prop-types";

import {
  // PARCEL_STATUS,
  // COLLECTION_TYPE,
  IMAGE_TYPE,
} from "@dpdgroupuk/redback-enums";

export const DeliveryAddress = {
  street: PropTypes.string,
  suburb: PropTypes.string,
  town: PropTypes.string,
  city: PropTypes.string,
  county: PropTypes.string,
  postcode: PropTypes.string,
  country: PropTypes.string,
  countryName: PropTypes.string,
  // undocumented
  organisation: PropTypes.string, // old delivery.deliveryName
};

export const Delivery = {
  quantity: PropTypes.number,
  weight: PropTypes.number,
  bulk: PropTypes.string,
  businessUnit: PropTypes.number,
  accountCode: PropTypes.string,
  customerReference: PropTypes.string,
  deliveryName: PropTypes.string,
  address: PropTypes.shape(DeliveryAddress),
  contact: PropTypes.string,
  notifyEmail: PropTypes.string,
  notifySms: PropTypes.string,
  phone: PropTypes.string,
  info: PropTypes.string,
  product: PropTypes.string,
  productName: PropTypes.string,
  service: PropTypes.string,
  serviceName: PropTypes.string,
  insurance: PropTypes.bool,
  insuranceValue: PropTypes.number,
  parcels: PropTypes.string,
  importReference: PropTypes.string,
  importParcels: PropTypes.string,
  position: PropTypes.string,
};

export const Customer = {
  name: PropTypes.string,
  logo: PropTypes.string,
  displayName: PropTypes.string,
};

export const ParcelEvent = {
  pos: PropTypes.number,
  offset: PropTypes.number,
  date: PropTypes.string,
  time: PropTypes.string,
  depotCode: PropTypes.string,
  depotName: PropTypes.string,
  depotType: PropTypes.string,
  routeNo: PropTypes.number,
  routeTime: PropTypes.number,
  stopNo: PropTypes.number,
  saturnCode: PropTypes.string,
  code: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  userCode: PropTypes.string,
  userName: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  notes: PropTypes.string,
  capturedName: PropTypes.string,
  cardNo: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      imageType: PropTypes.oneOf(values(IMAGE_TYPE)),
      imageKey: PropTypes.string,
      imagePath: PropTypes.string,
      imageCaption: PropTypes.string,
      imageNotes: PropTypes.string,
    })
  ),
  createDate: PropTypes.string,
  createTime: PropTypes.string,
  origin: PropTypes.string,
  originName: PropTypes.string,
  userType: PropTypes.string,
  userTypeName: PropTypes.string,
  carrierCode: PropTypes.string,
  packetName: PropTypes.string,
};

export const Route = {
  totalStopsCompleted: PropTypes.number,
  routeStopSequence: PropTypes.number,
  timeToStop: PropTypes.string,
  driverDisplayName: PropTypes.string,
  routeStatus: PropTypes.string,
};

export const Parcel = {
  parcelCode: PropTypes.string.isRequired,
  consignmentNumber: PropTypes.string.isRequired,
  service: PropTypes.object.isRequired,
  // createDate: PropTypes.string,
  // createTime: PropTypes.string,
  // account: PropTypes.string,
  // collectionDate: PropTypes.string,
  // customerReference: PropTypes.arrayOf(PropTypes.string),
  // collectionReference: PropTypes.string,
  // deliveryType: PropTypes.string,
  // collectionStatus: PropTypes.string,
  // collectionStatusFull: PropTypes.string,
  // collectionName: PropTypes.string,
  // routeTime: PropTypes.number,
  // readyTime: PropTypes.string,
  // closeTime: PropTypes.string,
  // etaStartTime: PropTypes.string,
  // etaEndTime: PropTypes.string,
  // contact: PropTypes.string,
  // notifySms: PropTypes.string,
  // notifyEmail: PropTypes.string,
  // instructions: PropTypes.string,
  // phoneNumber: PropTypes.string,
  // deliveries: PropTypes.arrayOf(PropTypes.shape(Delivery)),
  // customer: PropTypes.shape(Customer),
  // collectionAddress: PropTypes.shape(ParcelAddress),
  // collectedQuantity: PropTypes.number,
  // deliverQuantity: PropTypes.number,
  // collectionEvents: PropTypes.arrayOf(PropTypes.shape(CollectionEvent)),
  // routeDetails: PropTypes.shape(Route),
  // // additional params which are required on FE
  // collectionStatusType: PropTypes.oneOf(values(PARCEL_STATUS)),
  // collectionTypeCode: PropTypes.oneOf(values(COLLECTION_TYPE)),
  // collectionCode: PropTypes.string.isRequired,
  // collectionNumber: PropTypes.string,
};
export const ClaimInformation = {
  goodsDescription: PropTypes.string,
  goodsValue: PropTypes.number,
  packagingDescription: PropTypes.string,
};
export const CollectionDetailsAddress = {
  countryCode: PropTypes.string,
  countryName: PropTypes.string,
  county: PropTypes.string,
  locality: PropTypes.string,
  organisation: PropTypes.string,
  postcode: PropTypes.string,
  property: PropTypes.string,
  street: PropTypes.string,
  town: PropTypes.string,
};
export const Container = {
  containerRef: PropTypes.string,
  containerType: PropTypes.string,
};
export const Duty = {
  adminFee: PropTypes.number,
  adminFeeVat: PropTypes.number,
  amount: PropTypes.number,
  customsReceiverVat: PropTypes.string,
  tax: PropTypes.number,
  vat: PropTypes.number,
};
export const IssueDetails = {
  issueLogoUrl: PropTypes.string,
  issueName: PropTypes.string,
  issueDelayDays: PropTypes.number,
  issueCode: PropTypes.string,
};
export const NextSteps = {
  code: PropTypes.string,
  neighbourInstruction: PropTypes.string,
  safePlace: PropTypes.string,
};
export const OutForDeliveryDetails = {
  outForDelivery: PropTypes.string,
  outForDeliveryDate: PropTypes.arrayOf(PropTypes.string),
  mapAvailable: PropTypes.bool,
};
export const PartnerRef = {
  carrierName: PropTypes.string,
  carrierCode: PropTypes.string,
  carrierRef: PropTypes.string,
  carrierURL: PropTypes.string,
};
export const Product = {
  productCode: PropTypes.string,
  productDescription: PropTypes.string,
};
export const RfiReason = {
  rfiCarrierEmail: PropTypes.string,
  rfiGroupCode: PropTypes.string,
  rfiReasonDescription: PropTypes.string,
  rfiReasonExplanation: PropTypes.string,
  rfiWithCustomer: PropTypes.bool,
};
export const Service = {
  serviceCode: PropTypes.string,
  serviceDescription: PropTypes.string,
};
export const ShipperDetails = {
  accountNumber: PropTypes.string,
  customerDisplayName: PropTypes.string,
  customerDisplayShortName: PropTypes.string,
  organisation: PropTypes.string,
  shippingRef1: PropTypes.string,
  shippingRef2: PropTypes.string,
  shippingRef3: PropTypes.string,
};
export const ParcelEvents = {
  code: PropTypes.string,
  date: PropTypes.string,
  eventLocation: PropTypes.string,
  eventNotes: PropTypes.string,
  imageKey: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  name: PropTypes.string,
  offset: PropTypes.number,
  parcelEventCode: PropTypes.string,
  pos: PropTypes.number,
  routeNo: PropTypes.number,
  routeTime: PropTypes.number,
  stopNo: PropTypes.number,
  text: PropTypes.string,
  time: PropTypes.string,
};
export const AddressPoint = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
};
export const DeliveryDepotRouteStop = {
  addressPoint: PropTypes.shape(AddressPoint),
  deliveryWindowFrom: PropTypes.string,
  deliveryWindowTo: PropTypes.string,
  estimatedMinsToStop: PropTypes.string,
  stopNumber: PropTypes.number,
};
export const DeliveryDepotRoute = {
  routeCode: PropTypes.string,
  stop: PropTypes.shape(DeliveryDepotRouteStop),
};
export const DeliveryDepot = {
  depotCode: PropTypes.string,
  distanceToDelivery: PropTypes.string,
  route: PropTypes.shape(DeliveryDepotRoute),
};
export const DeliveryDetailsAddressPoint = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
};
export const DeliveryDetailsAddress = {
  addressPoint: PropTypes.shape(DeliveryDetailsAddressPoint),
  countryCode: PropTypes.string,
  countryName: PropTypes.string,
  county: PropTypes.string,
  locality: PropTypes.string,
  organisation: PropTypes.string,
  postcode: PropTypes.string,
  property: PropTypes.string,
  street: PropTypes.string,
  town: PropTypes.string,
  udprn: PropTypes.string,
};
export const DeliveryDetailsNotification = {
  contactName: PropTypes.string,
  email: PropTypes.string,
  mobile: PropTypes.string,
};
export const DeliveryDetailsPod = {
  podName: PropTypes.string,
  podNeighbour: PropTypes.string,
  podSafePlaceCode: PropTypes.string,
  podDate: PropTypes.string,
  podDriverCode: PropTypes.string,
};
export const DeliveryDetails = {
  address: PropTypes.shape(DeliveryDetailsAddress),
  liability: PropTypes.bool,
  liabilityValue: PropTypes.number,
  notificationDetails: PropTypes.shape(DeliveryDetailsNotification),
  numberOfParcels: PropTypes.number,
  onRouteElectric: PropTypes.bool,
  podDetails: PropTypes.shape(DeliveryDetailsPod),
  totalWeight: PropTypes.number,
  verifiedWeight: PropTypes.number,
};
export const ParcelStatusType = {
  alignedDepotCode: PropTypes.string,
  alignedDepotName: PropTypes.string,
  businessUnit: PropTypes.number,
  callingCardNumber: PropTypes.arrayOf(PropTypes.string),
  canCollectParcel: PropTypes.bool,
  claimInformation: PropTypes.shape(ClaimInformation),
  collectFromDepotDiaryDate: PropTypes.string,
  collectedFromShop: PropTypes.bool,
  collectionCode: PropTypes.string,
  collectionCodeDisplay: PropTypes.string,
  collectionDate: PropTypes.string,
  collectionDetails: PropTypes.objectOf(
    PropTypes.shape(CollectionDetailsAddress)
  ),
  collectionOnDeliveryCode: PropTypes.string,
  collectionOnDeliveryName: PropTypes.string,
  consignmentCode: PropTypes.string,
  consignmentNumber: PropTypes.string,
  consumer: PropTypes.object,
  consumerId: PropTypes.string,
  consumerPreference: PropTypes.string,
  consumerTrackingShortCode: PropTypes.string,
  container: PropTypes.arrayOf(PropTypes.shape(Container)),
  customerImageLogo: PropTypes.string,
  customerTargetDate: PropTypes.string,
  deliveredToConsumer: PropTypes.bool,
  deliveryAttempts: PropTypes.string,
  deliveryBusinessUnit: PropTypes.number,
  deliveryDepot: PropTypes.shape(DeliveryDepot),
  deliveryDetails: PropTypes.shape(DeliveryDetails),
  depotTargetDate: PropTypes.string,
  duty: PropTypes.shape(Duty),
  estimatedDeliveryActionCode: PropTypes.string,
  estimatedDeliveryDate: PropTypes.string,
  estimatedDeliveryEndTime: PropTypes.string,
  estimatedDeliveryStartTime: PropTypes.string,
  etaEtaTime: PropTypes.string,
  eventLatestDisplayPosition: PropTypes.string,
  eventLatestPosition: PropTypes.string,
  goodsDescription: PropTypes.string,
  goodsValue: PropTypes.string,
  investigationNotes: PropTypes.string,
  invoiceDate: PropTypes.string,
  issueDetails: PropTypes.shape(IssueDetails),
  ivrStatusCode: PropTypes.string,
  labelSentMethod: PropTypes.string,
  lastConfirmDate: PropTypes.string,
  lastConfirmDepot: PropTypes.string,
  lastInstructionDate: PropTypes.string,
  messageActionCode: PropTypes.arrayOf(PropTypes.string),
  messageResponseCode: PropTypes.arrayOf(PropTypes.string),
  nextSteps: PropTypes.shape(NextSteps),
  notificationFromCustomerData: PropTypes.bool,
  outForDeliveryDetails: PropTypes.shape(OutForDeliveryDetails),
  packagingDescription: PropTypes.string,
  parcelCode: PropTypes.string,
  parcelEvents: PropTypes.arrayOf(PropTypes.shape(ParcelEvents)),
  parcelNumber: PropTypes.string,
  parcelStatusHtml: PropTypes.string,
  parcelStatusInfo: PropTypes.string,
  parcelStatusType: PropTypes.number,
  parcelsCollectedOnDelivery: PropTypes.arrayOf(PropTypes.string),
  parcelsDeliveredOnDelivery: PropTypes.arrayOf(PropTypes.string),
  partnerRef: PropTypes.arrayOf(PropTypes.shape(PartnerRef)),
  perishableDate: PropTypes.string,
  pickupAvailable: PropTypes.bool,
  pickupAvailableDescription: PropTypes.string,
  pickupCollectionRequest: PropTypes.bool,
  pickupExpiryDate: PropTypes.string,
  pickupLocationCode: PropTypes.string,
  pickupStatus: PropTypes.string,
  pickupType: PropTypes.string,
  pickupValueGoods: PropTypes.string,
  product: PropTypes.shape(Product),
  reconsignedConsignmentCode: PropTypes.string,
  reconsignedParcelCode: PropTypes.string,
  relabelledAsDisplayParcelCode: PropTypes.string,
  relabelledAsParcelCode: PropTypes.string,
  relabelledFromDisplayParcelCode: PropTypes.string,
  relabelledFromParcelCode: PropTypes.string,
  rfiReason: PropTypes.shape(RfiReason),
  serialNumber: PropTypes.string,
  service: PropTypes.shape(Service),
  serviceEndTime: PropTypes.string,
  shipmentDate: PropTypes.string,
  shipmentTargetDate: PropTypes.string,
  shipperDetails: PropTypes.shape(ShipperDetails),
  trackingStatusCurrent: PropTypes.string,
  transferToAgent: PropTypes.bool,
  undelivered: PropTypes.bool,
};
