import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field, getFormMeta, getFormSyncErrors } from "redux-form";

import { Tile } from "@dpdgroupuk/mydpd-ui";
import { SAFE_PLACE_TYPE } from "@dpdgroupuk/redback-enums";

import {
  Garage,
  Other,
  Porch,
  RearPorch,
  Shed,
} from "../../../../components/Icon";
import { Fields, PARCEL_EDIT_FORM } from "../../../../constants/forms";
import { getSafePlaceObjects } from "../../../../models/enum";
import SafePlaceOption from "./SafePlaceOption";
import styles from "./SafePlaceOptions.module.scss";

export const SvgIcons = {
  [SAFE_PLACE_TYPE.GAR]: Garage,
  [SAFE_PLACE_TYPE.FPC]: Porch,
  [SAFE_PLACE_TYPE.RPC]: RearPorch,
  [SAFE_PLACE_TYPE.OBL]: Shed,
  [SAFE_PLACE_TYPE.OTH]: Other,
};

const SafePlaceOptions = ({ onChange, errors, formMeta }) => {
  const options = getSafePlaceObjects();

  return (
    <Col className={styles.tileColumn}>
      <Tile.Stack className="m-0 p-0">
        {options.map(({ name, type }) => (
          <Field
            key={type}
            name={Fields.SAFE_PLACE_CODE}
            type="radio"
            value={type}
            title={name}
            SvgIcon={SvgIcons[type]}
            component={SafePlaceOption}
            onChange={onChange}
          />
        ))}
      </Tile.Stack>
      {formMeta?.safePlaceCode?.touched && errors.safePlaceCode && (
        <div className={styles.errorMessage}>{errors.safePlaceCode}</div>
      )}
    </Col>
  );
};

SafePlaceOptions.propTypes = {
  onChange: PropTypes.func,
  errors: PropTypes.shape({
    safePlaceCode: PropTypes.string,
  }),
  formMeta: PropTypes.shape({
    safePlaceCode: PropTypes.shape({
      touched: PropTypes.bool,
    }),
  }),
};

export default compose(
  connect(state => ({
    errors: getFormSyncErrors(PARCEL_EDIT_FORM)(state),
    formMeta: getFormMeta(PARCEL_EDIT_FORM)(state),
  }))
)(SafePlaceOptions);
