import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Field, reduxForm, propTypes as reduxFormPropTypes } from "redux-form";

import { Button, FormControl } from "@dpdgroupuk/mydpd-ui";

import CardWithTitle from "../../../components/CardWithTitle";
import { DASHBOARD_FORM, Fields } from "../../../constants/forms";
import * as M from "../../../constants/strings";
import { validators } from "../../../models";
import { transformDashboardSearchValues } from "../../../models/dashboard";
import styles from "./FilterForm.module.scss";

const FilterForm = ({
  title,
  handleSubmit,
  onValidateSuccess,
  accounts,
  isFilterDisabled,
}) => (
  <CardWithTitle title={title}>
    <Container fluid>
      <Row className="justify-content-between">
        <Col xs={12} md={4} className="p-0">
          <Field
            component={FormControl.Dropdown}
            label={M.ACCOUNT}
            name={Fields.ACCOUNT}
            values={accounts}
            textTransform={M.UPPERCASE}
            disabled={accounts.length <= 1}
          />
        </Col>
        <Col xs lg={1} md={1} className={styles.buttonContainer}>
          <Button
            disabled={isFilterDisabled}
            onClick={handleSubmit(onValidateSuccess)}
          >
            {M.FILTER}
          </Button>
        </Col>
      </Row>
    </Container>
  </CardWithTitle>
);

FilterForm.defaultProps = {
  onFilter: () => null,
};

FilterForm.propTypes = {
  ...reduxFormPropTypes,
  onFilter: PropTypes.func,
  isFilterDisabled: PropTypes.bool,
};

export default reduxForm({
  form: DASHBOARD_FORM,
  validate: validators.dashboardValidator,
  enableReinitialize: true,
  onValidateSuccess: (_, __, { dirty, values, onFilter }) => {
    dirty && onFilter(transformDashboardSearchValues(values));
  },
})(FilterForm);
