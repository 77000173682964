import { useEffect } from "react";

import { keys, omit } from "lodash";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {
  change,
  clearFields,
  Field,
  getFormMeta,
  propTypes,
  reduxForm,
  SubmissionError,
  untouch,
} from "redux-form";

import { withAppUserPreferences } from "@dpdgroupuk/mydpd-app";
import { Button, Card, Form, Main, withOverlay } from "@dpdgroupuk/mydpd-ui";

import CardWithTitle from "../../../components/CardWithTitle";
import {
  Fields,
  SEARCH_FORM,
  SEARCH_TYPE_LIST,
} from "../../../constants/forms";
import * as M from "../../../constants/strings";
import withDatePicker from "../../../HOCS/withDatePicker";
import { findParcels as validate } from "../../../models/validators";
import {
  getEndDatesRange,
  initializeForm,
  onStartDateChange,
} from "../actions";
import { transformSearchFormValues } from "../model";
import {
  getAccounts,
  getDateType,
  getRelatedDate,
  getType,
  isCustomerReferenceFieldSelected,
  isEmailFieldSelected,
  isPhoneFieldSelected,
} from "../selectors";
import withSearchAnalytics from "../withSearchAnalytics";
import withSearchHandler from "../withSearchHandler";
import styles from "./Form.module.scss";
import SearchOptions from "./SearchOptions";

const SearchForm = props => {
  useEffect(() => {
    props.initializeForm(props.accounts?.[0]?.value);
    // eslint-disable-next-line
  }, []);

  return (
    <Main body>
      <Card.Stack className="p-0">
        <Col className="p-0">
          <CardWithTitle title={M.DELIVERY_SEARCH}>
            <Container fluid className="p-0">
              <Row>
                <Col className={styles.inputContainer}>
                  <Field
                    component={Form.MaterialDropdown}
                    label={M.ACCOUNT}
                    name={Fields.ACCOUNT}
                    values={props.accounts}
                    onBlur={props.onFieldEntry}
                    textTransform={M.UPPERCASE}
                    required
                    disabled={props.accounts.length <= 1}
                  />
                </Col>
                <Col className={styles.inputContainer}>
                  <Field
                    name={Fields.TYPE}
                    component={Form.MaterialDropdown}
                    label={M.SEARCH_BY}
                    placeholder={M.SEARCH_BY}
                    maxLength={255}
                    values={SEARCH_TYPE_LIST}
                    onBlur={props.onFieldEntry}
                    onChange={() =>
                      props.clearFields(
                        keys(omit(props.fields, [Fields.ACCOUNT, Fields.TYPE]))
                      )
                    }
                    textTransform={M.UPPERCASE}
                    required
                  />
                </Col>
                <SearchOptions {...props} />
              </Row>
              <Row>
                <Col className="col-12 text-right">
                  <Button
                    disabled={props.submitting || props.invalid}
                    onClick={props.handleSubmit(props.onSubmitSuccess)}
                  >
                    {M.SEARCH}
                  </Button>
                  <div className={styles.error}>{props.error}</div>
                </Col>
              </Row>
            </Container>
          </CardWithTitle>
        </Col>
      </Card.Stack>
    </Main>
  );
};

SearchForm.propTypes = {
  dateType: PropTypes.any,
  onFieldEntry: PropTypes.func, // only for analytics
  onSearchPress: PropTypes.func,
  initializeForm: PropTypes.func,
  onStartDateChange: PropTypes.func,
  optionType: PropTypes.string,
  onSelectAddressFromModal: PropTypes.func,
  ...propTypes,
};

export default compose(
  reduxForm({
    form: SEARCH_FORM,
    validate,
    onSubmitFail: (errors, dispatch) => {
      // redux-form marks all fields as touched on handleSubmit
      // We must show only first error on submit
      Object.keys(errors).forEach((field, index) => {
        if (index > 0) {
          dispatch(untouch(SEARCH_FORM, field));
        }
      });
    },
  }),
  withDatePicker,
  withOverlay,
  withAppUserPreferences, // TODO look if needed or remove
  withSearchHandler,
  connect(
    state => ({
      dateType: getDateType(state),
      isEmailFieldSelected: isEmailFieldSelected(state),
      isPhoneFieldSelected: isPhoneFieldSelected(state),
      isCustomerReferenceFieldSelected: isCustomerReferenceFieldSelected(state),
      optionType: getType(state),
      accounts: getAccounts(state),
      fields: getFormMeta(SEARCH_FORM)(state),
      relatedDate: getRelatedDate(state),
    }),
    (dispatch, { overlay, searchHandler, form }) => ({
      ...bindActionCreators(
        {
          initializeForm,
          onStartDateChange,
        },
        dispatch
      ),
      onSubmitSuccess: overlay.showWhile(async values => {
        try {
          const requestedFormValue = transformSearchFormValues(values);
          await searchHandler(requestedFormValue);
        } catch (e) {
          throw new SubmissionError({
            _error: M.NOT_FOUND_DELIVERIES,
          });
        }
      }),
      onSelectAddressFromModal: ({ postcode }, fieldName = Fields.POSTCODE) =>
        dispatch(change(form, fieldName, postcode)),
      clearFields: fields => {
        dispatch(clearFields(SEARCH_FORM, false, false, ...fields));
      },
      getEndDatesRange: () => dispatch(getEndDatesRange()),
    })
  ),
  withSearchAnalytics
)(SearchForm);
