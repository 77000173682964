import { Fields } from "../../../constants/forms";
import * as M from "../../../constants/strings";
import { phoneNumberNormalize } from "../../../utils/normalize";

export const getCollectionOrCaseCodeObject = optionType => {
  if (
    optionType === Fields.COLLECTION_CODE ||
    optionType === Fields.CASE_CODE
  ) {
    return {
      name:
        optionType === Fields.COLLECTION_CODE
          ? Fields.COLLECTION_CODE
          : Fields.CASE_CODE,
      label:
        optionType === Fields.COLLECTION_CODE ? M.COLLECTION_CODE : M.CASE_CODE,
    };
  }
};

export const getReferenceOrEmailOrPhoneObject = optionType => {
  if (
    optionType === Fields.REFERENCE ||
    optionType === Fields.EMAIL ||
    optionType === Fields.PHONE
  ) {
    switch (optionType) {
      case Fields.REFERENCE:
        return {
          requiredName: Fields.REFERENCE,
          optionalName: Fields.POSTCODE_CUSTOMER_REFERENCE,
          label: M.CUSTOMER_REFERENCE,
          maxLength: 25,
          helperText: M.MAX_25_CHARACTERS,
        };
      case Fields.EMAIL:
        return {
          requiredName: Fields.EMAIL,
          optionalName: Fields.POSTCODE_EMAIL,
          label: M.EMAIL_ADDRESS,
          maxLength: 100,
          helperText: M.MAX_100_CHARACTERS,
        };
      case Fields.PHONE:
        return {
          requiredName: Fields.PHONE,
          optionalName: Fields.POSTCODE_PHONE,
          label: M.PHONE_NUMBER,
          maxLength: 15,
          minLength: 7,
          helperText: M.MAX_15_CHARACTERS_INCLUDING_SPACES,
          normalize: phoneNumberNormalize,
        };
      default:
        return {};
    }
  }
};
