import { useCallback, useState } from "react";

import PropTypes from "prop-types";
import ImageViewer from "react-simple-image-viewer";
import { propTypes } from "redux-form";

import CustomCheckbox from "../../../EditDelivery/components/CustomCheckbox";
import ResponsiveImage from "./ImageView/ResponsiveImage";

export const CheckBoxGroup = ({ fields, checkBoxData, onChange, ...props }) => {
  const fieldValues = fields.getAll() || [];
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback(image => {
    setCurrentImage(image);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <>
      {isViewerOpen && (
        <ImageViewer
          src={checkBoxData.map(image => image.src)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex: 999999,
            padding: "10%",
          }}
          closeOnClickOutside={true}
        />
      )}
      {checkBoxData.map((image, index) => {
        const itemIndex = fieldValues
          .map(image => image.imageKey)
          .indexOf(image.imageKey);
        return (
          <div
            key={image.imageKey + index}
            style={{
              height: "200px",
              display: "inline-block",
              float: "none",
              borderRadius: "4px",
              margin: "4px",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px",
                height: "29px",
              }}
            >
              <div
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "150%",
                  color: "#000000",
                }}
              >
                {index + 1}.
              </div>
              <div>
                <CustomCheckbox
                  name={image.imageKey + index}
                  disabled={!image.title}
                  {...props}
                  onChange={event => {
                    onChange && onChange(event);
                    if (event.target.checked) {
                      return fields.push(image);
                    }

                    fields.remove(itemIndex);
                  }}
                  input={{ value: itemIndex > -1 }}
                />
              </div>
            </div>
            <div style={{ width: "298px", height: "163px" }}>
              <ResponsiveImage
                key={index}
                alt={image.title}
                src={image.src}
                width="100%"
                onClick={() => {
                  openImageViewer(index);
                }}
              />
            </div>
            <div
              style={{
                padding: "15px 0",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "10px",
                lineHeight: "200%",
              }}
            >
              {image.title || "Image not Found"}
            </div>
          </div>
        );
      })}
    </>
  );
};

CheckBoxGroup.defaultProps = {
  onChange: () => null,
  checkBoxData: [],
  fields: [],
};

CheckBoxGroup.propTypes = {
  ...propTypes,
  onChange: PropTypes.func,
  checkBoxData: PropTypes.array,
  fields: PropTypes.array,
};
