const Snowflake = props => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
    <rect width="48" height="48" fill="white" />
    <path
      d="M41.5949 41.6374H6.40551C5.53721 41.6374 4.76015 41.1885 4.32561 40.437C3.89106 39.6856 3.89186 38.7868 4.32561 38.0354L21.9203 7.56039C22.354 6.80894 23.1319 6.35999 24.0002 6.35999C24.8685 6.35999 25.6456 6.80894 26.0801 7.56039L43.6748 38.0354C44.1085 38.7876 44.1085 39.6864 43.674 40.437C43.2402 41.1893 42.4624 41.6374 41.5949 41.6374ZM24.0002 7.96052C23.861 7.96052 23.5152 7.99973 23.3072 8.36066L5.71167 38.8357C5.5028 39.1966 5.64205 39.5167 5.71167 39.6367C5.7813 39.7568 5.98777 40.0369 6.40551 40.0369H41.5949C42.0118 40.0369 42.2183 39.7568 42.2879 39.6367C42.3576 39.5167 42.4968 39.1974 42.2879 38.8357L24.6932 8.36066C24.4852 7.99973 24.1394 7.96052 24.0002 7.96052Z"
      fill="#414042"
    />
    <path
      d="M30.0237 33.9811C30.1403 33.9928 30.2584 34 30.3779 34C32.3752 34 33.9996 32.2947 34 30.198C34 28.2025 32.5275 26.5614 30.6633 26.408C30.2512 23.8731 28.1599 22 25.6763 22C23.5781 22 21.6852 23.3903 20.9462 25.4261C20.6068 25.3181 20.2544 25.2633 19.8945 25.2633C17.9652 25.2633 16.3832 26.8546 16.2781 28.8523H16.2723C15.0194 28.8523 14 29.9226 14 31.2372C14 32.507 14.9927 33.983 16.2723 33.983L24 33.9819C24 33.9819 24.4 33.9951 24.4 33.6C24.4 33.2049 24 33.2278 24 33.2278H16.2723C15.4124 33.2278 14.7199 32.139 14.7199 31.2376C14.7199 30.3393 15.4164 29.6082 16.2723 29.6082C16.3688 29.6082 16.4699 29.6188 16.5725 29.6407L17.0275 29.7348L16.9976 29.1859L16.9969 29.1742C16.9946 29.1383 16.9922 29.1018 16.9922 29.0654C16.9922 27.3861 18.2941 26.0196 19.8945 26.0196C20.2879 26.0196 20.6694 26.1009 21.0287 26.261L21.4001 26.4265L21.5146 26.02C22.0556 24.0982 23.7671 22.7559 25.6763 22.7559C27.8975 22.7559 29.7523 24.5021 29.9909 26.8172L30.0266 27.1606L30.3861 27.1526C31.9829 27.1572 33.2801 28.5221 33.2801 30.1987C33.2801 31.8784 31.9778 33.2448 30.3779 33.2448C30.2814 33.2448 30.1864 33.2391 30.0582 33.2278H30C30 33.2278 29.6797 33.2891 29.6797 33.6C29.6797 33.9109 30 33.9811 30 33.9811L30.0237 33.9811Z"
      fill="#414042"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.0996 31C27.2566 31 27.3839 31.1273 27.3839 31.2843V33.3503L29.1732 32.3173C29.3091 32.2388 29.483 32.2854 29.5615 32.4213C29.64 32.5573 29.5934 32.7312 29.4574 32.8097L27.6682 33.8427L29.4574 34.8758C29.5934 34.9543 29.64 35.1281 29.5615 35.2641C29.483 35.4 29.3091 35.4466 29.1732 35.3681L27.3839 34.3351V36.4012C27.3839 36.5582 27.2566 36.6854 27.0996 36.6854C26.9426 36.6854 26.8153 36.5582 26.8153 36.4012V34.3351L25.0261 35.3681C24.8901 35.4466 24.7162 35.4 24.6377 35.2641C24.5592 35.1281 24.6058 34.9542 24.7418 34.8757L26.5311 33.8427L24.7418 32.8097C24.6058 32.7312 24.5593 32.5573 24.6378 32.4214C24.7163 32.2854 24.8901 32.2388 25.0261 32.3173L26.8153 33.3503V31.2843C26.8153 31.1273 26.9426 31 27.0996 31Z"
    />
  </svg>
);

export default Snowflake;
