import PropTypes from "prop-types";

export default function Depot({ fill }) {
  return (
    <svg
      id="Depot-1"
      xmlns="http://www.w3.org/2000/svg"
      width="284"
      height="284"
      viewBox="0 0 284 284"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M246.805 104.812L142 64.8864L37.1945 104.812C34.6617 105.777 33.3907 108.613 34.3555 111.145C35.3204 113.678 38.1558 114.949 40.6886 113.984L142 75.3895L243.311 113.984C245.844 114.949 248.679 113.678 249.644 111.145C250.609 108.613 249.338 105.777 246.805 104.812ZM56.9357 119.213C56.9357 116.503 54.7385 114.306 52.0281 114.306C49.3178 114.306 47.1206 116.503 47.1206 119.213V219H236.879V119.213C236.879 116.503 234.682 114.306 231.971 114.306C229.261 114.306 227.064 116.503 227.064 119.213V209.185H56.9357V119.213ZM94.5604 196.098C94.5604 193.388 96.7576 191.191 99.4679 191.191H184.532C187.242 191.191 189.439 193.388 189.439 196.098C189.439 198.809 187.242 201.006 184.532 201.006H99.4679C96.7576 201.006 94.5604 198.809 94.5604 196.098ZM99.4679 174.832C96.7576 174.832 94.5604 177.029 94.5604 179.74C94.5604 182.45 96.7576 184.647 99.4679 184.647H184.532C187.242 184.647 189.439 182.45 189.439 179.74C189.439 177.029 187.242 174.832 184.532 174.832H99.4679ZM94.5604 163.381C94.5604 160.671 96.7576 158.474 99.4679 158.474H184.532C187.242 158.474 189.439 160.671 189.439 163.381C189.439 166.092 187.242 168.289 184.532 168.289H99.4679C96.7576 168.289 94.5604 166.092 94.5604 163.381ZM99.4679 142.115C96.7576 142.115 94.5604 144.312 94.5604 147.023C94.5604 149.733 96.7576 151.93 99.4679 151.93H184.532C187.242 151.93 189.439 149.733 189.439 147.023C189.439 144.312 187.242 142.115 184.532 142.115H99.4679Z"
        fill={fill}
      />
    </svg>
  );
}

Depot.propTypes = {
  fill: PropTypes.string,
};
