import { useMemo } from "react";

import { get, isNil, omitBy } from "lodash";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose, lifecycle, withHandlers } from "recompose";

import {
  withAppLoader,
  withAppUserPreferences,
  withNotifier,
} from "@dpdgroupuk/mydpd-app";
import { APP_ROLES } from "@dpdgroupuk/mydpd-enums";
import { Card, Main, withOverlay } from "@dpdgroupuk/mydpd-ui";
import {
  trackProps,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import CardWithTitle from "../../components/CardWithTitle";
import ServiceBanner from "../../components/ServiceBanner";
import { DASHBOARD } from "../../constants/analytics";
import { PARCEL_DASHBOARD_TYPES, POTS } from "../../constants/dashboard";
import {
  SERVICE_DISRUPTION,
  DELIVERY_DASHBOARD as TITLE,
} from "../../constants/strings";
import withLoaderHandlers from "../../HOCS/withLoaderHandlers";
import {
  getDashboardInitialValues,
  prepareFilters,
} from "../../models/dashboard";
import { getAppRoles } from "../../redux/auth/selectors";
import { DeliveriesActions } from "../../redux/deliveries";
import { DASHBOARD as DASHBOARD_ROUTE, DELIVERIES } from "../../router";
import {
  createLocationState,
  getSearchQuery,
  stringifyQuery,
} from "../../utils/query";
import {
  clearStats,
  fetchDashboardStats,
  fetchIsPDFavailable,
  fetchWatchList,
} from "./actions";
import DashboardStats from "./DashboardStats";
import Filter from "./Filter";
import {
  getAccounts,
  getDashboardStats,
  getPDFavailableSelector,
  getServiceDisruptionSelector,
} from "./selectors";
import ServiceDisruption from "./ServiceDisruption";
import Issues from "./ServiceDisruption/Issues/Issues";

const Dashboard = ({
  stats,
  location,
  onFilter,
  accounts,
  onClickTile,
  serviceDisruption,
  appRoles,
  isFilterDisabled,
  onClickServicePDF,
  isServicePDFavailable,
}) => {
  const filters = useMemo(
    () =>
      getDashboardInitialValues(getSearchQuery(location), accounts?.[0]?.value),
    // eslint-disable-next-line
    [location]
  );

  const showStats = useMemo(
    () =>
      appRoles.includes(APP_ROLES.RFI_ROLE) ||
      appRoles.includes(APP_ROLES.IEEXCEPTIONS_ROLE),
    [appRoles]
  );

  return (
    <Main body>
      <Card.Stack fluid>
        <Col sm={12}>
          <Filter
            onFilter={onFilter}
            initialValues={filters}
            accounts={accounts}
            isFilterDisabled={isFilterDisabled}
            title={TITLE}
          />
        </Col>
      </Card.Stack>
      <Card.Stack>
        <Col sm={12}>
          <DashboardStats
            stats={stats}
            onClickTile={onClickTile}
            showStats={showStats}
            POTS={POTS}
          />
        </Col>
      </Card.Stack>
      {isServicePDFavailable && (
        <Card.Stack>
          <Col sm={12}>
            <ServiceBanner onClickServicePDF={onClickServicePDF} />
          </Col>
        </Card.Stack>
      )}
      <Card.Stack>
        <Col sm={12}>
          {serviceDisruption && (
            <CardWithTitle title={SERVICE_DISRUPTION}>
              <ServiceDisruption />
              <Issues />
            </CardWithTitle>
          )}
        </Col>
      </Card.Stack>
    </Main>
  );
};

Dashboard.propTypes = {
  ...DashboardStats.propTypes,
  onFilter: PropTypes.func,
  serviceDisruption: PropTypes.array,
  isFilterDisabled: PropTypes.bool,
  onClickServicePDF: PropTypes.func,
  isServicePDFavailable: PropTypes.bool,
};

Dashboard.defaultProps = {
  stats: [],
};

const mapStateToProps = state => {
  const accounts = getAccounts(state);
  return {
    stats: getDashboardStats(state),
    serviceDisruption: getServiceDisruptionSelector(state),
    accounts,
    isFilterDisabled: accounts.length <= 1,
    appRoles: getAppRoles(state),
    isServicePDFavailable: getPDFavailableSelector(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    history,
    overlay,
    notifier,
    location,
    fetchDashboardStats,
    fetchWatchList,
    fetchDeliveryFindByCodeByType,
  } = ownProps;

  return {
    onFilter: overlay.showWhile(
      notifier.runAsync(async formValues => {
        const query = prepareFilters(formValues);
        await fetchWatchList(query);
        await fetchDashboardStats(query);
        history.push({
          search: stringifyQuery(query),
          state: {
            reload: false,
          },
        });
      })
    ),
    onClick: overlay.showWhile(
      notifier.runAsync(async ({ id: deliveryType, amount }) => {
        const query = getSearchQuery(location);
        const {
          parcelCodes = [],
          findByCode: searchFindByCode,
          expiresIn,
        } = deliveryType === PARCEL_DASHBOARD_TYPES.WATCH
          ? await fetchWatchList(query, deliveryType)
          : await fetchDeliveryFindByCodeByType(query, deliveryType);
        if (amount === 1) {
          history.push(`${DELIVERIES}/${parcelCodes[0]}`);
        } else {
          history.push(
            `${DASHBOARD_ROUTE}/${deliveryType}?${stringifyQuery(query)}`,
            createLocationState({
              searchFindByCode,
              expiresIn,
            })
          );
        }
      })
    ),
    onClickServicePDF: overlay.showWhile(
      notifier.runAsync(() => {
        window.open(
          `${process.env.REACT_APP_CT_API_URL}/parcels/dashboard/service/pdf`,
          "_blank"
        );
      })
    ),
  };
};

export default compose(
  withRouter,
  withAppUserPreferences, // TODO look if needed or remove
  connect(mapStateToProps, {
    clearStats,
    fetchDashboardStats,
    fetchIsPDFavailable,
    fetchWatchList,
    fetchDeliveryFindByCodeByType: DeliveriesActions.searchParcelsByType,
  }),
  withLoaderHandlers,
  withAppLoader({
    loadFn: (
      { fetchDashboardStats, fetchWatchList, location, fetchIsPDFavailable },
      fetchOptions
    ) => {
      const query = getSearchQuery(location);
      return Promise.all([
        fetchWatchList(query, fetchOptions),
        fetchDashboardStats(query, fetchOptions),
        fetchIsPDFavailable(fetchOptions),
      ]);
    },
  }),
  withOverlay,
  withNotifier,
  connect(null, mapDispatchToProps),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.location.search !== this.props.location.search) {
        const reloadState = get(this.props.location, "state.reload", true);
        if (reloadState) {
          const { reloadFn, overlay } = this.props;
          overlay.show();
          reloadFn(true).finally(overlay.hide);
        }
      }
    },
    componentWillUnmount() {
      this.props.clearStats();
    },
  }),
  withHandlers({
    onClickTile:
      ({ onClick, filters }) =>
      stat =>
        onClick(stat, omitBy(filters, isNil)),
  }),
  withTrack(trackProps(DASHBOARD)),
  withTrackProps({
    onClickTile: ({ id }) => {
      switch (id) {
        case PARCEL_DASHBOARD_TYPES.INSTRUCTIONS_REQUIRED:
          return DASHBOARD.CLICK_INSTRUCTIONS_REQUIRED;
        case PARCEL_DASHBOARD_TYPES.INSTRUCTIONS_PROVIDED:
          return DASHBOARD.CLICK_INSTRUCTIONS_IN_PROGRESS;
        case PARCEL_DASHBOARD_TYPES.EXCEPTION:
          return DASHBOARD.CLICK_EXCEPTIONS;
        case PARCEL_DASHBOARD_TYPES.MISSING_DATA:
          return DASHBOARD.CLICK_PARCELS_NO_DATA;
        case PARCEL_DASHBOARD_TYPES.RETURN:
          return DASHBOARD.CLICK_RETURNS;
        case PARCEL_DASHBOARD_TYPES.WATCH:
          return DASHBOARD.CLICK_WATCH_LIST;
        case PARCEL_DASHBOARD_TYPES.PREADVICE_ONLY:
          return DASHBOARD.CLICK_DATA_ONLY_NO_PARCEL;
        case PARCEL_DASHBOARD_TYPES.OVERDUE:
          return DASHBOARD.CLICK_OVERDUE;
        case PARCEL_DASHBOARD_TYPES.PRO_ALERT:
          return DASHBOARD.CLICK_PROALERT;
        default:
          break;
      }
    },
  })
)(Dashboard);
