import PropTypes from "prop-types";

export default function PickupShopMarker({ index, fill = "#414042" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="33"
      viewBox="0 0 28 33"
      fill="none"
      style={{
        position: "relative",
        color: "#ffffff",
        cursor: "pointer",
      }}
    >
      <path
        d="M0 4C0 1.79087 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V23.5457C28 25.7548 26.2091 27.5457 24 27.5457H17.5031L15.0166 31.4171C14.5408 32.158 13.4581 32.1587 12.9813 31.4184L10.4869 27.5457H4C1.79086 27.5457 0 25.7548 0 23.5457V4Z"
        fill={fill}
      />
      <text
        x="14px"
        y="18px"
        textAnchor="middle"
        style={{
          fill: "#ffffff",
          fontSize: "14px",
        }}
      >
        {index + 1}
      </text>
    </svg>
  );
}

PickupShopMarker.propTypes = {
  index: PropTypes.number,
  fill: PropTypes.string,
};
