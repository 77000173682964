import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { PARCEL_DASHBOARD_TYPES } from "../constants/dashboard";
import { emptyStringLIstFilters, Fields } from "../constants/forms";
import {
  ALL,
  DATA_ONLY_NO_PARCEL,
  EXCEPTIONS,
  INSTRUCTIONS_PROGRESS,
  INSTRUCTIONS_REQUIRED,
  OVERDUE,
  PARCELS_NO_DATA,
  PRO_ALERT,
  RETURNS,
  WATCH_LIST,
} from "../constants/strings";
import { omitNilValues } from "../utils/object";
import { transformAccount } from "./query";

const createDashboardStatEnum = (title, amount, id) => ({
  title,
  amount,
  id,
});

export const transformDashboardStats = stats => [
  createDashboardStatEnum(
    INSTRUCTIONS_REQUIRED,
    stats[PARCEL_DASHBOARD_TYPES.INSTRUCTIONS_REQUIRED],
    PARCEL_DASHBOARD_TYPES.INSTRUCTIONS_REQUIRED
  ),
  createDashboardStatEnum(
    INSTRUCTIONS_PROGRESS,
    stats[PARCEL_DASHBOARD_TYPES.INSTRUCTIONS_PROVIDED],
    PARCEL_DASHBOARD_TYPES.INSTRUCTIONS_PROVIDED
  ),
  createDashboardStatEnum(
    PARCELS_NO_DATA,
    stats[PARCEL_DASHBOARD_TYPES.MISSING_DATA],
    PARCEL_DASHBOARD_TYPES.MISSING_DATA
  ),
  createDashboardStatEnum(
    DATA_ONLY_NO_PARCEL,
    stats[PARCEL_DASHBOARD_TYPES.PREADVICE_ONLY],
    PARCEL_DASHBOARD_TYPES.PREADVICE_ONLY
  ),
  createDashboardStatEnum(
    EXCEPTIONS,
    stats[PARCEL_DASHBOARD_TYPES.EXCEPTION],
    PARCEL_DASHBOARD_TYPES.EXCEPTION
  ),
  createDashboardStatEnum(
    OVERDUE,
    stats[PARCEL_DASHBOARD_TYPES.OVERDUE],
    PARCEL_DASHBOARD_TYPES.OVERDUE
  ),
  createDashboardStatEnum(
    PRO_ALERT,
    stats[PARCEL_DASHBOARD_TYPES.PRO_ALERT],
    PARCEL_DASHBOARD_TYPES.PRO_ALERT
  ),
  createDashboardStatEnum(
    RETURNS,
    stats[PARCEL_DASHBOARD_TYPES.RETURN],
    PARCEL_DASHBOARD_TYPES.RETURN
  ),
  createDashboardStatEnum(
    WATCH_LIST,
    stats[PARCEL_DASHBOARD_TYPES.WATCH],
    PARCEL_DASHBOARD_TYPES.WATCH
  ),
];

export const emptyValuesMapper = (values, fieldNames) => {
  const mappedFields = {};

  fieldNames.forEach(fieldName => {
    if (emptyStringLIstFilters.includes(values[fieldName])) {
      mappedFields[fieldName] = "";
    }
  });
  return mappedFields;
};

export const getDashboardInitialValues = (query, account = ALL) =>
  !get(query, Fields.ACCOUNT) ? { [Fields.ACCOUNT]: account, ...query } : query;

export const transformDashboardSearchValues = values =>
  omitNilValues({
    ...values,
    [Fields.ACCOUNT]: transformAccount(values[Fields.ACCOUNT]),
  });

export const deleteDisabledValues = (values = {}, disabledFields = []) => {
  if (!isEmpty(values) && !isEmpty(disabledFields)) {
    const cleanedValues = { ...values };

    disabledFields.forEach(key => {
      cleanedValues[key] = null;
    });

    return omitNilValues(cleanedValues);
  }

  return values;
};

export const prepareFilters = filters => {
  if (filters) {
    const { deliveryPostcode, ...rest } = filters;
    return { ...rest, postcode: deliveryPostcode };
  }

  return filters;
};
