import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose, lifecycle, withProps } from "recompose";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";
import { withOverlay } from "@dpdgroupuk/mydpd-ui";

import withOnLoadFailure from "../../../../HOCS/withOnLoadFailure";
import withReportFeedbackHandler from "../../../../HOCS/withReportFeedbackHandler";
import {
  getLocationState,
  getQueryPagination,
  getSearchQuery,
  stringifyQuery,
} from "../../../../utils/query";
import { fetchRelatedParcels } from "../../actions";
import { getRelatedParcels, getRelatedParcelsTotal } from "../../selectors";

export default compose(
  withRouter,
  withReportFeedbackHandler,
  withOnLoadFailure,
  withOverlay,
  connect(null, (dispatch, { parcelCode }) => ({
    findParcels: (query, fetchOptions) =>
      dispatch(fetchRelatedParcels(parcelCode, query, fetchOptions)),
  })),
  withAppLoader({
    loadFn: async ({ findParcels, location, history }, fetchOptions) => {
      const pageQuery = getQueryPagination(location);
      await findParcels(pageQuery, fetchOptions);
      history.replace({
        search: location.search,
      });
    },
  }),
  connect(
    state => ({
      totalCount: getRelatedParcelsTotal(state),
      relatedParcels: getRelatedParcels(state),
    }),
    (dispatch, { history, location }) => {
      const onNextOrPrev = targetPage => {
        const query = getSearchQuery(location);

        history.push({
          search: stringifyQuery({
            ...query,
            page: targetPage,
          }),
          state: getLocationState(location),
        });
      };

      return {
        onNext: onNextOrPrev,
        onPrevious: onNextOrPrev,
        onFirst: onNextOrPrev,
        onLast: onNextOrPrev,
      };
    }
  ),
  withProps(({ location }) => ({
    ...getQueryPagination(location),
  })),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { search } = this.props.location;
      const { search: prevSearch } = prevProps.location;
      if (prevSearch !== search) {
        this.props.overlay.show();
        const { reloadFn } = this.props;
        reloadFn(true).finally(this.props.overlay.hide);
      }
    },
    componentWillUnmount() {
      this.props.history.replace({
        search: "",
      });
    },
  })
);
