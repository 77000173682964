import { useState } from "react";

import PropTypes from "prop-types";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import mapImg from "../../../../assets/icons/map.svg";
import MapView from "../../../../components/MapView";
import { PARCEL_HISTORY_COMPONENT } from "../../../../constants/analytics";
import { DASH } from "../../../../constants/strings";
import { ParcelTypes } from "../../../../models/types";
import { getEventLocationMapUrl } from "../../model";
import styles from "./ParcelHistory.module.scss";

const MapViewCell = ({ row, onClickParcelMap, onCloseParcelMap }) => {
  const [showMap, setShowMap] = useState(false);
  const { latitude, longitude } = row;

  return (
    <>
      {latitude && longitude ? (
        <div className={styles.icon}>
          <img
            id="Map-view-cell-1"
            alt="map"
            className="cursor-pointer"
            src={mapImg}
            onClick={() => {
              onClickParcelMap();
              setShowMap(true);
            }}
          />
        </div>
      ) : (
        DASH
      )}
      <MapView
        show={showMap}
        longitude={longitude}
        latitude={latitude}
        src={getEventLocationMapUrl({ longitude, latitude })}
        onClose={() => {
          onCloseParcelMap();
          setShowMap(false);
        }}
      />
    </>
  );
};

MapViewCell.propTypes = {
  onClick: PropTypes.func,
  onClickParcelMap: PropTypes.func,
  onCloseParcelMap: PropTypes.func,
  row: PropTypes.shape(ParcelTypes.ParcelEvent),
};

export default withTrackProps({
  onClickParcelMap: PARCEL_HISTORY_COMPONENT.CLICK_MAP,
  onCloseParcelMap: PARCEL_HISTORY_COMPONENT.CLOSE_MAP,
})(MapViewCell);
