import { Fields } from "../../constants/forms";
import { isDateWithinLast10Months, isValidDate } from "./date";

// eslint-disable-next-line import/no-anonymous-default-export
export default values => {
  const errors = {};

  const date = values[Fields.DATE];
  if (date) {
    if (!isValidDate(date)) {
      errors[Fields.DATE] = "Please enter a valid value for From Date";
    }
    if (!isDateWithinLast10Months(date)) {
      errors[Fields.DATE] = "Please enter a date within the last 10 months";
    }
  }

  return errors;
};

export const moveItemToFirst = (arr, index) => {
  if (index < 0 || index >= arr.length) {
    return arr;
  }

  const value = arr[index];
  const remainingArray = arr.filter(item => item !== value);

  return [value, ...remainingArray];
};
