import EAVVehicle from "../assets/images/Electric/EAVP1Cargo.png";
import ENVVehicle from "../assets/images/Electric/eNV.png";
import ECAVehicle from "../assets/images/Electric/Fuso.png";
import PAXVehicle from "../assets/images/Electric/Paxster.png";
import PARVehicle from "../assets/images/Electric/peugeot.png";
import VITVehicle from "../assets/images/Electric/vito.png";
import Van from "../assets/images/truck.png";

export const VEHICLE_IMAGE_MAP = {
  EAV: EAVVehicle,
  ECA: ECAVehicle,
  ENV: ENVVehicle,
  PAR: PARVehicle,
  PAX: PAXVehicle,
  RED: ENVVehicle,
  VIT: VITVehicle,
};

export const getVehicleImage = vehicleCode =>
  VEHICLE_IMAGE_MAP[vehicleCode] || Van;
