const Covid = props => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
    <rect width="48" height="48" fill="white" />
    <path
      d="M41.5949 41.6374H6.40551C5.53721 41.6374 4.76015 41.1885 4.32561 40.437C3.89106 39.6856 3.89186 38.7868 4.32561 38.0354L21.9203 7.56039C22.354 6.80894 23.1319 6.35999 24.0002 6.35999C24.8685 6.35999 25.6456 6.80894 26.0801 7.56039L43.6748 38.0354C44.1085 38.7876 44.1085 39.6864 43.674 40.437C43.2402 41.1893 42.4624 41.6374 41.5949 41.6374ZM24.0002 7.96052C23.861 7.96052 23.5152 7.99973 23.3072 8.36066L5.71167 38.8357C5.5028 39.1966 5.64205 39.5167 5.71167 39.6367C5.7813 39.7568 5.98777 40.0369 6.40551 40.0369H41.5949C42.0118 40.0369 42.2183 39.7568 42.2879 39.6367C42.3576 39.5167 42.4968 39.1974 42.2879 38.8357L24.6932 8.36066C24.4852 7.99973 24.1394 7.96052 24.0002 7.96052Z"
      fill="#414042"
    />
    <path d="M19.6675 28C19.5868 28 19.5088 27.9707 19.448 27.9176C19.3873 27.8645 19.3478 27.7911 19.3371 27.7111C19.3263 27.6311 19.3449 27.55 19.3894 27.4827C19.434 27.4154 19.5014 27.3665 19.5793 27.3452C21.0277 26.9581 22.514 26.7306 24.0119 26.6666C25.5079 26.701 26.9932 26.9299 28.4302 27.3475C28.4726 27.3596 28.5123 27.3801 28.5468 27.4076C28.5812 27.4352 28.6099 27.4694 28.6311 27.5081C28.6523 27.5469 28.6655 27.5895 28.6701 27.6334C28.6747 27.6773 28.6705 27.7217 28.6578 27.764C28.645 27.8063 28.624 27.8456 28.596 27.8797C28.5679 27.9138 28.5334 27.942 28.4943 27.9627C28.4553 27.9833 28.4125 27.996 28.3685 27.9999C28.3245 28.0039 28.2802 27.9991 28.2381 27.9858C26.8995 27.6003 25.5186 27.3809 24.1265 27.3326C24.091 27.3326 24.0558 27.3326 24.0203 27.3333C22.5795 27.3992 21.1499 27.6187 19.7557 27.9881C19.7269 27.996 19.6973 28 19.6675 28Z" />
    <path d="M24.0008 33.3333C22.5721 33.3333 19.6541 32.0263 19.5304 31.9707C19.4498 31.9343 19.3869 31.8674 19.3556 31.7847C19.3243 31.702 19.3271 31.6102 19.3634 31.5296C19.3998 31.449 19.4667 31.3861 19.5494 31.3547C19.6321 31.3234 19.7239 31.3263 19.8045 31.3626C19.8332 31.3756 22.715 32.6666 24.0008 32.6666C25.2866 32.6666 28.1684 31.3756 28.1971 31.3626C28.2777 31.3263 28.3695 31.3234 28.4522 31.3547C28.5349 31.3861 28.6018 31.449 28.6382 31.5296C28.6745 31.6102 28.6773 31.702 28.646 31.7847C28.6147 31.8674 28.5518 31.9343 28.4712 31.9707C28.3475 32.0263 25.4295 33.3333 24.0008 33.3333Z" />
    <path d="M19.6265 29.9974C19.7403 30.0117 22.3628 30.3343 23.9403 30.3343C23.9871 30.3343 24.033 30.334 24.0776 30.3333C25.6292 30.3131 28.2612 30.0107 28.3725 29.9977C28.4591 29.9861 28.5376 29.9409 28.5913 29.872C28.6449 29.803 28.6693 29.7158 28.6593 29.629C28.6492 29.5422 28.6055 29.4628 28.5375 29.408C28.4696 29.3531 28.3828 29.3271 28.2958 29.3356L28.2894 29.3363C28.1413 29.3531 25.5501 29.6471 24.0692 29.6666C22.6109 29.6313 21.1554 29.5209 19.7085 29.3359C19.6217 29.3269 19.5349 29.3523 19.4666 29.4066C19.3983 29.461 19.3541 29.5399 19.3434 29.6264C19.3326 29.713 19.3563 29.8003 19.4092 29.8697C19.4621 29.939 19.5401 29.9849 19.6265 29.9974Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 35.3333C20.1921 35.3333 17.5176 32.015 17.4056 31.8737C17.3588 31.8149 17.3333 31.7419 17.3333 31.6667V25.6667C17.3334 25.5945 17.3568 25.5243 17.4001 25.4666C17.4435 25.409 17.5043 25.3669 17.5736 25.3467C17.7627 25.2917 22.2269 24 24 24C25.7731 24 30.2373 25.2917 30.4264 25.3467C30.4957 25.3669 30.5565 25.409 30.5999 25.4666C30.6432 25.5243 30.6666 25.5945 30.6667 25.6667V31.6667C30.6667 31.7419 30.6412 31.8149 30.5944 31.8737C30.4824 32.015 27.8079 35.3333 24 35.3333ZM18 31.5456C18.4404 32.057 20.8437 34.6667 24 34.6667C27.1631 34.6667 29.5605 32.0573 30 31.5456V25.9183C29.0384 25.6476 25.4544 24.6667 24 24.6667C22.5456 24.6667 18.9616 25.6476 18 25.9183V31.5456Z"
      fill="#414042"
    />
    <path
      d="M16.3333 31.3333C16.31 31.3333 14 31.3027 14 28.6667C14 26.0306 16.31 26 16.3333 26C16.4217 25.9998 16.5066 26.0348 16.5692 26.0972C16.6319 26.1596 16.6671 26.2443 16.6673 26.3327C16.6675 26.4211 16.6325 26.5059 16.5701 26.5686C16.5078 26.6312 16.423 26.6665 16.3346 26.6667C16.1618 26.6685 14.6667 26.7407 14.6667 28.6667C14.6667 30.5941 16.1641 30.665 16.335 30.6667C16.4234 30.6669 16.5081 30.7022 16.5704 30.7649C16.6328 30.8275 16.6677 30.9124 16.6675 31.0008C16.6673 31.0892 16.6319 31.1739 16.5693 31.2363C16.5066 31.2986 16.4217 31.3336 16.3333 31.3333Z"
      fill="#414042"
    />
    <path
      d="M31.431 31.2357C31.4935 31.2982 31.5783 31.3333 31.6667 31.3333C31.69 31.3333 34 31.3029 34 28.6667C34 26.0304 31.69 26 31.6667 26C31.5783 25.9998 31.4934 26.0347 31.4307 26.0971C31.3681 26.1594 31.3327 26.2441 31.3325 26.3325C31.3323 26.4209 31.3672 26.5058 31.4296 26.5685C31.4919 26.6311 31.5766 26.6665 31.665 26.6667C31.8359 26.6685 33.3333 26.7393 33.3333 28.6667C33.3333 30.5941 31.8359 30.665 31.6667 30.6667C31.5783 30.6667 31.4935 30.7018 31.431 30.7643C31.3685 30.8268 31.3333 30.9116 31.3333 31C31.3333 31.0884 31.3685 31.1732 31.431 31.2357Z"
      fill="#414042"
    />
  </svg>
);

export default Covid;
