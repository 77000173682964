import PropTypes from "prop-types";

export default function Clock({ fill }) {
  return (
    <svg
      id="Clock-1"
      focusable="false"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      fill={fill}
      aria-hidden="true"
      role="presentation"
    >
      <path
        id="Clock"
        d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm.5-13H11v6l5.25,3.15L17,14.92l-4.5-2.67Z"
      />
    </svg>
  );
}

Clock.propTypes = {
  fill: PropTypes.string,
};
