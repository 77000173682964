import PropTypes from "prop-types";

export default function WheelChair({ fill }) {
  return (
    <svg
      id="WheelChair"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <path d="M0 0L16 0V16H0L0 0Z" fill={fill} />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5 0C8.32843 0 9 0.671573 9 1.5C9 2.32843 8.32843 3 7.5 3C6.67157 3 6 2.32843 6 1.5C6 0.671573 6.67157 0 7.5 0ZM13 8.8025V7.20305C11.7937 7.18629 10.6481 6.65648 9.83532 5.73956L8.83226 4.59595C8.69201 4.44231 8.52265 4.31984 8.33462 4.23608C8.05736 4.06115 7.73375 3.98004 7.40932 4.00416C6.58101 4.08441 5.96083 4.82094 6.00193 5.67558V10.4019C6.00193 11.2853 6.69819 12.0014 7.55706 12.0014H11.4449V16H13V11.6015C13 10.7182 12.3037 10.0021 11.4449 10.0021H9.11218V7.24304C10.2112 8.17954 11.5747 8.72644 13 8.8025ZM5.76716 14.367C6.88858 14.4949 7.94993 13.8321 8.32708 12.7682H10C9.59499 14.7497 7.78748 16.1266 5.76961 15.9908C3.75174 15.855 2.14505 14.2483 2.00923 12.2304C1.87341 10.2125 3.2503 8.40501 5.23177 8V9.67292C4.16795 10.0501 3.5051 11.1114 3.63301 12.2328C3.76091 13.3543 4.64574 14.2391 5.76716 14.367Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

WheelChair.propTypes = {
  fill: PropTypes.string,
};

WheelChair.defaultProps = {
  fill: "#414042",
};
