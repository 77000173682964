import { Col } from "react-bootstrap";
import { compose } from "recompose";
import { propTypes as ReduxFormPropTypes } from "redux-form";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import CardWithTitle from "../../../../components/CardWithTitle";
import { PROVIDE_DELIVERY_INSTRUCTIONS } from "../../../../constants/analytics";
import { Fields } from "../../../../constants/forms";
import { PROVIDE_INSTRUCTIONS } from "../../../../constants/strings";
import { deliveryEditValidators } from "../../../../models/validators";
import { onProvideDeliveryInstructions } from "../../actions";
import EditPanel from "../../components/EditPanel";
import { hadleInputFocus, withParcelEdit } from "../../hocs";

const ProvideDeliveryInstructions = ({
  handleSubmit,
  onSubmit,
  error,
  ...rest
}) => (
  <Col className="col-sm-12 p-0">
    <CardWithTitle title={PROVIDE_INSTRUCTIONS}>
      <EditPanel
        onSubmit={handleSubmit(onSubmit)}
        error={error}
        offContactDetailsValidation={true}
        {...rest}
      />
    </CardWithTitle>
  </Col>
);

ProvideDeliveryInstructions.defaultProps = {
  onSubmit: () => null,
};

ProvideDeliveryInstructions.propTypes = {
  ...ReduxFormPropTypes,
};

export default compose(
  withParcelEdit({
    onSubmit: onProvideDeliveryInstructions,
    validate: deliveryEditValidators.provideDeliveryInstructions,
  }),
  withTrack(PROVIDE_DELIVERY_INSTRUCTIONS.LOAD),
  withTrackProps({
    onSubmit: PROVIDE_DELIVERY_INSTRUCTIONS.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      switch (fieldName) {
        case Fields.INSTRUCTIONS:
          return PROVIDE_DELIVERY_INSTRUCTIONS.INSTRUCTIONS_INPUT;
        case Fields.CONTACT_NAME:
          return PROVIDE_DELIVERY_INSTRUCTIONS.CONTACT_NAME_INPUT;
        case Fields.MOBILE:
          return PROVIDE_DELIVERY_INSTRUCTIONS.MOBILE_NUMBER_INPUT;
        case Fields.EMAIL:
          return PROVIDE_DELIVERY_INSTRUCTIONS.EMAIL_ADDRESS_INPUT;
        default:
          break;
      }
    },
  }),
  hadleInputFocus
)(ProvideDeliveryInstructions);
