import { get, isEmpty } from "lodash";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { Fields, SERVICE_DISRUPTION_FORM } from "../../constants/forms";
import { transformDashboardStats } from "../../models/dashboard";
import { getCustomers } from "../../redux/ums/selectors";
import { mapAccounts } from "../../utils/reference";

export const getServiceDisruptionFormValues = getFormValues(
  SERVICE_DISRUPTION_FORM
);

export const getDashboard = state => state.ui.dashboard;

export const getDashboardStats = createSelector(getDashboard, ({ stats }) =>
  transformDashboardStats(stats)
);

export const getPDFavailableSelector = createSelector(
  getDashboard,
  ({ isServicePDFavailable }) => isServicePDFavailable
);

export const getServiceDisruptionSelector = createSelector(
  getDashboard,
  ({ serviceDisruption }) => serviceDisruption
);

export const getUnaffectedMessageSelector = createSelector(
  getDashboard,
  ({ unaffectedMessage }) => unaffectedMessage
);

export const getServiceDisruptionPostcode = createSelector(
  getServiceDisruptionFormValues,
  values => get(values, Fields.POSTCODE, "")
);

export const isPostcodeEmpty = createSelector(
  getServiceDisruptionPostcode,
  postcode => isEmpty(postcode)
);

export const getAccounts = createSelector(getCustomers, customers =>
  mapAccounts(customers)
);

export const hasAsterisk = createSelector(
  getDashboard,
  ({ serviceDisruption }) => {
    const issues = serviceDisruption?.map(({ issues }) => issues).flat();

    for (const { postcodes } of issues) {
      const hasAsterisk = postcodes?.some(postcode => postcode.includes("*"));

      if (hasAsterisk) {
        return hasAsterisk;
      }
    }

    return false;
  }
);
