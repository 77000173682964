import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { Field, FieldArray, propTypes, reduxForm } from "redux-form";

import { Button, Form, FormControl, Popover } from "@dpdgroupuk/mydpd-ui";

import {
  SEND_EMAIL_FIELDS as Fields,
  SEND_EMAIL_FORM,
} from "../../../../constants/forms";
import * as M from "../../../../constants/strings";
import { sendEmail } from "../../../../models/validators";
import { initializeEmailForm, sendProofOfDelivery } from "../../actions";
import { getParcelCode } from "../../selectors";
import { CheckBoxGroup } from "./ChekBoxGroup";
import styles from "./SendEmailForm.module.scss";

const SendEmailForm = ({
  onClose,
  error,
  handleSubmit,
  onSubmit,
  submitting,
  invalid,
  images,
}) => (
  <>
    <Container>
      <Row
        style={{
          overflowY: "hidden",
          height: "250px",
          overflowX: "auto",
          whiteSpace: "nowrap",
          display: "block",
        }}
      >
        <FieldArray
          name={Fields.ATTACHMENTS}
          component={CheckBoxGroup}
          checkBoxData={images}
          classes={{
            checkmark: styles.checkmark,
          }}
        />
      </Row>
    </Container>
    <Container>
      <Row className={styles.title}>{M.EMAIL_IMAGE}</Row>
      <Row className={styles.text}>{M.PLEASE_COMPLETE_FIELDS}</Row>
      <Row>
        <Field
          component={FormControl.Input}
          name={Fields.EMAIL}
          label={M.EMAIL}
          required
        />
      </Row>
      <Row>
        <Field
          component={FormControl.Input}
          name={Fields.SUBJECT}
          label={M.SUBJECT}
          required
        />
      </Row>
      <Row>
        <Field
          component={Form.MaterialTextInput}
          multiline
          classes={{
            input: styles.textarea,
          }}
          rows={8}
          maxLength={1000}
          name={Fields.MESSAGE}
          label={M.MESSAGE}
          helperText={M.MAX_1000_CHARACTERS}
          required
        />
      </Row>
      <Row>
        <Col xs={12} className="text-right">
          <Button
            className={styles.closeButton}
            variant="dark"
            onClick={onClose}
          >
            {M.CLOSE}
          </Button>
          <Popover isOpen={error} content={error}>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={submitting || invalid}
            >
              {M.SUBMIT}
            </Button>
          </Popover>
        </Col>
      </Row>
    </Container>
  </>
);

SendEmailForm.defaultProps = {
  onClose: () => null,
  onSubmit: () => null,
};

SendEmailForm.propTypes = {
  ...propTypes,
  onClose: PropTypes.func,
  parcelCode: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default compose(
  connect(state => ({
    parcelCode: getParcelCode(state),
  })),
  connect(null, (dispatch, ownProps) => ({
    onSubmit: data => {
      ownProps.onClose();
      dispatch(sendProofOfDelivery(data, ownProps.images, ownProps.parcelCode));
    },
    init: () => dispatch(initializeEmailForm()),
  })),
  reduxForm({
    form: SEND_EMAIL_FORM,
    validate: sendEmail,
  }),
  lifecycle({
    componentDidMount() {
      this.props.init();
    },
  })
)(SendEmailForm);
