import PropTypes from "prop-types";

export default function CalendarEvent({ fill }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.925 18.5C14.2417 18.5 13.6667 18.2667 13.2 17.8C12.7333 17.3333 12.5 16.7583 12.5 16.075C12.5 15.3917 12.7333 14.8167 13.2 14.35C13.6667 13.8833 14.2417 13.65 14.925 13.65C15.6083 13.65 16.1833 13.8833 16.65 14.35C17.1167 14.8167 17.35 15.3917 17.35 16.075C17.35 16.7583 17.1167 17.3333 16.65 17.8C16.1833 18.2667 15.6083 18.5 14.925 18.5ZM4.5 22C4.1 22 3.75 21.85 3.45 21.55C3.15 21.25 3 20.9 3 20.5V5C3 4.6 3.15 4.25 3.45 3.95C3.75 3.65 4.1 3.5 4.5 3.5H6.125V2H7.75V3.5H16.25V2H17.875V3.5H19.5C19.9 3.5 20.25 3.65 20.55 3.95C20.85 4.25 21 4.6 21 5V20.5C21 20.9 20.85 21.25 20.55 21.55C20.25 21.85 19.9 22 19.5 22H4.5ZM4.5 20.5H19.5V9.75H4.5V20.5ZM4.5 8.25H19.5V5H4.5V8.25ZM4.5 8.25V5V8.25Z"
        fill={fill}
      />
    </svg>
  );
}

CalendarEvent.defaultProps = {
  fill: "#000",
};

CalendarEvent.propTypes = {
  fill: PropTypes.string,
};
