import { findIndex, flatten, slice } from "lodash";

import * as M from "../constants/strings";
import { capitalizeEachWord } from "./string";

export const changeIrelandCountryCode = countries => {
  const indexOfIrelandItem = findIndex(countries, ["value", "IE"]);

  return [
    ...slice(countries, 0, indexOfIrelandItem),
    { value: "EI", label: "Republic Of Ireland" },
    ...slice(countries, indexOfIrelandItem + 1, countries.length),
  ];
}; // replace COUNTRIES_ENUM "IE" with "EI"

export const mapForDropdown = (arr, val = "id", name = "name") =>
  arr
    .sort((a, b) => (a.name <= b.name ? -1 : 1))
    .map(item => ({
      value: item[val],
      label: capitalizeEachWord(item[name]),
    }));

export const mapCountries = (arr, val = "id", name = "name") =>
  changeIrelandCountryCode(
    arr.map(item => ({
      value: item[val],
      label: capitalizeEachWord(item[name]),
    }))
  );

const getServices = depot =>
  depot.supportedBusinessUnit
    .join("/")
    .replace("1", "dpd")
    .replace("2", "local");

export const mapDepot = (arr = []) =>
  arr
    .sort((a, b) => (a.depotDescription <= b.depotDescription ? -1 : 1))
    .map(item => ({
      value: item.depotCode,
      label: `${capitalizeEachWord(item.depotDescription)} (${getServices(
        item
      )})`,
    }));

export const mapRangeReference = ({
  parcelRangeStart = [],
  parcelRangeEnd = [],
}) => {
  parcelRangeEnd = flatten([parcelRangeEnd]);
  parcelRangeStart = flatten([parcelRangeStart]);
  const result = parcelRangeStart.map((start, index) => ({
    label: `${start} - ${parcelRangeEnd[index]}`,
    value: parcelRangeEnd[index],
  }));
  return [{ label: M.ALL_RANGES, value: M.ALL }, ...result];
};

export const mapAccounts = customers => {
  let mappedAccounts = [
    ...customers?.map(customer => ({
      label: `${customer.customername} (${customer.account})`,
      value: customer.account,
    })),
  ];
  if (customers?.length > 1) {
    mappedAccounts = [
      { label: M.ALL_ACCOUNTS, value: M.ALL },
      ...mappedAccounts,
    ];
  }

  return mappedAccounts;
};
