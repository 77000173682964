import { useMemo, useState } from "react";

import PropTypes from "prop-types";
import { compose } from "recompose";

import {
  trackProps,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import photoImg from "../../../../assets/icons/photo.svg";
import { PARCEL_HISTORY_COMPONENT } from "../../../../constants/analytics";
import { DASH, OK } from "../../../../constants/strings";
import { ParcelTypes } from "../../../../models/types";
import { getImageUrl } from "../../model";
import styles from "./ParcelHistory.module.scss";
import ImageView from "./ParcelImageView";

const ImageViewCell = ({ row: rowData, onClickImage, onClickClose }) => {
  const [showImage, setShowImage] = useState(false);
  const images = useMemo(
    () =>
      rowData.images.map(({ imageKey, imageType, imageCaption }) => ({
        src: getImageUrl(imageKey, imageType),
        imageKey,
        title: imageCaption,
      })),
    [rowData]
  );

  return (
    <>
      {images.length > 0 ? (
        <div className={styles.icon}>
          <img
            id="Image-view-cell-1"
            className="cursor-pointer"
            alt="img"
            src={photoImg}
            onClick={() => {
              onClickImage();
              setShowImage(true);
            }}
          />
        </div>
      ) : (
        DASH
      )}
      <ImageView
        images={images}
        show={showImage}
        carouselProps={{
          indicators: false,
        }}
        onClose={() => {
          onClickClose();
          setShowImage(false);
        }}
        buttonText={OK}
        parcel={rowData}
      />
    </>
  );
};

ImageViewCell.propTypes = {
  onClick: PropTypes.func,
  onClickClose: PropTypes.func,
  onClickImage: PropTypes.func,
  onClickNextImage: PropTypes.func,
  onClickPreviousImage: PropTypes.func,
  row: PropTypes.shape(ParcelTypes.ParcelEvent),
};

export default compose(
  withTrack(trackProps(PARCEL_HISTORY_COMPONENT.LOAD)),
  withTrackProps({
    onClickClose: PARCEL_HISTORY_COMPONENT.CLOSE,
    onClickImage: PARCEL_HISTORY_COMPONENT.IMAGE,
    onClickNextImage: PARCEL_HISTORY_COMPONENT.NEXT_IMAGE,
    onClickPreviousImage: PARCEL_HISTORY_COMPONENT.PREVIOUS_IMAGE,
  })
)(ImageViewCell);
