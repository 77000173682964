import classNames from "classnames";
import PropTypes from "prop-types";
import { Field } from "redux-form";

import { ReduxifiedDayPicker } from "../../../../components/DayPicker";
import { PLEASE_DELIVER_PARCEL_ON_DATE } from "../../../../constants/strings";
import styles from "./ParcelDayPicker.module.scss";

const ParcelDayPicker = ({
  dates,
  title,
  defaultHeaderDate,
  className,
  name,
}) => (
  <div className={classNames(styles.dayPickerContainer, className)}>
    {dates && dates.length > 0 && (
      <div>
        <div className={styles.title}>{title}</div>
        <Field
          name={name}
          component={ReduxifiedDayPicker}
          values={dates}
          defaultHeaderDate={defaultHeaderDate}
        />
      </div>
    )}
  </div>
);

ParcelDayPicker.propTypes = {
  selected: PropTypes.string,
  dates: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.any,
  title: PropTypes.string,
  defaultHeaderDate: PropTypes.string,
  name: PropTypes.string,
};

ParcelDayPicker.defaultProps = {
  title: PLEASE_DELIVER_PARCEL_ON_DATE,
};

export default ParcelDayPicker;
