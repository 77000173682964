import api from "./api";

export const getAddresses = (query, options) =>
  api.get({
    path: "/locations/addresses",
    query,
    options,
  });

export const getDepot = (depotCode, options) =>
  api.get({
    path: `/depots/${depotCode}`,
    options,
  });

export const getRoute = (
  { routeCode, depotCode, routeDate, routeNumber, routeTime },
  options
) =>
  api.get({
    path: `/routes/${routeCode}`,
    query: {
      routeCode,
      depotCode,
      routeDate,
      routeNumber,
      routeTime,
    },
    options,
  });
export const getDriver = (driverCode, options) =>
  api.get({
    path: `/drivers/${driverCode}`,
    query: {
      driverCode,
    },
    options,
  });
