import { useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { bindActionCreators } from "redux";

import { withNotifier } from "@dpdgroupuk/mydpd-app";
import { Trackable, trackProps } from "@dpdgroupuk/react-event-tracker";

import { PARCEL_DASHBOARD_TYPES } from "../../constants/dashboard";
import { withDeliveryHandlers } from "../../features/DeliveryListView";
import { DeliveriesActions } from "../../redux/deliveries";
import { getCustomers } from "../../redux/ums/selectors";
import { DASHBOARD, DELIVERIES } from "../../router";
import {
  createLocationState,
  getCurrentPageByTotal,
  getFiltersQuery,
  getSearchQuery,
  stringifyQuery,
} from "../../utils/query";
import { fetchWatchList } from "../Dashboard/actions";
import { getTitle, trackLoadAction } from "../DashboardResult/utils";
import { removeFromWatchList, resetForm } from "./actions";
import * as watchListSelectors from "./selectors";
import WatchListView from "./WatchListView";

const TrackablePage = ({ loadId, interfaceId, ...rest }) => {
  const pageTitle = getTitle(PARCEL_DASHBOARD_TYPES.WATCH);
  const [selectedItems, setSelectedItems] = useState([]);

  return (
    <Trackable loadId={loadId} interfaceId={interfaceId}>
      <WatchListView
        {...rest}
        pageTitle={pageTitle}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
    </Trackable>
  );
};

TrackablePage.propTypes = {
  loadId: PropTypes.string,
  interfaceId: PropTypes.string,
  watchListItems: PropTypes.array,
};

const mapStateToProps = state => ({
  customers: getCustomers(state),
});

const mapDispatchToProps = (dispatch, { notifier }) => ({
  ...bindActionCreators(
    { removeFromWatchList, resetForm, ...DeliveriesActions },
    dispatch
  ),
  findParcels: notifier.runAsync(async (query, fetchOptions) =>
    dispatch(fetchWatchList(query, fetchOptions))
  ),
});

export default compose(
  withNotifier,
  connect(mapStateToProps, mapDispatchToProps),
  withDeliveryHandlers,
  withProps(
    ({
      history,
      findParcels,
      filterParcels,
      removeFromWatchList,
      overlay,
      location,
      resetForm,
    }) => ({
      ...trackLoadAction(PARCEL_DASHBOARD_TYPES.WATCH, trackProps),
      onRemoveClick: async ids => {
        const searchQuery = getSearchQuery(location);
        try {
          overlay.show();
          await removeFromWatchList(ids);
          resetForm();

          const {
            findByTotal,
            findByCode: searchFindByCode,
            parcelCodes,
            expiresIn,
          } = await findParcels(searchQuery);
          const refresh = (query = {}, filterFindByCode, filterFindByTotal) => {
            history.replace({
              search: stringifyQuery({
                ...searchQuery,
                ...query,
                page: getCurrentPageByTotal(
                  location,
                  filterFindByTotal || findByTotal
                ),
              }),
              state: createLocationState({
                searchFindByCode,
                filterFindByCode: filterFindByCode || searchFindByCode,
                expiresIn,
              }),
            });
          };

          if (findByTotal === 0) {
            history.push(DASHBOARD);
          } else if (findByTotal === 1) {
            history.push(`${DELIVERIES}/${parcelCodes[0]}`);
          } else {
            const filters = getFiltersQuery(location);
            if (filters) {
              const { findByCode: filterFindByCode, findByTotal } =
                await filterParcels(searchFindByCode, filters);
              refresh(filters, filterFindByCode, findByTotal);
            } else {
              refresh();
            }
          }
          overlay.hide();
        } catch (e) {
          overlay.hide();
          history.replace({
            search: stringifyQuery(searchQuery),
          });
        }
      },
    })
  ),
  // withDeliveryAnalytics,
  connect(state => ({
    selectedItems: watchListSelectors.getSelectedItems(state),
  }))
)(TrackablePage);
