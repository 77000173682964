import { useMemo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { MapProgressBar } from "@dpdgroupuk/mydpd-ui";
import { withTrack } from "@dpdgroupuk/react-event-tracker";

import { PhotoIDSmall, Seedling } from "../../../../components/Icon";
import { ETA_BAR } from "../../../../constants/analytics";
import { getVehicleImage } from "../../../../constants/drivers";
import styles from "./EtaBar.module.scss";
import Status from "./Status/Status";

const EtaBar = ({
  progressData: {
    vehicleTypeCode,
    progressStatus,
    showProgressBar,
    ...restProgressData
  },
  isVehicleElectric,
  electricMessage,
  showIdMessage,
  parcelStatusInfoMessage,
}) => {
  const vehicleSrc = useMemo(
    () => getVehicleImage(vehicleTypeCode),
    [vehicleTypeCode]
  );

  return (
    showProgressBar && (
      <Container>
        <Row className="mb-2">
          <Col sm={12} className="pl-0">
            <Status
              routeStatus={progressStatus}
              statusClassName={styles.status}
            />
          </Col>
          <Row className={styles.infoContainer}>
            {isVehicleElectric && (
              <div className={classNames(styles.block, styles.carInfo)}>
                <Seedling />
                <Status
                  routeStatus={electricMessage}
                  textClassName={styles.message}
                />
              </div>
            )}
            {showIdMessage && (
              <div className={classNames(styles.block, styles.photoId)}>
                <PhotoIDSmall />
                <Status
                  routeStatus={parcelStatusInfoMessage}
                  textClassName={styles.message}
                />
              </div>
            )}
          </Row>
        </Row>
        <Row className="mb-3">
          <MapProgressBar {...restProgressData} vehicleSrc={vehicleSrc} />
        </Row>
      </Container>
    )
  );
};

EtaBar.propTypes = {
  progressData: PropTypes.object,
  parcelStatusInfoMessage: PropTypes.string,
  electricMessage: PropTypes.string,
  isVehicleElectric: PropTypes.bool,
  showIdMessage: PropTypes.bool,
};

export default withTrack(ETA_BAR.LOAD)(EtaBar);
