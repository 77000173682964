import PropTypes from "prop-types";

export default function ClockEvent({ fill }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.66667C16.595 3.66667 20.3333 7.405 20.3333 12C20.3333 16.595 16.595 20.3333 12 20.3333C7.405 20.3333 3.66667 16.595 3.66667 12C3.66667 7.405 7.405 3.66667 12 3.66667ZM12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 6.4775 17.5225 2 12 2ZM16.8733 12.3825C17.0417 12.4142 17.0417 12.66 16.8742 12.6925C15.285 12.9933 11.8367 13.6183 11.4183 13.6183C10.8192 13.6183 10.3342 13.1333 10.3342 12.5342C10.3342 12.1075 10.9758 7.995 11.2717 6.33C11.3 6.17 11.5317 6.17917 11.5575 6.34167L12.3783 11.54L16.8733 12.3825Z"
        fill={fill}
      />
    </svg>
  );
}

ClockEvent.defaultProps = {
  fill: "#DC0032",
};

ClockEvent.propTypes = {
  fill: PropTypes.string,
};
