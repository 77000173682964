import PropTypes from "prop-types";

import { useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

const PickupDetail = ({
  icon: Icon,
  value,
  itemClassName,
  iconClassName,
  iconFill,
  valueClassName,
}) => {
  const theme = useMyDpdTheme();
  return (
    value && (
      <div className={itemClassName}>
        {Icon && (
          <div className={iconClassName}>
            <Icon fill={iconFill || theme.palette.brand} />
          </div>
        )}
        <div className={valueClassName}>{value}</div>
      </div>
    )
  );
};

PickupDetail.propTypes = {
  value: PropTypes.string,
  itemClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  iconFill: PropTypes.string,
  valueClassName: PropTypes.string,
  icon: PropTypes.func,
};

PickupDetail.defaultProps = {
  itemClassName: "",
  iconClassName: "",
  valueClassName: "",
};

export default PickupDetail;
