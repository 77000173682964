import get from "lodash/get";

import { ActionTypes } from "./actions";

export const initialState = {
  parcel: null,
  actions: {},
  relatedParcels: [],
  route: {},
  driver: {},
  depot: {},
  pickupLocation: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PARCEL.SUCCESS: {
      return {
        ...state,
        parcel: get(action, "payload.data"),
      };
    }
    case ActionTypes.GET_PARCEL_ACTIONS.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        actions: data,
      };
    }
    case ActionTypes.GET_RELATED_PARCELS.SUCCESS: {
      const { data: relatedParcels } = action.payload;
      return {
        ...state,
        relatedParcels,
      };
    }
    case ActionTypes.GET_ROUTE.SUCCESS: {
      const { data: route } = action.payload;
      return {
        ...state,
        route,
      };
    }
    case ActionTypes.GET_DRIVER.SUCCESS: {
      const { data: driver } = action.payload;
      return {
        ...state,
        driver,
      };
    }
    case ActionTypes.GET_DEPOT.SUCCESS: {
      const { data: depot } = action.payload;
      return {
        ...state,
        depot,
      };
    }
    case ActionTypes.GET_PICKUP_LOCATION.SUCCESS: {
      const { data: pickupLocation } = action.payload;
      return {
        ...state,
        pickupLocation,
      };
    }
    case ActionTypes.CLEAR_PARCEL_VIEW_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
