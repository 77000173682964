import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { Fields, SEARCH_FORM } from "../../constants/forms";
import {
  isDateWithinLast10Months,
  isValidDate,
} from "../../models/validators/date";
import { getCustomers } from "../../redux/ums/selectors";
import { getValue } from "../../utils/object";
import { mapAccounts } from "../../utils/reference";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../constants/dateFormats";

const emptyObject = {};

export const getSearchFormValues = state => getFormValues(SEARCH_FORM)(state);

export const isValidStartDate = createSelector(
  getSearchFormValues,
  (values = emptyObject) => {
    const startDate =
      values[Fields.COLLECTION_START_DATE] || values[Fields.CREATE_START_DATE];
    return !!(
      startDate &&
      isValidDate(startDate) &&
      isDateWithinLast10Months(startDate)
    );
  }
);

export const isEmailFieldSelected = createSelector(
  getSearchFormValues,
  (values = emptyObject) => values.type === Fields.EMAIL
);

export const isPhoneFieldSelected = createSelector(
  getSearchFormValues,
  (values = emptyObject) => values.type === Fields.PHONE
);

export const isCustomerReferenceFieldSelected = createSelector(
  getSearchFormValues,
  (values = emptyObject) => values.type === Fields.REFERENCE
);

export const getType = createSelector(getSearchFormValues, values =>
  getValue(values, Fields.TYPE, "")
);

export const getDateType = createSelector(getSearchFormValues, values =>
  getValue(values, Fields.DATE_TYPE, "")
);

export const getAccounts = createSelector(getCustomers, customers =>
  mapAccounts(customers)
);

export const getRelatedDate = createSelector(
  getSearchFormValues,
  formValues => {
    const date = getValue(formValues, Fields.FROM_DATE);

    return date ? moment(date, DEFAULT_DATE_FORMAT).toDate() : date;
  }
);
