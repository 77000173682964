import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { compose, lifecycle, withProps } from "recompose";
import { Field, propTypes as ReduxFormPropTypes } from "redux-form";

import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import CardWithTitle from "../../../../components/CardWithTitle";
import { DELIVER_TO_SAFE_PLACE } from "../../../../constants/analytics";
import { Fields } from "../../../../constants/forms";
import {
  CHOOSE_PLACE,
  DELIVER_SAFE_PLACE,
  DELIVERY_DATE,
  PLEASE_CONFIRM,
} from "../../../../constants/strings";
import { deliveryEditValidators } from "../../../../models/validators";
import { formatDDMMYYYY } from "../../../../utils/date";
import { checkMinDate, onDeliverToSafePlaceSubmit } from "../../actions";
import CustomCheckbox from "../../components/CustomCheckbox";
import EditPanel from "../../components/EditPanel";
import ParcelDayPicker from "../../components/ParcelDayPicker";
import SafePlaceOptions from "../../components/SafePlaceOptions";
import { hadleInputFocus, loadActionDates, withParcelEdit } from "../../hocs";
import styles from "./DeliverToSafePlace.module.scss";
import { getValue } from "../../../../utils/object";

const DeliverToSafePlace = ({
  handleSubmit,
  onSubmit,
  cardDates,
  error,
  onClickSafeCode,
  onClickDate,
  defaultDate,
  ...rest
}) => (
  <Col className="col-12 p-0">
    <CardWithTitle title={DELIVER_SAFE_PLACE}>
      <SafePlaceOptions onChange={onClickSafeCode} />
      <EditPanel
        onSubmit={handleSubmit(onSubmit)}
        error={error}
        offContactDetailsValidation={true}
        {...rest}
      >
        <Row className="w-100 pl-2">
          {!!cardDates.length && (
            <Col className="col-6">
              <ParcelDayPicker
                onChange={onClickDate}
                name={Fields.DELIVERY_DATE}
                dates={cardDates}
                title={DELIVERY_DATE}
                defaultHeaderDate={defaultDate}
              />
            </Col>
          )}
          <Col
            className={classNames("col-6 pr-0 pl-4", styles.checkboxContainer)}
          >
            <Container>
              <Field
                component={CustomCheckbox}
                label={PLEASE_CONFIRM}
                name={Fields.AGREEMENT}
                id={Fields.AGREEMENT}
                className={styles.checkbox}
              />
              <ul className={styles.list}>
                {CHOOSE_PLACE.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </Container>
          </Col>
        </Row>
      </EditPanel>
    </CardWithTitle>
  </Col>
);

DeliverToSafePlace.defaultProps = {
  onSubmit: () => null,
  cardDates: [],
};

DeliverToSafePlace.propTypes = {
  cardDates: PropTypes.arrayOf(PropTypes.string),
  defaultDate: PropTypes.string,
  ...ReduxFormPropTypes,
};

export default compose(
  loadActionDates,
  withProps(({ cardDates, parcel }) => ({
    additionalInitialValues: {
      isDatesAvailable: !!cardDates.length,
    },
    defaultDate: formatDDMMYYYY(getValue(parcel, "estimatedDeliveryDate", "")),
  })),
  withParcelEdit({
    onSubmit: onDeliverToSafePlaceSubmit,
    validate: deliveryEditValidators.safePlaceValidator,
  }),
  withTrack(DELIVER_TO_SAFE_PLACE.LOAD),
  withTrackProps({
    onSubmit: DELIVER_TO_SAFE_PLACE.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      switch (fieldName) {
        case Fields.AGREEMENT:
          return DELIVER_TO_SAFE_PLACE.CLICK_CHECKBOX;
        case Fields.DELIVERY_DATE:
        case Fields.SAFE_PLACE_CODE:
          return DELIVER_TO_SAFE_PLACE.CLICK_DROP_DOWN_LIST;
        case Fields.INSTRUCTIONS:
          return DELIVER_TO_SAFE_PLACE.INSTRUCTIONS_INPUT;
        case Fields.CONTACT_NAME:
          return DELIVER_TO_SAFE_PLACE.CONTACT_NAME_INPUT;
        case Fields.MOBILE:
          return DELIVER_TO_SAFE_PLACE.MOBILE_NUMBER_INPUT;
        case Fields.EMAIL:
          return DELIVER_TO_SAFE_PLACE.EMAIL_ADDRESS_INPUT;
        default:
          break;
      }
    },
    onClickDate: DELIVER_TO_SAFE_PLACE.CLICK_DATE,
    onClickSafeCode: DELIVER_TO_SAFE_PLACE.CLICK_SAFE_PLACE,
  }),
  hadleInputFocus,
  lifecycle({
    componentDidUpdate() {
      this.props.dispatch(checkMinDate());
    },
  })
)(DeliverToSafePlace);
