import PropTypes from "prop-types";

export default function ReturnToSender({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="284.000000pt"
      height="284.000000pt"
      viewBox="0 0 284.000000 284.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,284.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path d="M667 2103 c-177 -96 -304 -170 -299 -175 5 -5 164 -98 353 -206 l344 -197 0 -300 0 -300 -37 -20 -38 -20 -37 20 -38 20 -3 252 -2 253 -27 17 c-15 10 -149 88 -297 174 l-271 157 -3 -340 c-1 -187 1 -346 6 -354 10 -18 650 -394 671 -394 16 0 634 365 657 387 11 12 14 78 14 359 0 189 -3 344 -6 344 -3 0 -93 -52 -200 -116 l-194 -117 -40 27 c-33 22 -40 32 -40 59 0 18 6 40 13 49 6 8 98 66 202 128 105 62 194 116 198 120 9 8 -583 340 -605 340 -7 0 -151 -75 -321 -167z" />
        <path
          fill="#000000"
          d="M1936 1780 l-141 -140 135 -135 c135 -136 167 -158 195 -135 32 27 18 62 -57 138 -66 68 -70 74 -43 67 119 -27 276 -157 334 -276 77 -158 77 -321 0 -478 -162 -328 -575 -404 -836 -154 -59 57 -94 66 -117 31 -16 -25 10 -64 82 -123 129 -107 257 -150 427 -143 92 4 122 10 187 35 193 76 341 245 389 443 19 77 16 231 -5 310 -60 222 -234 391 -465 450 l-44 12 82 81 c82 83 98 116 69 145 -27 27 -54 9 -192 -128z"
        />
      </g>
    </svg>
  );
}

ReturnToSender.propTypes = {
  fill: PropTypes.string,
};
