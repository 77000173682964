const Olympic = props => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
    <rect width="48" height="48" fill="white" />
    <path
      d="M41.5949 41.6374H6.40551C5.53721 41.6374 4.76015 41.1885 4.32561 40.437C3.89106 39.6856 3.89186 38.7868 4.32561 38.0354L21.9203 7.56039C22.354 6.80894 23.1319 6.35999 24.0002 6.35999C24.8685 6.35999 25.6456 6.80894 26.0801 7.56039L43.6748 38.0354C44.1085 38.7876 44.1085 39.6864 43.674 40.437C43.2402 41.1893 42.4624 41.6374 41.5949 41.6374ZM24.0002 7.96052C23.861 7.96052 23.5152 7.99973 23.3072 8.36066L5.71167 38.8357C5.5028 39.1966 5.64205 39.5167 5.71167 39.6367C5.7813 39.7568 5.98777 40.0369 6.40551 40.0369H41.5949C42.0118 40.0369 42.2183 39.7568 42.2879 39.6367C42.3576 39.5167 42.4968 39.1974 42.2879 38.8357L24.6932 8.36066C24.4852 7.99973 24.1394 7.96052 24.0002 7.96052Z"
      fill="#414042"
    />
    <path
      d="M18.0224 34.6414H13.059C12.8638 34.6414 12.7061 34.4837 12.7061 34.2885C12.7061 34.0933 12.8638 33.9355 13.059 33.9355H18.0224C18.2176 33.9355 18.3753 34.0933 18.3753 34.2885C18.3753 34.4837 18.2176 34.6414 18.0224 34.6414Z"
      fill="#414042"
    />
    <path
      d="M19.411 36.0292C18.451 36.0292 17.6699 35.2482 17.6699 34.2882C17.6699 33.3282 18.451 32.5471 19.411 32.5471C20.371 32.5471 21.152 33.3282 21.152 34.2882C21.152 35.2482 20.3706 36.0292 19.411 36.0292ZM19.411 33.2534C18.8403 33.2534 18.3758 33.7175 18.3758 34.2885C18.3758 34.8596 18.8399 35.3237 19.411 35.3237C19.982 35.3237 20.4462 34.8596 20.4462 34.2885C20.4462 33.7175 19.9817 33.2534 19.411 33.2534Z"
      fill="#414042"
    />
    <path d="M16.5848 31.4703C16.5661 31.4703 16.5477 31.4688 16.5287 31.4657L16.0208 31.3845C15.2772 31.2656 14.6976 30.6084 14.5783 29.749L14.4057 28.5038C14.3789 28.3104 14.5137 28.1321 14.7068 28.1057C14.8995 28.0767 15.0781 28.2137 15.1049 28.4067L15.2775 29.6519C15.3527 30.193 15.7042 30.619 16.1323 30.6878L16.6402 30.769C16.8326 30.7997 16.9639 30.9807 16.9328 31.1734C16.9049 31.3467 16.7549 31.4703 16.5848 31.4703Z" />
    <path
      d="M22.2312 34.6415C22.0361 34.6415 21.8783 34.4837 21.8783 34.2885V32.1465C21.8783 31.9926 21.6757 31.8786 21.4812 31.8345L18.5705 31.5592C18.069 31.4971 17.6299 31.2038 17.3857 30.7732L16.8393 29.8115C16.4857 29.2291 16.0883 28.652 15.5232 28.652H13.059C12.8638 28.652 12.7061 28.4943 12.7061 28.2991C12.7061 28.1039 12.8638 27.9462 13.059 27.9462H15.5229C16.5326 27.9462 17.1281 28.927 17.4475 29.4539L17.9991 30.4242C18.1343 30.662 18.3761 30.8237 18.6471 30.8576L21.6069 31.1392C22.2009 31.2617 22.5845 31.657 22.5845 32.1465V34.2885C22.5842 34.4833 22.4264 34.6415 22.2312 34.6415Z"
      fill="#414042"
    />
    <path
      d="M27.1483 36.0539C26.1883 36.0539 25.4072 35.2728 25.4072 34.3128C25.4072 33.3528 26.1883 32.5718 27.1483 32.5718C28.1083 32.5718 28.8893 33.3528 28.8893 34.3128C28.8893 35.2728 28.1079 36.0539 27.1483 36.0539ZM27.1483 33.278C26.5776 33.278 26.1131 33.7421 26.1131 34.3132C26.1131 34.8842 26.5772 35.3484 27.1483 35.3484C27.7193 35.3484 28.1835 34.8842 28.1835 34.3132C28.1835 33.7421 27.719 33.278 27.1483 33.278Z"
      fill="#414042"
    />
    <path
      d="M34.9407 34.6414H28.5365C28.3414 34.6414 28.1836 34.4837 28.1836 34.2885C28.1836 34.0933 28.3414 33.9355 28.5365 33.9355H34.9407C35.1358 33.9355 35.2936 34.0933 35.2936 34.2885C35.2936 34.4837 35.1355 34.6414 34.9407 34.6414Z"
      fill="#414042"
    />
    <path d="M34.2309 31.4703C34.2122 31.4703 34.1939 31.4688 34.1748 31.4657L33.6669 31.3845C32.9236 31.2656 32.3441 30.6084 32.2248 29.749L32.0522 28.5038C32.0254 28.3104 32.1602 28.1321 32.3533 28.1057C32.5471 28.0767 32.7246 28.2137 32.7514 28.4067L32.924 29.6519C32.9992 30.193 33.3507 30.619 33.7788 30.6878L34.2867 30.769C34.4791 30.7997 34.6103 30.9807 34.5793 31.1734C34.5511 31.3467 34.4011 31.4703 34.2309 31.4703Z" />
    <path
      d="M24.3568 33.9462C24.1617 33.9462 24.0039 33.7884 24.0039 33.5932V32.1462C24.0039 31.8716 24.1281 31.376 24.9579 31.1449L26.2066 30.9222C26.3951 30.8738 26.5603 30.7086 26.6128 30.4955L26.8267 29.6308C27.084 28.561 27.7253 27.9462 28.5854 27.9462H33.1691C34.1796 27.9462 34.7746 28.927 35.094 29.4539L35.2426 29.6999C35.3432 29.8669 35.2895 30.0836 35.1226 30.1845C34.9557 30.2851 34.739 30.2315 34.638 30.0645L34.4898 29.8192C34.1319 29.2288 33.7348 28.6517 33.1691 28.6517H28.5861C27.8923 28.6517 27.6159 29.3699 27.5132 29.798L27.2986 30.6649C27.1828 31.1322 26.8207 31.495 26.3541 31.6118L25.1143 31.8321C24.948 31.8804 24.7098 31.9813 24.7098 32.1462V33.5932C24.7098 33.7884 24.552 33.9462 24.3568 33.9462Z"
      fill="#414042"
    />
  </svg>
);

export default Olympic;
