import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Icon, IconButton } from "@dpdgroupuk/mydpd-ui";

import "./Tooltip.scss";

const InfoTooltip = ({ message, icon, placement }) => (
  <OverlayTrigger
    placement={placement ?? "top"}
    overlay={<Tooltip className="mydpd-tooltip">{message}</Tooltip>}
  >
    <IconButton icon={icon ?? <Icon.Info color={"#414042"} />} />
  </OverlayTrigger>
);

InfoTooltip.propTypes = {
  placement: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.string,
};

export default InfoTooltip;
