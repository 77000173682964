import { joinStringsWithComma } from "../utils/string";

export const formatFullAddressString = ({
  organisation,
  street,
  suburb,
  town,
  city,
  county,
  postcode,
}) =>
  joinStringsWithComma([
    organisation,
    street,
    suburb,
    town,
    city,
    county,
    postcode,
  ]);

// @see:https://geopost.jira.com/browse/CT-1945
export const formatParcelDetailsAddressString = ({
  organisation,
  street,
  property,
  town,
  postcode,
}) => joinStringsWithComma([organisation, street, property, town, postcode]);

export const postcodeAutocompleteMapper = option =>
  joinStringsWithComma([
    option.organisation,
    option.property,
    option.street,
    option.locality,
    option.town,
  ]);
