import { useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";

import { withAppUserPreferences } from "@dpdgroupuk/mydpd-app";
import { Button, Page } from "@dpdgroupuk/mydpd-ui";

import Footer from "../../components/Footer";
import NoResults from "../../components/NoResults";
import {
  DELIVERY_SEARCH,
  GO_TO_SEARCH,
  WE_CANT_FIND,
} from "../../constants/strings";
import { SEARCH } from "../../router";

const NoSearchResultsView = ({ pageTitle, history, appUser: { user } }) => {
  const footer = useMemo(
    () => (
      <Footer>
        <Button onClick={history.goBack}>{GO_TO_SEARCH}</Button>
      </Footer>
    ),
    [history]
  );
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      history.push(SEARCH);
    }, 5000);
    return () => {
      clearTimeout(timeoutId);
    };
  });
  return (
    <Page
      title={pageTitle}
      classNameBody="mx-auto"
      username={user.username}
      footer={footer}
    >
      <NoResults description={WE_CANT_FIND} />
    </Page>
  );
};

NoSearchResultsView.propTypes = {
  ...ReactRouterPropTypes,
  pageTitle: PropTypes.string,
  appUser: PropTypes.object,
};

NoSearchResultsView.defaultProps = {
  pageTitle: DELIVERY_SEARCH,
};

export default withAppUserPreferences(NoSearchResultsView);
