import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { apisClient } from "../../apis";

export const UmsActionTypes = createActionTypes("ums", {
  FETCH_CUSTOMER: createAsyncActionTypes("FETCH_CUSTOMER"),
  FETCH_CUSTOMERS: createAsyncActionTypes("FETCH_CUSTOMERS"),
});

export const fetchCustomer = createAsyncAction(
  () => apisClient.ums.fetchCustomer(),
  UmsActionTypes.FETCH_CUSTOMER
);

export const fetchCustomers = createAsyncAction(
  () => apisClient.ums.fetchCustomers(),
  UmsActionTypes.FETCH_CUSTOMERS
);

export const fetchInitialUmsData = () => dispatch =>
  Promise.all([dispatch(fetchCustomer()), dispatch(fetchCustomers())]);
