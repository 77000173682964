import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import Tooltip from "../../../../components/Tooltip";
import { Fields } from "../../../../constants/forms";
import {
  CONTACT_NAME,
  MAX_100_CHARACTERS,
  MAX_15_CHARACTERS_INCLUDING_SPACES,
  MAX_35_CHARACTERS,
  NOTIFICATION_EMAIL,
  NOTIFICATION_MOBILE,
  TO_RECIVE_NOTIFICATIONS,
} from "../../../../constants/strings";
import {
  instructionValueNormalize,
  phoneNumberNormalize,
} from "../../../../utils/normalize";
import styles from "./ContactDetails.module.scss";

const ContactDetailsFields = ({
  isMobileEmailRequired,
  offValidation = false,
}) => (
  <Col md={12}>
    <Container>
      <Row className={styles.contactContainer}>
        <div className={styles.item}>
          <Field
            name={Fields.CONTACT_NAME}
            component={FormControl.Input}
            label={CONTACT_NAME}
            maxLength={35}
            normalize={instructionValueNormalize}
            helperText={MAX_35_CHARACTERS}
            required={!offValidation}
          />
        </div>
        <div className={styles.item}>
          <Field
            name={Fields.MOBILE}
            component={FormControl.Input}
            label={NOTIFICATION_MOBILE}
            maxLength={15}
            minLength={7}
            helperText={MAX_15_CHARACTERS_INCLUDING_SPACES}
            oneFromSeveral={!offValidation}
            required={!offValidation && isMobileEmailRequired}
            normalize={phoneNumberNormalize}
          />
        </div>
        <div className={styles.tooltipContainer}>
          <div className={classNames(styles.item, styles.lastField)}>
            <Field
              name={Fields.EMAIL}
              component={FormControl.Input}
              label={NOTIFICATION_EMAIL}
              maxLength={100}
              helperText={MAX_100_CHARACTERS}
              oneFromSeveral={!offValidation}
              required={!offValidation && isMobileEmailRequired}
            />
          </div>
          <div>
            <Tooltip message={TO_RECIVE_NOTIFICATIONS} placement="left" />
          </div>
        </div>
      </Row>
    </Container>
  </Col>
);

ContactDetailsFields.propTypes = {
  isMobileEmailRequired: PropTypes.bool,
  offValidation: PropTypes.bool,
};

export default ContactDetailsFields;
