import PropTypes from "prop-types";

export default function ContactDetails({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="284"
      height="284"
      viewBox="0 0 284 284"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.874 166.994C124.806 167.014 124.738 167.032 124.668 167.048L93.6173 174.213C93.3941 174.266 93.1709 174.291 92.9477 174.296C92.9198 174.297 92.8919 174.297 92.864 174.297L92.861 174.297C92.8343 174.297 92.8075 174.297 92.7808 174.296C92.7206 174.295 92.6607 174.292 92.6011 174.287C92.4351 174.275 92.2685 174.25 92.1005 174.213C90.5344 173.853 89.4781 172.46 89.4799 170.921C89.4796 170.632 89.5165 170.349 89.587 170.076L96.7318 139.112C97.1541 137.291 98.9748 136.166 100.782 136.578C102.603 136.997 103.734 138.811 103.316 140.629L98.7983 160.206L100.026 158.978C101.347 157.657 103.482 157.657 104.802 158.978C106.123 160.299 106.123 162.434 104.802 163.755L103.576 164.981L122.208 160.682L219.459 63.4338L200.35 44.3243L114.357 130.317L126.516 142.476C127.837 143.797 127.837 145.932 126.516 147.253C125.858 147.912 124.993 148.243 124.128 148.243C123.263 148.243 122.399 147.912 121.74 147.253L107.191 132.704C105.87 131.383 105.87 129.248 107.191 127.927C107.221 127.897 107.251 127.868 107.282 127.839L200.35 34.7713L229.012 63.4338L126.367 166.076C126.133 166.324 125.861 166.538 125.556 166.707C125.338 166.829 125.109 166.925 124.874 166.994ZM160.794 66.7523C160.794 64.3779 158.869 62.453 156.495 62.453H36.5442V268.82H137.212L172.867 233.166C174.548 231.485 174.548 228.767 172.867 227.086C171.186 225.405 168.468 225.405 166.787 227.086L133.653 260.221H45.1428V71.0516H156.495C158.869 71.0516 160.794 69.1267 160.794 66.7523ZM178.421 123.872C176.047 123.872 174.122 125.797 174.122 128.171V208.629H126.83V247.323C126.83 249.701 128.752 251.623 131.129 251.623C133.507 251.623 135.428 249.701 135.428 247.323V217.228L178.421 217.228L178.421 217.228C179.907 217.228 181.215 216.477 181.987 215.334C182.45 214.648 182.721 213.82 182.721 212.929L182.721 212.896V128.171C182.721 125.797 180.796 123.872 178.421 123.872Z"
        fill="#414042"
      />
      <path
        d="M233.789 57.2554C232.924 57.2554 232.06 56.9244 231.401 56.2656C230.08 54.9448 230.08 52.8099 231.401 51.4891L238.566 44.3243L219.459 25.2148L212.291 32.383C210.971 33.7038 208.836 33.7038 207.515 32.383C206.194 31.0622 206.194 28.9239 207.515 27.6065L219.459 15.6618L248.119 44.3243L236.177 56.2656C235.519 56.9277 234.654 57.2554 233.789 57.2554Z"
        fill={fill}
      />
    </svg>
  );
}

ContactDetails.propTypes = {
  fill: PropTypes.string,
};
