import PropTypes from "prop-types";

export default function Document({ fill = "#DC0032" }) {
  return (
    <svg
      id="Document-1"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 68 68"
      style={{
        enableBackground: "new 0 0 68 68",
      }}
      xmlSpace="preserve"
    >
      <g id="Navigation_x5F_Document_x5F_Form_x5F_blackred_x5F_pos_x5F_rgb">
        <g id="Document_x5F_form_2_">
          <rect id="XMLID_745_" fill="none" width="68" height="68" />
          <path
            fill="#414042"
            d="M40.415,58H17V10h34v35c0,0.553-0.447,1-1,1s-1-0.447-1-1V12H19v44h20.587l7.707-7.707
			c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414L40.415,58z"
          />
          <path
            fill="#414042"
            d="M39,54c-0.553,0-1-0.447-1-1v-9h12c0.553,0,1,0.447,1,1s-0.447,1-1,1H40v7
			C40,53.553,39.553,54,39,54z"
          />
          <path
            fill="#414042"
            d="M44,18H22c-0.552,0-1-0.448-1-1s0.448-1,1-1h22c0.553,0,1,0.448,1,1S44.553,18,44,18z"
          />
          <path
            fill="#414042"
            d="M44,24H22c-0.552,0-1-0.448-1-1s0.448-1,1-1h22c0.553,0,1,0.448,1,1S44.553,24,44,24z"
          />
          <path
            fill={fill}
            d="M38,30H22c-0.552,0-1-0.448-1-1s0.448-1,1-1h16c0.553,0,1,0.448,1,1S38.553,30,38,30z"
          />
          <path
            fill="#414042"
            d="M42,36H22c-0.552,0-1-0.447-1-1c0-0.552,0.448-1,1-1h20c0.553,0,1,0.448,1,1
			C43,35.553,42.553,36,42,36z"
          />
        </g>
      </g>
      <g id="Layer_1" />
    </svg>
  );
}

Document.propTypes = {
  fill: PropTypes.string,
};
