import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import { Button, Icon, Modal } from "@dpdgroupuk/mydpd-ui";

import * as M from "../../constants/strings";
import styles from "./WarningModal.module.scss";

const TooltipModal = ({ show, close, message, header, closeText }) => (
  <Modal
    show={show}
    dialogClassName={classNames(styles.modal)}
    bsPrefix="modal"
  >
    <Modal.Body>
      <Container fluid className={styles.container}>
        <Row>
          <Col className="col-2">
            <Icon.Warning className={styles.icon} color="#FE9400" />
          </Col>
          <Col className={styles.content}>
            <Row className={styles.header}>{header ?? M.WARNING}</Row>
            <Row className={styles.message}>{message}</Row>
          </Col>
        </Row>
      </Container>
    </Modal.Body>
    <Modal.Footer className={styles.footer}>
      <Button onClick={close}>{closeText ?? M.OK}</Button>
    </Modal.Footer>
  </Modal>
);

TooltipModal.propTypes = {
  show: PropTypes.bool,
  open: PropTypes.func,
  close: PropTypes.func,
  message: PropTypes.string,
  header: PropTypes.string,
  closeText: PropTypes.string,
};

export default TooltipModal;
