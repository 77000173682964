import { useEffect, useMemo, useRef } from "react";

import { get, isEmpty } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, lifecycle, withProps, withState } from "recompose";
import { Field, propTypes as ReduxFormPropTypes } from "redux-form";

import { withAppLoader, withNotifier } from "@dpdgroupuk/mydpd-app";
import { Card, withPrompt, withSnackbar } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { withParcelStatus } from "../../../../components/AuthUser/withParcelStatus";
import CardWithTitle from "../../../../components/CardWithTitle";
import Carousel from "../../../../components/Carousel/Carousel";
import Delimiter from "../../../../components/Delimiter";
import InputWithTitle from "../../../../components/InputWithTitle";
import WarningModal from "../../../../components/WarningModal";
import { COLLECT_FROM_PICKUP_SHOP } from "../../../../constants/analytics";
import { DEFAULT_DATE_FORMAT } from "../../../../constants/dateFormats";
import { CollectFromPickupShop as Fields } from "../../../../constants/forms";
import {
  CONTACT_DETAILS,
  NO_PICKUP_SHOPS_FOR_POSTCODE,
  PLEASE_SELECT_PICKUP_SHOP,
} from "../../../../constants/strings";
import { getCollectionTimeFromPickupShop } from "../../../../models/parcel";
import { deliveryEditValidators } from "../../../../models/validators";
import {
  clearDeliveryEditData,
  fetchPickupLocationShop,
  onCollectFromPickupShop,
  onSelectPickupShop,
} from "../../actions";
import EditPanel from "../../components/EditPanel";
import { copyTextToClipboard } from "../../helpers";
import { hadleInputFocus, withParcelEdit } from "../../hocs";
import {
  getParcelFormState,
  getPickupShops,
  getSelectedPickupShop,
} from "../../selectors";
import styles from "./CollectFromPickupShop.module.scss";
import CarouselItem from "./components/CarouselItem";
import { PromptMessage } from "./components/PromptInner";
import ShopDetails from "./components/ShopDetails";

import "./Slider.scss";

const CollectFromPickupShop = ({
  handleSubmit,
  onSubmit,
  error,
  parcel,
  pickupShops,
  selectedPickupShop,
  onSelect,
  onClickCopyAddress,
  onClickPrev,
  onClickNext,
  initialize,
  formState,
  showWarningModal,
  setShowWarningModal,
  snackbar,
  onGoBackClick,
  ...rest
}) => {
  useEffect(() => {
    const pickupLocationCode = get(
      selectedPickupShop,
      "pickupLocation.pickupLocationCode"
    );
    const pickupDate = moment(get(selectedPickupShop, "pickupDate")).format(
      DEFAULT_DATE_FORMAT
    );
    formState &&
      initialize({
        ...formState.values,
        pickupLocationCode,
        pickupDate,
      });
    // eslint-disable-next-line
  }, [selectedPickupShop]);

  const showError = useMemo(() => {
    const pickupLocationCode = get(formState, "syncErrors.pickupLocationCode");
    const pickupDate = get(formState, "syncErrors.pickupDate");
    const submitFailed = get(formState, "submitFailed");
    return submitFailed && (!!pickupLocationCode || !!pickupDate);
  }, [formState]);

  const carouselRef = useRef(null);

  const detailedShop = isEmpty(selectedPickupShop)
    ? pickupShops[0]
    : selectedPickupShop;

  return detailedShop ? (
    <>
      <Card>
        <ShopDetails
          shop={detailedShop}
          onSelect={onSelect}
          onClickCopyAddress={copyTextToClipboard(snackbar, onClickCopyAddress)}
          carousel={carouselRef}
        />
      </Card>
      <Delimiter />
      <Card>
        <Carousel
          ref={carouselRef}
          onNextStart={onClickNext}
          onPrevStart={onClickPrev}
          className={"mydpd-slider-container"}
          CarouselItem={CarouselItem}
          items={pickupShops}
          onClickCarouselItem={onSelect}
          carouselItemProps={{
            parcel,
            selectedPickupShop,
          }}
        />
        {showError && (
          <span className={styles.error}>{PLEASE_SELECT_PICKUP_SHOP}</span>
        )}
      </Card>
      <Delimiter />
      <CardWithTitle title={CONTACT_DETAILS}>
        <EditPanel onSubmit={handleSubmit(onSubmit)} error={error} {...rest}>
          <div className={styles.hiddenFields}>
            <Field
              component={InputWithTitle}
              name={Fields.PICKUP_LOCATION_CODE}
              autoFocus={false}
              hideErrors={true}
            />
            <Field
              component={InputWithTitle}
              name={Fields.PICKUP_DATE}
              autoFocus={false}
              hideErrors={true}
            />
          </div>
        </EditPanel>
      </CardWithTitle>
    </>
  ) : (
    <WarningModal
      show={showWarningModal}
      close={() => {
        setShowWarningModal(false);
        onGoBackClick();
      }}
      message={NO_PICKUP_SHOPS_FOR_POSTCODE}
    />
  );
};

CollectFromPickupShop.defaultProps = {
  onSubmit: () => null,
};

CollectFromPickupShop.propTypes = {
  onGoBackClick: PropTypes.func,
  ...ReduxFormPropTypes,
};

export default compose(
  connect(
    state => ({
      pickupShops: getPickupShops(state),
      selectedPickupShop: getSelectedPickupShop(state),
      formState: getParcelFormState(state),
    }),
    dispatch => ({
      onSelect: shop => dispatch(onSelectPickupShop(shop)),
      clearData: shop => dispatch(clearDeliveryEditData(shop)),
    })
  ),
  withParcelStatus,
  withProps(props => ({
    onSelect: selectedPickupShop => {
      props.onSelect(selectedPickupShop);
    },
  })),
  withPrompt,
  withNotifier,
  withSnackbar,
  withState("showWarningModal", "setShowWarningModal", false),
  withAppLoader(async ({ match, dispatch, setShowWarningModal }, options) => {
    const result = await dispatch(
      fetchPickupLocationShop(match.params.parcelCode, null, options)
    );
    if (result.data.length === 0) {
      setShowWarningModal(true);
    } else {
      return result.data;
    }
  }),
  withProps(({ prompt, selectedPickupShop }) => {
    const pickupLocationCode = get(
      selectedPickupShop,
      "pickupLocation.pickupLocationCode"
    );
    const pickupDate = get(selectedPickupShop, "pickupDate");
    const collectionTime = getCollectionTimeFromPickupShop(selectedPickupShop);
    return {
      additionalInitialValues: {
        pickupLocationCode,
        pickupDate,
      },
      collectionTime,
      beforeSubmitProps: {
        prompt,
        selectedPickupShop,
      },
    };
  }),
  withParcelEdit({
    onSubmit: (parcelCode, values) => {
      const { email, mobile, contactName, pickupLocationCode, pickupDate } =
        values;
      return onCollectFromPickupShop(parcelCode, {
        pickupLocationCode,
        pickupDate,
        notificationDetails: {
          email,
          mobile,
          contactName,
        },
      });
    },
    validate: deliveryEditValidators.collectFromPickupShop,
  }),
  withProps(({ onSubmit, prompt, notifier, initialize }) => ({
    onSubmit: notifier.runAsync(
      async (values, dispatch, props) =>
        new Promise(resolve =>
          prompt.showConfirmation({
            message: (
              <PromptMessage
                {...props.selectedPickupShop}
                collectionTime={props.collectionTime}
              />
            ),
            onConfirm: () => resolve(onSubmit(values, dispatch, props)),
            onReject: () => initialize(values),
          })
        )
    ),
  })),
  withTrack(COLLECT_FROM_PICKUP_SHOP.LOAD),
  withTrackProps({
    onSubmit: COLLECT_FROM_PICKUP_SHOP.CLICK_SUBMIT,
    onSelect: COLLECT_FROM_PICKUP_SHOP.CLICK_PICKUP_SHOP,
    onClickPrev: COLLECT_FROM_PICKUP_SHOP.CLICK_PREVIOUS,
    onClickNext: COLLECT_FROM_PICKUP_SHOP.CLICK_NEXT,
    onClickCopyAddress: COLLECT_FROM_PICKUP_SHOP.CLICK_COPY_ADDRESS,
    onClickOnMapPoint: COLLECT_FROM_PICKUP_SHOP.CLICK_PICKUP_SHOP_MAP_POINT,
    onInputFocus: fieldName => {
      switch (fieldName) {
        case Fields.CONTACT_NAME:
          return COLLECT_FROM_PICKUP_SHOP.CONTACT_NAME_INPUT;
        case Fields.MOBILE:
          return COLLECT_FROM_PICKUP_SHOP.MOBILE_NUMBER_INPUT;
        case Fields.EMAIL:
          return COLLECT_FROM_PICKUP_SHOP.EMAIL_ADDRESS_INPUT;
        default:
          break;
      }
    },
  }),
  hadleInputFocus,
  lifecycle({
    componentWillUnmount() {
      this.props.clearData();
      this.props.changeParcelStatus(this.props.parcel.parcelStatusHtml);
    },
  })
)(CollectFromPickupShop);
