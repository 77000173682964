import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { getAddresses } from "../../apis/location";

export const ActionTypes = createActionTypes("ADDRESS_LOOKUP", {
  SEARCH: createAsyncActionTypes("SEARCH"),
});

export const search = createAsyncAction(
  (data, options) =>
    getAddresses(
      {
        pageSize: 100,
        ...data,
      },
      options
    ).then(({ data }) => data),
  ActionTypes.SEARCH
);
