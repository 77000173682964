import { useEffect, useState } from "react";

import { noop } from "lodash";
import PropTypes from "prop-types";

import ParcelStatusContext from "./ParcelStatusContext";

const ParcelStatusProvider = ({ children, parcelStatus }) => {
  const [parcelStatusText, setParcelStatusText] = useState(parcelStatus);

  useEffect(() => {
    setParcelStatusText(parcelStatus);
  }, [parcelStatus]);

  return (
    <ParcelStatusContext.Provider
      value={{ parcelStatusText, changeParcelStatus: setParcelStatusText }}
    >
      {children}
    </ParcelStatusContext.Provider>
  );
};

ParcelStatusProvider.defaultProps = {
  onStatusChange: noop,
  parcelStatusText: "",
};

ParcelStatusProvider.propTypes = {
  children: PropTypes.node,
  parcelStatus: PropTypes.string,
  onStatusChange: PropTypes.func,
};

export default ParcelStatusProvider;
