import { useMemo, useRef } from "react";

import PropTypes from "prop-types";
import { compose } from "recompose";

import {
  Paginator,
  DndTable as Table,
  DndPaginator as TablePaginator,
} from "@dpdgroupuk/mydpd-ui";

import withRelatedHandlers from "./withRelatedHandlers";

const renderStatus = ({
  row: {
    original: { parcelStatusHtml },
  },
}) => (
  <span
    dangerouslySetInnerHTML={{
      __html: parcelStatusHtml,
    }}
  />
);

const renderParcelNumber = ({
  row: {
    original: { parcelCode, parcelNumber },
  },
}) => (
  <a
    target="_blank"
    href={`${window.location.origin}/parcels/${parcelCode}`}
    rel="noreferrer"
  >
    {parcelNumber}
  </a>
);

const columns = [
  {
    Header: "Parcel No",
    accessor: "parcelNumber",
    Cell: renderParcelNumber,
    width: 60,
  },
  {
    Header: "Status",
    Cell: renderStatus,
    width: 60,
  },
];

const RelatedParcelsList = ({
  relatedParcels,
  onOpen,
  page,
  totalCount,
  pageSize,
  onPrevious,
  onNext,
  onFirst,
  onLast,
}) => {
  // eslint-disable-next-line
  const memoColumns = useMemo(() => columns, [columns]);
  const tableRef = useRef();

  return relatedParcels.length > 0 ? (
    <Table
      tableRef={tableRef}
      columns={memoColumns}
      data={relatedParcels}
      onClickRow={onOpen}
    >
      <TablePaginator
        tableRef={tableRef}
        page={page}
        totalCount={totalCount}
        pageSize={pageSize}
        onPrevious={onPrevious}
        onNext={onNext}
        onFirst={onFirst}
        onLast={onLast}
      />
    </Table>
  ) : null;
};

RelatedParcelsList.propTypes = {
  parcelCode: PropTypes.string,
  relatedParcels: PropTypes.array,
  onOpen: PropTypes.func,
  ...Paginator.propTypes,
};

export default compose(withRelatedHandlers)(RelatedParcelsList);
