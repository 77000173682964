import { useEffect, useMemo } from "react";
import json2csv from "json2csv";
import PropTypes from "prop-types";
import { Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useFlexLayout } from "react-table";
import { compose } from "recompose";

import { Card, DndTable as Table, Button } from "@dpdgroupuk/mydpd-ui";

import { COUNTY, DELAY, POSTCODES } from "../../../../constants/strings";
import {
  capitalizeEachWord,
  joinStringsWithComma,
} from "../../../../utils/string";
import { getServiceDisruptionSelector } from "../../selectors";
import styles from "./Issues.module.scss";

export const columns = [
  {
    Header: COUNTY,
    accessor: "county",
  },
  {
    Header: DELAY,
    accessor: "delay",
  },
  {
    Header: POSTCODES,
    Cell: ({
      row: {
        original: { postcodes },
      },
    }) => joinStringsWithComma(postcodes),
  },
];

const Issues = ({ serviceDisruption }) => {
  // eslint-disable-next-line
  const memoColumns = useMemo(() => columns, [columns]);

  useEffect(() => {
    const tableRows = document.querySelectorAll("tr");
    for (const tableRow of tableRows) {
      let html = tableRow.innerHTML;
      html = html.replace(/\*/g, '<span class="asterisk">* </span>');
      tableRow.innerHTML = html;

      const asteriskElements = tableRow.querySelectorAll(".asterisk");
      for (const asteriskElement of asteriskElements) {
        asteriskElement.style.color = "red";
      }
    }
  }, [serviceDisruption]);

  const columnMapping = {
    issueName: "Issue Name",
    county: "County",
    delay: "Delay in days from today",
    postcodes: "Postcodes",
  };

  const headers = Object.values(columnMapping);

  const exportToCsv = (headers, data, filename) => {
    const flatData = [];

    data.forEach(item => {
      item.issues.forEach(issue => {
        flatData.push({
          [columnMapping.issueName]: capitalizeEachWord(item.issueName),
          [columnMapping.county]: issue.county,
          [columnMapping.delay]: issue.delay,
          [columnMapping.postcodes]: issue.postcodes.join(", "),
        });
      });
    });

    const csv = json2csv.parse(flatData, { fields: headers });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${filename}.csv`;
    link.click();
  };

  return (
    <>
      {serviceDisruption.map((service, index) => (
        <Container key={index} className={"p-0"}>
          <Col className="w-100 pl-0">
            <Card.StepTitle
              className={styles.title}
              title={service.issueName}
              textClassName={styles.issueName}
            />
          </Col>
          <Table
            columns={memoColumns}
            data={service.issues}
            tableHooks={[useFlexLayout]}
          />
        </Container>
      ))}
      {serviceDisruption.length > 0 && (
        <div className={styles.extractPostcodes}>
          <Button
            onClick={() =>
              exportToCsv(headers, serviceDisruption, "service_disruption")
            }
          >
            Extract
          </Button>
        </div>
      )}
    </>
  );
};

Issues.propTypes = {
  serviceDisruption: PropTypes.array,
};

export default compose(
  connect(state => ({
    serviceDisruption: getServiceDisruptionSelector(state),
  }))
)(Issues);
