import { useRef } from "react";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import { Button } from "@dpdgroupuk/mydpd-ui";

import { Car, Parking, WheelChair } from "../../../../../../components/Icon";
import {
  COPY_ADDRESS,
  LATE,
  SAT,
  SUN,
} from "../../../../../../constants/strings";
import { joinStringsWithComma } from "../../../../../../utils/string";
import PickupShopDetail from "../PickupShopDetail";
import PickupShopsMap from "../PickupShopsMap/PickupShopsMap";
import Timetable from "../Timetable";
import styles from "./ShopDetails.module.scss";

const ShopDetails = ({
  shop: { distance, pickupLocation },
  onClickCopyAddress,
  onSelect,
  carousel,
}) => {
  const mapRef = useRef();
  let times = get(
    pickupLocation,
    "pickupLocationAvailability.pickupLocationOpenWindow",
    []
  );
  times = Array.isArray(times) ? times : [times];
  const organisation = get(pickupLocation, "address.organisation");
  const street = get(pickupLocation, "address.street");
  const town = get(pickupLocation, "address.town");
  const postcode = get(pickupLocation, "address.postcode");
  const openAvailability = {
    late: get(pickupLocation, "openLate"),
    sat: get(pickupLocation, "openSaturday"),
    sun: get(pickupLocation, "openSunday"),
  };
  const parkingAvailable = get(pickupLocation, "parkingAvailable");
  const disabledAccess = get(pickupLocation, "disabledAccess");
  const fullAddress = joinStringsWithComma([
    street && street,
    town && town,
    postcode && postcode,
  ]);
  const title = `${organisation}`.concat(town ? ` - ${town}` : "");

  return (
    <Container>
      <Row>
        <Col className="col-8">
          <PickupShopsMap
            mapRef={mapRef}
            carousel={carousel}
            onSelect={onSelect}
          />
        </Col>
        <Col className="col-4 pb-3">
          <div className={styles.pickupShopDetails}>
            <Row className={styles.title}>{title}</Row>
            <Row className={styles.location}>
              <Col className={styles.addressContainer}>
                <PickupShopDetail
                  icon={Car}
                  text={`${parseFloat(distance).toFixed(2)} miles`}
                  visible={!!distance}
                />
                <div>{street}</div>
                <div>{town}</div>
                <div>{postcode}</div>
                <div className={styles.copyToClipBoard}>
                  <Button onClick={() => onClickCopyAddress(fullAddress)}>
                    {COPY_ADDRESS}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <div className={styles.separator} />
          <div className={styles.details}>
            <Timetable data={times} />
          </div>
          <Row className={"d-flex justify-content-around mt-3"}>
            <PickupShopDetail icon={Parking} visible={parkingAvailable} />
            <PickupShopDetail icon={WheelChair} visible={disabledAccess} />
            <PickupShopDetail text={LATE} visible={openAvailability.late} />
            <PickupShopDetail text={SAT} visible={openAvailability.sat} />
            <PickupShopDetail text={SUN} visible={openAvailability.sun} />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

ShopDetails.propTypes = {
  distance: PropTypes.number,
  pickupDate: PropTypes.string,
  pickupLocation: PropTypes.object,
  onClickCopyAddress: PropTypes.func,
  onSelect: PropTypes.func,
  shop: PropTypes.object,
  carousel: PropTypes.object,
};

export default ShopDetails;
