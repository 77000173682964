import PropTypes from "prop-types";

export default function AlternateAddress({ fill = "#DC0032" }) {
  return (
    <svg viewBox="0 0 50 40" fill={fill} id="alternateAddress">
      <path
        d="M25,2.2L46.1,21h-6.3v16.8H27.1V25.2h-4.2v12.6H10.3V21H3.9L25,2.2z M25,7.8 l-10.5,9.4v16.4h4.2V21h12.6v12.6h4.2V17.3L25,7.8z"
        fill="#808285"
      />
      <polygon points="19.1,37.8 23.4,37.8 23.4,24.9 27.7,24.9 27.7,37.8 32,37.8 32,20.5 19.1,20.5" />
    </svg>
  );
}

AlternateAddress.propTypes = {
  fill: PropTypes.string,
};
