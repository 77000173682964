import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";

import { Button, Main } from "@dpdgroupuk/mydpd-ui";

import NoResults from "../../components/NoResults";
import { BACK } from "../../constants/strings";

const NoResultsView = ({ pageTitle, history }) => (
  <Main>
    <Main.Body className="mx-auto">
      <NoResults
        pageTitle={pageTitle}
        description={`You have no parcels in your ${pageTitle}`}
      />
    </Main.Body>
    <Main.Footer className="dark">
      <Button.Toolbar>
        <Button onClick={history.goBack}>{BACK}</Button>
      </Button.Toolbar>
    </Main.Footer>
  </Main>
);

NoResultsView.propTypes = {
  ...ReactRouterPropTypes,
  pageTitle: PropTypes.string,
};

NoResultsView.defaultProps = {
  pageTitle: "Delivery Search",
};

export default NoResultsView;
