import { compose, withProps, withState } from "recompose";
import { connect } from "react-redux";
import { getDatesRange, isAvailableDate } from "../pages/EditDelivery/actions";

export default compose(
  withState("isDatePickerOpen", "setIsDatePickerOpen", false),
  withProps(({ isDatePickerOpen }) => ({
    preventDatePickerBlur: e => {
      if (isDatePickerOpen) {
        e.preventDefault();
      }
    },
  })),
  connect(null, dispatch => ({
    filterDate: date => dispatch(isAvailableDate(date)),
    getDatesRange: () => dispatch(getDatesRange()),
  }))
);
