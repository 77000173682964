import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { FallBackImage, useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";
import { withTrack } from "@dpdgroupuk/react-event-tracker";

import { useParcelStatus } from "../../../../components/AuthUser/useParcelStatus";
import { withParcelStatus } from "../../../../components/AuthUser/withParcelStatus";
import { PARCEL_STATUS } from "../../../../constants/analytics";
import styles from "./ParcelStatus.module.scss";

const ParcelStatus = ({ className, parcel, children }) => {
  const { logoImg } = useMyDpdTheme();
  const { parcelStatusText } = useParcelStatus();
  return (
    <Row className={classNames(styles.container, className)}>
      <Col
        className={classNames(
          styles.infoContainer,
          "flex-wrap",
          "flex-md-nowrap"
        )}
      >
        <Col className={styles.status}>
          <Row className={styles.trackingStatusText}>
            {parcelStatusText && (
              <span
                dangerouslySetInnerHTML={{
                  __html: parcelStatusText,
                }}
              />
            )}
          </Row>
          <Row>{children}</Row>
        </Col>
        <Col className={styles.customerLogo}>
          <FallBackImage
            src={parcel.customerImageLogo || logoImg}
            fallBackSrc={logoImg}
            alt={parcel.shipperDetails.customerDisplayName}
            className={styles.customerLogoImg}
          />
        </Col>
      </Col>
    </Row>
  );
};

ParcelStatus.propTypes = {
  className: PropTypes.string,
  parcel: PropTypes.object,
  children: PropTypes.node,
};

export default withParcelStatus(withTrack(PARCEL_STATUS.LOAD)(ParcelStatus));
