import { ENTER_VALID_EMAIL } from "../../constants/strings";
import { EMAIL } from "./regex";

// eslint-disable-next-line import/no-anonymous-default-export
export default values => {
  const errors = {};

  if (!values.email) {
    errors.email = "Please enter Email";
  } else if (!EMAIL.test(values.email)) {
    errors.email = ENTER_VALID_EMAIL;
  }

  if (!values.subject) {
    errors.subject = "Please enter Subject";
  }

  if (!values.message) {
    errors.message = "Please enter Message";
  }

  return errors;
};
