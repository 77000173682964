import PropTypes from "prop-types";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { DELIVERY_IMAGE_VIEW } from "../../../../constants/analytics";
import ImageView from "./ImageView";
import SendEmailForm from "./SendEmailForm";

const ParcelImageView = ({ parcel, ...rest }) => (
  <div>
    <ImageView {...rest}>
      <SendEmailForm parcel={parcel} {...rest} />
    </ImageView>
  </div>
);

ParcelImageView.propTypes = {
  parcel: PropTypes.object,
};

export default withTrackProps({
  onShow: DELIVERY_IMAGE_VIEW.LOAD,
  onClose: DELIVERY_IMAGE_VIEW.CLOSE_IMAGE,
  onImageSelect: (activeIndex, event) => {
    const className = event.target.className;
    if (className.includes("next")) {
      return DELIVERY_IMAGE_VIEW.CLICK_NEXT;
    } else {
      return DELIVERY_IMAGE_VIEW.CLICK_PREV;
    }
  },
})(ParcelImageView);
