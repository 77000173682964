import { getFormValues, initialize } from "redux-form";

import { Fields, SEARCH_FORM } from "../../constants/forms";
import { ALL } from "../../constants/strings";
import { DeliveriesActions } from "../../redux/deliveries";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../constants/dateFormats";
import { getValue } from "../../utils/object";

const dateFields = [
  Fields.FROM_DATE,
  Fields.TO_DATE,
  Fields.CREATE_START_DATE,
  Fields.CREATE_END_DATE,
  Fields.COLLECTION_START_DATE,
  Fields.COLLECTION_END_DATE,
];
const subFields = [
  Fields.POSTCODE_EMAIL,
  Fields.POSTCODE_PHONE,
  Fields.POSTCODE_DATE,
  Fields.POSTCODE_CUSTOMER_REFERENCE,
];
const fields = [
  Fields.REFERENCE,
  Fields.DELIVERY_REFERENCE,
  Fields.COLLECTION_CODE,
  Fields.CASE_CODE,
  Fields.EMAIL,
  Fields.PHONE,
  Fields.POSTCODE,
];

export const initializeForm =
  (account = ALL) =>
  dispatch => {
    dispatch(
      initialize(SEARCH_FORM, {
        account,
      })
    );
  };

export const reinitializeForm = data => (dispatch, getState) => {
  const formData = getFormValues(SEARCH_FORM)(getState());

  if (!formData) return;

  data.forEach(field => {
    formData[field] = undefined;
  });
  dispatch(initialize(SEARCH_FORM, formData));
};

export const onCollectionDateChange = () => dispatch => {
  dispatch(reinitializeForm([...subFields, ...dateFields, "subtype"]));
};

export const onSubTypeChange = () => dispatch => {
  dispatch(reinitializeForm([...fields, ...subFields]));
};

export const onStartDateChange = (event, newValue) => dispatch => {
  if (!newValue) {
    dispatch(reinitializeForm([...subFields, "subtype"]));
  }
};

export const searchDeliveries = DeliveriesActions.findParcels;

export const getEndDatesRange = () => (dispatch, getState) => {
  const formValues = getFormValues(SEARCH_FORM)(getState());
  const startDate = getValue(formValues, Fields.FROM_DATE);

  return {
    minDate: startDate
      ? moment(startDate, DEFAULT_DATE_FORMAT).toDate()
      : startDate,
    maxDate: startDate
      ? moment(startDate, DEFAULT_DATE_FORMAT).add(6, "d").toDate()
      : startDate,
  };
};
