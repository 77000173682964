import PropTypes from "prop-types";

const Porch = ({ fill = "#DC0032" }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2646 13.4609C21.2646 13.1089 21.55 12.8236 21.9019 12.8236H26.8039C27.1558 12.8236 27.4411 13.1089 27.4411 13.4609V18.3628C27.4411 18.7148 27.1558 19.0001 26.8039 19.0001H21.9019C21.55 19.0001 21.2646 18.7148 21.2646 18.3628V13.4609ZM26.1666 14.0981H22.5392V17.7256H26.1666V14.0981Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4607 7.03079C11.4607 5.36883 12.8378 4 14.5489 4H34.1568C35.8679 4 37.245 5.36883 37.245 7.03079V33.15L37.6393 33.2877C38.8424 33.7077 39.696 34.8346 39.696 36.1438V37.9754L40.0903 38.113C41.2934 38.5331 42.147 39.66 42.147 40.9692V43.3819C42.147 43.7146 41.8704 44 41.5097 44H7.19597C6.83528 44 6.55872 43.7146 6.55872 43.3819V40.9692C6.55872 39.66 7.41229 38.5331 8.61535 38.113L9.0097 37.9754V36.1438C9.0097 34.8346 9.86327 33.7077 11.0663 33.2877L11.4607 33.15V7.03079ZM14.5489 5.23621C13.5571 5.23621 12.7352 6.03204 12.7352 7.03079V33.1131H16.3626V9.44348C16.3626 9.11083 16.6392 8.82537 16.9999 8.82537H31.7058C32.0665 8.82537 32.343 9.11082 32.343 9.44348V33.1131H35.9705V7.03079C35.9705 6.03204 35.1486 5.23621 34.1568 5.23621H14.5489ZM31.0685 10.0616H17.6372V33.1131H31.0685V24.5377H29.2548C28.8941 24.5377 28.6175 24.2522 28.6175 23.9196C28.6175 23.5869 28.8941 23.3015 29.2548 23.3015H31.0685V10.0616ZM12.0979 34.3493C11.1061 34.3493 10.2842 35.1451 10.2842 36.1438V37.9384H19.0545L20.8776 34.3493H12.0979ZM26.4074 34.3493H22.2982L18.0242 42.7638H30.6815L26.4074 34.3493ZM36.6077 34.3493H27.8281L29.6512 37.9384H38.4215V36.1438C38.4215 35.1451 37.5996 34.3493 36.6077 34.3493ZM9.64695 39.1746C8.65511 39.1746 7.83323 39.9705 7.83323 40.9692V42.7638H16.6035L18.4266 39.1746H9.64695ZM39.0587 39.1746H30.2791L32.1022 42.7638H40.8725V40.9692C40.8725 39.9705 40.0506 39.1746 39.0587 39.1746Z"
      fill="#414042"
    />
  </svg>
);

Porch.propTypes = {
  fill: PropTypes.string,
};

export default Porch;
