import { connect } from "react-redux";
import { compose } from "recompose";

import { withAppLoader } from "@dpdgroupuk/mydpd-app";

import { fetchNeighbourAddresses } from "../actions";
import { getNeighbourAddress } from "../selectors";

export const loadNeighbourAddresses = compose(
  connect(),
  withAppLoader(({ parcel, dispatch }, options) =>
    dispatch(fetchNeighbourAddresses(parcel.deliveryDetails.address, options))
  ),
  connect(state => ({
    address: getNeighbourAddress(state),
  }))
);
