import PropTypes from "prop-types";

import { ParcelTypes } from "../../../models/types";
import AdditionalInformation from "../components/AdditionalInformation";
import HeaderWithDetails from "../components/HeaderWithDetails";
import ParcelHistory from "../components/ParcelHistory";
import RelatedParcels from "../components/RelatedParcels";

const GeneralParcelView = ({
  parcel,
  children,
  onClickDeliveryHistory,
  onClickConsignment,
  onClickAdditionalInformation,
  isRelatedParcelsAvailable,
}) => (
  <>
    <HeaderWithDetails parcel={parcel} />
    {children}
    <ParcelHistory data={parcel.parcelEvents} onOpen={onClickDeliveryHistory} />
    <RelatedParcels
      parcelCode={parcel.parcelCode}
      onOpen={onClickConsignment}
      isRelatedParcelsAvailable={isRelatedParcelsAvailable}
    />
    <AdditionalInformation
      parcel={parcel}
      onOpen={onClickAdditionalInformation}
    />
  </>
);

GeneralParcelView.propTypes = {
  parcel: PropTypes.shape(ParcelTypes.Parcel),
  children: PropTypes.node,
  onClickDeliveryHistory: PropTypes.func,
  onClickConsignment: PropTypes.func,
  onClickAdditionalInformation: PropTypes.func,
  isRelatedParcelsAvailable: PropTypes.bool,
};

export default GeneralParcelView;
