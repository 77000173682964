import { capitalize } from "lodash";
import fp from "lodash/fp";

export const onlyStrings = fp.compose(
  fp.filter(fp.isString),
  fp.filter(fp.negate(fp.isEmpty))
);

export const joinStringsWithComma = fp.compose(fp.join(", "), onlyStrings);

export const replaceLinebreak = (str, replaceWith = " ") =>
  str.replace(/(?:\r\n|\r|\n)/g, replaceWith);

export const capitalizeEachWord = str =>
  str.split(" ").map(capitalize).join(" ");

export const removeSpaces = (str = "") => str.split(" ").join("");

export const formatMessage = (template, ...args) =>
  template.replace(/{(\d)}/g, (s, num) => args[num]);
