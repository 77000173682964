import { get, set } from "lodash";
import { initialize } from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { deliveriesApi, locationApi, messageApi, pickupsApi } from "../../apis";
import {
  SEND_EMAIL_FIELDS as Fields,
  SEND_EMAIL_FORM,
} from "../../constants/forms";
import { pageToPos } from "../../models/parcel";
import { phoneNumberNormalize } from "../../utils/normalize";
import { joinStringsWithComma } from "../../utils/string";
import { getParcel } from "./selectors";

export const ActionTypes = createActionTypes("PARCEL_VIEW", {
  GET_PARCEL: createAsyncActionTypes("GET_PARCEL"),
  GET_DEPOT: createAsyncActionTypes("GET_DEPOT"),
  GET_PICKUP_LOCATION: createAsyncActionTypes("GET_PICKUP_LOCATION"),
  GET_PARCEL_ACTIONS: createAsyncActionTypes("GET_PARCEL_ACTIONS"),
  GET_RELATED_PARCELS: createAsyncActionTypes("GET_RELATED_PARCELS"),
  ADD_TO_WATCH_LIST: createAsyncActionTypes("ADD_TO_WATCH_LIST"),
  SEND_EMAIL: createAsyncActionTypes("SEND_EMAIL"),
  REMOVE_FROM_WATCH_LIST: createAsyncActionTypes("REMOVE_FROM_WATCH_LIST"),
  DISPOSE_REQUEST: createAsyncActionTypes("DISPOSE_REQUEST"),
  GET_ROUTE: createAsyncActionTypes("GET_ROUTE"),
  GET_DRIVER: createAsyncActionTypes("GET_DRIVER"),
  CLEAR_PARCEL_VIEW_DATA: "CLEAR_PARCEL_VIEW_DATA",
});

export const clearParcelData = () => ({
  type: ActionTypes.CLEAR_PARCEL_VIEW_DATA,
});

export const fetchParcel = createAsyncAction(
  (parcelCode, options) =>
    deliveriesApi.getParcel(parcelCode, options).then(res => {
      const fieldPath = "deliveryDetails.notificationDetails.mobile";
      set(
        res.data,
        fieldPath,
        phoneNumberNormalize(get(res.data, fieldPath, ""))
      );

      return res;
    }),
  ActionTypes.GET_PARCEL
);

export const fetchParcelActions = createAsyncAction(
  deliveriesApi.getParcelActions,
  ActionTypes.GET_PARCEL_ACTIONS
);

export const fetchRelatedParcels = createAsyncAction(
  // it will change when redback team fix bug
  async (parcelCode, { page, pageSize } = {}, options) => {
    try {
      return await deliveriesApi.getRelatedParcels(
        parcelCode,
        pageToPos(page, pageSize),
        options
      );
    } catch (e) {
      if (!e.message || !e.message.includes("CONS")) {
        throw e;
      } else {
        return { data: { findByTotal: 0, parcels: [] } };
      }
    }
  },
  ActionTypes.GET_RELATED_PARCELS
);

export const addToWatchList = createAsyncAction(
  deliveriesApi.addParcelToWatchList,
  ActionTypes.ADD_TO_WATCH_LIST
);

export const removeFromWatchList = createAsyncAction(
  deliveriesApi.removeParcelFromWatchList,
  ActionTypes.REMOVE_FROM_WATCH_LIST
);
export const sendProofOfDelivery = createAsyncAction(
  (values, images, parcelCode) => {
    const data = {
      email: values.email,
      message: values.message,
      subject: values.subject,
      parcelCode,
      attachments: values.attachments,
    };
    return messageApi.sendProofOfDelivery(data);
  },
  ActionTypes.SEND_EMAIL
);

const getReference = parcel => {
  const ref = [];
  if (get(parcel.shipperDetails, "shippingRef1")) {
    ref.push(get(parcel.shipperDetails, "shippingRef1"));
  }
  if (get(parcel.shipperDetails, "shippingRef2")) {
    ref.push(get(parcel.shipperDetails, "shippingRef2"));
  }
  if (get(parcel.shipperDetails, "shippingRef3")) {
    ref.push(get(parcel.shipperDetails, "shippingRef3"));
  }
  return ref.length ? ref.join(", ") : "";
};

export const initializeEmailForm = () => (dispatch, getState) => {
  const parcel = getParcel(getState());
  const values = {
    [Fields.SUBJECT]: `Delivery Signature (${parcel.parcelNumber})`,
    [Fields.MESSAGE]: `    Parcel Number: ${parcel.parcelNumber}
    Collection Date: ${parcel.collectionDate}
    Customer Ref: ${getReference(parcel)}
    Service: ${parcel.service.serviceDescription || ""}
    Consignment: ${parcel.consignmentNumber} (${
      parcel.deliveryDetails.numberOfParcels
    })
    Address: ${joinStringsWithComma([
      parcel.deliveryDetails.address.organisation,
      parcel.deliveryDetails.address.street,
      parcel.deliveryDetails.address.town,
      parcel.deliveryDetails.address.county,
      parcel.deliveryDetails.address.postcode,
    ])}
    Email Address: ${parcel.deliveryDetails.notificationDetails.email || ""}
    Phone Number: ${parcel.deliveryDetails.notificationDetails.mobile || ""}`,
  };
  return dispatch(initialize(SEND_EMAIL_FORM, values));
};

export const disposeRequest = createAsyncAction(
  deliveriesApi.disposeRequest,
  ActionTypes.DISPOSE_REQUEST
);

export const fetchRoute = createAsyncAction(
  locationApi.getRoute,
  ActionTypes.GET_ROUTE
);

export const fetchDriver = createAsyncAction(
  locationApi.getDriver,
  ActionTypes.GET_DRIVER
);

export const fetchDepot = createAsyncAction(
  locationApi.getDepot,
  ActionTypes.GET_DEPOT
);

export const fetchPickupLocation = createAsyncAction(
  pickupsApi.getPickupLocationByCode,
  ActionTypes.GET_PICKUP_LOCATION
);
