import PropTypes from "prop-types";

export default function Car({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <mask
        id="mask0"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <path d="M0 0L16 0V16H0L0 0Z" fill={fill} />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8889 5.16319e-05C13.4567 -0.00503586 13.9644 0.366275 14.1511 0.923125L16 6.46157V13.8462C16 14.356 15.602 14.7692 15.1111 14.7692H14.2222C13.7313 14.7692 13.3333 14.356 13.3333 13.8462V12.9231H2.66667V13.8462C2.66667 14.356 2.2687 14.7692 1.77778 14.7692H0.888889C0.397969 14.7692 0 14.356 0 13.8462V6.46157L1.84889 0.923125C2.03786 0.368056 2.54418 -0.00221795 3.11111 5.16319e-05H12.8889ZM1.84615 8.61539C1.84615 9.29512 2.39719 9.84615 3.07692 9.84615C3.75666 9.84615 4.30769 9.29512 4.30769 8.61539C4.30769 7.93565 3.75666 7.38462 3.07692 7.38462C2.39719 7.38462 1.84615 7.93565 1.84615 8.61539ZM12.9231 9.84615C12.2433 9.84615 11.6923 9.29512 11.6923 8.61539C11.6923 7.93565 12.2433 7.38462 12.9231 7.38462C13.6028 7.38462 14.1538 7.93565 14.1538 8.61539C14.1538 9.29512 13.6028 9.84615 12.9231 9.84615ZM3.16483 1.23077L1.84615 4.92308H14.1538L12.8352 1.23077H3.16483Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

Car.propTypes = {
  fill: PropTypes.string,
};

Car.defaultProps = {
  fill: "#000000",
};
