import PropTypes from "prop-types";

export default function ChangeAddress({ fill }) {
  return (
    <svg
      id="Change-address-1"
      xmlns="http://www.w3.org/2000/svg"
      width="284"
      height="284"
      viewBox="0 0 284 284"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.421 107.11C125.235 107.924 126.304 108.329 127.369 108.329C128.438 108.329 129.512 107.92 130.326 107.101L175.324 61.9454L196.068 82.7025C197.701 84.3313 200.341 84.3313 201.974 82.7025C203.607 81.0695 203.607 78.4258 201.974 76.7928L175.315 50.1176L124.412 101.2C122.784 102.837 122.788 105.481 124.421 107.11ZM193.328 112.35C193.591 112.401 193.859 112.426 194.126 112.426C194.405 112.426 194.684 112.399 194.958 112.344C195.735 112.187 196.477 111.809 197.079 111.207L230.562 77.7032C232.195 76.0702 232.195 73.4265 230.562 71.7935C228.929 70.1647 226.289 70.1605 224.656 71.7935L194.128 102.341L180.39 88.5955C178.757 86.9667 176.117 86.9667 174.484 88.5955C172.851 90.2285 172.851 92.8722 174.484 94.5052L191.063 111.092C191.099 111.131 191.135 111.169 191.173 111.207C191.784 111.815 192.538 112.197 193.328 112.35ZM233.886 200.918H162.807C160.497 200.918 158.63 199.051 158.63 196.741C158.63 194.431 160.497 192.565 162.807 192.565H225.533V134.094C225.533 131.789 227.4 129.918 229.709 129.918C232.019 129.918 233.886 131.789 233.886 134.094V200.918ZM246.411 131.35C245.342 131.35 244.273 130.941 243.458 130.126L219.661 106.312C218.028 104.679 218.028 102.035 219.661 100.402C221.294 98.7735 223.933 98.7735 225.566 100.402L249.364 124.217C250.997 125.85 250.997 128.493 249.364 130.126C248.55 130.941 247.48 131.35 246.411 131.35Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6391 159.378C35.4535 160.193 36.5227 160.598 37.5877 160.598C38.661 160.598 39.7344 160.193 40.5488 159.37L108.496 91.1807L131.349 114.051C132.982 115.68 135.626 115.68 137.259 114.051C138.888 112.418 138.888 109.774 137.259 108.141L108.487 79.3529L34.6308 153.473C33.0019 155.11 33.0061 157.749 34.6391 159.378ZM143.152 125.854L156.89 139.602L143.152 153.348C141.519 154.98 141.519 157.624 143.152 159.253C143.967 160.067 145.036 160.477 146.105 160.477C147.174 160.477 148.243 160.067 149.058 159.253L162.796 145.511L176.635 159.357C177.449 160.172 178.519 160.581 179.588 160.581C180.657 160.581 181.726 160.176 182.541 159.353C184.174 157.72 184.174 155.081 182.541 153.448L168.702 139.602L182.541 125.754C184.174 124.121 184.174 121.477 182.541 119.844C180.908 118.215 178.268 118.211 176.635 119.844L162.794 133.695L149.058 119.944C147.425 118.315 144.785 118.315 143.152 119.944C141.523 121.577 141.523 124.221 143.152 125.854ZM125.21 213.334C125.21 215.644 127.081 217.511 129.387 217.511C131.692 217.511 133.563 215.644 133.563 213.334V159.04H83.4454V213.334C83.4454 215.644 85.3165 217.511 87.6219 217.511C89.9273 217.511 91.7983 215.644 91.7983 213.334V167.393H125.21V213.334ZM167.063 234.329H50.1213V163.329C50.1213 161.02 51.9924 159.153 54.2978 159.153C56.6032 159.153 58.4743 161.02 58.4743 163.329V225.976H158.71V163.329C158.71 161.02 160.576 159.153 162.886 159.153C165.196 159.153 167.063 161.02 167.063 163.329V234.329Z"
        fill="#414042"
      />
    </svg>
  );
}

ChangeAddress.propTypes = {
  fill: PropTypes.string,
};
