import { useMemo, useRef } from "react";

import PropTypes from "prop-types";
import { useFlexLayout } from "react-table";

import {
  Button,
  Paginator,
  DndTable as Table,
  DndPaginator as TablePaginator,
} from "@dpdgroupuk/mydpd-ui";
import { PARCEL_ACTION_CODE } from "@dpdgroupuk/redback-enums";

import CardWithTitle from "../../../components/CardWithTitle";
import * as M from "../../../constants/strings";
import { formatFullAddressString } from "../../../models/address";
import { getReferencesString } from "../../../models/parcel";
import { formatDDMMYYYY } from "../../../utils/date";
import { getExportCSVLink } from "../../../utils/query";
import styles from "./DeliveryList.module.scss";
import { getValue } from "../../../utils/object";
import { PARCEL_DASHBOARD_TYPES } from "../../../constants/dashboard";

const renderStatus = ({ deliveryType, trackingStatusCurrent, rfiReason }) => (
  <span
    dangerouslySetInnerHTML={{
      __html:
        // @see: https://geopost.jira.com/browse/CT-1972
        deliveryType === PARCEL_DASHBOARD_TYPES.INSTRUCTIONS_REQUIRED
          ? getValue(rfiReason, "rfiReasonDescription", "")
          : trackingStatusCurrent,
    }}
  />
);

export const columns = (deliveryType = "") => [
  {
    Header: "Collection",
    Cell: ({
      row: {
        original: { collectionDate },
      },
    }) => formatDDMMYYYY(collectionDate),
    width: 100,
  },
  {
    Header: "Customer Ref",
    Cell: ({ row: { original } }) => getReferencesString(original),
    width: 125,
  },
  {
    Header: "Account",
    Cell: ({
      row: {
        original: {
          shipperDetails: { accountNumber },
        },
      },
    }) => accountNumber,
    width: 90,
  },
  {
    Header: "Parcel No",
    accessor: "parcelNumber",
    width: 138,
  },
  {
    Header: "Service",
    Cell: ({
      row: {
        original: {
          service: { serviceDescription },
        },
      },
    }) => serviceDescription,
    width: 111,
  },
  {
    Header: "Status",
    Cell: ({
      row: {
        original: { trackingStatusCurrent, rfiReason },
      },
    }) => renderStatus({ deliveryType, trackingStatusCurrent, rfiReason }),
    width: 150,
  },
  {
    Header: "Consignment",
    accessor: "consignmentNumber",
    width: 110,
  },
  {
    Header: "Address",
    Cell: ({
      row: {
        original: {
          deliveryDetails: { address },
        },
      },
    }) => formatFullAddressString(address),
    width: 150,
  },
  {
    Header: "Delivery depot",
    Cell: ({
      row: {
        original: {
          deliveryDepot: { depotName },
        },
      },
    }) => depotName,
    width: 120,
  },
  {
    Header: "Delivery/ Estimate",
    Cell: ({
      row: {
        original: {
          deliveryDetails: {
            podDetails: { podDate },
          },
          estimatedDeliveryDate,
        },
      },
    }) =>
      podDate ? formatDDMMYYYY(podDate) : formatDDMMYYYY(estimatedDeliveryDate),
    width: 100,
  },
  {
    Header: "Last Inst.",
    Cell: ({
      row: {
        original: { estimatedDeliveryActionName, estimatedDeliveryActionCode },
      },
    }) =>
      estimatedDeliveryActionCode &&
      estimatedDeliveryActionCode.includes(PARCEL_ACTION_CODE.SFP)
        ? M.SAFE_PLACE
        : estimatedDeliveryActionName,
    width: 100,
  },
];

const DeliveriesList = ({
  parcels,
  page,
  totalCount,
  pageSize,
  onPrevious,
  onNext,
  onFirst,
  onLast,
  onClickRow,
  findByCode,
  onExportPress,
  showExtract,
  deliveryType,
}) => {
  // eslint-disable-next-line
  const memoColumns = useMemo(() => columns(deliveryType), [deliveryType]);

  const tableRef = useRef(null);

  const actionButtons = useMemo(
    () => (
      <Button.ButtonGroup className={styles.extraButtons}>
        <Button href={getExportCSVLink(findByCode)} onClick={onExportPress}>
          {M.EXTRACT}
        </Button>
      </Button.ButtonGroup>
    ),
    [findByCode, onExportPress]
  );

  return (
    <CardWithTitle title={M.RESULTS}>
      <div className={styles.table}>
        <Table
          tableRef={tableRef}
          data={parcels}
          columns={memoColumns}
          onClickRow={onClickRow}
          tableHooks={[useFlexLayout]}
          classes={{ container: styles.tableContent }}
        >
          <TablePaginator
            tableRef={tableRef}
            page={page}
            totalCount={totalCount}
            pageSize={pageSize}
            onPrevious={onPrevious}
            onNext={onNext}
            onFirst={onFirst}
            onLast={onLast}
          />
          {showExtract && actionButtons}
        </Table>
      </div>
    </CardWithTitle>
  );
};

DeliveriesList.defaultProps = {
  deliveryType: "",
};

export const propTypes = {
  parcels: PropTypes.arrayOf(PropTypes.object),
  onClickRow: PropTypes.func,
  showExtract: PropTypes.bool,
  deliveryType: PropTypes.string,
  // eslint-disable-next-line react/forbid-foreign-prop-types
  ...Paginator.propTypes,
};

DeliveriesList.propTypes = propTypes;

export default DeliveriesList;
