import PropTypes from "prop-types";

import { FallBackImage } from "@dpdgroupuk/mydpd-ui";
import { withTrack } from "@dpdgroupuk/react-event-tracker";

import map from "../../../../assets/images/map.png";
import { MAP_VIEW } from "../../../../constants/analytics";
import styles from "./MapView.module.scss";

const MapView = ({ src }) => (
  <FallBackImage className={styles.image} src={src} fallBackSrc={map} fluid />
);

MapView.propTypes = {
  src: PropTypes.string.isRequired,
};

export default withTrack(MAP_VIEW.LOAD)(MapView);
