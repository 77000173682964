import { get, mapValues } from "lodash";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { Fields, PARCEL_EDIT_FORM } from "../../constants/forms";
import { PARCEL_CODE } from "../../models/validators/regex";
import { getValue } from "../../utils/object";
import { formatDDMMYYYY } from "../../utils/date";
import { formatAddresses } from "./view/ReturnToSender/model";

export const getEditDelivery = state => state.ui.editDelivery;

const emptyArray = [];

export const getActionDates = createSelector(getEditDelivery, data =>
  getValue(data, "dates", emptyArray)
);

export const getCardDates = createSelector(getActionDates, dates =>
  dates.map(date => formatDDMMYYYY(date))
);

export const getDepot = createSelector(getEditDelivery, ({ depot }) => depot);

export const getNeighbourAddress = createSelector(
  getEditDelivery,
  ({ addresses }) => addresses
);

export const getParcelFormState = state =>
  state && state.form && state.form[PARCEL_EDIT_FORM];

export const getParcelFormStateValues = createSelector(
  getParcelFormState,
  state => state && state.values
);

export const getFoucusedParcelFormField = createSelector(
  getParcelFormState,
  state => state && state.active
);

export const getReturnAddresses = createSelector(getEditDelivery, data =>
  formatAddresses(getValue(data, "addresses", emptyArray))
);

export const getReturnKeyValueAddresses = createSelector(
  getReturnAddresses,
  addresses =>
    addresses.map((address, value) => ({
      label: address.deliveryAddressDescription,
      value,
    }))
);

export const getUpgradeDeliveryTimes = createSelector(
  getEditDelivery,
  ({ services }) => services
);

export const getAlternativeTimes = createSelector(
  getEditDelivery,
  ({ alternativeTimes }) =>
    alternativeTimes.map(value => ({
      label: value.upgradeDescription,
      value: value.upgradeId,
    }))
);

export const getPickupShops = createSelector(
  getEditDelivery,
  ({ shops }) => shops
);

export const getSelectedPickupShop = createSelector(
  getEditDelivery,
  ({ selectedPickupShop }) => selectedPickupShop
);

export const getParcelFormValues = getFormValues(PARCEL_EDIT_FORM);

export const getRelatedParcelValues = createSelector(
  getFormValues(PARCEL_EDIT_FORM),
  (values = null) => {
    const parcelCodes = {};
    if (values !== null) {
      mapValues(values, (value, key) => {
        if (PARCEL_CODE.test(key)) {
          parcelCodes[key] = value;
        }
      });
    }
    return parcelCodes;
  }
);

export const getIsMobileEmailRequired = createSelector(
  getParcelFormValues,
  values => {
    const mobile = get(values, Fields.PHONE, "");
    const email = get(values, Fields.EMAIL, "");
    return !mobile && !email;
  }
);

export const isDeliveryDateDisabled = createSelector(
  getParcelFormStateValues,
  formValues => formValues && !(formValues.street && formValues.city)
);
