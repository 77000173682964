import { useMemo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { Tile, useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

import styles from "./SafePlaceOptions.module.scss";

const Option = ({ input, value, SvgIcon, title, meta, ...inputProps }) => {
  const theme = useMyDpdTheme();
  const icon = useMemo(() => {
    if (SvgIcon) {
      return <SvgIcon fill={theme.palette.brand} />;
    }
    return null;
    // eslint-disable-next-line
  }, [theme, SvgIcon, input.checked]);

  const tileClasses = {
    container: classNames(
      styles.tileCard,
      input.checked && styles.tileCardActive,
      meta.error && meta.touched && styles.error
    ),
    footer: classNames(
      styles.tileFooter,
      input.checked && styles.tileFooterActive
    ),
  };

  return (
    <label className={styles.tileContainer} key={value} htmlFor={value}>
      <input id={value} className={styles.hidden} {...input} {...inputProps} />
      <Tile classes={tileClasses} key={title} title={title}>
        {icon}
      </Tile>
    </label>
  );
};

Option.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  value: PropTypes.string,
  src: PropTypes.string,
  SvgIcon: PropTypes.elementType,
  title: PropTypes.string,
};

export default Option;
